<!--div style="margin-left:20%;"><h2>Login</h2></div>
<br / -->
<div style="text-align: center;"> 

        <div class="imgcontainer"> 
            <img width="150px" src="assets/images/logo_eRestau.png"
                     alt="eRestau"> 
        </div> 
 
    <form [formGroup]="loginForm" (ngSubmit) ="login()">
            
        <br/>
        <!--trong style="font-size: 20px; color: green;">LOGIN</strong--><br/>
        <input #loginUsername type="text" formControlName="username" size="50" placeholder="USERNAME" style="width: 18em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/>
        <br />
        <br />

        <input type="password" formControlName="password" size="50" placeholder="PASSWORD" style="width: 18em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/>
        <br />
        <br />
        <!--mat-form-field style="margin-left: 180px;" appearance="outline"-->
           
             
        <input type="text" style="width: 18em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;" formControlName="org" placeholder="Select Your Organization" aria-label="Organization" matInput [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
        <!--/mat-form-field-->
        <br />
        <br />

        <div style="text-align: center; margin-top: 15px;">
                <button style="font-size: 24px;" type="submit">LOGIN</button>
                <!--input value="LOGIN" name="login" type="submit" style="width: 365px;" class="submit" /-->
        </div>
        <br/>
        <br/>
    </form>

</div>

 
<style> 
  
      
    form { 
        border: 5px solid #f1f1f1; 
    } 
    /*assign full width inputs*/ 
      
    input[type=text], 
    input[type=password] { 
        width: 30%; 
        padding: 12px 20px; 
        margin: 8px 0; 
        display: inline-block; 
        border: 1px solid #ccc; 
         
    } 
    /*set a style for the buttons*/ 
      
    button { 
        background-color: #4CAF50; 
        color: white; 
        padding: 14px 20px; 
        margin: 8px 0; 
        border: none; 
        cursor: pointer; 
        width: 35%; 
    } 
 
   .imgcontainer { 
        text-align: center; 
        margin: 24px 0 12px 0; 
    } 
 
</style> 
 

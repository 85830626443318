<div style="text-align: center; font-weight: bold; font-size: 1.5em;">Print Barcode Label</div>
<br/><br/>
<mat-dialog-content>
<!--    <span style="padding-right: 4px; font-weight: bold; font-size: 2em; color: #000000;">Item Name:</span>-->
    <div style="font-weight: bold; font-size: 2em; color: darkblue; text-align: center;">{{menuItemService.LabelItemName}}</div>
    <br/><br/><br/>
    <div style="display: flex; justify-content: start; text-align: center; margin-left: 8px; font-weight: bold; font-size: 4em; color: #000000;">
        <div><input type="radio" [(ngModel)]="menuItemService.LabelSize" class="large-radio" id="large" name="size" value="large"></div>
        <div><span style="font-weight: bold; font-size: 3em; padding-left: 4px;">Large</span></div>
        <div style="padding-left: 100px;"><input type="radio" [(ngModel)]="menuItemService.LabelSize" class="large-radio" id="small" name="size" value="small"></div>
        <div><span style="font-weight: bold; font-size: 3em; padding-left: 4px;">Small</span></div>
    </div>
    <div style="display: flex; justify-content: start; text-align: center;">
        <div style="padding-top: 35px;">
            <span style="font-size: 3em; font-weight: bold;">Quantity:</span> <input #quantityInput size="5" type="text" [(ngModel)]="menuItemService.LabelQuantity" style="font-size: 2em; font-weight: bold;"/>
        </div>
        <div style="padding-top: 35px;" *ngIf="menuItemService.WeightedItem">
            <span style="padding-left:40px; font-size: 3em; font-weight: bold;">Weight:</span> <input size="5" type="text" [(ngModel)]="menuItemService.CapturedWeight" style="font-size: 2em; font-weight: bold;"/>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="onDismiss()" mat-button><span class="cancel-button">Cancel</span></button>
    <button (click)="onConfirm()" mat-button><span class="print-button">Print</span></button>
</mat-dialog-actions>

import { User } from './User';
import { ShoppingCartItem } from './ShoppingCartItem';

export class PaymentRequest {
    organizationId: number;
    chairId: number;
    userId: string;
    paymentType: string;
    transactionType: string;
    transactionId: number;
    refNumber: string;
    receiptNumber: string;
    tenderedAmount: number;
    invoiceAmount: number;
    processAsDebit: boolean;
    splitPayment: boolean;
    paymentTerminalPresent: boolean;
    terminal: string;
    user: User;
    selectedShoppingCartItems: Array<ShoppingCartItem> = [];
    customerId: number;
    giftCard: boolean;
    taxIncluded: boolean;
}

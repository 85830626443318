<div class="page-frame">
	<div class="page-frame-body">
        <div class="menu-selection-list">
            <div class="menu-selection-col" *ngIf="!isRetail">
                <a routerLink="../restaurantorder">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon orange">
                            <span>
                                <i class="fa fa-shopping-cart"></i>
                            </span>
                        </div>
                        <label>Orders</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col" *ngIf="isRetail">
                <a routerLink="../retailorder">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon orange">
                            <span>
                                <i class="fa fa-shopping-cart"></i>
                            </span>
                        </div>
                        <label>Orders</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col">
                <a routerLink="../category">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <i class="fa fa-cubes"></i>
                            </span>
                        </div>
                        <label>Category</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col">
                <a routerLink="../menuItem">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <i *ngIf="!isRetail" class="fa fa-utensils"></i>
                                <i *ngIf="isRetail" class="fa fa-apple-alt"></i>
                            </span>
                        </div>
                        <label *ngIf="!isRetail">Menu Card</label>
                        <label *ngIf="isRetail">Products</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col">
                <a *ngIf="session.get('identity').role !== 'ROLE_MANAGER'" routerLink="/home">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <i class="fa fa-chart-bar"></i>
                            </span>
                        </div>
                        <label>Reports</label>
                    </div>
                </a>
                <a *ngIf="session.get('identity').role === 'ROLE_MANAGER'" routerLink="/reports">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <i class="fa fa-chart-bar"></i>
                            </span>
                        </div>
                        <label>Reports</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col">
                <a routerLink="../receipt">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <i class="fa fa-receipt"></i>
                            </span>
                        </div>
                        <label>Receipts</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col" *ngIf="!isRetail">
                <a [routerLink]="['../table', {page: 'default'}]">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <img src="/assets/images/table-icon.png" />
                            </span>
                        </div>
                        <label>Tables</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col" *ngIf="!isRetail">
                <a routerLink="../submenuitems">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <i class="fa fa-cookie-bite"></i>
                            </span>
                        </div>
                        <label>Side</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col">
                <a routerLink="../user">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <i class="fa fa-user-friends"></i>
                            </span>
                        </div>
                        <label>Users</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col">
                <a routerLink="../payments">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon green">
                            <span>
                                <i class="fa fa-money-check-alt"></i>
                            </span>
                        </div>
                        <label>Payments</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col">
                <a routerLink="../settings">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <i class="fa fa-cogs"></i>
                            </span>
                        </div>
                        <label>Settings</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col">
                <a href="javascript:;" routerLink="/onlineorders">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <i class="fa fa-globe"></i>
                            </span>
                        </div>
                        <label>Online Orders</label>
                    </div>
                </a>
            </div>
            <div class="menu-selection-col">
                <a href="javascript:;" routerLink="../paymentresponselog">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <img width="60%" src="/assets/images/credit-card.png" />
                            </span>
                        </div>
                        <label>Verify</label>
                    </div>
                </a>
            </div>
            
            
            
            <div class="menu-selection-col" *ngIf="!isRetail">
                <a routerLink="/home">
                    <div class="menu-selection-block">
                        <div class="menu-selection-icon">
                            <span>
                                <i class="fa fa-digital-tachograph"></i>
                            </span>
                        </div>
                        <label>Digital menu</label>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

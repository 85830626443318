import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { Constants } from '../models/Constants';
import { HttpClient } from '@angular/common/http';
import { Property } from '../models/Property';
import {ClientEnvironmentProperty} from '../models/ClientEnvironmentProperty';
import {PrinterManagement} from '../models/PrinterManagement';
import {UserTerminal} from '../models/UserTerminal';
import {MenuItem} from '../models/MenuItem';
import {switchMap} from 'rxjs/operators';
import {LocalStorageService} from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private selectedTerminal: string;

  constructor(private httpClient: HttpClient, private storage: LocalStorageService) { }

  getPropertyTypes(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(Constants.ERESTAU_DOMAIN + '/api/properties/types');
  }

  getProperties(organizationId: number): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(Constants.ERESTAU_DOMAIN + '/api/properties/organization/' + `${organizationId}`);
  }

  saveSetting(property: Property): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/properties', property);
  }

  deleteSetting(propertyId: number): Observable<any> {
    return this.httpClient.delete<any>(Constants.ERESTAU_DOMAIN + '/api/properties/' + `${propertyId}`);
  }

  saveUserPrinter(selectedPrinterInfo: any): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/printermanagement', selectedPrinterInfo);
  }

  getUserPrinters(organizationId: number, userId: number): Observable<Array<PrinterManagement>> {
    return this.httpClient.get<Array<PrinterManagement>>(Constants.ERESTAU_DOMAIN + `/api/printermanagement/organization/${organizationId}/user/${userId}`);
  }

  getUserTerminal(id: number): Observable<UserTerminal> {
    return this.httpClient.get<UserTerminal>(Constants.ERESTAU_DOMAIN + `/api/userterminal/${id}`);
  }

  getUserTerminalByUser(userId: number): Observable<UserTerminal> {
    return this.httpClient.get<UserTerminal>(Constants.ERESTAU_DOMAIN + `/api/userterminal/user/${userId}`);
  }

  getUserTerminals(organizationId: number): Observable<Array<UserTerminal>> {
    return this.httpClient.get<Array<UserTerminal>>(Constants.ERESTAU_DOMAIN + `/api/userterminal/organization/${organizationId}`);
  }

  saveUserTerminal(userTerminal: UserTerminal): Observable<UserTerminal> {
    return this.httpClient.post<UserTerminal>(Constants.ERESTAU_DOMAIN + '/api/userterminal', userTerminal);
  }

  deleteUserTerminal(id: number): Observable<any> {
    return this.httpClient.delete<any>(Constants.ERESTAU_DOMAIN + '/api/userterminal/' + `${id}`);
  }

  getDeliveryInfo(organizationId: number): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/merchant/' + `${organizationId}` + '/deliveryinfo');
  }

  saveMerchntInfo(merchantInfo: any): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/merchant', merchantInfo);
  }

  getAllClientEnvironmentProperties(organizationId: number): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/clientenvironmentproperties/organization/' + `${organizationId}`);
  }

  getClientEnvironmentProperties(organizationId: number, terminal: string): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/clientenvironmentproperties/organization/' + `${organizationId}` + '/terminal/' + `${terminal}`);
  }

  saveClientEnvironmentProperties(clientEnvironmentProperties: Array<ClientEnvironmentProperty>): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/clientenvironmentproperties', clientEnvironmentProperties);
  }

  restartClientService(organizationId: number): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/clientenvironmentproperties/restartclientservice/' + `${organizationId}`, null);
  }

  initPrintReceiptPropertyInLocalStorage(organizationId: number, propertyType: string) {
    return this.httpClient.get<Property>(Constants.ERESTAU_DOMAIN + `/api/properties/${propertyType}/${organizationId}`)
        .pipe(
            switchMap(property => {
              this.storage.set('PRINT_RECEIPT_PROPERTY', JSON.stringify(property));
              return of(property);
            })
        );
  }

  initDeleteReceiptPropertyInLocalStorage(organizationId: number, propertyType: string) {
    return this.httpClient.get<Property>(Constants.ERESTAU_DOMAIN + `/api/properties/${propertyType}/${organizationId}`)
        .pipe(
            switchMap(property => {
              this.storage.set('DELETE_RECEIPT_PROPERTY', JSON.stringify(property));
              return of(property);
            })
        );
  }

  set SelectedTerminal(selectedTerminal: string) {
    this.selectedTerminal = selectedTerminal;
  }

  get SelectedTerminal() {
    return this.selectedTerminal;
  }
}

<div class="page-frame">
    <!-- Report Header start Here -->
    <div class="page-frame-header">
        <div class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="page-logo">
                    <i class="fas fa-chart-bar"></i>
                    Sales Reports
                </div>
                <div class="page-buttons">
                    <button (click)="reportMenu()" class="btn btn-60 btn-icon-text btn-white">
                        <i class="fas fa-chart-bar"></i> <span>Reports</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Report Body Start Here -->
    <div class="page-frame-body">
        <div class="full-height-container pad-20">
            <div *ngIf="toggleService.ShowReportMenu" class="report-container">
                <div class="row space-10 full-height">
                    <!-- Report Left Col Start Here -->
                    <div class="col-md-4">
                        <div class="shadow-radius-container radius-5 mar-b-10">
                            <div class="report-sm-block">
                                <span class="report-icon bg-light-blue"><i class="fas fa-file-invoice-dollar"></i></span>
                                <div class="report-content">
                                    <h4>Daily Revenue</h4>
                                    <h2 class="text-light-blue"></h2>
                                </div>
                                <span (click)="getDailyRevenue()" class="report-view-icon">
                  <i class="fas fa-chevron-circle-right"></i>
                </span>
                            </div>
                        </div>
                        <div class="shadow-radius-container radius-5 mar-b-10">
                            <div class="report-sm-block">
                                <span class="report-icon bg-green"><i class="fas fa-file-invoice-dollar"></i></span>
                                <div class="report-content">
                                    <h4>Sales By Intervals</h4>
                                    <h2 class="text-green"></h2>
                                </div>
                                <span (click)="getOrderItemSummary()" class="report-view-icon">
                  <i class="fas fa-chevron-circle-right"></i>
                </span>
                            </div>
                        </div>
                        <div class="shadow-radius-container radius-5 mar-b-10">
                            <div class="report-sm-block">
                                <span class="report-icon bg-orange"><i class="fas fa-file-invoice-dollar"></i></span>
                                <div class="report-content">
                                    <h4>Sales By Tax Rate</h4>
                                    <h2 class="text-orange"></h2>
                                </div>
                                <span (click)="getTaxRateData()" class="report-view-icon">
                  <i class="fas fa-chevron-circle-right"></i>
                </span>
                            </div>
                        </div>
                        <div class="shadow-radius-container radius-5 mar-b-10">
                            <div class="report-sm-block">
                                <span class="report-icon bg-purple"><i class="fas fa-file-invoice-dollar"></i></span>
                                <div class="report-content">
                                    <h4>Sales By Category</h4>
                                    <h2 class="text-purple"></h2>
                                </div>
                                <span (click)="getSalesByCategoryReport()" class="report-view-icon">
                        <i class="fas fa-chevron-circle-right"></i>
                      </span>
                            </div>
                        </div>
                    </div>
                    <!-- Report Right Col Start Here -->
                    <div class="col-md-8">
                        <div *ngIf="showMatLoader" class="shadow-radius-container radius-5 full-height-container">
                            <mat-spinner></mat-spinner>
                        </div>
                        <div *ngIf="showInventoryTable && !showMatLoader" class="shadow-radius-container radius-5 full-height-container">
                            <div class="inventory-cost-container">
                                <div class="inventory-cost-header">
                                    <h2>Inventory Details</h2>
                                    <span (click)="closeInventoryView()" class="close-inventory">
                    <i class="far fa-times-circle"></i>
                  </span>
                                </div>
                                <div class="inventory-cost-body">
                                    <ag-grid-angular
                                            (gridReady)="onGridReady($event)"
                                            [columnDefs]="columnDefsInventory"
                                            [gridOptions]="gridOptions"
                                            [headerHeight]="'40'"
                                            [rowClass]="'product-table-row'"
                                            [rowData]="inventoryData"
                                            [rowHeight]="'60'"
                                            [rowSelection]="rowSelection"
                                            class="ag-theme-alpine product-table">
                                    </ag-grid-angular>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!showInventoryTable && !showMatLoader" class="shadow-radius-container radius-5 full-height-container">
                            <div class="report-tab">
                                <ul>
                                    <li (click)="getRevenueBarchartData()" class="active">Sales in Amount</li>
                                    <li class="not-clicable">Sales in Quantity</li>
                                    <li class="not-clicable">Sales in Quantity By Menu</li>
                                </ul>
                            </div>
                            <div *ngIf="toggleService.ShowRevenueBarchart" class="report-form">
                                <form #receiptSearchForm="ngForm">
                                    <div class="row space-10">
                                        <div class="col-md-3">
                                            <div class="mat-datepicker">
                                                <mat-form-field appearance="outline">
                                                    <input [(ngModel)]="reportStartDate" [matDatepicker]="startDatePicker" [ngModelOptions]="{standalone: true}"
                                                           matInput placeholder="Start Date">
                                                    <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
                                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="mat-datepicker">
                                                <mat-form-field appearance="outline">
                                                    <input [(ngModel)]="reportEndDate" [matDatepicker]="endDatePicker" [ngModelOptions]="{standalone: true}"
                                                           matInput placeholder="End Date">
                                                    <mat-datepicker-toggle [for]="endDatePicker" matSuffix></mat-datepicker-toggle>
                                                    <mat-datepicker #endDatePicker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <button (click)="getRevenueBarchartData()" class="btn btn-white btn-40">Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div *ngIf="toggleService.ShowRevenueBarchart" class="chart-container">
                                <div *ngIf="toggleService.ShowRevenueBarchart && dataSet.length > 0" style="display: block">
                                    <canvas (chartClick)="chartClicked($event)" (chartHover)="chartHovered($event)" [chartType]="chartType" [colors]="chartColors"
                                            [datasets]="chartDatasets" [labels]="chartLabels" [legend]="false" [options]="chartOptions"
                                            baseChart>
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="toggleService.ShowOrderItemSummary" class="shadow-radius-container grow-height">

                <!-- Form Start Here -->
                <div class="form-container">
                    <form #receiptSearchForm="ngForm" (ngSubmit)="getOrderItemSummary()">
                        <div class="summery-container">
                            <div class="summery-container-body pad-20">
                                <div class="row space-20">
                                    <div class="col-md-3">
                                        <div class="mat-datepicker">
                                            <mat-form-field appearance="outline">
                                                <input [(ngModel)]="reportStartDate" [matDatepicker]="startDatePicker" [ngModelOptions]="{standalone: true}"
                                                       matInput placeholder="START DATE">
                                                <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
                                                <mat-datepicker #startDatePicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mat-datepicker">
                                            <mat-form-field appearance="outline">
                                                <input [(ngModel)]="reportEndDate" [matDatepicker]="endDatePicker" [ngModelOptions]="{standalone: true}"
                                                       matInput placeholder="END DATE">
                                                <mat-datepicker-toggle [for]="endDatePicker" matSuffix></mat-datepicker-toggle>
                                                <mat-datepicker #endDatePicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <button class="btn btn-40 btn-white" type="submit">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- Form End Here -->
                <!-- Total Start Here -->
                <div class="receipt-total-section">
                    <div class="receipt-total">
                        <label>Sub Total:</label>
                        <span class="green-theme">{{orderTotal | currency}}</span>
                    </div>
                    <div class="receipt-total">
                        <label>Tax:</label>
                        <span class="green-theme">{{taxTotal | currency}}</span>
                    </div>
                    <div class="receipt-total">
                        <label>Total:</label>
                        <span class="green-theme">{{grandTotal | currency}}</span>
                    </div>
                    <div class="receipt-total">
                        <span (click)="printRevenueReport()"><i class="fa fa-print"></i></span>
                    </div>
                </div>
                <!-- Total End Here -->
                <!-- List Start Here -->
                <div *ngIf="loadSummeryTable" class="ag-table-grid">
                    <ag-grid-angular
                            (gridReady)="onGridReady($event)"
                            [columnDefs]="columnDefs"
                            [headerHeight]="'40'"
                            [rowClass]="'ag-custom-table-row'"
                            [rowData]="rowData"
                            [rowHeight]="'60'"
                            [rowSelection]="rowSelection"
                            class="ag-theme-alpine user-table">
                    </ag-grid-angular>
                </div>
                <!-- List End Here -->
            </div>
            <div *ngIf="toggleService.ShowDailyRevenueReport" class="shadow-radius-container grow-height">
                <div class="sales-summery">
                    <div class="sales-summery-header">
                        <label>{{currentDate | date:'fullDate'}}</label>
                        <span (click)="printDailyRevenueReport()"><i class="fa fa-print"></i></span>
                    </div>
                    <div class="sales-summery-body">
                        <div class="row space-0">
                            <div class="col-md-4" style="border-right:2px solid #cdcdcd;">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row">
                                                <div class="col-md-8 text-start">
                                                    <h5 class="pad-t-20 pad-b-10">Cash</h5>
                                                </div>
                                                <div class="col text-end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                        <!-- <div class="dot-table-row">
                                            <div class="row align-items-center">
                                                <div class="col-md-8 text-start">
                                                    <span>Net Amount:</span>
                                                </div>
                                                <div class="col text-end">
                                                    <label>{{dailyRevenue.netCashAmount | currency}}</label>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="dot-table-row">
                                            <div class="row align-items-center">
                                                <div class="col-md-8 text-start">
                                                    <span>Tax Amount:</span>
                                                </div>
                                                <div class="col text-end">
                                                    <label>{{dailyRevenue.taxCashAmount | currency}}</label>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="dot-table-footer">
                                        <div class="dot-table-row no-border">
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <label>Total Amount:</label>
                                                </div>
                                                <div class="col pad-t-10 pad-b-10 d-flex justify-content-end">
                                                    <h5 class="green-theme align-self-center">{{dailyRevenue.totalCashAmount | currency}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="border-right:2px solid #cdcdcd;">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row">
                                                <div class="col-md-8 text-start">
                                                    <h5 class="pad-t-20 pad-b-10">Card</h5>
                                                </div>
                                                <div class="col text-end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                        <!-- <div class="dot-table-row">
                                            <div class="row align-items-center">
                                                <div class="col-md-8 text-start">
                                                    <span>Net Amount</span>
                                                </div>
                                                <div class="col text-end">
                                                    <label>{{dailyRevenue.netCardAmount | currency}}</label>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="dot-table-row">
                                            <div class="row align-items-center">
                                                <div class="col-md-8 text-start">
                                                    <span>Tax Amount</span>
                                                </div>
                                                <div class="col text-end">
                                                    <label>{{dailyRevenue.taxCardAmount | currency}}</label>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="dot-table-footer">
                                        <div class="dot-table-row no-border">
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <label>Total Amount:</label>
                                                </div>
                                                <div class="col pad-t-10 pad-b-10 d-flex justify-content-end">
                                                    <h5 class="green-theme align-self-center">{{dailyRevenue.totalCardAmount | currency}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row">
                                                <div class="col-md-8 text-start">
                                                    <h5 class="pad-t-20 pad-b-10">EBT</h5>
                                                </div>
                                                <div class="col text-end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                        <!-- <div class="dot-table-row">
                                            <div class="row align-items-center">
                                                <div class="col-md-8 text-start">
                                                    <span>Net Amount</span>
                                                </div>
                                                <div class="col text-end">
                                                    <label>{{dailyRevenue.netEbtAmount | currency}}</label>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="dot-table-row">
                                            <div class="row align-items-center">
                                                <div class="col-md-8 text-start">
                                                    <span>Tax Amount</span>
                                                </div>
                                                <div class="col text-end">
                                                    <label>{{dailyRevenue.taxEbtAmount | currency}}</label>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="dot-table-footer">
                                        <div class="dot-table-row no-border">
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <label>Total Amount:</label>
                                                </div>
                                                <div class="col pad-t-10 pad-b-10 d-flex justify-content-end">
                                                    <h5 class="green-theme align-self-center">{{dailyRevenue.totalEbtAmount | currency}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="total-section">
                                    <span>Net Total: <b>{{dailyRevenue.netTotalAmount | currency}}</b></span>
                                    <span>Net Tax: <b>{{dailyRevenue.taxTotalAmount | currency}}</b></span>
                                    <h4>Sum: <b class="green-theme">{{dailyRevenue.totalAmount | currency}}</b></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="toggleService.ShowTaxRateReport" class="shadow-radius-container grow-height">
                <div class="sales-summery">
                    <!-- Form Start Here -->
                    <div class="form-container">
                        <form #taxRateSearchForm="ngForm" (ngSubmit)="getTaxRateRecords()">
                            <div class="summery-container">
                                <div class="summery-container-body pad-20">
                                    <div class="row space-20">
                                        <div class="col-md-3">
                                            <input [(ngModel)]="taxRateField" class="form-control" name="taxRateField" placeholder="Tax Rate" type="number"/>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="mat-datepicker">
                                                <mat-form-field appearance="outline">
                                                    <input [(ngModel)]="reportStartDate" [matDatepicker]="startDatePicker" [ngModelOptions]="{standalone: true}"
                                                           matInput placeholder="START DATE">
                                                    <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
                                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="mat-datepicker">
                                                <mat-form-field appearance="outline">
                                                    <input [(ngModel)]="reportEndDate" [matDatepicker]="endDatePicker" [ngModelOptions]="{standalone: true}"
                                                           matInput placeholder="END DATE">
                                                    <mat-datepicker-toggle [for]="endDatePicker" matSuffix></mat-datepicker-toggle>
                                                    <mat-datepicker #endDatePicker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <button class="btn btn-40 btn-white" type="submit">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Form End Here -->
                    <div *ngIf="taxRateData" class="sales-summery-header">
                        <label>{{reportStartDate | date:'fullDate'}} to {{reportEndDate | date:'fullDate'}}</label>
                        <span (click)="printTaxRateReport()"><i class="fa fa-print"></i></span>
                    </div>
                    <div *ngIf="taxRateData" class="sales-summery-body">
                        <div class="row space-0">
                            <div class="col-md-3" style="border-right:2px solid #cdcdcd;">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row">
                                                <div class="col-md-8 text-start">
                                                    <h5 class="pad-t-20 pad-b-10">Total</h5>
                                                </div>
                                                <div class="col text-end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">

                                    </div>
                                    <div class="dot-table-footer">
                                        <div class="dot-table-row no-border">
                                            <div class="row align-items-center">

                                                <div class="col pad-t-10 pad-b-10 d-flex">
                                                    <h5 class="green-theme align-self-center">{{taxRateData.total | currency}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3" style="border-right:2px solid #cdcdcd;">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row">
                                                <div class="col-md-8 text-start">
                                                    <h5 class="pad-t-20 pad-b-10">Sub Total</h5>
                                                </div>
                                                <div class="col text-end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                    </div>
                                    <div class="dot-table-footer">
                                        <div class="dot-table-row no-border">
                                            <div class="row align-items-center">
                                                <div class="col pad-t-10 pad-b-10 d-flex">
                                                    <h5 class="green-theme align-self-center">{{taxRateData.subTotal | currency}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3" style="border-right:2px solid #cdcdcd;">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row">
                                                <div class="col-md-8 text-start">
                                                    <h5 class="pad-t-20 pad-b-10">Tax Rate</h5>
                                                </div>
                                                <div class="col text-end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                    </div>
                                    <div class="dot-table-footer">
                                        <div class="dot-table-row no-border">
                                            <div class="row align-items-center">
                                                <div class="col pad-t-10 pad-b-10 d-flex">
                                                    <h5 class="green-theme align-self-center">{{ taxRateData.taxRate === -999.0 ? 'All Rates' : taxRateData.taxRate }}{{ taxRateData.taxRate === -999.0 ? '' : '%' }}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row">
                                                <div class="col-md-8 text-start">
                                                    <h5 class="pad-t-20 pad-b-10">Total Tax</h5>
                                                </div>
                                                <div class="col text-end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                    </div>
                                    <div class="dot-table-footer">
                                        <div class="dot-table-row no-border">
                                            <div class="row align-items-center">
                                                <div class="col pad-t-10 pad-b-10 d-flex">
                                                    <h5 class="green-theme align-self-center">{{ taxRateData.taxTotal | currency }}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="total-section">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="toggleService.ShowCategoryReport" class="shadow-radius-container grow-height">
                <div class="sales-summery">
                    <!-- Form Start Here -->
                    <div class="form-container">
                        <form #categorySearchForm="ngForm" (ngSubmit)="getCategoryReportRecords()">
                            <div class="summery-container">
                                <div class="summery-container-body pad-20">
                                    <div class="row space-20">
                                        <div class="col-md-3">
                                            <input [(ngModel)]="categoryNameField" class="form-control" name="categoryNameField" placeholder="RESTAURANT" type="number" readonly/>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="mat-datepicker">
                                                <mat-form-field appearance="outline">
                                                    <input [(ngModel)]="reportStartDate" [matDatepicker]="startDatePicker" [ngModelOptions]="{standalone: true}"
                                                           matInput placeholder="START DATE">
                                                    <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
                                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="mat-datepicker">
                                                <mat-form-field appearance="outline">
                                                    <input [(ngModel)]="reportEndDate" [matDatepicker]="endDatePicker" [ngModelOptions]="{standalone: true}"
                                                           matInput placeholder="END DATE">
                                                    <mat-datepicker-toggle [for]="endDatePicker" matSuffix></mat-datepicker-toggle>
                                                    <mat-datepicker #endDatePicker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <button class="btn btn-40 btn-white" type="submit">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Form End Here -->
                    <div *ngIf="categoryReportData" class="sales-summery-header">
                        <label>{{reportStartDate | date:'fullDate'}} to {{reportEndDate | date:'fullDate'}}</label>
                        <span (click)="printCategoryReport()"><i class="fa fa-print"></i></span>
                    </div>
                    <div *ngIf="categoryReportData" class="sales-summery-body">
                        <div class="row space-0">
                            <div class="col-md-3" style="border-right:2px solid #cdcdcd;">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row">
                                                <div class="col-md-8 text-start">
                                                    <h5 class="pad-t-20 pad-b-10">Category</h5>
                                                </div>
                                                <div class="col text-end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">

                                    </div>
                                    <div class="dot-table-footer">
                                        <div class="dot-table-row no-border">
                                            <div class="row align-items-center">

                                                <div class="col pad-t-10 pad-b-10 d-flex">
                                                    <h5 class="green-theme align-self-center">{{categoryReportData.category}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3" style="border-right:2px solid #cdcdcd;">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row">
                                                <div class="col-md-8 text-start">
                                                    <h5 class="pad-t-20 pad-b-10">Sub Total</h5>
                                                </div>
                                                <div class="col text-end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                    </div>
                                    <div class="dot-table-footer">
                                        <div class="dot-table-row no-border">
                                            <div class="row align-items-center">
                                                <div class="col pad-t-10 pad-b-10 d-flex">
                                                    <h5 class="green-theme align-self-center">{{categoryReportData.subTotal | currency}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3" style="border-right:2px solid #cdcdcd;">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row">
                                                <div class="col-md-8 text-start">
                                                    <h5 class="pad-t-20 pad-b-10">Tax Total</h5>
                                                </div>
                                                <div class="col text-end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                    </div>
                                    <div class="dot-table-footer">
                                        <div class="dot-table-row no-border">
                                            <div class="row align-items-center">
                                                <div class="col pad-t-10 pad-b-10 d-flex">
                                                    <h5 class="green-theme align-self-center">{{ categoryReportData.taxTotal | currency }}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row">
                                                <div class="col-md-8 text-start">
                                                    <h5 class="pad-t-20 pad-b-10">Total</h5>
                                                </div>
                                                <div class="col text-end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                    </div>
                                    <div class="dot-table-footer">
                                        <div class="dot-table-row no-border">
                                            <div class="row align-items-center">
                                                <div class="col pad-t-10 pad-b-10 d-flex">
                                                    <h5 class="green-theme align-self-center">{{ categoryReportData.total | currency }}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="total-section">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MenuItemService} from '../../../../services/menu-item.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-receipt-dialog',
  templateUrl: './barcode-label-dialog.component.html',
  styleUrls: ['./barcode-label-dialog.component.css']
})
export class BarcodeLabelDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('quantityInput') quantityInput: ElementRef;

  constructor(public dialogRef: MatDialogRef<BarcodeLabelDialogComponent>, public menuItemService: MenuItemService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.setFocusOnQuantity();
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  setFocusOnQuantity() {
    setTimeout(() => {
      this.quantityInput.nativeElement.focus();
    }, 500);
  }
}

import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-keypad',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.css']
})
export class KeypadComponent implements OnInit, OnChanges {

  // Input/Output Properties
  @Input('bindKeyPad') bindKeyPad: boolean;
  @Input('bindKey') bindKey: string;
  @Input('inputValue') inputValue: string | number;
  @Input('specialKeys') specialKeys: boolean;
  @Input('isPrice') isPrice: boolean;
  @Input('isDotAllowed') isDotAllowed: boolean;
  @Output('outputValue') outputValue = new EventEmitter;

  // Local Properties
  public fieldVal: string;

  constructor() { }

  ngOnInit() { }

  // Track Change Event of the Input Value
  ngOnChanges() {
    if (this.bindKeyPad) {
      if (this.inputValue == 0) {
        this.fieldVal = ""; 
      } else {
        this.fieldVal = this.inputValue.toString();
        if(this.isPrice) {
          if(this.fieldVal.indexOf('.') != -1) {
            this.fieldVal = this.fieldVal.split('.').join("");
          }
        }
      }
    }
  }

  // Identify keys and functions
  pressKey(key, specialNumber?) {
    setTimeout(() => {
      if (this.bindKeyPad) {
        let returnObj = {};
        switch(key) {
          case 'back':
            returnObj['key'] = this.bindKey;
            returnObj['value'] = this.back();
            this.outputValue.emit(returnObj);
            break;
  
          case 'clear':
            returnObj['key'] = this.bindKey;
            returnObj['value'] = this.clear();
            this.outputValue.emit(returnObj);
            break;
  
          case '.':
            if(this.fieldVal.indexOf(".") == -1) {
              returnObj['key'] = this.bindKey;
              returnObj['value'] = this.addDot();
              this.outputValue.emit(returnObj);
            }
            break;
  
          default:
            returnObj['key'] = this.bindKey;
            returnObj['value'] = this.add(key, specialNumber);
            this.outputValue.emit(returnObj);
            break;
        }
      }
    },0);
  }

  // Add Key Press with Arguments
  add(value, specialNumber?) {
    if(this.fieldVal == "" && (value == '0' || value == '00')) {
      return "0";  
    }
    else {
      let formatedValue
      if(specialNumber && this.isPrice && this.isDotAllowed) {
        formatedValue = this.formatDecimal(value + '00');
      } else {
        formatedValue = this.formatDecimal(this.fieldVal + value);
      }
      return formatedValue;
    }
  }

  // Back Key Press
  back() {
    if(this.fieldVal.length > 0) {
      let formatedValue = this.formatDecimal(this.fieldVal.substring(0, (this.fieldVal.length - 1)));
      return formatedValue;
    }
    else {
      return "0";
    }
  }

  // Clear Key Press
  clear() {
    return "0";
  }

  // Add Dot Key Press
  addDot() {
    if (this.isPrice) {
      return this.formatDecimal(this.fieldVal);
    } else if(this.isDotAllowed) {
      if (this.fieldVal == "" || this.fieldVal == '0' || this.fieldVal == '00') {
        return "0."
      }
      else {
        return this.formatDecimal(this.fieldVal + '.');
      }
    } else {
      if (this.fieldVal == "" || this.fieldVal == '0' || this.fieldVal == '00') {
        return "0"
      }
      else {
        return this.formatDecimal(this.fieldVal);
      }
    }
  }

  // Formating Decimal by adding dots
  formatDecimal(value) {
    if (this.isPrice && this.isDotAllowed) {
      if(value.length <= 1) {
        return value.substr(0, (value.length - 2)) + '.0' + value.substr(value.length - 2);
      }
      else {
        return value.substr(0, (value.length - 2)) + '.' + value.substr(value.length - 2);
      }
    }
    else {
      return value;
    }
  }

}

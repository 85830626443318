import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { AuthenticationService } from './authentication.service';
import { Constants } from '../models/Constants';
import { JobRequest } from '../models/JobRequest';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { PaymentResponse } from 'src/app/models/PaymentResponse';
import { Router } from '@angular/router';
import { ShoppingCartServiceService } from './shopping-cart-service.service';
import { PaymentService } from './payment.service';
import { ToggleService } from './toggle.service';
import {LocalStorageService, SessionStorageService} from 'angular-web-storage';
import {Receipt} from '../models/Receipt';
import {ReceiptService} from './receipt.service';
import {User} from '../models/User';
import {OrderItem} from '../models/OrderItem';
import {OrderService} from './order.service';
import {BigDecimal} from "../models/BigDecimal";
import { SharedService } from 'src/app/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  ws: any;
  disabled: boolean;

  constructor(private authenticationService: AuthenticationService, private shoppingCartService: ShoppingCartServiceService,
              private session: SessionStorageService, private storage: LocalStorageService, private orderService: OrderService,
              private paymentService: PaymentService, private dialog: MatDialog, private router: Router,
              private toggleService: ToggleService, private receiptService: ReceiptService,
              private _shared: SharedService) { }

  connect(transactionType: string, terminal: string) {
    const sessionId = this.random(20);
    const stompClient = this.connectionHandshake(sessionId);
    stompClient.debug = null;

    stompClient.connect({'Authorization': 'Bearer ' + this.authenticationService.getToken()}, frame => {
      // Subscribe to notification topic
      stompClient.subscribe('/user/' + sessionId + '/messages', notification => {
        let response: any;
        response = JSON.parse(notification.body);
        // console.log('websocket response', response);
        
        let ack = 'SUCCESS';
        let approvedAmount = 0.00;
        let msg = response.resultTxt;
        if (response.message.toUpperCase().startsWith('PARTIALLY')) {
          ack = 'WARNING';
          approvedAmount = this.toBigDecimal(response.approvedAmount);
          const balance = BigDecimal.diff(this.paymentService.InvoiceAmount, approvedAmount);
          msg = msg + '\n\nBALANCE: $' + response.paymentSummary.balance;
        } else if (!response.authCode || response.authCode === null || response.resultTxt.startsWith('DECLINE')) {
          ack = 'ERROR';
        }

        // Set Payment Progress Off
        this._shared.setGlobalLoader(false);
        this._shared.setWebSocketLoader(false, ack);
        this.paymentService.setPaymentProgress(false, ack);

        if (Constants.PAYMENT_TYPE_CREDIT_CARD === transactionType) {
          this.shoppingCartService.CloseOrderRequest.paymentResponse = response;
          this.toggleService.ShowPaymentProgress = false;
          this.openDialog(ack, msg, stompClient);
        }
        
      });

      const registrationRequest = new JobRequest();
      registrationRequest.sessionId = sessionId;
      registrationRequest.organization = this.getOrganizationSessionKey();
      registrationRequest.terminal = terminal;
      stompClient.send('/app/register/transaction/notification', {}, JSON.stringify(registrationRequest));
    });
  }

  voidTransaction(receipt: Receipt) {
    const sessionId = this.random(20);
    const stompClient = this.connectionHandshake(sessionId);
    stompClient.debug = null;

    stompClient.connect({'Authorization': 'Bearer ' + this.authenticationService.getToken()}, frame => {
      // Subscribe to notification topic
      stompClient.subscribe('/user/' + sessionId + '/messages', notification => {
        let response: PaymentResponse;
        response = JSON.parse(notification.body);
        let ack = 'SUCCESS';
        if (!response.authCode || response.authCode === null) {
          ack = 'ERROR';
        }

        // Set Payment Progress Off
        this.paymentService.setPaymentProgress(false, ack);
        this._shared.setGlobalLoader(false);
        this._shared.setWebSocketLoader(false, ack);
        
        this.openVoidDialog(ack, response, receipt, stompClient);
      });

      const registrationRequest = new JobRequest();
      registrationRequest.sessionId = sessionId;
      registrationRequest.organization = this.getOrganizationSessionKey();
      registrationRequest.terminal = receipt.terminal;
      stompClient.send('/app/register/transaction/notification', {}, JSON.stringify(registrationRequest));
    });
  }

  refundTransaction(orderItems: Array<OrderItem>) {
    const sessionId = this.random(20);
    const stompClient = this.connectionHandshake(sessionId);
    stompClient.debug = null;

    stompClient.connect({'Authorization': 'Bearer ' + this.authenticationService.getToken()}, frame => {
      // Subscribe to notification topic
      stompClient.subscribe('/user/' + sessionId + '/messages', notification => {
        let response: PaymentResponse;
        response = JSON.parse(notification.body);
        let ack = 'SUCCESS';
        if (!response.authCode || response.authCode === null) {
          ack = 'ERROR';
        }
        this._shared.setGlobalLoader(false);
        this._shared.setWebSocketLoader(false, ack);
        this.openRefundDialog(ack, response.resultTxt, response.refNum, orderItems, stompClient);
      });

      const registrationRequest = new JobRequest();
      registrationRequest.sessionId = sessionId;
      registrationRequest.organization = this.getOrganizationSessionKey();
      registrationRequest.terminal = orderItems[0].receipt.terminal;
      stompClient.send('/app/register/transaction/notification', {}, JSON.stringify(registrationRequest));
    });
  }

  splitPayTransaction(cartTotalAmount: number, splitPaymentTenderedAmount: number, terminal: string) {
    const sessionId = this.random(20);
    const stompClient = this.connectionHandshake(sessionId);
    stompClient.debug = null;

    stompClient.connect({'Authorization': 'Bearer ' + this.authenticationService.getToken()}, frame => {
      // Subscribe to notification topic
      stompClient.subscribe('/user/' + sessionId + '/messages', notification => {
        let response: PaymentResponse;
        response = JSON.parse(notification.body);
        let ack = 'SUCCESS';
        if (!response.authCode || response.authCode === null) {
          ack = 'ERROR';
        }
        this.openSplitPaymentDialog(ack, response, cartTotalAmount, splitPaymentTenderedAmount, stompClient);
      });

      const registrationRequest = new JobRequest();
      registrationRequest.sessionId = sessionId;
      registrationRequest.organization = this.getOrganizationSessionKey();
      registrationRequest.terminal = terminal;
      stompClient.send('/app/register/transaction/notification', {}, JSON.stringify(registrationRequest));
    });
  }

  tipAdjustTransaction(receipt: Receipt) {
    const sessionId = this.random(20);
    const stompClient = this.connectionHandshake(sessionId);
    stompClient.debug = null;

    stompClient.connect({'Authorization': 'Bearer ' + this.authenticationService.getToken()}, frame => {
      // Subscribe to notification topic
      stompClient.subscribe('/user/' + sessionId + '/messages', notification => {
        let response: PaymentResponse;
        response = JSON.parse(notification.body);
        let ack = 'SUCCESS';
        if (response.message.toUpperCase() !== 'SUCCESS') {
          ack = 'ERROR';
        }
        this._shared.setGlobalLoader(false);
        this._shared.setWebSocketLoader(false, ack);
        this.toggleService.ShowPaymentProgress = false;
        this.openTipAdjustDialog(ack, receipt, stompClient);
      });

      const registrationRequest = new JobRequest();
      registrationRequest.sessionId = sessionId;
      registrationRequest.organization = this.getOrganizationSessionKey();
      registrationRequest.terminal = receipt.terminal;
      stompClient.send('/app/register/transaction/notification', {}, JSON.stringify(registrationRequest));
    });
  }

  closeBatchTransaction(terminal: string) {
    const sessionId = this.random(20);
    const stompClient = this.connectionHandshake(sessionId);
    stompClient.debug = null;

    stompClient.connect({'Authorization': 'Bearer ' + this.authenticationService.getToken()}, frame => {
      // Subscribe to notification topic
      stompClient.subscribe('/user/' + sessionId + '/messages', notification => {
        let response: PaymentResponse;
        response = JSON.parse(notification.body);
        let ack = 'SUCCESS';
        if (response.message.toUpperCase() !== 'SUCCESS') {
          ack = 'ERROR';
        }
        this.toggleService.ShowPaymentProgress = false;
        this.openBatchCloseDialog(ack, response.resultTxt, terminal, stompClient);
      });

      const registrationRequest = new JobRequest();
      registrationRequest.sessionId = sessionId;
      registrationRequest.organization = this.getOrganizationSessionKey();
      registrationRequest.terminal = terminal;
      stompClient.send('/app/register/transaction/notification', {}, JSON.stringify(registrationRequest));
    });
  }

  connectionHandshake(sessionId: string) {
    const socket = new SockJS(Constants.WEBSOCKET_DOMAIN + '/wsEndpoint?access_token=' + this.authenticationService.getToken(), [], {
      sessionId: () => {
         return sessionId;
      }
    });
    const stompClient = Stomp.over(socket);
    return stompClient;
  }

  disconnect() {
    if (this.ws != null) {
      this.ws.ws.close();
    }
    this.setConnected(false);
    // console.log('Disconnected');
  }

  setConnected(connected) {
    this.disabled = connected;
  }

  random(length: number) {
    const radom13chars = function () {
      return Math.random().toString(16).substring(2, 15);
    }
    const loops = Math.ceil(length / 13);
    return new Array(loops).fill(radom13chars).reduce((string, func) => {
      return string + func();
    }, '').substring(0, length);
  }

  openDialog(msgHeader: string, msg: string, stompClient: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '310px';
    dialogConfig.data = {description: msgHeader, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // this.disconnect();
      stompClient.disconnect(() => { /*console.log('STOMP client disconnected');*/ });
      // if (refNum && refNum.length > 0) {
      //     this.shoppingCartService.closeOrder(refNum).subscribe((res) => {
      //     this.paymentService.InvoiceAmount = 0.00;
      //     this.shoppingCartService.updateObservableItems([]);
      //     this.shoppingCartService.updateObservableCartSum(0);
      //     this.shoppingCartService.updateObservableCartSumTax(0);
      //     this.shoppingCartService.updateObservableCartSumTotal(0);
      //     this.shoppingCartService.EbtCartItems = false;
      //     this.toggleService.ProcessPaymentAsEBT = false;
      //     this.navigateToOrderView();
      //     });
      // }
    });
  }

  openVoidDialog(msgHeader: string, response: PaymentResponse, receipt: Receipt, stompClient: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: response.resultTxt};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // this.disconnect();
      stompClient.disconnect(() => { /*console.log('STOMP client disconnected');*/ });
      if (response.resultTxt === 'Payment Request Approved') {
        // const aReceipt = new Receipt();
        // aReceipt.id = receipt.id;
        // aReceipt.receiptNo = receipt.receiptNo;
        // aReceipt.terminal = receipt.terminal;
        // aReceipt.status = 'VOIDED'; 
        // this.paymentService.deleteReceipt(receipt.id).subscribe(res => {
        //   const foundIndex = this.paymentService.Receipts.findIndex( r => r.id === receipt.id);
        //   this.paymentService.Receipts.splice(foundIndex, 1);
        //   this.paymentService.MatTableReceiptDataSource = this.paymentService.Receipts;
        //   // this.receiptService.findAndUpdateVoidedStatus(aReceipt).subscribe(r => {
        //   // }, (error) => { alert('ERROR updating receipt statuses to VOIDED in POS'); });
        // });
      }
    });
  }

  openRefundDialog(msgHeader: string, msg: string, refNum: string, orderItems: Array<OrderItem>, stompClient: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // this.disconnect();
      stompClient.disconnect(() => { /*console.log('STOMP client disconnected');*/ }); 
      if (refNum) {
        for (let i = 0; i < orderItems.length; i++) {
          orderItems[i].returned = true;
        }
        this.orderService.saveOrderItems(orderItems).subscribe((res) => { /*console.log(res);*/ });
        this.orderService.ShowOrderDetails = false;
        this.orderService.ShowOrderSummary = true;
      }
    });
  }

  openSplitPaymentDialog(msgHeader: string, response: PaymentResponse, cartTotalAmount: number, splitPaymentTenderedAmount: number, stompClient: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: response.resultTxt};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // this.disconnect();
      stompClient.disconnect(() => { /*console.log('STOMP client disconnected');*/ });
      this.toggleService.ShowPaymentProgress = false;
      if (response.refNum) {
        this.paymentService.setSplitBalance(cartTotalAmount, splitPaymentTenderedAmount);
        const activeChair = this.session.get(Constants.ACTIVE_CHAIR);
        // this.paymentService.saveCreditCardSplitPayment(this.authenticationService.getOrganizationId(), activeChair.id, response).subscribe(sp => {
        //   this.paymentService.getSubmittedSplitPayments(this.authenticationService.getOrganizationId(), activeChair.id);
        //   this.paymentService.displayPoleBalance();
        //   if (!this.storage.get(this.paymentService.getCustomerBalanceKey())) {
        //     this.paymentService.CashBack = false;
        //     this.toggleService.ShowDoneButton = true;
        //     this.shoppingCartService.ShoppingCart = [];
        //   }
        // });
        // START OF BLOCK
        this.paymentService.getSubmittedSplitPayments(this.authenticationService.getOrganizationId(), activeChair.id);
        this.paymentService.displayPoleBalance();
        if (!this.storage.get(this.paymentService.getCustomerBalanceKey())) {
          this.paymentService.CashBack = false;
          this.toggleService.ShowDoneButton = true;
          this.shoppingCartService.ShoppingCart = [];
        } // END  OF BLOCK
      }
    });
  }

  openTipAdjustDialog(msgHeader: string, receipt: Receipt, stompClient: any): void {
    let msg = 'Tip Adjust Error';
    if (msgHeader === 'SUCCESS') {
      msg = 'TIP ADJUSTED';
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: msg};

    if (msgHeader === 'SUCCESS') {
      receipt.status = 'ADJUSTED';
      this.receiptService.findAndUpdateTipStatus(receipt).subscribe(r => {
        const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => { this.disconnect(); });
      }, error => {
        alert('ERROR updating tip amount in POS');
      });
    } else {
      const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        // this.disconnect();
        stompClient.disconnect(() => { /*console.log('STOMP client disconnected');*/ });
      });
    }
  }

  openBatchCloseDialog(msgHeader: string, msg: string, terminal: string, stompClient: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: msg};

    if (msgHeader === 'SUCCESS') {
      const user = new User();
      user.id = this.authenticationService.getIdentity().id;
      const receipt = new Receipt();
      receipt.user = user;
      receipt.status = 'BATCHED';
      receipt.terminal = terminal;
      receipt.organizationId = this.authenticationService.getOrganizationId();
      this.receiptService.updateReceipts(receipt).subscribe(r => {
        const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          // this.disconnect();
          stompClient.disconnect(() => { /*console.log('STOMP client disconnected');*/ });
          this.receiptService.getTodaysReceipts(receipt.organizationId).subscribe(results => {
            this.receiptService.ReceiptDataSource = results;
          });
        });
      }, error => {
        alert('ERROR updating receipt statuses in POS');
      });
    }
  }

  navigateToOrderView() {
    const orgType = this.session.get('orgType');
    if (Constants.ORGANIZATION_TYPE_RETAIL === orgType) {
      this.router.navigate(['/retailorder'], { queryParams: { pageView: 'quickStart' } });
    } else {
      this.router.navigate(['/restaurantorder'], { queryParams: { pageView: 'quickStart' } });
    }
  }

  getRandomTerminal() {
    return 'RANDOM_TERMINAL_' + this.getRandomNumber(100, 1000).toFixed(0);
  }

  getRandomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }

  getOrganizationSessionKey() {
    return 'organization_' + this.authenticationService.getOrganizationId();
  }

  toBigDecimal(amount: string) {
    const cents = amount.substring(amount.length - 2);
    const dollars = amount.substring(0, amount.length - 2);
    return Number(dollars + '.' + cents);
  }
}

import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-image-renderer',
  templateUrl: './image-renderer.component.html',
  styleUrls: ['./image-renderer.component.css']
})
export class ImageRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public fullUrl;

  agInit(params: any): void {
    this.params = params;
    const imageNameIndex = params.value?.lastIndexOf('/');
    let imageName = params.value?.substring(imageNameIndex + 1);
    if (!imageName) {
      imageName = 'no-image.png';
    }
    this.fullUrl = params.url + imageName;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  viewDetails(params){

  }

  constructor() { }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/User';
import { Constants } from '../models/Constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private users: Array<User> = [];
  private displayUserForm: boolean = false;
  private displayUserList: boolean = false;

  constructor(private httpClient: HttpClient) { }

  getUsers(organizationId: number): Observable<Array<User>> {
    return this.httpClient.get<Array<User>>(Constants.ERESTAU_DOMAIN + `/api/users?org=${organizationId}`);
  }

  saveUser(user: User): Observable<User> {
    return this.httpClient.post<User>(Constants.ERESTAU_DOMAIN + "/api/user", user);
  }

  set Users(users: Array<User>) {
    this.users = users;
  }

  get Users() {
    return this.users;
  }

  set DisplayUserList(displayUserList: boolean) {
    this.displayUserList = displayUserList;
  }

  get DisplayUserList() {
    return this.displayUserList;
  }

  set DisplayUserForm(displayUserForm: boolean) {
    this.displayUserForm = displayUserForm;
  }

  get DisplayUserForm() {
    return this.displayUserForm;
  }
}
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-message-dialog-confirmation',
  templateUrl: './message-dialog-confirmation.component.html',
  styleUrls: ['./message-dialog-confirmation.component.css']
})
export class MessageDialogConfirmationComponent implements OnInit {

  description: string;
  message: string;

  constructor(private dialogRef: MatDialogRef<MessageDialogConfirmationComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.description = data.description;
    this.message = data.message;
  }

  ngOnInit() {
  }

  onOkClick(): void {
    this.dialogRef.close(true);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }
}

import { User } from './User';
import { Receipt } from './Receipt';
import { MenuItem } from './MenuItem';
import { Customer } from './Customer';
import { OrderType } from '../enums/OrderType';
import { OrderStatus } from '../enums/OrderStatus';
import {SubOrderItem} from './SubOrderItem';


export class OrderItem {
    id: number;
    name: string;
    quantity: number;
    number: string;
    updatedBy: User;
    receipt: Receipt;
    menuItem: MenuItem;
    subOrderItems: Array<SubOrderItem>;
    price: number;
    taxRate: number;
    totalPrice: number;
    discountPrice: number;
    user: User;
    customer: Customer;
    updated: Date;
    orderPlaced: Date;
    orderReady: Date;
    orderServed: Date;
    orderType: OrderType;
    orderStatus: OrderStatus;
    discount: number;
    notes: string;
    organizationId: number;
    checked: boolean;
    highlighted: boolean;
    returned: boolean;
    returnQuantity: number;
}

export class DailyRevenue {
    organizationId: number;
    netCashAmount: number;
    taxCashAmount: number;
    totalCashAmount: number;
    netCardAmount: number;
    taxCardAmount: number;
    totalCardAmount: number;
    netEbtAmount: number;
    taxEbtAmount: number;
    totalEbtAmount: number;
    netTotalAmount: number;
    taxTotalAmount: number;
    totalAmount: number;
}

import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MenuItemService} from '../../services/menu-item.service';
import {AuthenticationService} from '../../services/authentication.service';
import {ToggleService} from '../../services/toggle.service';
import {MenuItem} from '../../models/MenuItem';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Constants} from '../../models/Constants';
import {CategoryService} from '../../services/category.service';
import {Category} from '../../models/Category';
import {BarcodeLabelDialogComponent} from './message-dialog/barcode-label/barcode-label-dialog.component';
import {Subscription} from 'rxjs';
import {GridOptions} from 'ag-grid-community';
import {Router} from '@angular/router';
import {ImageRendererComponent} from '../image-renderer/image-renderer.component';

@Component({
    selector: 'app-inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class InventoryComponent implements OnInit, OnDestroy {

    @ViewChild('barcodeInput') barcodeElement: ElementRef;
    @ViewChild('nameSearchInput') nameSearchElement: ElementRef;
    @ViewChild('priceEditInput') priceEditElement: ElementRef;
    @ViewChild('itemPriceInput') itemPriceElement: ElementRef;

    showSpinner = false;
    public menuItem: MenuItem;
    public categories: Array<Category> = [];
    public itemSearchForm: FormGroup;
    public showItemAddForm = false;
    public showItemEditForm = false;
    public displayItem = false;
    public selectedFiles: FileList;
    public currentFileUpload: File;
    public searchByItemName = false;
    public searchByBarcode = true;
    public navigationFromList: boolean;
    public displayedColumns = ['image', 'name', 'stock', 'category', 'description', 'taxRate', 'price', 'actionPrintBarcode', 'actionEdit'];
    public dataSource = new MatTableDataSource<MenuItem>();
    public url: string = Constants.ERESTAU_DOMAIN + '/public/api/image/resize?org=' + this.authenticationService.getOrganizationId() + '&width=' + Constants.MENU_IMAGE_WIDTH + '&height=' + Constants.MENU_IMAGE_HEIGHT + '&imageName=';
    public rowSelection = 'single';
    public rowData = [];
    public frameworkComponents = {
        imageDisplayComponent: ImageRendererComponent
    };
    public columnDefs = [
        {
            headerName: '',
            field: 'imageUrl',
            cellRenderer: 'imageDisplayComponent',
            cellRendererParams: {
                url: this.url
            }
        },
        {
            headerName: 'No.',
            field: 'number',
            filter: 'agTextColumnFilter',
            cellStyle: {'font-size': '1.6em'},
            width: 120
        },
        {
            field: 'name',
            sortable: true,
            // cellRenderer: (params) => {
            //     return '<a href="javascript:;">' + params.value + '</a>';
            // },
            filter: 'agTextColumnFilter',
            cellStyle: {
                'font-size': '1.6em',
                cursor: 'pointer'
            },
            width: 400,
        },
        {
            headerName: 'Category',
            field: 'category.name',
            filter: 'agTextColumnFilter',
            cellStyle: {'font-size': '1.6em'},
            width: 300
        },
        {
            field: 'stock',
            filter: 'agTextColumnFilter',
            cellStyle: {'font-size': '1.6em'},
            width: 140
        },
        {
            headerName: 'Tax%',
            field: 'taxRate',
            filter: 'agNumberColumnFilter',
            cellStyle: {'font-size': '1.6em'},
            width: 130
        },
        {
            field: 'price',
            filter: 'agNumberColumnFilter',
            cellStyle: {'font-size': '1.6em'},
            width: 120
        },
        {
            headerName: '',
            field: '',
            cellRenderer: this.actionsRenderer
        },
        {
            field: 'barcode',
            hide: true
        }
    ];
    public gridOptions: GridOptions;
    private apiSubscription = new Subscription();

    constructor(private menuItemService: MenuItemService, private authenticationService: AuthenticationService, private dialog: MatDialog,
                public categoryService: CategoryService, private toggleService: ToggleService, private formBuilder: FormBuilder, private router: Router) {
    }

    ngOnInit() {
        this.itemSearchForm = this.formBuilder.group({
            itemBarcode: ''
        });
        this.barcodeInputFocus();

        this.gridOptions = ({
            rowHeight: 75
        } as GridOptions);
    }

    actionsRenderer(curVal: any): string {
        return `
                <i data-action-type="Print" class="fa fa-print fa-2x" style="color: cornflowerblue; cursor: pointer;"></i>
                <i data-action-type="Edit" class="fa fa-edit fa-3x" style="color: cornflowerblue; cursor: pointer; padding-left: 30px;"></i>
        `;
    }

    public handleAction(e) {
        if (e.event.target !== undefined) {
            const actionType = e.event.target.getAttribute('data-action-type');
            switch (actionType) {
                case 'Edit':
                    this.editMenuItem(e.node.data, true);
                    break;
                case 'Print':
                    this.openPrintDialog(e.node.data);
                    break;
            }
        }
    }

    applyFilter(filterValue: string) {
        this.gridOptions.api.setQuickFilter(filterValue);
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onNameClicked(e) {
        if (e.column.colId === 'name') {
            this.menuItemService.saveSelectedItem(e.node.data);
            this.router.navigate(['/menuItemDetails']);
        }
    }

    lookupItemByBarcode() {
        this.searchByBarcode = true;
        this.searchByItemName = false;
        this.showItemEditForm = false;
        this.showItemAddForm = false;
        this.barcodeInputFocus();
    }

    lookupItemByName() {
        this.showSpinner = true;
        setTimeout(() => {
            this.searchByItemName = true;
            this.getMenuItems();
        });
        this.searchByItemName = true;
        this.searchByBarcode = false;
        this.showItemEditForm = false;
        this.showItemAddForm = false;
        this.nameSearchInputFocus();
    }

    getMenuItems() {
        const itemSubscription = this.menuItemService.getMenuItems(this.authenticationService.getOrganizationId()).subscribe((itms) => {
            this.menuItemService.MenuItems = itms;
            this.showSpinner = false;
            // this.dataSource.data = itms;
            this.rowData = itms;
        });
        this.apiSubscription.add(itemSubscription);
    }

    editMenuItem(menuItem: MenuItem, fromList: boolean) {
        this.navigationFromList = fromList;
        const menuSubscription = this.categoryService.getCategories(this.authenticationService.getOrganizationId()).subscribe((cats) => {
            this.categories = cats;
            this.menuItem = menuItem;
            this.showItemEditForm = true;
            this.searchByItemName = false;
            this.searchByBarcode = false;
            this.priceEditInputFocus();
        });
        this.apiSubscription.add(menuSubscription);
    }

    saveEditedMenuItem() {
        if (this.selectedFiles) {
            this.currentFileUpload = this.selectedFiles.item(0);
        }

        this.menuItem.organizationId = this.authenticationService.getOrganizationId();
        if (this.menuItem.category.id) {
            this.menuItem.category.organizationId = this.menuItem.organizationId;
            const foundIndex = this.categories.findIndex(c => Number(c.id) === Number(this.menuItem.category.id));
            if (foundIndex >= 0) {
                this.menuItem.category.name = this.categories[foundIndex].name;
            }
            const mIndex = this.menuItemService.MenuItems.findIndex(m => Number(m.id) === Number(this.menuItem.id));
            if (mIndex >= 0) {
                this.menuItemService.MenuItems[mIndex] = this.menuItem;
            }
        }

        const menuSubscription = this.menuItemService.saveMenuItem(this.menuItem, this.currentFileUpload).subscribe((response) => {
                this.menuItem = null;
                this.searchByItemName = true;
                this.showItemEditForm = false;
                if (this.navigationFromList) {
                    this.searchByBarcode = false;
                    this.getMenuItems();
                } else {
                    this.searchByBarcode = true;
                    this.searchByItemName = false;
                    this.menuItem = response;
                    this.barcodeInputFocus();
                }
            },
            (error) => {
                console.log(error);
            });
        this.apiSubscription.add(menuSubscription);
    }

    updateMenuItemCount(menuItem: MenuItem) {
        const menuSubscription = this.menuItemService.updateMenuItemCount(menuItem).subscribe((response) => {
            // this.itemMap.delete(response.barcode);
            this.menuItem = null;
            this.displayItem = false;
            this.barcodeInputFocus();
        });
        this.apiSubscription.add(menuSubscription);
    }

    addNewItem() {
        if (this.selectedFiles) {
            this.currentFileUpload = this.selectedFiles.item(0);
        }

        this.menuItem.organizationId = this.authenticationService.getOrganizationId();
        const menuSubscription = this.menuItemService.inventoryAddMenuItem(this.menuItem, this.currentFileUpload).subscribe((itm) => {
                this.menuItem = itm;
                // this.itemMap.set(itm.barcode, this.menuItem);
                this.openDialog('Confirmation', 'Menu Item [ ' + itm.name + ' ] saved');
            },
            (err) => {
                // console.log('The error message is : ');
                // console.log(err.error);
                this.openDialog('ERROR', err.error);
            });
        this.apiSubscription.add(menuSubscription);
    }

    cancelAdd() {
        this.showItemAddForm = false;
        this.searchByBarcode = true;
        this.menuItem = null;
    }

    cancelEdit() {
        this.searchByItemName = true;
        this.showItemEditForm = false;
        if (this.navigationFromList) {
            this.menuItem = null;
            this.searchByBarcode = false;
        } else {
            this.searchByBarcode = true;
            this.searchByItemName = false;
            this.barcodeInputFocus();
        }
    }

    barcodeLookup(data: string) {
        this.showItemAddForm = false;
        const menuSubscription = this.menuItemService.getMenuItem(this.authenticationService.getOrganizationId(), data).subscribe((item) => {
                this.menuItem = item;
                if (!this.menuItem.stock) {
                    this.menuItem.stock = 1;
                } else {
                    this.menuItem.stock++;
                }
                const items = this.menuItemService.getMenuItemsFromLocalStorage();
                const foundIndex = items.findIndex(itm => itm.id === this.menuItem.id);
                items[foundIndex] = this.menuItem;
                this.menuItemService.storeMenuItemsInLocalStorage(items);
                this.displayItem = true;
            },
            (error) => {
                // alert('********* Product not found *********');
                this.menuItem = new MenuItem();
                this.menuItem.organizationId = this.authenticationService.getOrganizationId();
                this.menuItem.barcode = data;
                this.menuItem.pricedByWeight = false;
                this.menuItem.fulfillmentTime = 45.00;
                this.menuItem.activeOnline = true;
                this.showItemAddForm = true;
            });
        this.apiSubscription.add(menuSubscription);
        this.itemSearchForm.reset();
    }

    initAddProduct() {
        this.categoryService.getParentCategories(this.authenticationService.getOrganizationId()).subscribe(cats => {
            this.menuItem = new MenuItem();
            const category = new Category();
            this.menuItem.category = category;
            this.menuItem.category.id = cats[0].id;
            this.menuItem.organizationId = this.authenticationService.getOrganizationId();
            this.menuItem.pricedByWeight = false;
            this.menuItem.ebtEligible = true;
            this.menuItem.price = 0.00;
            this.menuItem.taxRate = 3.0;
            this.menuItem.fulfillmentTime = 45.00;
            this.menuItem.stock = 1;
            this.menuItem.activeOnline = true;
            this.showItemAddForm = true;
            this.searchByItemName = false;
            this.searchByBarcode = false;
            this.categoryService.ParentCategories = cats;
        });
        this.itemPriceInputFocus();
    }

    incrementItemCount(barcode) {
        this.menuItem.stock++;
    }

    decrementItemCount(barcode) {
        this.menuItem.stock--;
    }

    barcodeInputFocus() {
        setTimeout(() => {
            this.barcodeElement.nativeElement.focus();
        }, 0);
    }

    nameSearchInputFocus() {
        setTimeout(() => {
            this.nameSearchElement.nativeElement.focus();
        }, 0);
    }

    priceEditInputFocus() {
        setTimeout(() => {
            this.priceEditElement.nativeElement.focus();
        }, 0);
    }

    itemPriceInputFocus() {
        setTimeout(() => {
            this.itemPriceElement.nativeElement.focus();
        }, 300);
    }

    selectFile(event) {
        this.selectedFiles = event.target.files;
    }

    openPrintDialog(menuItem: MenuItem): void {
        this.menuItemService.LabelItemName = menuItem.name;
        if (menuItem.pricedByWeight === true) {
            this.menuItemService.WeightedItem = true;
        }
        const dialogRef = this.dialog.open(BarcodeLabelDialogComponent, {
            width: '750px',
            disableClose: true
        });

        const dialogSubscription = dialogRef.afterClosed().subscribe((result) => {
            this.menuItemService.WeightedItem = false;
            if (result === true) {
                menuItem.barcodePrintQuantity = this.menuItemService.LabelQuantity;
                menuItem.barcodeLabelSize = this.menuItemService.LabelSize;
                menuItem.weight = this.menuItemService.CapturedWeight;
                const labelSubscription = this.menuItemService.printBarcodeLabel(menuItem).subscribe((response) => {
                    const foundIndex = this.menuItemService.MenuItems.findIndex(mi => Number(mi.id) === Number(menuItem.id));
                    if (foundIndex >= 0) {
                        this.menuItemService.MenuItems[foundIndex].barcode = response.barcode;
                    }
                    this.menuItemService.LabelQuantity = 1;
                }, (error) => {
                    alert(error);
                });
                this.apiSubscription.add(labelSubscription);
            }
        });
        this.apiSubscription.add(dialogSubscription);
    }

    openDialog(msgHeader: string, msg: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {description: msgHeader, message: msg};

        const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

        const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
            this.showItemAddForm = false;
            this.displayItem = true;
            this.searchByBarcode = true;
            this.barcodeInputFocus();
        });
        this.apiSubscription.add(dialogSubscription);
    }

    ngOnDestroy() {
        this.apiSubscription.unsubscribe();
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../models/Constants';
import { Address } from '../models/Address';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private httpClient: HttpClient) { }

  getAddress(organizationId: number) {
    return this.httpClient.get<Address>(Constants.ERESTAU_DOMAIN + `/api/address?org=${organizationId}`);
  }
}
import { SubMenuItem } from './SubMenuItem';
import { Category } from './Category';

export class MenuItem {
	id: number;
    number: number;
    name: string;
    longName: string;
    description: string;
    fulfillmentTime: number;
    price: number;
    purchasePrice: number;
    mediumPrice: number;
    smallPrice: number;
    onlinePrice: number;
    taxRate: number;
    onlineTaxRate: number;
    discountPrice: number;
    pricedByWeight: boolean;
    alcohol: boolean;
    activeDigiMenu: boolean;
    active: boolean;
    activeOnline: boolean;
    category: Category;
    meal: boolean;
    drink: boolean;
    special: boolean;
    sortText: string;
    barcode: string;
    imageFilename: string;
    imageUrl: string;
    numOfSubMenus: number;
	stock: number;
    barcodePrintQuantity: number;
    barcodeLabelSize: string;
    onPremiseBarcode: boolean;
    updatedBy: string;
    subMenuItems: Array<SubMenuItem> = [] ;
    organizationId: number;
    ebtEligible: boolean;
    weight: number;
}

import { User } from './User';
import { Customer } from './Customer';
import { OrderItem } from './OrderItem';

export class Receipt {
    id: number;
	receiptNo: string;
	updated: Date;
	user: User;
	customer: Customer;
	discount: number;
	signed: boolean;
	paymentType: string;
	cashAmount: number;
	cardAmount: number;
	tipAmount: number;
	mobileMoneyAmount: number;
	signedBy: User;
	orderItems: Array<OrderItem> = [];
	totalAsString: string;
	total: number;
	nettoAsString: string;
	netto: number;
	tax: number;
	street: string;
	businessName: string;
	phoneNumber: string;
	city: string;
	state: string;
	zipCode: string;
	taxIncluded: number;
	currencySymbol: string;
	allSubUsers: string;
	amountPaid: number;
	changeAmount: number;
    paymentReceived: boolean;
    paymentRef: string;
    paymentAuthCode: string;
    bogusAccountNum: string;
    cardType: string;
	uid: string;
	taxNbr: string;
	status: string;
	organizationId: number;
	customerPhoneNumber: number;
	customerEmail: string;
	terminal: string;
}

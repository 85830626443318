export class Category {
    id: number;
    name: string;
    activeDigiMenu: boolean;
    activeOnline: boolean;
    section: string;
    menuName: string;
    active: boolean;
    parent: Category;
    sortOrder: number;
    sortText: string;
    imageUrl: string;
    imageFileName: string;
    organizationId: number;
}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { MenuItemService } from 'src/app/services/menu-item.service';
import { Category } from 'src/app/models/Category';
import { MenuItem } from 'src/app/models/MenuItem';
import { ShoppingCartServiceService } from 'src/app/services/shopping-cart-service.service';
import { ShoppingCartItem } from 'src/app/models/ShoppingCartItem';
import { User } from 'src/app/models/User';
import { CustomerService } from 'src/app/services/customer.service';
import { SessionStorageService, LocalStorageService } from 'angular-web-storage';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/models/Constants';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ToggleService } from 'src/app/services/toggle.service';
import {OrderService} from '../../../services/order.service';
import {Subscription} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MessageDialogComponent} from '../../message-dialog/message-dialog.component';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-order',
  templateUrl: './restaurant-order.component.html',
  styleUrls: ['./restaurant-order.component.css']
})
export class RestaurantOrderComponent implements OnInit, OnDestroy {
  private apiSubscription = new Subscription();
  private routeSubscription = new Subscription();
  private clearCartsubscription = new Subscription();
  public organizationId: number;
  public cartItemFormData: ShoppingCartItem;
  public tempFormData: ShoppingCartItem;
  public menuItemDisplayIndex: number;
  @ViewChild('itemCurrentPrice') itemCurrentPriceElement: ElementRef;

  public bindKeypad: boolean = false;
  public activeKeypad: string = "";
  public inputItem: any = null;
  public cartLoaderFlag: boolean = false;

  constructor(public categoryService: CategoryService, private _shared: SharedService, public menuItemService: MenuItemService,
              public shoppingCartService: ShoppingCartServiceService, public customerService: CustomerService,
              private session: SessionStorageService, private storage: LocalStorageService, private paymentService: PaymentService,
              private router: Router, private authenticationService: AuthenticationService, private dialog: MatDialog,
              public toggleService: ToggleService, public orderService: OrderService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.organizationId = this.authenticationService.getOrganizationId();
    this.getTables();
    this.toggleService.ShowParentCategories = false;
    this.shoppingCartService.DisplayShoppingCart = false;
    this.shoppingCartService.DisplayTables = 'YES';
    const paramsSubscription = this.route.queryParams.subscribe(params => {
      const pageView = params.pageView || '';
      if (pageView === 'quickStart') {
        this.orderService.initializeOrderMenu();
      }
    });
    this.routeSubscription.add(paramsSubscription);

    const subMenuSubscriber = this.orderService.watchSubMenu.subscribe((response) => {
      if (response.flag) {
          this.toggleService.SelectedSubCategory = 0;
          this.getMenuItems(response.response[0], 0);
      }
    })
    this.apiSubscription.add(subMenuSubscriber);
  }

  initTableOrders(table: User) {
    this.orderService.initializeTableOrderMenu(table);
    this.shoppingCartService.calculateServiceFee();
  }

  triggerScrollTop() {
    setTimeout(() => {
      const cartItemList = document.getElementById('cartItemList');
      cartItemList.scrollTop = 0;
    }, 50);
  }

  addToShoppingCart(selectedMenuItem: MenuItem, displayIndex: number) {
    if (selectedMenuItem.subMenuItems.length == 0) {
      this.proceedToAdd(selectedMenuItem, displayIndex);
    } else {
        this.toggleService.ShowSideItems = true;
        this.shoppingCartService.DisplayShoppingCart = false;
        this.toggleService.ShowOrderMenuItems = false;
        this.initSideSelection(selectedMenuItem, displayIndex, false);
    }
  }

  outputSides(event) {
    if (event != 'cancel' && !event.isEditMode) {
      this.proceedToAdd(event.menuItem, event.displayIndex, event);
    } else if (event != 'cancel' && event.isEditMode) {
      this.tempFormData.notes = event.sideForm.notes;
      this.tempFormData.quantity = event.sideForm.quantity;
      this.tempFormData.price = event.sideForm.price;
      this.tempFormData.pricePermanent = event.sideForm.pricePermanent;
      this.tempFormData.subShoppingCartItems = event.subShoppingCartItems;
      this.tempFormData.subTaxTotal = (event.sideForm.subTaxTotal * event.sideForm.quantity);
      this.tempFormData.subPriceTotal = (event.sideForm.subPriceTotal * event.sideForm.quantity);
      this.saveUpdatedCartItem();
    }

    this.toggleService.ShowSideItems = false;
    this.shoppingCartService.DisplayShoppingCart = true;
    this.toggleService.ShowOrderMenuItems = true;
  }

  proceedToAdd(selectedMenuItem: MenuItem, displayIndex: number, event?: any) {
    this.toggleService.ShowSideItems = false;
    // this.shoppingCartService.DisplayShoppingCart = true;
    // this.toggleService.ShowOrderMenuItems = true;
    this.menuItemDisplayIndex = displayIndex;
    /* See if selected menu item is in the shopping cart already */
    let indx: number;
    if (event) {
       indx = this.menuItemExistInShoppingCart(selectedMenuItem, event.subShoppingCartItems);
    } else {
      indx = this.menuItemExistInShoppingCart(selectedMenuItem);
    }
    
    if (indx < 0) {
      /* Add new shopping cart item */
      const cartItem = new ShoppingCartItem();
      cartItem.organizationId = this.organizationId;
      cartItem.menuItem = selectedMenuItem;
      if (event && event.subShoppingCartItems && event.subShoppingCartItems.length > 0) {
        cartItem.subShoppingCartItems = event.subShoppingCartItems;
        cartItem.subPriceTotal = (event.sideForm.subPriceTotal * event.sideForm.quantity);
        cartItem.subTaxTotal = (event.sideForm.subTaxTotal * event.sideForm.quantity);
      } else {
        cartItem.subShoppingCartItems = [];
        cartItem.subPriceTotal = 0;
        cartItem.subTaxTotal = 0;
      }
      
      if (event && event.sideForm.price) {
        cartItem.price = event.sideForm.price;
      } else {
        cartItem.price = selectedMenuItem.price;
      }
      cartItem.user = this.getUser();
      cartItem.customer = this.session.get(Constants.ACTIVE_CHAIR);
      this.paymentService.adjustSplitPayBalance(Constants.ACTION_ADD, cartItem);
      //this.cartLoaderFlag = true;
      const cartSubscription = this.shoppingCartService.saveShoppingCartItem(cartItem).subscribe((ci) => {
        //this.cartLoaderFlag = false;
        this.shoppingCartService.ShoppingCart.unshift(ci);
        this.shoppingCartService.DisplayShoppingCart = true;
        this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
        this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
        this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
        this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
        this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
        this.shoppingCartService.calculateServiceFee();
        this.triggerScrollTop();
      });
      this.apiSubscription.add(cartSubscription);
    } else {
      /* Update existing shopping cart item */
      this.shoppingCartService.ShoppingCart[indx].quantity++;
      this.shoppingCartService.ShoppingCart[indx].price = selectedMenuItem.price * this.shoppingCartService.ShoppingCart[indx].quantity;
      
      if (event && event.subShoppingCartItems && event.subShoppingCartItems.length > 0) {
        this.shoppingCartService.ShoppingCart[indx].subPriceTotal = (event.sideForm.subPriceTotal * this.shoppingCartService.ShoppingCart[indx].quantity);
        this.shoppingCartService.ShoppingCart[indx].subTaxTotal = (event.sideForm.subTaxTotal * this.shoppingCartService.ShoppingCart[indx].quantity);
      } else {
        this.shoppingCartService.ShoppingCart[indx].subPriceTotal = 0;
        this.shoppingCartService.ShoppingCart[indx].subTaxTotal = 0;
      }

      this.paymentService.adjustSplitPayBalance(Constants.ACTION_ADD, this.shoppingCartService.ShoppingCart[indx]);
      //this.cartLoaderFlag = true;
      const cartSubscription = this.shoppingCartService.saveShoppingCartItem(this.shoppingCartService.ShoppingCart[indx]).subscribe((ci) => {
        //this.cartLoaderFlag = false;
        this.shoppingCartService.calculateServiceFee();
        this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
        this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
        this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
        this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
        this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
      });
      this.apiSubscription.add(cartSubscription);
    }
  }

  clearCart() {
    //this.cartLoaderFlag = true;
    const cartSubscription = this.shoppingCartService.clearCart(this.session.get(Constants.ACTIVE_CHAIR).id, this.authenticationService.getOrganizationId()).subscribe((response: any) => {
      //this.cartLoaderFlag = false;
      this.shoppingCartService.ShoppingCart.splice(0, this.shoppingCartService.ShoppingCart.length);
      this.shoppingCartService.calculateServiceFee();
      this.shoppingCartService.DisplayShoppingCart = false;
      this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
      this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
      this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
      this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
      this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
    });
    this.clearCartsubscription.add(cartSubscription);
  }

  menuItemExistInShoppingCart(selectedMenuItem: MenuItem, selectedSubShoppingCartItems?: ShoppingCartItem) {
    let isItemExists = false;
    let selectedIndex = -1;
    this.shoppingCartService.ShoppingCart.forEach((value, index) => {
      // Verify Same id and SubmenuItem Length
      if ((value.menuItem.id == selectedMenuItem.id) && (selectedMenuItem.subMenuItems.length == 0)) {
        isItemExists = true;
        selectedIndex = index;
      } else if ((value.menuItem.id == selectedMenuItem.id) && (selectedMenuItem.subMenuItems.length != 0)) {
        
        // Compare Items
        if (this.compareSubMenuItems(value.subShoppingCartItems, selectedSubShoppingCartItems)) {
          isItemExists = true
          selectedIndex = index;
        }

      }
    });
    return selectedIndex;
    // for (let i = 0; i < this.shoppingCartService.ShoppingCart.length; i++) {
    //   if (this.shoppingCartService.ShoppingCart[i].menuItem.id === selectedMenuItem.id && selectedMenuItem.subMenuItems && selectedMenuItem.subMenuItems.length === 0) {
    //     return i;
    //   } else if (this.shoppingCartService.ShoppingCart[i].menuItem.id === selectedMenuItem.id && selectedMenuItem.subMenuItems && selectedMenuItem.subMenuItems.length > 0) {
    //     if (this.compareSubMenuItems(this.shoppingCartService.ShoppingCart[i].menuItem.subMenuItems, selectedMenuItem.subMenuItems)) {
    //       return i;
    //     }
    //   }
    // }
    // return -1;
  }

  compareSubMenuItems(existingItem, newItem) {
    let existingSelected = existingItem;
    let newSelected = newItem;
    let existingPreference = existingItem.find(item => item.preference);
    let newPreference = newItem.find(item => item.preference);
    let verifySameItems = true;

    // Verify Cart Item Level
    newItem.forEach((i) => {
      if (!existingItem.find(j => j.subMenuItem.id === i.subMenuItem.id )) {
        verifySameItems = false;
      }
    });

    if ( existingSelected.length === newSelected.length ) {
      if ( existingPreference && existingPreference.name !== newPreference.name) {
        return false;
      } else if (!verifySameItems) {
        return false;
      }
    } else {
      return false;
    }
    return true;
  }

  removeFromShoppingCart(selectedCartItem: ShoppingCartItem) {
    //this.cartLoaderFlag = true;
    if (selectedCartItem.quantity > 1) {
      let price = selectedCartItem.price / selectedCartItem.quantity;
      let sidePrice = selectedCartItem.subPriceTotal / selectedCartItem.quantity;
      let sideTax = selectedCartItem.subTaxTotal / selectedCartItem.quantity;
      selectedCartItem.quantity--;
      selectedCartItem.price = price * selectedCartItem.quantity;
      selectedCartItem.subPriceTotal = sidePrice * selectedCartItem.quantity;
      selectedCartItem.subTaxTotal = sideTax * selectedCartItem.quantity;
      const cartSubscription = this.shoppingCartService.saveShoppingCartItem(selectedCartItem).subscribe((res) => {
        this.shoppingCartService.calculateServiceFee();
        this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
        this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
        this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
        this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
        this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
        //this.cartLoaderFlag = false;
      });
      this.apiSubscription.add(cartSubscription);
    } else {
      const index: number = this.shoppingCartService.ShoppingCart.indexOf(selectedCartItem);
      const cartSubscription = this.shoppingCartService.deleteShoppingCartItem(selectedCartItem).subscribe((res) => {
        this.shoppingCartService.ShoppingCart.splice(index, 1);
        this.shoppingCartService.calculateServiceFee();
        if (this.shoppingCartService.ShoppingCart.length === 0) {
          this.shoppingCartService.DisplayShoppingCart = false;
        }
        this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
        this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
        this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
        this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
        this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
        //this.cartLoaderFlag = false;
      });
      this.apiSubscription.add(cartSubscription);
    }

    // if (this.storage.get(this.paymentService.getCustomerBalanceKey())) {
    //   const unitPrice = selectedCartItem.price / selectedCartItem.quantity;
    //   const originalItem = selectedCartItem;
    //   originalItem.price = unitPrice;
    //   this.paymentService.adjustSplitPayBalance(Constants.ACTION_SUBTRACT, originalItem);
    // }
  }

  increaseItemQuantity(index: number) {
    //this.cartLoaderFlag = true;
    const unitPrice = this.shoppingCartService.ShoppingCart[index].price / this.shoppingCartService.ShoppingCart[index].quantity;
    let sidePrice = null;
    let sideTax = null;
    if (this.shoppingCartService.ShoppingCart[index].subPriceTotal) {
      sidePrice = this.shoppingCartService.ShoppingCart[index].subPriceTotal / this.shoppingCartService.ShoppingCart[index].quantity;
      sideTax = this.shoppingCartService.ShoppingCart[index].subTaxTotal / this.shoppingCartService.ShoppingCart[index].quantity;
    }
    
    const originalItem = this.shoppingCartService.ShoppingCart[index]; // Only used during split payment transaction
    originalItem.price = unitPrice;
    this.paymentService.adjustSplitPayBalance(Constants.ACTION_ADD, originalItem);
    this.shoppingCartService.ShoppingCart[index].quantity++;
    this.shoppingCartService.ShoppingCart[index].price = unitPrice * this.shoppingCartService.ShoppingCart[index].quantity;
    this.shoppingCartService.ShoppingCart[index].subPriceTotal = sidePrice * this.shoppingCartService.ShoppingCart[index].quantity;
    this.shoppingCartService.ShoppingCart[index].subTaxTotal = sideTax * this.shoppingCartService.ShoppingCart[index].quantity;
    const cartSubscription = this.shoppingCartService.saveShoppingCartItem(this.shoppingCartService.ShoppingCart[index]).subscribe((ci) => {
      //this.cartLoaderFlag = false;
      this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
      this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
      this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
      this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
      this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
    });
    this.apiSubscription.add(cartSubscription);
  }

  getMenuItems(category: Category, _index: number) {
    this.toggleService.SelectedSubCategory = _index;
    //this._shared.setGlobalLoader(true);
    const itemSubscription = this.menuItemService.getMenuItemsByCategory(category, this.organizationId).subscribe((mn) => {
      //this._shared.setGlobalLoader(false);
      this.menuItemService.OrderMenuItems = mn;
      this.toggleService.ShowOrderMenuItems = true;
    });
    this.apiSubscription.add(itemSubscription);
  }

  getUser() {
    const user = new User();
    user.id = this.session.get(Constants.IDENTITY).id;
    return user;
  }

  getTables() {
    this.toggleService.ShowParentCategories = false;
    this.toggleService.ShowSubCategories = false;
    //this._shared.setGlobalLoader(true);
    const tableSubscription = this.customerService.getTables(this.organizationId).subscribe((tbls) => {
      //this._shared.setGlobalLoader(false);
      this.customerService.Tables = tbls;
    });
    this.apiSubscription.add(tableSubscription);
  }

  editCartItemForm(cartItem: ShoppingCartItem) {
    if (cartItem.menuItem.subMenuItems.length > 0) {
      this.toggleService.ShowSideItems = true;
      this.shoppingCartService.DisplayShoppingCart = false;
      this.toggleService.ShowOrderMenuItems = false;
      this.cartItemFormData = cartItem;
      this.tempFormData = new ShoppingCartItem();
      this.tempFormData.quantity = cartItem.quantity;
      this.tempFormData.price = cartItem.price / cartItem.quantity;
      this.tempFormData.notes = cartItem.notes;
      this.initSideSelection(cartItem.menuItem, null, true, cartItem);
    } else {
      this.toggleService.ShowCartItemToEdit = true;
      this.shoppingCartService.DisplayShoppingCart = false;
      this.toggleService.ShowOrderMenuItems = false;
      this.cartItemFormData = cartItem;
      this.tempFormData = new ShoppingCartItem();
      this.tempFormData.quantity = cartItem.quantity;
      this.tempFormData.price = cartItem.price / cartItem.quantity;
      this.tempFormData.notes = cartItem.notes;
      this.itemCurrentPriceFocus();
    }
  }

  closeEditCartItemForm() {
    this.toggleService.ShowCartItemToEdit = false;
    this.shoppingCartService.DisplayShoppingCart = true;
    this.toggleService.ShowOrderMenuItems = true;
  }

  saveUpdatedCartItem() {
    this.shoppingCartService.DisplayShoppingCart = true;
    this.toggleService.ShowCartItemToEdit = false;
    this.cartItemFormData.notes = this.tempFormData.notes;
    this.cartItemFormData.quantity = this.tempFormData.quantity;
    this.cartItemFormData.price = this.tempFormData.price * this.tempFormData.quantity;
    this.cartItemFormData.pricePermanent = this.tempFormData.pricePermanent;
    this.cartItemFormData.subShoppingCartItems = this.tempFormData.subShoppingCartItems? this.tempFormData.subShoppingCartItems: [];
    this.cartItemFormData.subTaxTotal = this.tempFormData.subTaxTotal? this.tempFormData.subTaxTotal: 0;
    this.cartItemFormData.subPriceTotal = this.tempFormData.subPriceTotal? this.tempFormData.subPriceTotal: 0;
    //this.cartLoaderFlag = true;
    const cartSubscription = this.shoppingCartService.saveShoppingCartItem(this.cartItemFormData).subscribe((ci) => {
      //this.cartLoaderFlag = false;
      this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
      this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
      this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
      this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
      this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
      this.toggleService.ShowOrderMenuItems = true;
      this.paymentService.updateSplitBalance();
    });
    this.apiSubscription.add(cartSubscription);

    if (this.cartItemFormData.pricePermanent === true) {
      this.cartItemFormData.menuItem.price = this.tempFormData.price;
      this.menuItemService.updateLocalStorage(this.cartItemFormData.menuItem);
      const idx = this.menuItemService.OrderMenuItems.findIndex(oi => oi.id === this.cartItemFormData.menuItem.id);
      if (idx >= 0) {
        this.menuItemService.OrderMenuItems[idx].price = this.tempFormData.price;
      }
      this.tempFormData.menuItem.price = this.tempFormData.price;
    }
  }

  openOkayDialog(msgHeader: string, msg: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {});
    this.apiSubscription.add(dialogSubscription);
  }

  itemCurrentPriceFocus() {
    setTimeout(() => {
      this.itemCurrentPriceElement.nativeElement.focus();
    }, 100);
  }

  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
    this.clearCartsubscription.unsubscribe();
  }

  bindKeyPad(inputName) {
    this.activeKeypad = inputName;
    this.bindKeypad = true;
  }

  unBindKeyPad() {
  }

  navClickEvent() {
    this.toggleService.ShowSideItems = false;
    this.shoppingCartService.DisplayShoppingCart = true;
    this.toggleService.ShowOrderMenuItems = true;
  }

  initSideSelection(shoppingCartItem: MenuItem, displayIndex: number, isEdit: boolean, cartItem?) {
    this.inputItem = {
      menuItem : shoppingCartItem,
      subShoppingCartItems: [],
      displayIndex: displayIndex,
      isEditMode: isEdit,
      sideForm: {
        quantity: 1,
        price: shoppingCartItem.price,
        pricePermanent: false,
        preference: "",
        notes: "",
        subTaxTotal: 0,
        subPriceTotal: 0
      }
    }

    if (isEdit) {
      let currentPreference = cartItem.subShoppingCartItems.find(item => item.preference);
      this.inputItem.sideForm.quantity = cartItem.quantity;
      this.inputItem.sideForm.price = cartItem.price / cartItem.quantity;
      this.inputItem.sideForm.pricePermanent = cartItem.pricePermanent;
      this.inputItem.subShoppingCartItems = cartItem.subShoppingCartItems;
      this.inputItem.sideForm.preference = currentPreference? currentPreference['name'] : "";
    }
  }

  

  setAmount(data) {
    if (data.key.indexOf('.') != -1) {
      let keys = data.key.split('.');
      if(keys.length == 2) {
        this[keys[0]][keys[1]] = data.value;
      }
      else if(keys.length == 3) {
        this[keys[0]][keys[1]][keys[2]] = data.value;
      }
      else if(keys.length == 4) {
        this[keys[0]][keys[1]][keys[2]][keys[3]] = data.value;
      }
    }
    else {
      this[data.key] = data.value;
    }
  }

  showCartSides(item) { 
    let availableItem = item.subShoppingCartItems.filter(i => !i.preference);
    if (availableItem && availableItem.length > 0) {
      return availableItem.map(item => {
          return item.name;
      }).join(', ');
    } else {
      return null;
    }
  }

  showPreference(item) {
    let availableItem = item.subShoppingCartItems.find(i => i.preference);
    if (availableItem) {
      return availableItem.name;
    } else {
      return null;
    }
  }
}


<div class="page-frame">
	<div class="page-frame-header">
		<app-header (emittedCheckoutClick)="setFocusOnTenderedField($event.value)" (emittedChairClick)="toggleEbtPaymentCheckBox($event.value)"></app-header>
	</div>
	<div class="page-frame-body">
		<div class="full-height-container pad-20" style="border-top: 2px solid #cdcdcd;">
			<span class="payment-loader" *ngIf="paymentProgressBar.spinner"><mat-spinner></mat-spinner></span>
			<div class="invoice-checkout-container">
				<div class="invoice-checkout-steps">
					<ul>
						<li *ngFor="let item of cartStep" (click)="goToStep(item)" [ngClass]="{'active': item.isActive, 'pass': item.isPass }">
							<span>
								<span></span>
							</span>
						</li>
					</ul>
				</div>
				<div class="invoice-checkout-section shadow-radius-container grow-height">
					<div class="checkout-summary-container" [ngClass]="{'minimize' : this.cartStepSelected.step != '0' }">
						<div class="checkout-summary" [ngClass]="{'minimize' : (toggleSummary && this.cartStepSelected.step != '0') }">
							<div class="checkout-summary-header">
								<h2>Payment Summary</h2>
								<span (click)="toggleSummaryFn()" *ngIf="this.cartStepSelected.step != '0'"> 
									<i class="fas fa-angle-double-left" *ngIf="!toggleSummary"></i>
									<i class="fas fa-angle-double-right" *ngIf="toggleSummary"></i>
								</span>
							</div>
							<div class="checkout-summary-body" *ngIf="checkoutLoader" style="width: 100%; text-align: center; padding: 20px 0px;">
								<span class="mat-loader"><mat-spinner></mat-spinner></span>
							</div>
							<div class="checkout-summary-body" *ngIf="!checkoutLoader && cartInfo">
								<div class="checkout-summary-row">
									<div class="row">
										<div class="col">
											<label>Sub Total</label>
										</div>
										<div class="col text-end">
											<label>{{ cartInfo.nonEbtSubTotal + cartInfo.ebtTotal | currency}}</label>
										</div>
									</div>
									<div class="row" *ngIf="selectedPaymentMethod == 'EBT' || selectedPaymentMethod == 'SPLIT'">
										<div class="col">
											<span>EBT Eligable</span>
										</div>
										<div class="col text-end">
											<span>{{ cartInfo.ebtTotal | currency }}</span>
										</div>
									</div>
									<div class="row" *ngIf="selectedPaymentMethod == 'EBT' || selectedPaymentMethod == 'SPLIT'">
										<div class="col">
											<span>Non EBT Eligable</span>
										</div>
										<div class="col text-end">
											<span>{{ cartInfo.nonEbtSubTotal | currency }}</span>
										</div>
									</div>
								</div>
								<div class="checkout-summary-row">
									<div class="row">
										<div class="col">
											<label>Tax</label>
										</div>
										<div class="col text-end">
											<label>{{ cartInfo.taxTotal | currency}}</label>
										</div>
									</div>
								</div>
								<div class="checkout-summary-row total-row">
									<div class="row">
										<div class="col">
											<label>Total</label>
										</div>
										<div class="col text-end">
											<label>{{ cartInfo.invoiceAmount | currency}}</label>
										</div>
									</div>
								</div>
							</div>
							<div class="checkout-summary-footer">
								<button type="button" *ngIf="!paymentService.PaymentConfirmation" (click)="printInvoice()" class="btn btn-40 btn-white">
									<i class="fas fa-print"></i> Print Invoice
								</button>
								<button type="button" *ngIf="paymentService.PaymentConfirmation" (click)="emailReceipt()" class="btn btn-40 btn-white">
									<i class="fas fa-email"></i> Email Receipt
								</button>
								<button type="button" *ngIf="paymentService.PaymentConfirmation" (click)="textReceipt()" class="btn btn-40 btn-white">
									<i class="fas fa-print"></i> Text Receipt
								</button>
							</div>
						</div>
					</div>
					<div class="checkout-section" *ngIf="cartStepSelected.step == '0'">
						<div class="checkout-payment-selection">
							<ul class="payment-options">
								<li [ngClass]="{'active': selectedPaymentMethod == 'CASH'}" (click)="changePaymentMethod('CASH')">
									<span class="payment-selection"><span></span></span>
									<span class="payment-label">Cash</span>
									<span class="payment-icon"><i class="fas fa-coins"></i></span>
								</li>
								<li [ngClass]="{'active': selectedPaymentMethod == 'CREDIT_CARD'}" (click)="changePaymentMethod('CREDIT_CARD')">
									<span class="payment-selection"><span></span></span>
									<span class="payment-label">Credit</span>
									<span class="payment-icon"><i class="far fa-credit-card"></i></span>
								</li>
								<li [ngClass]="{'active': selectedPaymentMethod == 'DEBIT_CARD'}" (click)="changePaymentMethod('DEBIT_CARD')">
									<span class="payment-selection"><span></span></span>
									<span class="payment-label">Debit</span>
									<span class="payment-icon"><i class="far fa-credit-card"></i></span>
								</li>
								<ng-container *ngIf="isRetail">
									<li [ngClass]="{'active': selectedPaymentMethod == 'EBT'}" (click)="changePaymentMethod('EBT')">
										<span class="payment-selection"><span></span></span>
										<span class="payment-label">EBT</span>
										<span class="payment-icon"><i class="fas fa-credit-card"></i></span>
									</li>
								</ng-container>
								<li [ngClass]="{'active': selectedPaymentMethod == 'GIFT_CARD'}" (click)="changePaymentMethod('GIFT_CARD')">
									<span class="payment-selection"><span></span></span>
									<span class="payment-label">Gift Card</span>
									<span class="payment-icon"><i class="fas fa-gift"></i></span>
								</li>
								<li [ngClass]="{'active': selectedPaymentMethod == 'OTHER'}" (click)="changePaymentMethod('OTHER')">
									<span class="payment-selection"><span></span></span>
									<span class="payment-label">Other</span>
									<span class="payment-icon"><i class="far fa-credit-card"></i></span>
								</li>
								<li [ngClass]="{'active': selectedPaymentMethod == 'SPLIT'}" (click)="changePaymentMethod('SPLIT')">
									<span class="payment-selection"><span></span></span>
									<span class="payment-label">Split</span>
									<span class="payment-icon"><i class="fas fa-money-check-alt"></i></span>
								</li>
							</ul>
							<div class="btn-checkout-row">
								<button class="btn btn-40 btn-white float-start" type="button" (click)="backOrderPage()">
									<i class="fas fa-arrow-left"></i> Back
								</button>
								<button *ngIf="selectedPaymentMethod == 'SPLIT' || selectedPaymentMethod == 'CASH'" class="btn btn-40 btn-success float-end" type="button" [disabled]="!selectedPaymentMethod" (click)="changePaymentMethod(selectedPaymentMethod)">
									<i class="fas fa-check-circle"></i> Proceed
								</button>
								<button *ngIf="selectedPaymentMethod != 'SPLIT' && selectedPaymentMethod != 'CASH'" class="btn btn-40 btn-success float-end" type="button" [disabled]="!selectedPaymentMethod" (click)="byPassPaymentProcess(selectedPaymentMethod)">
									<i class="fas fa-check-circle"></i> Pay Now
								</button>
							</div>
						</div>
					</div>
					<div class="checkout-section" *ngIf="cartStepSelected.step == '1'">
						<div class="checkout-frame">
							<div class="checkout-frame-left">
								<div class="checkout-frame-header" *ngIf="!toggleTransactions">
									<h2>{{ constants['TITLE_' + selectedPaymentMethod] }}</h2>
									<span *ngIf="selectedPaymentMethod == 'SPLIT'" (click)="toggleTransactionsFn()">Transactions <img src="/assets/images/transactions-icon.png" /></span>
								</div>
								<div class="checkout-frame-header" *ngIf="toggleTransactions">
									<h2>Transactions</h2>
									<span (click)="toggleTransactionsFn()"><i class="far fa-times-circle"></i></span>
								</div>
								<div class="checkout-frame-body" *ngIf="!toggleTransactions && !checkoutLoader">
									<div class="checkout-payment-form">
										<div class="row" style="padding-bottom: 10px;">
											<!-- *ngIf="!ebtPaymentProcess || !isRetail" -->
											<div class="col-md-6">
												<div class="amount-snippet red-font" *ngIf="cartInfo.balance > 0">
													<label>Payment Due:</label>
													<span>{{ cartInfo.balance | currency}}</span>
												</div>
											</div>
											<!-- <div class="col-md-6" *ngIf="ebtPaymentProcess && isRetail">
												<div class="amount-snippet red-font" *ngIf="cartInfo.balance > 0">
													<label>Payment Due:</label>
													<span>{{ cartInfo.nonEbtSubTotal | currency}}</span>
												</div>
											</div> -->
											<div class="col-md-6" *ngIf="selectedSplitPaymentMethod == 'EBT' || selectedPaymentMethod == 'EBT'">
												<div class="amount-snippet red-font">
													<label class="text-end">EBT Due:</label>
													<span class="text-end">{{ cartInfo.cartCalculations.ebtBalance | currency }}</span>
												</div>
											</div>
										</div>
										<!-- <h2 *ngIf="cartInfo.balance > 0">Payment Due: 
											<span *ngIf="selectedSplitPaymentMethod != 'EBT' && selectedPaymentMethod != 'EBT'">A {{ cartInfo.balance | currency }}</span>
											<span *ngIf="selectedSplitPaymentMethod == 'EBT' || selectedPaymentMethod == 'EBT'">B {{ cartInfo.cartCalculations.ebtBalance | currency }}</span>
										</h2> -->
										<div class="payment-form-field checkbox-field d-flex align-items-center" *ngIf="selectedPaymentMethod == 'CREDIT_CARD'">
											<span class="custom-check">
												<input 
													id= "processAsDebit"
													type="checkbox" 
													name="processPaymentAsDebit" 
													[(ngModel)]="processPaymentAsDebit"
												/>
												<label for="processAsDebit"><i class="fas fa-check"></i></label>
											</span>
											<label for="processAsDebit">Process As Debit</label>
										</div>
										<div class="payment-form-field checkbox-field d-flex align-items-center" *ngIf="selectedPaymentMethod == 'OTHER' && isRetail">
											<span class="custom-check">
												<input 
													id= "ebtPayment"
													type="checkbox" 
													name="ebtPaymentProcess" 
													(change)="changeOtherEBT()"
													[(ngModel)]="ebtPaymentProcess"
												/>
												<label for="ebtPayment"><i class="fas fa-check"></i></label>
											</span>
											<label for="ebtPayment">EBT</label>
										</div>
										<div class="payment-form-field" *ngIf="selectedPaymentMethod == 'SPLIT'">
											<ul class="payment-options">
												<li [ngClass]="{'active': selectedSplitPaymentMethod == 'CASH'}" (click)="updatedSplitPayment('CASH')">
													<span class="payment-selection"><span></span></span>
													<span class="payment-label">Cash</span>
													<span class="payment-icon"><i class="fas fa-coins"></i></span>
												</li>
												<li [ngClass]="{'active': selectedSplitPaymentMethod == 'CREDIT_CARD'}" (click)="updatedSplitPayment('CREDIT_CARD')">
													<span class="payment-selection"><span></span></span>
													<span class="payment-label">Credit / Debit</span>
													<span class="payment-icon"><i class="far fa-credit-card"></i></span>
												</li>
												<ng-container *ngIf="isRetail">
													<li [ngClass]="{'active': selectedSplitPaymentMethod == 'EBT'}" (click)="updatedSplitPayment('EBT')">
														<span class="payment-selection"><span></span></span>
														<span class="payment-label">EBT</span>
														<span class="payment-icon"><i class="fas fa-credit-card"></i></span>
													</li>
												</ng-container>
												<li [ngClass]="{'active': selectedSplitPaymentMethod == 'GIFT_CARD'}" (click)="updatedSplitPayment('GIFT_CARD')">
													<span class="payment-selection"><span></span></span>
													<span class="payment-label">Gift Card</span>
													<span class="payment-icon"><i class="fas fa-gift"></i></span>
												</li>
											</ul>
										</div>
										<div class="payment-form-field" style="padding-bottom: 20px;">
											<input currencyMask [(ngModel)]="paymentAmount" [readonly]="selectedPaymentMethod != 'SPLIT' && selectedPaymentMethod != 'CASH' || (selectedPaymentMethod == 'SPLIT' && !selectedSplitPaymentMethod)" #paymentAmountField (focus)="bindKeyPad()" name="paymentAmountField" class="form-control" type="text" />
										</div>
										<button class="btn btn-40 btn-white float-start" type="button" (click)="resetStep()">
											<i class="fas fa-arrow-left"></i> Back
										</button>
										<button class="btn btn-40 btn-success float-end" type="button" (click)="sendPayment()">
											<i class="fas fa-check-circle"></i> Pay
										</button>
									</div>
								</div>
								<div class="checkout-frame-body" *ngIf="checkoutLoader" style="width: 100%; text-align: center; padding: 20px 0px;">
									<span class="mat-loader"><mat-spinner></mat-spinner></span> 
								</div>
								<div class="transaction-container" *ngIf="toggleTransactions && !checkoutLoader">
									<div class="row">
										<div class="col-md-6">
											<div class="amount-snippet red-font" *ngIf="cartInfo.balance > 0">
												<label>Payment Due:</label>
												<span>{{ cartInfo.balance | currency}}</span>
											</div>
										</div>
										<div class="col-md-6">
											<div class="amount-snippet green-font">
												<label class="text-end">Payment Received:</label>
												<span class="text-end">{{ cartInfo.totalAmountPaid | currency}}</span>
											</div>
										</div>
									</div>
									<div class="payment-history-section">
										<div class="payment-item" *ngFor="let payment of cartInfo.authorizedPayments">
											<div class="row">
												<div class="col" *ngIf="payment.paymentType == 'CASH'">
													<p>Paid by {{ payment.paymentType }}: <b>{{ payment.amount | currency }}</b></p>
												</div>
												<div class="col" *ngIf="payment.paymentType != 'CASH'">
													<p>Paid by {{ payment.cardType }}: <b>{{ payment.amount | currency }}</b></p>
												</div>
												<div class="col-auto">
													<span class="delete-icon" (click)="voidTransation(payment)"><i class="far fa-trash-alt"></i></span>
												</div>
											</div>
										</div>
										<div class="payment-item cashChange" *ngIf="cartInfo.balance < 0">
											<div class="row">
												<div class="col">
													<p>Cash Change: <b>$10</b></p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="checkout-frame-right">
								<app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'paymentAmount'" [inputValue]="paymentAmount" (outputValue)="setAmount($event)" [specialKeys]="true" [isPrice]="true" [isDotAllowed]="true"></app-keypad>
							</div>
						</div>
					</div>
					<div class="checkout-section" *ngIf="cartStepSelected.step == '2'">
						<div class="checkout-payment-success">
							<div class="success-message">
								<i class="far fa-check-circle"></i>
								<h2>Transaction Completed successfully</h2>
								<!-- <p>Your Order has been successfully Completed</p> -->
							</div>
							<br />
							<div class="cash-change" *ngIf="receipt && receipt.changeAmount > 0">
								Change Amount Due: {{ receipt.changeAmount | currency}}
							</div> 
							<br />
							<button class="btn btn-40 btn-success" type="button" (click)="backOrderPage()">
								<i class="fas fa-check-circle"></i> Complete
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="full-height-container pad-20" style="border-top: 2px solid #cdcdcd;">
			<div class="shadow-radius-container grow-height">
				<div class="invoice-checkout-container">
					<div class="invoice-checkout-details">
						<div class="invoice-inner-container">
							<div class="row space-0">
								<div class="col">
									<h2>Invoice <br/> <span class="invoice-status orange">Unpaid</span></h2>
								</div>
								<div class="col-md-auto">
									<button type="button" class="btn btn-40 btn-white float-end">
										<i class="fas fa-print"></i> Print Invoice
									</button>
								</div>
							</div>
							<div class="button-tab">
								<ul>
									<li [ngClass]="{'active': ebtStatus == 'ebt'}" (click)="changeTab('ebt')">EBT</li>
									<li [ngClass]="{'active': ebtStatus == 'nonEbt'}" (click)="changeTab('nonEbt')">NON EBT</li>
								</ul>
							</div>
							<div class="invoice-summary">
								<div class="invoice-summary-subsection">
									<div class="invoice-row">
										<div class="row align-items-center">
											<div class="col">
												<label>Sub Total</label>
											</div>
											<div class="col text-end">
												<span>$200</span>
											</div>
										</div>
										<div class="invoice-breakups" *ngIf="ebtStatus == 'ebt'">
											<div class="row align-items-center">
												<div class="col">
													<label>EBT Payable</label>
												</div>
												<div class="col text-end">
													<span>$20</span>
												</div>
											</div>
											<div class="row align-items-center">
												<div class="col">
													<label>Non EBT Payable</label>
												</div>
												<div class="col text-end">
													<span>$20</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="invoice-summary-subsection">
									<div class="invoice-row">
										<div class="row align-items-center">
											<div class="col">
												<label>Tax</label>
											</div>
											<div class="col text-end">
												<span>$20</span>
											</div>
										</div>
									</div>
									<div class="invoice-row total-row">
										<div class="row align-items-center">
											<div class="col">
												<label>Total Payable</label>
											</div>
											<div class="col text-end">
												<span>$220</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="invoice-summary-button text-center">
								<button class="btn btn-40 btn-success" type="submit">
									<i class="fas fa-check-circle"></i> Complete Payment
								</button>
							</div>
						</div>
					</div>
					<div class="invoice-checkout-payments">
						<div class="payment-summary-section">
							<div class="row">
								<div class="col-md-6">
									<div class="amount-snippet red-font">
										<label>Payment Due:</label>
										<span>$0.00</span>
									</div>
								</div>
								<div class="col-md-6">
									<div class="amount-snippet green-font">
										<label class="text-end">Payment Received:</label>
										<span class="text-end">$0.00</span>
									</div>
								</div>
							</div>
							<div class="payment-history-section">
								<div class="payment-item">
									<div class="row">
										<div class="col">
											<p>Paid by Cash: <b>$20</b></p>
										</div>
										<div class="col-auto">
											<span class="delete-icon"><i class="far fa-trash-alt"></i></span>
										</div>
									</div>
								</div>
								<div class="payment-item">
									<div class="row">
										<div class="col">
											<p>Paid by Cash: <b>$20</b></p>
										</div>
										<div class="col-auto">
											<span class="delete-icon"><i class="far fa-trash-alt"></i></span>
										</div>
									</div>
								</div>
								<div class="payment-item cashChange">
									<div class="row">
										<div class="col">
											<p>Cash Change: <b>$10</b></p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="payment-method-section">
							<div class="standard-accordion" *ngIf="paymentAccordion.length > 0">
								<div class="standard-accordion-pane" *ngFor="let accordion of paymentAccordion">
									<div class="standard-accordion-header" (click)="toggleAccordion(accordion, true)">
										<h2>{{ accordion.title }}</h2>
										<span><i *ngIf="!accordion.isOpen" class="fas fa-chevron-down"></i><i *ngIf="accordion.isOpen" class="fas fa-chevron-down"></i></span>
									</div>
									<div class="standard-accordion-body" *ngIf="accordion.isOpen">
										<div class="select-due-amount">
											<span class="custom-check">
												<input type="checkbox" id="payDueAmount" />
												<label for="payDueAmount"><i class="fas fa-check"></i></label>
											</span>
											<label for="payDueAmount">Pay Due Amount</label>
										</div>
										<div class="payment-field">
											<span>
												<input type="number" class="form-control" currency placeholder="Enter Amount">
											</span>
											<button class="btn btn-success">
												<i class="fas fa-check-circle"></i>
											</button>
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="invoice-checkout-keypad"> 
						<app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'cashTenderedAmount'" [inputValue]="cashTenderedAmount" (outputValue)="setAmount($event)" [specialKeys]="true" [isPrice]="true" [isDotAllowed]="true"></app-keypad>
					</div>
				</div>
			</div>
		</div> -->
		
		<!-------------------------------- CASH ENDS  ------------------------------------------->
		<!-- <div class="full-height-container pad-20" style="border-top: 2px solid #cdcdcd;">
			 -->
			<!-------------------------------- CASH BEGINS ------------------------------------->
			<!-- <div class="keypad-data-container" *ngIf="paymentService.SelectedPaymentType === paymentService.CASH">
				<div class="data-container">
					<form #checkoutForm="ngForm" class="full-width" (ngSubmit)="submitPayment()">
						<div class="summery-container">
							<div class="summery-container-header">
								<div class="row space-0">
									<div class="col d-flex">
										<h2 class="align-self-center">Payment Mode: <b>Cash</b></h2>
									</div>
									<div class="col-md-auto">
										<button type="button" *ngIf="!paymentService.PaymentConfirmation" (click)="printInvoice()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-print"></i> Print Invoice
										</button>
										<button type="button" *ngIf="paymentService.PaymentConfirmation" (click)="emailReceipt()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-email"></i> Email Receipt
										</button>
										<button type="button" *ngIf="paymentService.PaymentConfirmation" (click)="textReceipt()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-print"></i> Text Receipt
										</button>
									</div>
								</div>
							</div>
							<div class="summery-container-body">
								<div class="dot-table">
									<div class="dot-table-header">
										<div class="dot-table-row">
											<div class="row"> 
												<div class="col-md-8 text-start">
													<label>Items</label>
												</div>
												<div class="col text-end"> 
													<label>Amount</label>
												</div>
											</div>
										</div>
									</div>
									<div class="dot-table-body">
										<div class="dot-table-row">
											<div class="row">
												<div class="col-md-8 text-start">
													Sub Total
												</div>
												<div class="col text-end">
													{{cartAmount | currency}}
												</div>
											</div>
											<div class="row">
												<div class="col-md-8 text-start">
													Tax
												</div>
												<div class="col text-end">
													{{cartTaxAmount | currency}}
												</div>
											</div>
										</div>
									</div>
									<div class="dot-table-footer">
										<div class="dot-table-row no-border">
											<div class="row">
												<div class="col-md-8 text-start">
													<div class="row space-10">
														<div class="col-md-auto d-flex"><label class="align-self-center">Cash Amount: </label></div>
														<div class="col">
															<input currencyMask [(ngModel)]="cashTenderedAmount" #tenderedCashAmountInput (focus)="bindKeyPad()" name="tenderedAmount" class="form-control" type="text" />
														</div>
													</div>
												</div> 
												<div class="col d-flex justify-content-end">
													<h4 class="price-theme align-self-center">{{cartTotalAmount | currency}}</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="summery-container-footer">
								<button class="btn btn-40 btn-success" type="submit">
									<i class="fas fa-check-circle"></i> Submit Payment
								</button>
								<button class="btn btn-40 btn-danger float-end" type="button" (click)="backOrderPage()">
									<i class="fas fa-times-circle"></i> Close
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="keypad-container">
					<app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'cashTenderedAmount'" [inputValue]="cashTenderedAmount" (outputValue)="setAmount($event)" [specialKeys]="true" [isPrice]="true" [isDotAllowed]="true"></app-keypad>
				</div>
			</div> -->
			<!-------------------------------- CASH ENDS  ------------------------------------------->

			<!-------------------------------- EMV CARD BEGINS ------------------------------------->
			<!-- <div class="keypad-data-container" *ngIf="paymentService.SelectedPaymentType === paymentService.CREDIT_CARD">
				<div class="data-container">
					<form #checkoutForm="ngForm" class="full-width" (ngSubmit)="submitPayment()">
						<div class="summery-container">
							<div class="summery-container-header">
								<div class="row space-0">
									<div class="col d-flex">
										<h2 class="align-self-center">Payment Mode: <b>Debit / Credit</b></h2>
									</div>
									<div class="col-md-auto">
										
										<button type="button" *ngIf="!paymentService.PaymentConfirmation" (click)="printInvoice()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-print"></i> Print Invoice
										</button>
										<button type="button" *ngIf="paymentService.PaymentConfirmation" (click)="emailReceipt()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-email"></i> Email Receipt
										</button>
										<button type="button" *ngIf="paymentService.PaymentConfirmation" (click)="textReceipt()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-print"></i> Text Receipt
										</button>
									</div>
								</div>
							</div>
							<div class="summery-container-body">
								<div class="dot-table">
									<div class="dot-table-header">
										<div class="dot-table-row">
											<div class="row"> 
												<div class="col-md-8 text-start">
													<label>Items</label>
												</div>
												<div class="col text-end"> 
													<label>Amount</label>
												</div>
											</div>
										</div>
									</div>
									<div class="dot-table-body">
										<div class="dot-table-row">
											<div class="row">
												<div class="col-md-8 text-start">
													Sub Total
												</div>
												<div class="col text-end">
													{{cartAmount | currency}}
												</div>
											</div>
											<div class="row">
												<div class="col-md-8 text-start">
													Tax
												</div>
												<div class="col text-end">
													{{cartTaxAmount | currency}}
												</div>
											</div>
										</div>
									</div>
									<div class="dot-table-footer">
										<div class="dot-table-row no-border">
											<div class="row">
												<div class="col-md-8 text-start">
													<div class="row space-10">
														<div class="col-md-auto d-flex" *ngIf="authenticationService.isRetail() && toggleService.ProcessPaymentAsEBT">
															<span class="custom-check align-self-center">
																<input 
																	id="check2"
																	[disabled]="true" 
																	type="checkbox" 
																	name="processEBT"
																	[(ngModel)]="toggleService.ProcessPaymentAsEBT" 
																/>
																<label for="check2"><i class="fas fa-check"></i></label>
															</span>
														</div>
														<div class="col-md-auto d-flex" *ngIf="authenticationService.isRetail() && toggleService.ProcessPaymentAsEBT"><label class="align-self-center">EBT </label></div>
														<div class="col-md-auto d-flex">
															<span class="custom-check align-self-center">
																<input 
																	id= "check1"
																	type="checkbox" 
																	name="processPaymentAsDebit" 
																	[(ngModel)]="processPaymentAsDebit"
																/>
																<label for="check1"><i class="fas fa-check"></i></label>
															</span>
														</div>
														<div class="col-md-auto d-flex"><label class="align-self-center">Process as Debit </label></div>
														<div class="col">
															<input 
																currencyMask 
																[(ngModel)]="cardTenderedAmount" 
																#tenderedCreditCardAmountInput 
																name="tenderedAmount" 
																class="form-control" 
																(focus)="bindKeyPad()"
																disabled
																type="text" 
															/>
															<div class="spinner" *ngIf="toggleService.ShowPaymentProgress">
																<app-spinner>in progress.......</app-spinner>
															</div>
														</div>
													</div>
												</div> 
												<div class="col d-flex justify-content-end">
													<h4 class="price-theme align-self-center">{{cartTotalAmount | currency}}</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="summery-container-footer">
								<button class="btn btn-40 btn-success" type="submit">
									<i class="fas fa-check-circle"></i> Submit Payment
								</button>
								<button class="btn btn-40 btn-danger float-end" type="button" (click)="backOrderPage()">
									<i class="fas fa-times-circle"></i> Close
								</button>
								<button type="button" *ngIf="paymentService.SelectedPaymentType === paymentService.CREDIT_CARD" routerLink="../paymentresponselog" class="btn btn-40 btn-white mar-r-10 float-end">
									<i class="fa fa-user-check"></i> Verify Payment
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="keypad-container">
					<app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'cardTenderedAmount'" [inputValue]="cardTenderedAmount" (outputValue)="setAmount($event)" [specialKeys]="true" [isPrice]="true" [isDotAllowed]="true"></app-keypad>
				</div>
			</div> -->
			<!-------------------------------- EMV CARD ENDS  ------------------------------------------->

			<!-------------------------------- RECORD CARD PAYMENT BEGINS ------------------------------------->
			<!-- <div class="keypad-data-container" *ngIf="paymentService.SelectedPaymentType === paymentService.OTHER">
				<div class="data-container">
					<form #checkoutForm="ngForm" class="full-width" (ngSubmit)="submitPayment()">
						<div class="summery-container">
							<div class="summery-container-header">
								<div class="row space-0">
									<div class="col d-flex">
										<h2 class="align-self-center">Payment Outside of <b>P.O.S</b></h2>
									</div>
									<div class="col-md-auto">
										<button type="button" *ngIf="!paymentService.PaymentConfirmation" (click)="printInvoice()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-print"></i> Print Invoice
										</button>
										<button type="button" *ngIf="paymentService.PaymentConfirmation" (click)="emailReceipt()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-email"></i> Email Receipt
										</button>
										<button type="button" *ngIf="paymentService.PaymentConfirmation" (click)="textReceipt()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-print"></i> Text Receipt
										</button>
									</div>
								</div>
							</div>
							<div class="summery-container-body">
								<div class="dot-table">
									<div class="dot-table-header">
										<div class="dot-table-row">
											<div class="row"> 
												<div class="col-md-8 text-start">
													<label>Items</label>
												</div>
												<div class="col text-end"> 
													<label>Amount</label>
												</div>
											</div>
										</div>
									</div>
									<div class="dot-table-body">
										<div class="dot-table-row">
											<div class="row">
												<div class="col-md-8 text-start">
													Sub Total
												</div>
												<div class="col text-end">
													{{cartAmount | currency}}
												</div>
											</div>
											<div class="row">
												<div class="col-md-8 text-start">
													Tax
												</div>
												<div class="col text-end">
													{{cartTaxAmount | currency}}
												</div>
											</div>
										</div>
									</div>
									<div class="dot-table-footer">
										<div class="dot-table-row no-border">
											<div class="row">
												<div class="col-md-8 text-start">
													<div class="row space-10">
														<div class="col-md-auto d-flex" *ngIf="authenticationService.isRetail() && toggleService.ProcessPaymentAsEBT">
															<span class="custom-check align-self-center">
																<input 
																	id="check2"
																	[disabled]="true" 
																	type="checkbox" 
																	name="processEBT"
																	[(ngModel)]="toggleService.ProcessPaymentAsEBT"
																/>
																<label for="check2"><i class="fas fa-check"></i></label>
															</span>
														</div>
														<div class="col-md-auto d-flex" *ngIf="authenticationService.isRetail() && toggleService.ProcessPaymentAsEBT"><label class="align-self-center">EBT</label></div>
														<div class="col-md-auto d-flex"><label class="align-self-center" style="padding-left:20px;">Other Amount: </label></div>
														<div class="col">
															<input 
																currencyMask 
																[(ngModel)]="cardTenderedAmount" 
																#tenderedOtherAmountInput 
																name="tenderedAmount" 
																class="form-control" 
																(focus)="bindKeyPad()"
																type="text" 
															/>
														</div>
													</div>
												</div> 
												<div class="col d-flex justify-content-end">
													<h4 class="price-theme align-self-center">{{cartTotalAmount | currency}}</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="summery-container-footer">
								<button class="btn btn-40 btn-success" type="submit">
									<i class="fas fa-check-circle"></i> Submit Payment
								</button>
								<button class="btn btn-40 btn-danger float-end" type="button" (click)="backOrderPage()">
									<i class="fas fa-times-circle"></i> Close
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="keypad-container">
					<app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'cardTenderedAmount'" [inputValue]="cardTenderedAmount" (outputValue)="setAmount($event)" [specialKeys]="true" [isPrice]="true" [isDotAllowed]="true"></app-keypad>
				</div>
			</div> -->
			<!-------------------------------- RECORD CARD PAYMENT ENDS  ------------------------------------------->

			<!-------------------------------- SPLIT PAYMENT BEGINS ------------------------------------->
			<!-- <div class="center-container" *ngIf="(paymentService.SelectedPaymentType == paymentService.SPLIT_PAYMENT && toggleService.ShowDoneButton) || showMatLoader">
				<div class="full-width">
					<div class="row">
						<div class="col-md-4">

						</div>
						<div class="col-md-4">
							<div class="shadow-radius-container">
								<div class="summery-container">
									<div class="summery-container-header">
										<div class="row space-0">
											<div class="col d-flex">
												<h2 class="align-self-center">Payment Confirmation</h2>
											</div>
										</div>
									</div>
									<div class="summery-container-body">
										<div *ngIf="showMatLoader" style="padding-top: 20px; padding-bottom: 20px;"><mat-spinner></mat-spinner></div>
										<div class="dot-table" *ngIf="!showMatLoader">
											<div class="dot-table-header">
												<div class="dot-table-row">
													<div class="row"> 
														<div class="col text-start">
															<label></label>
														</div>
														<div class="col text-end"> 
															<label>Amount</label>
														</div>
													</div>
												</div>
											</div>
											<div class="dot-table-body">
												<div class="dot-table-row">
													<div class="row">
														<div class="col text-start">
															<label class="text-green">Invoice Amount:</label>
														</div>
														<div class="col text-end">
															<label class="text-green">{{cartTotalAmount | currency}}</label>
														</div>
													</div>
												</div>
												<div class="dot-table-row" *ngIf="paymentService && paymentService.SplitSummary.length > 0">
													<div class="row" *ngFor="let splitPay of paymentService.SplitSummary, let i = index;">
														<div class="col-md-8 text-start" *ngIf="splitPay.cardType">
															<label>Paid By: {{splitPay.cardType}}</label>
														</div>
														<div class="col-md-8 text-start" *ngIf="!splitPay.cardType">
															<label>Paid By: {{splitPay.paymentType}}</label>
														</div>
														<div class="col d-flex">
															<label style="width: 100%; text-align: right;" class="align-self-center">{{splitPay.amount | currency}} </label>
														</div>
													</div>
												</div>
												<div class="dot-table-row">
													<div class="row">
														<div class="col text-start">
															<label class="text-orange">Change Amount:</label>
														</div>
														<div class="col text-end">
															<label class="text-orange">{{paymentService.Balance | currency}}</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="summery-container-footer">
										<button class="btn btn-40 btn-success" type="submit" (click)="completeSplitPaymentTransaction()">
											<i class="fas fa-check-circle"></i> Complete
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4">

						</div>
					</div>
				</div>
			</div>
			<div class="keypad-data-container" *ngIf="paymentService.SelectedPaymentType == paymentService.SPLIT_PAYMENT && !toggleService.ShowDoneButton && !showMatLoader">
				<div class="data-container">
					<form #checkoutForm="ngForm" class="full-width" (ngSubmit)="submitPayment()">
						<div class="summery-container">
							<div class="summery-container-header">
								<div class="row space-0">
									<div class="col d-flex">
										<h2 class="align-self-center">Payment Type: <b>Split</b></h2>
									</div>
									<div class="col-md-auto">
										<button type="button" *ngIf="!paymentService.PaymentConfirmation" (click)="printInvoice()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-print"></i> Print Invoice
										</button>
										<button type="button" *ngIf="paymentService.PaymentConfirmation" (click)="emailReceipt()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-email"></i> Email Receipt
										</button>
										<button type="button" *ngIf="paymentService.PaymentConfirmation" (click)="textReceipt()" class="btn btn-40 btn-white float-end">
											<i class="fas fa-print"></i> Text Receipt
										</button>
									</div>
								</div>
							</div>
							<div class="summery-container-body">
								
								<div class="dot-table" *ngIf="!showMatLoader">
									<div class="dot-table-body">
										<div class="dot-table-row">
											<div class="row">
												<div class="col-md-8 text-start">
													<label>Total Invoice Amount</label>
												</div>
												<div class="col text-end">
													<label>{{cartTotalAmount | currency}}</label>
												</div>
											</div>
										</div>
										<div class="dot-table-row" *ngIf="paymentService && paymentService.SplitSummary.length > 0">
											<div class="row" *ngFor="let splitPay of paymentService.SplitSummary, let i = index;">
												<div class="col-md-8 text-start" *ngIf="splitPay.cardType">
													<button style="margin-right:10px" *ngIf="splitPay.paymentType === paymentService.CASH" type="button" class="btn btn-danger btn-icon btn-40" (click)="deleteSplitPayment(splitPay.id)">
														<i class="fas fa-minus-circle"></i>
													</button>
													<button style="margin-right:10px" *ngIf="splitPay.paymentType !== paymentService.CASH" type="button" class="btn btn-primary btn-icon btn-40" (click)="deleteSplitPayment(splitPay.id)">
														<i  class="fas fa-circle"></i> 
													</button>
													Paid By: {{splitPay.cardType}}
												</div>
												<div class="col-md-8 text-start" *ngIf="!splitPay.cardType">
													<button style="margin-right:10px" *ngIf="splitPay.paymentType === paymentService.CASH" type="button" class="btn btn-danger btn-icon btn-40" (click)="deleteSplitPayment(splitPay.id)">
														<i class="fas fa-minus-circle"></i>
													</button>
													<button style="margin-right:10px" *ngIf="splitPay.paymentType !== paymentService.CASH" type="button" class="btn btn-primary btn-icon btn-40" (click)="deleteSplitPayment(splitPay.id)">
														<i  class="fas fa-circle"></i> 
													</button> 
													Paid By: {{splitPay.paymentType}}
												</div>
												<div class="col d-flex">
													<span style="width: 100%; text-align: right;" class="align-self-center">- {{splitPay.amount | currency}} </span>
												</div>
											</div>
										</div>
										<div class="dot-table-row">
											<div class="row" *ngIf="!paymentService.CashBack">
												<div class="col-md-8 text-start">
													<label>Balance</label>
												</div> 
												<div class="col text-end">
													<label class="price-theme">{{paymentService.Balance | currency}}</label>
												</div>
											</div>
											<div class="row" *ngIf="paymentService.CashBack">
												<div class="col-md-8 text-start">
													Cash Back
												</div>
												<div class="col text-end">
													<label class="green-theme">{{paymentService.Balance | currency}}</label>
												</div>
											</div>
										</div>
									</div>
									
									<div class="dot-table-footer" *ngIf="!toggleService.ShowDoneButton">
										<div class="dot-table-row no-border">
											<div class="row">
												<div class="col-md-12 text-start">
													<div class="row space-10">
														<div class="col-md-auto d-flex">
															<label class="align-self-center">Pay By: </label>
														</div>
														<div class="col">
															<select (change)="onSplitPaymentTypeSelect($event)" class="form-control" id="splitPay"
																[(ngModel)]="paymentService.SplitPaySelected" name="splitPay"
																[disabled]="toggleService.ShowDoneButton"
																>
																<option value="NONE">------- SELECT -------</option>
																<option value="CASH">CASH</option>
																<option value="CREDIT_CARD">CREDIT CARD</option>
															</select>
														</div>
														<div class="col">
															<input 
																currencyMask 
																[(ngModel)]="splitPaymentTenderedAmount" 
																#tenderedSplitPaymentAmountInput 
																name="splitPayTenderedAmount" 
																class="form-control" 
																type="text" 
																(focus)="bindKeyPad()"
																[disabled]="toggleService.ShowDoneButton"
															/>
															<div class="spinner" *ngIf="toggleService.ShowPaymentProgress">
																<app-spinner>in progress.......</app-spinner>
															</div>
														</div>
													</div>
												</div> 
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="summery-container-footer">
								<button class="btn btn-40 btn-success" *ngIf="!toggleService.ShowDoneButton" type="submit">
									<i class="fas fa-check-circle"></i> Submit Payment
								</button>
								<button tyep="button" (click)="completeSplitPaymentTransaction()" class="btn btn-40 btn-success" *ngIf="toggleService.ShowDoneButton" type="submit">
									<i class="fas fa-check-circle"></i> Complete
								</button>
								<button class="btn btn-40 btn-danger float-end" type="button" (click)="backOrderPage()">
									<i class="fas fa-times-circle"></i> Close
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="keypad-container">
					<app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'splitPaymentTenderedAmount'" [inputValue]="splitPaymentTenderedAmount" (outputValue)="setAmount($event)" [specialKeys]="true" [isPrice]="true" [isDotAllowed]="true"></app-keypad>
				</div>
			</div> -->
			<!-------------------------------- SPLIT PAYMENT ENDS  ------------------------------------------->

			<!-------------------------------- PAYMENT CONFIRMATION SPLIT ------------------------------------->
			<!-- <div class="center-container" *ngIf="paymentService.PaymentConfirmation">
				<div class="full-width">
					<div class="row">
						<div class="col-md-4">

						</div>
						<div class="col-md-4">
							<div class="shadow-radius-container">
								<div class="summery-container">
									<div class="summery-container-header">
										<div class="row space-0">
											<div class="col d-flex">
												<h2 class="align-self-center">Payment Confirmation</h2>
											</div>
										</div>
									</div>
									<div class="summery-container-body">
										<div class="dot-table">
											<div class="dot-table-header">
												<div class="dot-table-row">
													<div class="row"> 
														<div class="col text-start">
															<label>Items</label>
														</div>
														<div class="col text-end"> 
															<label>Amount</label>
														</div>
													</div>
												</div>
											</div>
											<div class="dot-table-body">
												<div class="dot-table-row">
													<div class="row">
														<div class="col text-start">
															<label class="text-green">Invoice Amount:</label>
														</div>
														<div class="col text-end">
															<label class="text-green">{{shoppingCartService.CustomerInvoiceAmount	| currency}}</label>
														</div>
													</div>
												</div>
												<div class="dot-table-row">
													<div class="row">
														<div class="col text-start">
															<label class="text-gray">Tendered Amount:</label>
														</div>
														<div class="col text-end">
															<ng-container *ngIf="paymentService.SelectedPaymentType == 'CASH_CONFIRMATION_TENDERED_AMOUNT'">
																<label class="text-gray">{{cashTenderedAmount | currency}}</label>
															</ng-container>
															<ng-container *ngIf="paymentService.SelectedPaymentType == 'EBT_CONFIRMATION_TENDERED_AMOUNT'">
																<label class="text-gray">{{ebtTenderedAmount | currency}}</label>
															</ng-container>
														</div>
													</div>
												</div>
												<div class="dot-table-row">
													<div class="row">
														<div class="col text-start">
															<label class="text-orange">Change Amount:</label>
														</div>
														<div class="col text-end">
															<label class="text-orange">{{changeAmount | currency}}</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="summery-container-footer">
										<button class="btn btn-40 btn-success" type="submit" (click)="navigateToOrderView(true)">
											<i class="fas fa-check-circle"></i> Continue
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4">

						</div>
					</div>
				</div>
			</div> -->
			<!-------------------------------- PAYMENT CONFIRMATION ENDS  ------------------------------------------->
		<!-- </div> -->

		<!-------------------------------- EBT BEGINS ------------------------------------->
		<!-- <div *ngIf="paymentService.SelectedPaymentType === paymentService.EBT" class="thick-solid-border tbl">
			<div class="tblrowothers" style="color:#5a0209ab; text-align: right;font-weight: bold; font-size: 25px;">
				EBT DETAILS
			</div>

			<div class="tblrowothers">
				<div style="display:flex; justify-content: space-between">
					<div
						style="margin-top: 2em;color:#023005ab;font-weight: bold; font-size: 25px; padding-left: 1em; text-align: left;">
						Payment Type: EBT
					</div>

					<div style="font-weight: bold; padding-right: 0.5em;">
						<div style="color:#032505ab; font-weight: bold; text-align: right; font-size: 25px">
							{{cartAmount | currency}}</div>
						<div style="color:#032505ab; font-weight: bold; text-align: right; font-size: 20px">
							{{cartTaxAmount | currency}}</div>
						<div style="color:#5a0209ab; font-weight: bold; text-align: right; font-size: 45px">
							{{cartTotalAmount | currency}} </div>
						<br />
					</div>
				</div>
			</div>

			<div class="tblrowothers" style="color:#5a0209ab; text-align: right;font-weight: bold; font-size: 25px;">
				<div style="display: flex; justify-content: center; text-align: right; margin-top: 0.5em;">
					<form #checkoutForm="ngForm" (ngSubmit)="submitPayment()">
						<div style="text-align: right; margin-top: 0.5em;  margin-bottom: 0.5em;">
							<span style="font-weight: bold; font-size: 4em;"> EBT AMOUNT: </span>
							<input #tenderedEbtAmountInput type="text" currencyMask name="tenderedAmount"
								[(ngModel)]="ebtTenderedAmount" class="text"
								style="width: 6em; font-size: 4em; font-weight: bold;" />
						</div>
						<div style="margin-left: 70%;" *ngIf="toggleService.ShowPaymentProgress">
							<app-spinner>in progress.......</app-spinner>
						</div><br />
						<input class="submit" type="submit" value="SUBMIT PAYMENT" /><br /><br />
					</form>
				</div>
			</div>
		</div> -->
		<!-------------------------------- EBT ENDS  ------------------------------------------->


		<!-------------------------------- GIFT CARD BEGINS ------------------------------------->
		<!-- <div *ngIf="paymentService.SelectedPaymentType === paymentService.GIFT_CARD" class="thick-solid-border tbl">
			<div class="tblrowothers" style="color:#5a0209ab; text-align: right;font-weight: bold; font-size: 25px;">
				GIFT CARD
			</div>

			<div class="tblrowothers" *ngIf="paymentService.SelectedPaymentType == paymentService.GIFT_CARD">
				<div class="widthsize55em"
					style="color:#023005ab;font-weight: bold; font-size: 25px; padding-left: 1em; text-align: left;">
					Payment Type: GIFTCARD </div>

				<div class="widthsize55em" style="font-weight: bold; padding-right: 0.5em;">
					<div style="color:#032505ab; font-weight: bold; text-align: right; font-size: 25px"> {{cartAmount |
						currency}}</div>
					<div style="color:#032505ab; font-weight: bold; text-align: right; font-size: 20px">{{cartTaxAmount
						| currency}}</div>
					<div style="color:#5a0209ab; font-weight: bold; text-align: right; font-size: 45px">
						{{cartTotalAmount | currency}} </div>
					<div style="font-weight: bold; text-align: right; font-size: 15px"> SWIPE GIFTCARD: <input
							type="text" maxlength="30" /> </div>
					<br />
				</div>
			</div>

			<div style="text-align: right; margin-top: 0.5em;">
				<form #checkoutForm="ngForm" (ngSubmit)="submitPayment()">
					<input type="text" name="tenderedAmount" [(ngModel)]="shoppingCartService.TenderedAmount"
						class="text" style="width: 4em;" size="20" />
					<input class="submit" type="submit" value="SUBMIT PAYMENT" /><br /><br />
				</form>
			</div>

			<div class="tblrowothers"></div>
		</div> -->
		<!-------------------------------- GIFT CARD ENDS  ------------------------------------------->


		<!-------------------------------- Mobile Money BEGINS ------------------------------------->
		<!-- <div *ngIf="paymentService.SelectedPaymentType === paymentService.MOBILE_MONEY" class="thick-solid-border tbl">
			<div class="tblrowothers">
				<div class="widthsize55em"
					style="color:#023005ab;font-weight: bold; font-size: 25px; padding-left: 1em; text-align: left;">
					Payment Type: Mobile Money
				</div>

				<div class="widthsize55em" style="font-weight: bold; padding-right: 0.5em;">
					<div style="color:#032505ab; font-weight: bold; text-align: right; font-size: 25px"> {{cartAmount |
						currency}}</div>
					<div style="color:#032505ab; font-weight: bold; text-align: right; font-size: 20px">{{cartTaxAmount
						| currency}}</div>
					<div style="color:#5a0209ab; font-weight: bold; text-align: right; font-size: 45px">
						{{cartTotalAmount | currency}} </div>
					<br />
				</div>
			</div>

			<div style="text-align: right; margin-top: 0.5em;">
				<form #checkoutForm="ngForm" (ngSubmit)="submitMoMoPayment()">
					<strong>Phone Number: </strong><input type="text" name="phoneNumber" [(ngModel)]="phoneNumber"
						class="text" style="width: 8em;" size="20" />
					<input class="submit" type="submit" value="SUBMIT PAYMENT" /><br /><br />
				</form>
			</div>
		</div> -->
		<!-------------------------------- Mobile Money ENDS  ------------------------------------------->

	</div>
</div>

<div class="page-frame">
	<div class="page-frame-header">
		<div class="page-header">
			<div class="d-flex align-items-center justify-content-between">
				<div class="page-logo">
					<span class="image-icon sm"><img src="/assets/images/table-icon.png" alt="" /></span>
					Tables
				</div>
				<div class="page-buttons" *ngIf="showTableList">
					<button (click)="addTable()" class="btn btn-60 btn-icon-text btn-white">
						<i class="fas fa-plus"></i> <span>Add Table</span>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="page-frame-body">
		<div class="full-height-container pad-20">
			<div class="shadow-radius-container grow-height">
				<!-- List Start Here -->
				<div class="ag-table-grid" *ngIf="showTableList">
					<ag-grid-angular 
						[columnDefs]="columnDefs"
						[rowData]="rowData"
						[rowSelection]="rowSelection"
						[rowHeight]="'60'"
                        [headerHeight]="'40'"
						[rowClass]="'ag-custom-table-row'"
						(gridReady)="onGridReady($event)"
						(rowClicked)="onRowClicked($event)"
						class="ag-theme-alpine user-table">
					</ag-grid-angular>
				</div>
				<!-- List End Here -->
                <!-- Add Guest Form Start Here -->
                <div class="product-form-container grow-height" *ngIf="toggleService.ShowAdditionalTableChairForm">
                    <form #addChairForm="ngForm" class="grow-height" (ngSubmit)="saveNewTableChair()">
                        <div class="summery-container grow-height">
							<div class="summery-container-header">
								<h2><b>New Table</b></h2>
							</div>
							<div class="summery-container-body pad-l-20 pad-r-20">
								<div class="row">
                                    <div class="col-md-6">
                                        <div class="form-container pad-r-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="additionalTableChair">Guest:</label>
                                                </div>
                                                <div class="form-field-control">
													<input class="form-control" maxlength="4" id="additionalTableChair" name="additionalTableChair" type="text" [(ngModel)]="additionalTableChair" />
                                                    <i>Max. 4 Chars</i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
							</div>
							<div class="summery-container-footer">
                                <button (click)="getTables('init');" class="btn btn-40 btn-danger" type="button">
									<i class="fas fa-times-circle"></i> Cancel
								</button>
								<button  class="btn btn-40 btn-success float-end" type="submit">
									<i class="fas fa-check-circle"></i> Add Chair
								</button>
							</div>
						</div>
                    </form>
                </div>
                <!-- Add Guest Form End Here -->
				<!-- Form Start Here -->
				<div class="product-form-container grow-height" *ngIf="showTableForm">
                    <form #tableForm="ngForm" class="grow-height" (ngSubmit)="saveTableInformation()">
                        <div class="summery-container grow-height">
							<div class="summery-container-header">
								<h2><b>Create/Update Table</b></h2>
							</div>
							<div class="summery-container-body pad-l-20 pad-r-20">
								<div class="row">
                                    <div class="col-md-6" style="border-right:2px solid #cdcdcd;">
                                        <div class="form-container pad-r-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="name">Name:</label>
                                                </div>
                                                <div class="form-field-control">
													<input class="form-control" maxlength="8" id="name" name="name" type="text" [(ngModel)]="tableFormData.name" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="numberOfChairs">Pers.:</label>
                                                </div>
                                                <div class="form-field-control">
													<select class="form-select" id="numberOfChairs" name="numberOfChairs" [(ngModel)]="tableFormData.numberOfChairs">
														<option *ngFor="let chair of chairs" [value]="chair.id">{{chair.name}}</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-container pad-l-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="prefix">Prefix:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" size="2" maxlength="2" id="prefix" name="prefix" type="text" [(ngModel)]="tableFormData.prefix" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
							</div>
							<div class="summery-container-footer">
								<button (click)="getTables('init');" class="btn btn-40 btn-danger" type="button">
									<i class="fas fa-times-circle"></i> Cancel
								</button>
                                <button  class="btn btn-40 btn-success float-end" type="submit">
									<i class="fas fa-check-circle"></i> Save
								</button>
							</div>
						</div>
                    </form>
                </div>
				<!-- Form End Here -->
			</div>
		</div>
	</div>
</div>






<!-- 
<app-header></app-header>
<br/><br/><br/><br/>
<div id="main">
    <div version="2.0" class="spacefix">
        <div style="margin-bottom: 5px;">
            <a href="javascript:;" class="buttontrans" (click)="getTables('tableList')"
                style="margin-right: 5px;"><img height="35"
                src="assets/images/users.png" /></a>
            <div
                style="margin-right: 7px; margin-bottom: 5px; float: right; text-align: center;">
                <a class="buttontrans" href="javascript:;" (click)="addTable()"
                    style="padding: 7px 60px;"><img
                    src="assets/images/create.png" /></a>
            </div>
        </div>
        <div *ngIf="toggleService.ShowAdditionalTableChairForm">
            <br/><br/><br/><br/><br/>
            <form #addChairForm="ngForm" (ngSubmit)="saveNewTableChair()">
                <strong> Guest:</strong><input name="additionalTableChair" [(ngModel)]="additionalTableChair" type="text" maxlength="4" style="width: 4em;" id="additionalTableChair" class="text"/>Max. 4 Chars <br/><br/>
                <div style="margin-top: 0.5em;">
                    <input value="ADD CHAIR" name="Create" type="submit" style="width: 15.7em;" class="submit"/>
                </div>
            </form><br/><br/><br/><br/><br/>
        </div>
        <div style="width: 99%;" class="tbl" *ngIf="showTableList">
            <div class="tblth">
                <div
                    style="border-bottom: 3px solid #08c1ed !important; padding-left: 0.4em;"
                    class="widthsize9em">Table Name</div>
                <div style="border-bottom: 3px solid #08c1ed !important;"
                    class="widthsize12em">Table Prefix</div>
                <div style="border-bottom: 3px solid #08c1ed !important;"
                    class="widthsize6em">Password</div>
                <div style="border-bottom: 3px solid #08c1ed !important;"
                    class="widthsize6em">Chairs</div>
                <div style="border-bottom: 3px solid #08c1ed !important;"
                    class="widthsize6em">Account Active</div>
                <div
                    style="border-bottom: 3px solid #08c1ed !important; text-align: center;"
                    class="widthsize15em">Action</div>
            </div>
            <div class="tblrowothers" *ngFor="let table of customerService.Tables">
                <div style="padding-left: 0.4em;" class="widthsize9em">{{table?.userId}}</div>
                <div class="widthsize12em">{{table?.firstName}}</div>
                <div class="widthsize6em">{{table?.pin}}</div>
                <div class="widthsize6em">{{table?.lastName}}</div>
                <div class="widthsize6em">{{table?.accountNonLocked}}</div>
                <div style="text-align: center;" class="widthsize15em">
                    
                        <a class="buttonInbox" href="javascript:;" (click)="editTable(table)"
                            style="padding: 0.6em 2em;"><img
                            src="assets/images/update.png" /></a>
                    
                </div>
            </div>
        </div>
        <div version="2.0" id="menu"></div>
        <div *ngIf="showTableForm">
            <div version="2.0" Style="margin-top: 55px;"><span class="buttontrans4">CREATE OR EDIT A TABLE</span><span
                    style="margin-left: 0em;" class="errors"> </span><br/>
                <form #tableForm="ngForm" (ngSubmit)="saveTableInformation()">
                    <div style="margin-top: 10px;" class="tblpL">
                        <div class="tblth">
                            <div style="padding-right: 5px;" class="navcell">Name</div>
                            <div style="padding-right: 5px;" class="navcell">Prefix</div>
                            <div style="padding-right: 5px;" class="navcell">Pers.</div>
                        </div>
                        <div class="tblrowplain">
                            <div style="padding-right: 5px;" class="navcell">
                                <input name="name" [(ngModel)]="tableFormData.name" type="text" maxlength="8" style="width: 160px;" id="name" class="text" />
                            </div>
                            <div style="padding-right: 5px;" class="navcell">
                                <input name="prefix" [(ngModel)]="tableFormData.prefix" type="text" size="2" maxlength="2" style="width: 60px;" id="prefix" class="text" />
                            </div>
                            <div style="padding-right: 5px;" class="navcell">
                                <select id="numberOfChairs" name="numberOfChairs" [(ngModel)]="tableFormData.numberOfChairs" style="width: 110px; height: 60px; padding: 10px 15px;">
                                    <option *ngFor="let chair of chairs" [value]="chair.id">{{chair.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 15px;">
                        <input value="SAVE" name="Create" type="submit" style="width: 365px;" class="submit" />
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->
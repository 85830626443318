import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './components/category/category.component';
import { RestaurantOrderComponent } from './components/order/restaurant-order/restaurant-order.component';
import { HomeComponent } from './components/home/home.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { TableComponent } from './components/table/table.component';
import { LoginComponent } from './components/login/login.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { UserComponent } from './components/user/user.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { ReportsComponent } from './components/reports/reports.component';
import { OnlineOrdersComponent } from './components/online-orders/online-orders.component';
import { PaymentOptionsComponent } from './components/payment-options/payment-options.component';
import { SettingsComponent } from './components/settings/settings.component';
import {RetailOrderComponent} from './components/order/retail-order/retail-order.component';
import {InventoryComponent} from './components/inventory/inventory.component';
import {PaymentsComponent} from './components/payments/payments.component';
import {MenuItemDetailsComponent} from './components/menu-item-details/menu-item-details.component';
import {SidesComponent} from './components/sides/sides.component';
import {PaymentResponseLogComponent} from "./components/payment-response-log/payment-response-log.component";


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'category', component: CategoryComponent },
  { path: 'menuItem', component: MenuItemComponent },
  { path: 'retailorder', component: RetailOrderComponent },
  { path: 'restaurantorder', component: RestaurantOrderComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'table', component: TableComponent },
  { path: 'user', component: UserComponent },
  { path: 'receipt', component: ReceiptComponent },
  { path: 'reports', component: ReportsComponent },
  { path: 'onlineorders', component: OnlineOrdersComponent },
  { path: 'inventory', component: InventoryComponent },
  { path: 'paymentoptions', component: PaymentOptionsComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'payments', component: PaymentsComponent },
  { path: 'menuItemDetails', component: MenuItemDetailsComponent },
  { path: 'submenuitems', component: SidesComponent },
  { path: 'paymentresponselog', component: PaymentResponseLogComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import {MenuItemService} from '../../services/menu-item.service';

@Component({
  selector: 'app-receipt-dialog',
  templateUrl: './receipt-dialog.component.html',
  styleUrls: ['./receipt-dialog.component.css']
})
export class ReceiptDialogComponent implements OnInit {

  public htmlContent: string;

  constructor(public menuItemService: MenuItemService) { }

  ngOnInit() {
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import { ShoppingCartServiceService } from 'src/app/services/shopping-cart-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ShoppingCartItem } from 'src/app/models/ShoppingCartItem';
import { PaymentService } from 'src/app/services/payment.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { Constants } from 'src/app/models/Constants';
import {Subscription} from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.css']
})
export class PaymentOptionsComponent implements OnInit, OnDestroy {
  private apiSubscription = new Subscription();
  public shoppingCartItems: Array<ShoppingCartItem> = [];

  public tableData: Array<any> = [];

  public invoiceAmount: number = 0;

  public selectedItems = new Array<ShoppingCartItem>();
  public currentChair: any = null;
  public currentOrgId: any = null;

  constructor(private paymentService: PaymentService, private _shared: SharedService, private shoppingCartService: ShoppingCartServiceService, 
              private router: Router, private authenticationService: AuthenticationService,
              private session: SessionStorageService) { }

  ngOnInit() {
    this.getDefaultCartItems();
    
    this.paymentService.SelectedPaymentType = Constants.PAYMENT_TYPE_CASH;
    
    this.currentChair = this.session.get(Constants.ACTIVE_CHAIR).id;
    this.currentOrgId = this.session.get(Constants.ORGANIZATION_KEY).id;
  }

  getTableList() {
    const tableSubscription = this.shoppingCartService.getOccupiedTables(this.authenticationService.getOrganizationId()).subscribe(tables => {
      // Map Array Stucture for Table
      let tableData = tables.map((item) => {
        return {...item, data:[], isChecked: false, isExpand: false, showLoader: false}
      })

      // Get Item Service call if cart has items
      if (this.shoppingCartItems.length > 0) {
        this.getTableOrders(tableData.find(item => item.id == this.shoppingCartItems[0].customer.user.id));
      }

      // Set Table Data
      this.tableData = tableData;
    });

    // Add to Subscription
    this.apiSubscription.add(tableSubscription);
  }

  toggleTable(tableData) {
    tableData.showLoader = true;

    // Check if data is not then call service to fetch data else just open
    if (tableData.data.length == 0) {
      this.getTableOrders(tableData);
    }
    else {
      tableData.isExpand = !tableData.isExpand;
      tableData.showLoader = false;
    }
  }

  getTableOrders(tableData) {
    // Fetch Menu Items for perticular table
    const tableOrderSubscription = this.shoppingCartService.getShoppingCartItemsByTable(tableData.id).subscribe(response => {
      // Map Menu Item with New Object structure
      let orderData = response.map(( item ) => {
        return {...item, isChecked: false, isDisable: false}
      })
      // Default Check item if there in shopping cart
      if (this.shoppingCartItems.length > 0) {
        orderData.forEach(( item ) => {
          if (this.shoppingCartItems.find(cartItem => item.id == cartItem.id)) {
            item.isChecked = true;
            item.isDisable = true;
          }
        })
      }
      
      // Set Data
      tableData.data = orderData;
      // Toggle Expand Collapse
      tableData.isExpand = !tableData.isExpand;
      tableData.showLoader = false;

      // Mark Tick and Get Total Amount
      this.markSelectedTable();
    });

    // Add to Subscription
    this.apiSubscription.add(tableOrderSubscription);
  }

  markSelectedTable(record?, index?) {
    // Find tick from menu item list and mark table as tick or untick
    if (this.tableData && this.tableData.length > 0) {
      this.tableData.forEach((table) => {
        let isSelectedFound = false;
        if (table.data && table.data.length > 0) {
          table.data.forEach((item) => {
            if (item.isChecked) {
              isSelectedFound = true;
            }
          });
        }
        table.isChecked = isSelectedFound;
      })
    }

    // Get Total Amount Update
    this.getCalculatedTotal();
    
    if (record && record.isChecked) {
      // let index;
      // this.shoppingCartItems.forEach((item, index) => {
      //   if (item.id == record.id) {
      //     index = index;
      //   }
      // });
      // this.shoppingCartItems.splice(index, 1);
      // Move Item to Other Table
      //this._shared.setGlobalLoader(true);
      this.shoppingCartService.transferCartItem(record.id, record.customer.id, this.currentChair).subscribe((response) => {
        // this.shoppingCartService.ShoppingCart = this.shoppingCartItems;
        //this._shared.setGlobalLoader(false);
      }, (error) => {
        //this._shared.setGlobalLoader(false);
      });
    }
    else if (record && !record.isChecked) {
      // this.shoppingCartItems.push(record);
      // Move Item to Other Table
      //this._shared.setGlobalLoader(true);
      this.shoppingCartService.transferCartItem(record.id, this.currentChair, record.customer.id).subscribe((response) => {
        // this.shoppingCartService.ShoppingCart = this.shoppingCartItems;
        //this._shared.setGlobalLoader(false);
      }, (error) => {
        //this._shared.setGlobalLoader(false);
      });
    }

    
    
  }

  getCalculatedTotal() {
    this.selectedItems = [];
    this.tableData.forEach((table) => {
      if (table.isChecked) {
        table.data.forEach((item) => {
          if (item.isChecked) {
            this.selectedItems.push(item);
          }
        });
      }
    });
    this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCartItemsTotal(this.selectedItems);
    this.invoiceAmount = this.paymentService.InvoiceAmount;
    this.shoppingCartService.CustomerInvoiceAmount = this.invoiceAmount;
  }

  getDefaultCartItems() {
    // Fetch Default Cart Items
    const itemSubscription = this.shoppingCartService.getObservableItems().subscribe((alreadySelectedItems) => {
      this.shoppingCartItems = alreadySelectedItems;
      // if (alreadySelectedItems.length > 0) {
      //   this.currentChair = alreadySelectedItems[0].customer.id;
      //   this.currentOrgId = alreadySelectedItems[0].organizationId;
      // }
      this.getTableList();
    });
    this.apiSubscription.add(itemSubscription);
  }

  showSelectedItems() {
    //this._shared.setGlobalLoader(true);
    this.shoppingCartService.getUserShoppingCartItemsForChair(this.session.get(Constants.ACTIVE_CHAIR), this.currentOrgId).subscribe((response) => {
      this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCartItemsTotal(this.selectedItems);
      this.shoppingCartService.CustomerInvoiceAmount = this.paymentService.InvoiceAmount; //TODO
      this.shoppingCartService.updateObservableItems(response);
      this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
      this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
      this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
      //this._shared.setGlobalLoader(false);
      this.router.navigate(['/checkout'], { queryParams: { payment: 'group' } });
    }, (error) => {
      //this._shared.setGlobalLoader(false);
    });
  }

  updatePaymentType(paymentType: string) {
    this.paymentService.SelectedPaymentType = paymentType;
  }

  backOrderPage() {
    this.router.navigateByUrl('/restaurantorder?pageView=quickStart');
  }

  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
  }
}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'erestau';
  public subscription: Subscription;
  public globalLoader: boolean = false;

  constructor(private _shared : SharedService) {
    this._shared.globalLoader.subscribe(response => {
      this.globalLoader = response;
    })
  }

  ngOnInit(): void {
  }


}

import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {OrganizationService} from '../../services/organization.service';
import {ToggleService} from '../../services/toggle.service';
import {AuthenticationService} from '../../services/authentication.service';
import {ShoppingCartServiceService} from '../../services/shopping-cart-service.service';
import {Constants} from '../../models/Constants';
import {SessionStorageService} from 'angular-web-storage';
import {CustomerService} from '../../services/customer.service';
import {MenuItemService} from '../../services/menu-item.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  public isRetail = false;
  apiSubscription = new Subscription();

  constructor(private router: Router, private organizationService: OrganizationService, private toggleService: ToggleService,
              private authenticationService: AuthenticationService, private shoppingCartService: ShoppingCartServiceService,
              public session: SessionStorageService, private menuItemService: MenuItemService, private customerService: CustomerService) { }

  ngOnInit() {
    const orgSubscription = this.organizationService.getOrganization(this.authenticationService.getOrganizationId()).subscribe((org) => {
        if (org.retail === true) {
          this.isRetail = true;
          this.session.set('orgType', Constants.ORGANIZATION_TYPE_RETAIL);
          this.toggleService.setRetailStoreObservableSubject(true);
        } else {
          this.session.set('orgType', Constants.ORGANIZATION_TYPE_RESTAURANT);
          this.toggleService.setRetailStoreObservableSubject(true);
        }
        this.toggleService.DeliveryAvailable = org.deliveryAvailable;
        this.toggleService.PickupAvailable = org.pickupAvailable;
        this.toggleService.ShowFooterIcon = true;
        this.toggleService.ShowOrderMenuItems = false;
        this.toggleService.ShowActiveChairs = false;
        this.shoppingCartService.updateObservableItems([]);
        this.shoppingCartService.CustomerInvoiceAmount = 0.00;
        if (this.customerService.Tables.length === 0) {
            const customerSubscription = this.customerService.getTables(this.authenticationService.getOrganizationId()).subscribe(resp => {
                this.customerService.Tables = resp;
            });
            this.apiSubscription.add(customerSubscription);
        }
      },
      (error) => {
        console.log(error);
      });
    this.apiSubscription.add(orgSubscription);
  }

  ngOnDestroy() {
      this.apiSubscription.unsubscribe();
  }
}

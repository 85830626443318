import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Identity } from 'src/app/models/Identity';
import { SessionStorageService } from 'angular-web-storage';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { Organization } from 'src/app/models/Organization';
import { OrganizationService } from 'src/app/services/organization.service';
import {Observable} from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ShoppingCartServiceService } from 'src/app/services/shopping-cart-service.service';
import { CategoryService } from 'src/app/services/category.service';
import { ToggleService } from 'src/app/services/toggle.service';
import {CustomerService} from '../../services/customer.service';
import {MenuItemService} from '../../services/menu-item.service';
import {Constants} from '../../models/Constants';
import { ActivatedRoute } from '@angular/router';
import {SettingsService} from '../../services/settings.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit  {
  organizations: Array<Organization> = [];
  filteredOptions: Observable<Array<Organization>>;
  loginForm: FormGroup;
  @ViewChild('loginUsername') usernameElement: ElementRef;
  queryAlias: any = {};

  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private organizationService: OrganizationService,
              private session: SessionStorageService,
              private router: Router, private dialog: MatDialog,
              private shoppingCartService: ShoppingCartServiceService,
              private menuItemService: MenuItemService,
              private categoryService: CategoryService,
              private toggleService: ToggleService,
              private customerService: CustomerService,
              private settingsService: SettingsService,
              private _activatedRoute: ActivatedRoute) { 
                if (this._activatedRoute.snapshot.queryParams) {
                  this.queryAlias = this._activatedRoute.snapshot.queryParams.name;
                }
              }

  ngOnInit() {
    this.shoppingCartService.ShoppingCart = null;
    this.categoryService.ParentCategories = null;
    this.shoppingCartService.DisplayShoppingCart = false;
    this.toggleService.ShowFooterIcon = false;
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: [''],
      org: [''],
    });

    const orgSubscription = this.organizationService.getOrganizations().subscribe((orgs) => {
      this.organizations = orgs;
      if (this.queryAlias) {
        let selectedOrg = this.organizations.find((item: any) => item.alias == this.queryAlias);
        this.loginForm.get('org').setValue(selectedOrg);
      }
    });

    this.filteredOptions = this.loginForm.get('org').valueChanges
      .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.organizations.slice())
      );
  }

  ngAfterViewInit(): void {
    this.userNameFocus();
  }

  private _filter(value: string): Array<Organization> {
    const filterValue = value.toLowerCase();
    return this.organizations.filter(option => option.name.toLowerCase().startsWith(filterValue));
  }

  displayFn(org?: Organization): string | undefined {
    return org ? org.name : undefined;
  }

  login() {
    const identity = new Identity();
    identity.username = this.loginForm.value.username;
    identity.password = this.loginForm.value.password;
    identity.organizationId = this.loginForm.value.org.id;
    const idSubscription = this.authenticationService.authenticate(identity).subscribe((jwt) => {
        this.authenticationService.setToken(jwt.token);
        this.authenticationService.setIdentity(identity);
        this.toggleService.ShowFooterIcon = true;
        const menuSubscription = this.menuItemService.initMenuItemsInLocalStorage(identity.organizationId).subscribe(r => {});
        const catSubscription = this.categoryService.initCategoriesInLocalStorage(identity.organizationId).subscribe(r => {});
        const receiptPropertySubscription = this.settingsService.initDeleteReceiptPropertyInLocalStorage(this.authenticationService.getOrganizationId(), 'DISABLE_DELETE_RECEIPT').subscribe(r => {});
        const orgSubscription = this.organizationService.getOrganization(this.authenticationService.getOrganizationId()).subscribe((r) => {
          this.session.set(Constants.ORGANIZATION_KEY, r);
          const org = new Organization();
          org.id = r.id;
          org.name = r.name;
          this.authenticationService.Organization = org;
        });
        this.router.navigate(['/home']);
    },
    (err) => {
      // console.log("The error message is : ");
      // console.log(err.error);
      this.openDialog('ERROR', 'Login Failed. Try again!!');
    });
  }

  openDialog(msgHeader: string, msg: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  userNameFocus() {
    setTimeout(() => {
      this.usernameElement.nativeElement.focus();
    }, 0);
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import { Category } from 'src/app/models/Category';
import { CategoryService } from 'src/app/services/category.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import {Subscription} from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit, OnDestroy {

  public categoryFormData: Category;
  public trueFalseArray: any;
  public categories: any;
  public parentCategories: any;
  public showCategoryForm = false;
  public showCategoryList = false;
  public organizationId: number;

  private apiSubscription = new Subscription();

  public gridOptions: GridOptions;
  public rowSelection = 'single';

  columnDefs = [
    {
      headerName: 'Category',
      field: 'name',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Parent Category',
      field: 'parent.name',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Sort Order',
      field: 'sortOrder',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Active',
      field: 'active',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col',
    },
    {
      headerName: 'Active Online',
      field: 'activeOnline',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Digital Active',
      field: 'activeDigiMenu',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Sort Text',
      field: 'sortText',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: '',
      field: '',
      cellClass: 'ag-table-col-action',
      cellRenderer: this.actionsRenderer
    }
  ];

  constructor(private categoryService: CategoryService, private _shared: SharedService, private authenticationService: AuthenticationService, private dialog: MatDialog) { }

  ngOnInit() {
    this.gridOptions = ({
      rowHeight: 40
    } as GridOptions);

    this.organizationId = this.authenticationService.getOrganizationId();
    this.trueFalseArray = [
                            {'id' : 'true', 'name' : 'True'},
                            {'id' : 'false', 'name' : 'False'}
                          ];
    setTimeout(() => {
      this.getCategories();
    }, 0);
  }

  saveCategory() {
    if (this.categoryFormData.parent.id && this.categoryFormData.parent.id.toString() === 'undefined') {
      this.categoryFormData.parent = new Category();
    }
    this._shared.setGlobalLoader(true);
    const catSubscription = this.categoryService.saveCategory(this.categoryFormData).subscribe((cat) => {
      if (this.categoryFormData.parent.id) {
        this.openDialog('SUCCESS', 'Sub Category [ ' + this.categoryFormData.name + ' ] saved');
      } else {
        this.openDialog('SUCCESS', 'Category [ ' + this.categoryFormData.name + ' ] saved');
      }
      this._shared.setGlobalLoader(false);
      this.getCategories();
      this.categoryFormData = new Category();
      this.categoryFormData.parent = new Category();
      this.categoryFormData.organizationId = this.organizationId;
      this.categoryFormData.parent.organizationId = this.organizationId;
    }, (error) => {
      this.openDialog('ERROR', 'Could not save changes made on this category');
    });
    this.apiSubscription.add(catSubscription);
  }

  getCategories() {
    this._shared.setGlobalLoader(true);
    const catSubscription = this.categoryService.getCategories(this.organizationId).subscribe((cats) => {
      this.categories = cats;
      this.showCategoryList = true;
      this._shared.setGlobalLoader(false);
    });
    this.apiSubscription.add(catSubscription);
  }

  updateCategory(cat: Category) {
    this.showCategoryForm = true;
    this.showCategoryList = false;
    if( cat.parent === null ){
      cat.parent = new Category();
    }
    this.categoryFormData = cat;
  }

  deleteCategory(cat: Category) {
    this._shared.setGlobalLoader(true);
    const catSubscription = this.categoryService.deleteCategory(cat).subscribe((cats) => {
      this._shared.setGlobalLoader(false);
      this.categories = this.getCategories();
    }, error => {
      this._shared.setGlobalLoader(false);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '500px';
      dialogConfig.height = '270px';
      dialogConfig.data = {description: 'ERROR', message: 'Cannot delete category. Products associated to it.'};

      const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

      const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
        this.categoryListDisplay();
      });
      this.apiSubscription.add(dialogSubscription);
    });
    this.apiSubscription.add(catSubscription);
  }

  getParentCategories() {
    this._shared.setGlobalLoader(true);
    this.categoryService.getParentCategories(this.organizationId).subscribe((cats) => {
      this.parentCategories = cats;
      this._shared.setGlobalLoader(false);
    });
  }

  categoryFormDisplay() {
    this.categoryFormData = new Category();
    this.categoryFormData.parent = new Category();
    this.categoryFormData.organizationId = this.organizationId;
    this.categoryFormData.parent.organizationId = this.organizationId;
    this.showCategoryForm = true;
    this.showCategoryList = false;
  }

  categoryListDisplay() {
    this.showCategoryForm = false;
    this.showCategoryList = true;
  }

  openDialog(msgHeader: string, msg: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      this.categoryListDisplay();
    });
    this.apiSubscription.add(dialogSubscription);
  }

  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
  }

  onGridReady(params) {
    params.api.sizeColumnsToFit();
  }

  onRowClicked(e) {
    if (e.event.target !== undefined) {
        const actionType = e.event.target.getAttribute('data-action-type');
        switch (actionType) {
            case 'Edit':
                this.updateCategory(e.node.data);
                break;
            case 'Delete':
                this.deleteCategory(e.node.data);
                break;
        }
    }
  }

  actionsRenderer(curVal: any): string {
    return `
        <i data-action-type="Edit" class="fas fa-pencil-alt"></i>
        <i data-action-type="Delete" class="far fa-trash-alt text-light-red"></i>
    `;
  }
}

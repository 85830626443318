<div class="application-frame">
    <div class="application-body">
        <router-outlet></router-outlet>
    </div>
    <div class="application-footer">
        <app-footer></app-footer>
    </div>
</div>
<div class="global-loader" *ngIf="globalLoader">
    <mat-spinner></mat-spinner>
</div>



<div class="page-frame">
    <div class="page-frame-header">
        <app-header [isSerachShow]="true" (emittedItem)="addToShoppingCart($event.value, -1)" (emittedFocusEvent)="itemNotFoundPriceFocus()"
            itemName="reset"></app-header>
    </div>
    <div class="page-frame-nav">
        <div version="2.0" id="menu"></div>
        <!-- --------------------------------- CATEGORY BEGINS ----------------------------- -->
        <div class="main-nav" *ngIf="toggleService.ShowParentCategories">
            <drag-scroll [drag-scroll-y-disabled]="true">
                <ul>
                    <li *ngFor="let cat of categoryService.ParentCategories; let i = index;" (click)="orderService.getOrderSubCategories(cat.id, i)"
                    [ngClass]="{'active': toggleService.SelectedCategory === i}">
                        {{cat?.name}}
                    </li>
                </ul>
            </drag-scroll>
		</div>
		<div class="sub-nav" *ngIf="toggleService.ShowSubCategories">
            <drag-scroll [drag-scroll-y-disabled]="true">
                <ul>
                    <li *ngFor="let cat of categoryService.SubCategories; let j = index;" (click)="getMenuItems(cat, j)"
                    [ngClass]="{'active': toggleService.SelectedSubCategory === j}">
                        {{cat?.name}}
                    </li>
                </ul>
            </drag-scroll>
        </div> 
    </div>
    <div class="page-frame-body">
        <div class="full-height-container" *ngIf="toggleService.ShowOrderMenuItems && menuItemService?.OrderMenuItems.length > 0">
            <div class="product-list">
                <div class="product-column" *ngFor="let item of menuItemService.OrderMenuItems; let i = index;">
                    <div class="product-items" (click)="addToShoppingCart(item, i)">
                        <div class="product-header">
                            <span>{{item?.id}}</span>
                            <label>{{item?.price | currency}}</label>
                        </div>
                        <div class="product-title">
                            <h3>{{item?.name}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="product-cart-window" *ngIf="shoppingCartService.DisplayShoppingCart">
            <div class="cart-loader" *ngIf="cartLoaderFlag">
				<span class="mat-loader"><mat-spinner></mat-spinner></span>
			</div>
            <div class="cart-header">
				<button class="btn btn-transparent-dark btn-icon-text btn-sm" (click)="clearCart()"><i class="far fa-trash-alt"></i> Remove All</button>
			</div>
            <div class="cart-item-list" id="cartItemList">
                <div class="cart-item" *ngFor="let cartItem of shoppingCartService.ShoppingCart; let i = index;">
                    <div class="cart-item-quantity">
                        <button (click)="updateEbtStatus(i, cartItem, false)" *ngIf="cartItem.menuItem.ebtEligible" class="btn btn-primary btn-40">{{cartItem.quantity}}</button>
                        <button (click)="updateEbtStatus(i, cartItem, true)" *ngIf="!cartItem.menuItem.ebtEligible" class="btn btn-orange btn-40">{{cartItem.quantity}}</button>
                    </div>
                    <div class="cart-item-title">
                        <h2 tooltip="{{cartItem.menuItem.price | currency}}" placement="bottom" (click)="editCartItemForm(cartItem, i)">
                            {{cartItem.menuItem.name}}
                        </h2>
                    </div>
                    <div class="cart-item-price">
                        <span>{{cartItem.price | currency}}</span>
                    </div>
                    <div class="cart-item-action">
                        <button class="btn btn-success btn-40 btn-icon" (click)="increaseItemQuantity(i)"><i class="fas fa-plus"></i></button>
                        <button class="btn btn-danger btn-40 btn-icon" (click)="removeFromShoppingCart(cartItem)"><i class="fas fa-minus"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- -------------------- EDIT CART ITEM STARTS ------------------ -->
		<div class="full-height-container pad-20" *ngIf="toggleService.ShowCartItemToEdit">
			<div class="keypad-data-container">
				<div class="data-container">
					<form #cartItemForm="ngForm" class="full-width" (ngSubmit)="saveUpdatedCartItem()">
						<div class="summery-container">
							<div class="summery-container-header">
								<div class="row space-0">
									<div class="col d-flex">
										<h2 class="align-self-center"><b>{{cartItemFormData.menuItem.name}}</b></h2>
									</div>
								</div>
							</div>
							<div class="summery-container-body">
								<div class="dot-table">
									<div class="dot-table-header">
										<div class="dot-table-row">
											<div class="row"> 
												<div class="col-md-4">
													<label>&nbsp;</label>
												</div>
												<div class="col-md-4">
													<label>Selected</label>
												</div>
												<div class="col-md-4"> 
													<label>Changes</label>
												</div>
											</div>
										</div>
									</div>
									<div class="dot-table-body">
										<div class="dot-table-row">
											<div class="row">
												<div class="col-md-4 d-flex align-items-center">
													<label>Quantity</label>
												</div>
												<div class="col-md-4 d-flex align-items-center">
													<span>{{tempFormData.quantity}}</span>
												</div>
												<div class="col-md-4">
													<input 
														name="quantity" 
														class="form-control" 
														[(ngModel)]="tempFormData.quantity" 
                                                        (focus)="bindKeyPad('quantity')"
														type="text" 
													/>
												</div>
											</div>
										</div>
										<div class="dot-table-row">
											<div class="row">
												<div class="col-md-4 d-flex align-items-center">
													<label>Price</label>
												</div>
												<div class="col-md-4 d-flex align-items-center">
													<span>{{tempFormData.price | currency}}</span>
												</div>
												<div class="col-md-4">
													<input 
														#itemCurrentPrice 
														name="price" 
														currencyMask 
														[(ngModel)]="tempFormData.price"
														type="text" 
                                                        (focus)="bindKeyPad('price')"
														class="form-control"
													/>
												</div>
											</div>
										</div>
									</div>
									<div class="dot-table-footer">
										<div class="dot-table-row no-border">
											<div class="row space-10">
												<div *ngIf="tempFormData.menuItem.category.name != 'MISCELLANEOUS'" class="col-md-auto d-flex align-items-center"><label>Remove Tax</label></div>
												<div *ngIf="tempFormData.menuItem.category.name != 'MISCELLANEOUS'" class="col-md-auto d-flex" style="padding-right:20px;">
													<span class="custom-check align-self-center">
                                                        <input  
                                                            type="checkbox"
                                                            id= "check1"
                                                            name="removeTax" 
                                                            value="{{tempFormData.removeTax}}"
                                                            [(ngModel)]="tempFormData.removeTax" 
                                                            [checked]="tempFormData.removeTax" />
														<label for="check1"><i class="fas fa-check"></i></label>
													</span>
												</div>
                                                <div *ngIf="tempFormData.menuItem.category.name != 'MISCELLANEOUS'" class="col-md-auto d-flex align-items-center"><label>Permanent?</label></div>
												<div *ngIf="tempFormData.menuItem.category.name != 'MISCELLANEOUS'" class="col-md-auto d-flex">
													<span class="custom-check align-self-center">
                                                        <input 
                                                            id= "check2"
                                                            type="checkbox"
                                                            name="pricePermanent" 
                                                            value="{{tempFormData.pricePermanent}}"
                                                            [(ngModel)]="tempFormData.pricePermanent" 
                                                            [checked]="tempFormData.pricePermanent" />
														<label for="check2"><i class="fas fa-check"></i></label>
													</span>
												</div>
												<div class="col">
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="summery-container-footer">
								<button class="btn btn-40 btn-danger" type="button" (click)="closeEditCartItemForm()">
									<i class="fas fa-times-circle"></i> Close
								</button>
                                <button class="btn btn-40 btn-success float-end" type="submit">
									<i class="fas fa-check-circle"></i> Save
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="keypad-container">
                    <ng-container *ngIf="activeKeypad == 'quantity'">
                        <app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'tempFormData.quantity'" [inputValue]="tempFormData.quantity" (outputValue)="setAmount($event)" [specialKeys]="false" [isPrice]="false" [isDotAllowed]="false"></app-keypad>
                    </ng-container>
                    <ng-container *ngIf="activeKeypad == 'price'">
                        <app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'tempFormData.price'" [inputValue]="tempFormData.price" (outputValue)="setAmount($event)" [specialKeys]="false" [isPrice]="true" [isDotAllowed]="true"></app-keypad>
                    </ng-container>
				</div>
			</div>
		</div>  
		<!-- -------------------- EDIT CART ENDS STARTS ------------------ -->
        <!-- -------------------- ITEM WEIGHT FORM STARTS ------------------ -->
        <div class="full-height-container pad-20" *ngIf="toggleService.ShowItemWeightForm">
			<div class="keypad-data-container">
				<div class="data-container">
					<form class="full-width" #cartItemWeightForm="ngForm" (ngSubmit)="addWeightedItemToCart()">
						<div class="summery-container">
							<div class="summery-container-header">
								<div class="row space-0">
									<div class="col d-flex">
										<h2 class="align-self-center"><b>{{cartItemFormData.menuItem.name}}</b></h2>
									</div>
								</div>
							</div>
							<div class="summery-container-body">
								<div class="dot-table">
									<div class="dot-table-header">
										<div class="dot-table-row">
											<div class="row"> 
												<div class="col">
													<label>&nbsp;</label>
												</div>
												<div class="col"> 
													<label>&nbsp;</label>
												</div>
											</div>
										</div>
									</div>
									<div class="dot-table-body">
										<div class="dot-table-row">
											<div class="row">
												<div class="col d-flex align-items-center">
													<label>Enter Weight</label>
												</div>
												<div class="col">
                                                    <input 
                                                        class="form-control"
                                                        #itemWeightInput 
                                                        name="weight" 
                                                        currencyMask
                                                        [options]="{ prefix: '', precision: '3'}"
                                                        [(ngModel)]="tempFormData.weight" 
                                                        (focus)="bindKeyPad('weight')"
                                                        type="text"
                                                    />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="summery-container-footer">
								<button class="btn btn-40 btn-danger" type="button" (click)="closeWeightedItemToCart()">
									<i class="fas fa-times-circle"></i> Close
								</button>
                                <button class="btn btn-40 btn-success float-end" type="submit">
									<i class="fas fa-check-circle"></i> Save
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="keypad-container">
                    <app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'tempFormData.weight'" [inputValue]="tempFormData.weight" (outputValue)="setAmount($event)" [specialKeys]="false" [isPrice]="true" [isDotAllowed]="true"></app-keypad>
				</div>
			</div>
		</div>  
        <!-- -------------------- ITEM WEIGHT FORM ENDS STARTS ------------------ -->
        <div class="full-height-container pad-20" *ngIf="toggleService.ShowNewItemForm">
			<div class="keypad-data-container">
                <form class="full-width" #menuItemForm="ngForm" (ngSubmit)="saveNewItem()">
                    <div class="summery-container">
                        <div class="summery-container-header">
                            <div class="row space-0">
                                <div class="col d-flex">
                                    <h2 class="align-self-center"><b>Create New Item</b></h2>
                                </div>
                            </div>
                        </div>
                        <div class="summery-container-body pad-l-20 pad-r-20">
                            <div class="row">
                                <div class="col-md-6" style="border-right:2px solid #cdcdcd;">
                                    <div class="form-container pad-r-10">
                                        <div class="form-field-row">
                                            <div class="form-field-label">
                                                <label>Item Name:</label>
                                            </div>
                                            <div class="form-field-control">
                                                <input class="form-control" type="text" name="name" [(ngModel)]="menuItemService.NewMenuItem.name" />
                                            </div>
                                        </div>
                                        <div class="form-field-row">
                                            <div class="form-field-label">
                                                <label>Price:</label>
                                            </div>
                                            <div class="form-field-control">
                                                <input class="form-control" #itemNotFoundPrice type="text" name="price" currencyMask [(ngModel)]="menuItemService.NewMenuItem.price" />
                                            </div>
                                        </div>
                                        <div class="form-field-row">
                                            <div class="form-field-label">
                                                <label>Priced by weight?</label>
                                            </div>
                                            <div class="form-field-control d-flex flex-row">
                                                <div class="form-radio-field">
                                                    <span class="custom-radio align-self-center">
                                                        <input type="radio" [(ngModel)]="menuItemService.NewMenuItem.pricedByWeight" [value]="true" id="pricedByWeight" name="pricedByWeight" />
                                                        <label for="pricedByWeight"><i class="fas fa-check"></i></label>
                                                    </span>
                                                    <label for="pricedByWeight">Yes</label>
                                                </div>
                                                <div class="form-radio-field">
                                                    <span class="custom-radio align-self-center">
                                                        <input type="radio" [(ngModel)]="menuItemService.NewMenuItem.pricedByWeight" [value]="false" id="notPricedByWeight" name="pricedByWeight" />
                                                        <label for="notPricedByWeight"><i class="fas fa-check"></i></label>
                                                    </span>
                                                    <label for="notPricedByWeight">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-field-row">
                                            <div class="form-field-label">
                                                <label>Barcode:</label>
                                            </div>
                                            <div class="form-field-control">
                                                <h2>{{menuItemService.NewMenuItem.barcode}}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-container">
                                        <div class="form-field-row">
                                            <div class="form-field-label">
                                                <label>Category:</label>
                                            </div>
                                            <div class="form-field-control">
                                                <select class="form-select" [(ngModel)]="menuItemService.NewMenuItem.category.id" id="category" name="category">
                                                    <option *ngFor="let cat of categories; let i = index" [value]="cat.id">{{cat.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-field-row">
                                            <div class="form-field-label">
                                                <label>EBT Eligible:</label>
                                            </div>
                                            <div class="form-field-control d-flex flex-row">
                                                <div class="form-radio-field">
                                                    <span class="custom-radio align-self-center">
                                                        <input type="radio" id="ebtEligible1" [(ngModel)]="menuItemService.NewMenuItem.ebtEligible" [value]="true" name="ebtEligible" />
                                                        <label for="ebtEligible1"><i class="fas fa-check"></i></label>
                                                    </span>
                                                    <label for="ebtEligible1">Yes</label>
                                                </div>
                                                <div class="form-radio-field">
                                                    <span class="custom-radio align-self-center">
                                                        <input type="radio" id="ebtEligible2" [(ngModel)]="menuItemService.NewMenuItem.ebtEligible" [value]="false" name="ebtEligible" />
                                                        <label for="ebtEligible2"><i class="fas fa-check"></i></label>
                                                    </span>
                                                    <label for="ebtEligible2">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-field-row">
                                            <div class="form-field-label">
                                                <label>Tax Rate:</label>
                                            </div>
                                            <div class="form-field-control">
                                                <input class="form-control" [(ngModel)]="menuItemService.NewMenuItem.taxRate" maxlength="3" name="taxRate" size="3" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summery-container-footer">
                            <button class="btn btn-40 btn-danger" type="button" (click)="cancelNewItemAdd()">
                                <i class="fas fa-times-circle"></i> Close
                            </button>
                            <button class="btn btn-40 btn-success float-end" type="submit">
                                <i class="fas fa-check-circle"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
			</div>
		</div>  
        <!-- -------------------- ITEM ADD FORM ENDS ----------------------- -->
        <!-- --------------------------------- SHOPPING CART BEGINS ---------------------- -->
        <div id="main">
            <span class="error"></span>
            
            <!-- -------------------- SHOW TABLES START ----------------- -->
            <div class="table-selection-view" *ngIf="shoppingCartService.DisplayTables === 'YES'">
                <div class="table-selection-body">
                    <div class="table-selection-list">
                        <div class="row space-20 space-v-20">
                            <div class="col-md-2" *ngFor="let aTable of customerService.Tables; let i = index;">
                                <div class="table-selection-block" (click)="initTableOrders(aTable)">
                                    <div class="table-selection-image">
                                        <i class="fa fa-shopping-cart"></i>
                                    </div>
                                    <label>{{aTable?.userId}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- -------------------- SHOW TABLES  ENDS ----------------- -->

            <div class="clear">
            </div>
        </div>
    </div>
</div>

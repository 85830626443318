import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'angular-web-storage';
import { Constants } from '../models/Constants';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { PaymentRequest } from 'src/app/models/PaymentRequest';
import { Customer } from '../models/Customer';
import { AuthenticationService } from './authentication.service';
import {PaymentResponse} from '../models/PaymentResponse';
import {CloseOrderRequest} from '../models/CloseOrderRequest';
import {ShoppingCartItem} from '../models/ShoppingCartItem';
import {ToggleService} from './toggle.service';
import {Pair} from '../models/Pair';
import {SplitPayment} from '../models/SplitPayment';
import {OrderItem} from '../models/OrderItem';
import {MatTableDataSource} from '@angular/material/table';
import {Receipt} from '../models/Receipt';
import {SearchParams} from "../models/SearchParams";
import {BigDecimal} from "../models/BigDecimal";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private selectedPaymentType: string;
  private paymentType: string;
  private invoiceAmount: number;
  private tenderedAmount: number;
  private totalSplitPaid = 0.00;
  private processAsDebit: boolean;
  private splitPayment: boolean;
  private paymentConfirmation = false;
  private activeChair: Customer;
  private customerPhoneNumber: number;
  private customerEmail: string;
  private balance: number;
  private cashBack = false;
  private splitPaySelected = 'NONE';
  private splitSummary: Array<SplitPayment> = [];
  private receipts: Array<Receipt> = [];
  private matTableReceiptDataSource: any;

  public CASH = 'CASH';
  public EBT = 'EBT';
  public CREDIT_CARD = 'CREDIT_CARD';
  public OTHER = 'OTHER';
  public GIFT_CARD = 'GIFT_CARD';
  public APPLE_PAY = 'APPLE_PAY';
  public GOOGLE_PAY = 'GOOGLE_PAY';
  public SAMSUNG_PAY = 'SAMSUNG_PAY';
  public MOBILE_MONEY = 'MOBILE_MONEY';
  public SPLIT_PAYMENT = 'SPLIT';

  private _paymentProgressObservable: Subject<any> = new Subject<any>();
  public paymentProgressObservable = this._paymentProgressObservable.asObservable();

  private paymentTimeout: any;

  constructor(private httpClient: HttpClient, private session: SessionStorageService, private storage: LocalStorageService,
              private authenticationService: AuthenticationService, private toggleService: ToggleService) { }

  
  setPaymentProgress(flag, status?) {
    let setObject = {
      spinner : flag,
      status : status ? status : null
    }
    if (flag) {
      this.initTimeout();
    } else {
      this.clearTimeout();
    }
    this._paymentProgressObservable.next(setObject);
  }

  initTimeout() {
    this.paymentTimeout = setTimeout(() => {
      this.setPaymentProgress(false);
    }, 40000);
  }

  clearTimeout() {
    clearTimeout(this.paymentTimeout);
  }
  
  submitPayment(paymentRequest: PaymentRequest): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/submitpayment', paymentRequest);
  }

  submitRefund(orderItems: Array<OrderItem>): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/payments/refund', orderItems);
  }

  submitMtnMoMoPayment(phoneNumber: number, tenderedAmount: number): Observable<any> {
    return this.httpClient.get<any>(Constants.MTN_MOMO_URI + '&_amount=' + `${tenderedAmount}` + '&_tel=' + `${phoneNumber}`);
  }

  getSplitPayments(organizationId: number, customerId: number): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/payments/splitpayments/organization/' + `${organizationId}` + '/customer/' + `${customerId}`);
  }

  deleteSplitPayment(splitPaymentId: number): Observable<any> {
    return this.httpClient.delete<any>(Constants.ERESTAU_DOMAIN + '/api/payments/splitpayments/' + `${splitPaymentId}`);
  }

  closeSplitPaymentTransaction(closeOrderRequest: CloseOrderRequest): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/payments/closesplitpayment', closeOrderRequest);
  }

  getPaymentSummary(organizationId: number, customerId: number, paymentType: string): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + `/api/payments/customer/${customerId}/paymenttype/${paymentType}/organization/${organizationId}`);
  }

  deletePaymentSummary(customerId: number): Observable<any> {
    return this.httpClient.delete<any>(Constants.ERESTAU_DOMAIN + `/api/payments/authorizedpayments/${customerId}`);
  }

  saveCreditCardSplitPayment(organizationId: number, chairId: number, paymentResponse: PaymentResponse): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/payments/creditcardsplit?org=' + `${organizationId}` + '&customerId=' + `${chairId}`, paymentResponse);
  }

  getResponseLog(searchParams: SearchParams): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/payments/responselogs', searchParams);
  }

  printResponseLog(searchParams: SearchParams): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/payments/printresponselogs', searchParams);
  }

  deleteReceipt(receiptId: number) {
    return this.httpClient.delete<any>(Constants.ERESTAU_DOMAIN + '/api/receipt/' + `${receiptId}`);
  }

  voidTransaction(paymentRequest: PaymentRequest) {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/payments/voidtransaction', paymentRequest);
  }

  sendToDisplayPole(pair: Pair): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/shoppingCartItems/displaypole', pair);
  }

  openCashDrawer(organizationId: number): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/payments/opencashdrawer/' + `${organizationId}`);
  }

  public getDataTokenizationKey(organizationId) {
      const apiPath = Constants.ERESTAU_DOMAIN + `/public/api/merchantdata/${organizationId}`;
      return this.httpClient.get(apiPath);
  }

  public sendToken(token, totalAmount, organizationId): Observable<any> {
    const apiPath = Constants.ERESTAU_DOMAIN + '/public/api/payment?token=' + `${token.token}` + '&amount=' + `${totalAmount}` + '&organizationId=' + `${organizationId}`;
    return this.httpClient.get(apiPath);
  }

  setSplitBalance(cartTotalAmount: number, splitPaymentTenderedAmount: number) {
    this.Balance = cartTotalAmount - splitPaymentTenderedAmount;
    if (this.storage.get(this.getCustomerBalanceKey())) {
      this.Balance = this.storage.get(this.getCustomerBalanceKey()) - splitPaymentTenderedAmount;
    }
    if (this.Balance === 0) {
      /* This is because storage.get('key') returns null if value stored is zero '0' */
      this.Balance = 0.0000001;
    }
    this.storage.set(this.getCustomerBalanceKey(), this.Balance);

    this.TotalSplitPaid = splitPaymentTenderedAmount;
    if (this.storage.get(this.getCustomerSplitPaidKey())) {
      this.TotalSplitPaid = this.storage.get(this.getCustomerSplitPaidKey()) + splitPaymentTenderedAmount;
    }
    this.storage.set(this.getCustomerSplitPaidKey(), this.TotalSplitPaid);

    if (BigDecimal.round(this.Balance, 2) <= 0.00) {
      if (BigDecimal.round(Math.abs(this.Balance), 2) !== 0.00) {
        this.CashBack = true;
        this.toggleService.ShowDoneButton = true;
        this.Balance = Math.abs(this.Balance);
      } else if (BigDecimal.round(Math.abs(this.Balance), 2) === 0.00) {
        this.toggleService.ShowDoneButton = true;
      } else {
        this.CashBack = false;
        this.toggleService.ShowDoneButton = false;
      }
    }
  }

  adjustSplitPayBalance(action: string, selectedCartItem: ShoppingCartItem) {
    if (this.storage.get(this.getCustomerBalanceKey())) {
      let newBalance = 0.00;
      if (Constants.ACTION_SUBTRACT === action) {
        newBalance = this.storage.get(this.getCustomerBalanceKey()) - (selectedCartItem.price + (selectedCartItem.price * (selectedCartItem.menuItem.taxRate / 100)));
      }
      if (Constants.ACTION_ADD === action) {
        newBalance = this.storage.get(this.getCustomerBalanceKey()) + selectedCartItem.price + (selectedCartItem.price * (selectedCartItem.menuItem.taxRate / 100));
      }
      if (newBalance === 0) {
        /* This is because storage.get('key') returns null if value stored is zero '0' */
        newBalance = 0.0000001;
      }
      this.storage.set(this.getCustomerBalanceKey(), newBalance);

      this.isCashBack(newBalance);
    }
  }

  addSplitPayBalance(amount: number) {
    if (this.storage.get(this.getCustomerBalanceKey())) {
      let newBalance = this.storage.get(this.getCustomerBalanceKey()) + amount;
      if (newBalance === 0) {
        /* This is because storage.get('key') returns null if value stored is zero '0' */
        newBalance = 0.0000001;
      }
      this.Balance = newBalance;
      this.storage.set(this.getCustomerBalanceKey(), newBalance);

      this.isCashBack(newBalance);
    }
  }

  updateSplitBalance() {
    if (this.storage.get(this.getCustomerBalanceKey())) {
      this.Balance = this.InvoiceAmount - this.storage.get(this.getCustomerSplitPaidKey());
      if (this.Balance === 0) {
        /* This is because storage.get('key') returns null if value stored is zero '0' */
        this.Balance = 0.0000001;
      }
      this.storage.set(this.getCustomerBalanceKey(), this.Balance);

      this.isCashBack(this.Balance);
    }
  }

  isCashBack(amount: number) {
    if (BigDecimal.round(amount, 2) <= 0.00) {
      if (BigDecimal.round(amount, 2) === 0.00) {
        this.CashBack = false;
      } else {
        this.CashBack = true;
        this.Balance = Math.abs(amount);
      }
      this.toggleService.ShowDoneButton = true;
    } else {
      this.CashBack = false;
      this.toggleService.ShowDoneButton = false;
    }
    return this.CashBack;
  }

  getCustomerBalanceKey() {
    return 'organization_' + this.authenticationService.getOrganizationId() + '_chair_' + this.session.get(Constants.ACTIVE_CHAIR).id + '_split_balance';
  }

  getCustomerSplitPaidKey() {
    return 'organization_' + this.authenticationService.getOrganizationId() + '_chair_' + this.session.get(Constants.ACTIVE_CHAIR).id + '_split_paid';
  }

  getSubmittedSplitPayments(organizationId: number, customerId: number) {
    this.getSplitPayments(organizationId, customerId).subscribe(res => {
      this.SplitSummary = res;
    });
  }

  displayPoleBalance() {
    const pair = new Pair();
    pair.organizationId = this.authenticationService.getOrganizationId();
    pair.key = 'Total: $' + this.InvoiceAmount;
    if (this.CashBack) {
      pair.value = 'Cash Back: $' + Math.abs(BigDecimal.round(Number(this.storage.get(this.getCustomerBalanceKey())), 2));
    } else {
      pair.value = 'Balance: $' + BigDecimal.round(Number(this.storage.get(this.getCustomerBalanceKey())), 2);
    }
    this.sendToDisplayPole(pair).subscribe(response => {});
  }

  updateDisplayPole(pair: Pair) {
    this.sendToDisplayPole(pair).subscribe(r => {});
  }

  set PaymentType(paymentType: string) {
    this.paymentType = paymentType;
  }

  get PaymentType() {
    return this.paymentType;
  }

  set TenderedAmount(tenderedAmount: number) {
    this.tenderedAmount = tenderedAmount;
  }

  get TenderedAmount() {
    return this.tenderedAmount;
  }

  set InvoiceAmount(invoiceAmount: number) {
    this.invoiceAmount = invoiceAmount;
  }

  get InvoiceAmount() {
    return this.invoiceAmount;
  }

  set ProcessAsDebit(processAsDebit: boolean) {
    this.processAsDebit = processAsDebit;
  }

  get ProcessAsDebit() {
    return this.processAsDebit;
  }

  set ActiveChair(activeChair: Customer) {
    this.activeChair = activeChair;
  }

  get ActiveChair() {
    return this.activeChair;
  }

  set SelectedPaymentType(selectedPaymentType: string) {
    this.selectedPaymentType = selectedPaymentType;
  }

  get SelectedPaymentType() {
    return this.selectedPaymentType;
  }

  set SplitPayment(splitPayment: boolean) {
    this.splitPayment = splitPayment;
  }

  get SplitPayment() {
    return this.splitPayment;
  }

  set PaymentConfirmation(paymentConfirmation: boolean) {
    this.paymentConfirmation = paymentConfirmation;
  }

  get PaymentConfirmation() {
    return this.paymentConfirmation;
  }

  set CustomerPhoneNumber(customerPhoneNumber: number) {
    this.customerPhoneNumber = customerPhoneNumber;
  }

  get CustomerPhoneNumber() {
    return this.customerPhoneNumber;
  }

  set CustomerEmail(customerEmail: string) {
    this.customerEmail = customerEmail;
  }

  get CustomerEmail() {
    return this.customerEmail;
  }

  set Balance(balance: number) {
    this.balance = balance;
  }

  get Balance() {
    return this.balance;
  }

  set TotalSplitPaid(totalSplitPaid: number) {
    this.totalSplitPaid = totalSplitPaid;
  }

  get TotalSplitPaid() {
    return this.totalSplitPaid;
  }

  set CashBack(cashBack: boolean) {
    this.cashBack = cashBack;
  }

  get CashBack() {
    return this.cashBack;
  }

  set SplitPaySelected(splitPaySelected: string) {
    this.splitPaySelected = splitPaySelected;
  }

  get SplitPaySelected() {
    return this.splitPaySelected;
  }

  set SplitSummary(splitSummary: Array<SplitPayment>) {
    this.splitSummary = splitSummary;
  }

  get SplitSummary() {
    return this.splitSummary;
  }

  set Receipts(receipts: Array<Receipt>) {
    this.receipts = receipts;
  }

  get Receipts() {
    return this.receipts;
  }

  set MatTableReceiptDataSource(receipts: Array<Receipt>) {
    this.matTableReceiptDataSource = new MatTableDataSource<Receipt>(receipts);
  }

  get MatTableReceiptDataSource() {
    return this.matTableReceiptDataSource;
  }
}

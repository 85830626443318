import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService, 
              private session: SessionStorageService,
              private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf('/api/auth') !== -1) {
      return next.handle(req); // do nothing
    }
    // Get the auth token
    const jwtToken = this.authenticationService.getToken();
    if (this.authenticationService.isTokenExpired(jwtToken)) {
      this.router.navigate(['/']);
      return next.handle(req);
    }

    // Clone the request and set the new header in one step.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + jwtToken)
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
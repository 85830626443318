import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { MenuItemService } from 'src/app/services/menu-item.service';
import { Category } from 'src/app/models/Category';
import { MenuItem } from 'src/app/models/MenuItem';
import { ShoppingCartServiceService } from 'src/app/services/shopping-cart-service.service';
import { ShoppingCartItem } from 'src/app/models/ShoppingCartItem';
import { User } from 'src/app/models/User';
import { CustomerService } from 'src/app/services/customer.service';
import {LocalStorageService, SessionStorageService} from 'angular-web-storage';
import {ActivatedRoute, Router} from '@angular/router';
import { Constants } from 'src/app/models/Constants';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ToggleService } from 'src/app/services/toggle.service';
import {OrderService} from '../../../services/order.service';
import {HeaderComponent} from '../../header/header.component';
import {MessageDialogComponent} from '../../message-dialog/message-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {BigDecimal} from '../../../models/BigDecimal';
import { SharedService } from 'src/app/services/shared.service';


@Component({
  selector: 'app-retail-order',
  templateUrl: './retail-order.component.html',
  styleUrls: ['./retail-order.component.css']
})
export class RetailOrderComponent implements OnInit, OnDestroy, AfterViewInit {
  private apiSubscription = new Subscription();
  private routeSubscription = new Subscription();
  private clearCartsubscription = new Subscription();
  private cartItemIndex = 0;
  private menuItemDisplayIndex = -1;
  private organizationId: number;
  public cartItemFormData: ShoppingCartItem;
  public tempFormData: ShoppingCartItem;
  private selectedItem: MenuItem = new MenuItem();
  @ViewChild(HeaderComponent) headerComponent: HeaderComponent;
  @ViewChild('itemCurrentPrice') itemCurrentPriceElement: ElementRef;
  @ViewChild('itemNotFoundPrice') itemNotFoundPriceElement: ElementRef;
  @ViewChild('itemWeightInput') itemWeightElement: ElementRef;

  public bindKeypad: boolean = false;
  public activeKeypad: string = "";
  public categories: any;
  public cartLoaderFlag: boolean = false;

  constructor(public categoryService: CategoryService, private _shared: SharedService, public menuItemService: MenuItemService,
              public shoppingCartService: ShoppingCartServiceService, public customerService: CustomerService,
              private session: SessionStorageService, private storage: LocalStorageService, private paymentService: PaymentService,
              private router: Router, private authenticationService: AuthenticationService, private dialog: MatDialog,
              public toggleService: ToggleService, public orderService: OrderService, private route: ActivatedRoute) { }

  ngOnInit() {
    // this.selectedItem.imageUrl = 'http://erestaupos.tikarsys.com/images/logo.png';
    this.organizationId = this.authenticationService.getOrganizationId();
    setTimeout(() => {
      this.getTables();
    }, 0);
    this.toggleService.ShowParentCategories = false;
    this.shoppingCartService.DisplayShoppingCart = false;
    this.shoppingCartService.DisplayTables = 'YES';
    const routeSub = this.route.queryParams.subscribe(params => {
      const pageView = params['pageView'] || '';
      if (pageView === 'quickStart') {
        this.orderService.initializeOrderMenu();
      }
    });
    this.routeSubscription.add(routeSub);

    // Get Category
    setTimeout(() => {
    this.getCategories();
    }, 0);

    const subMenuSubscriber = this.orderService.watchSubMenu.subscribe((response) => {
      if (response.flag) {
        setTimeout(() => {
          this.toggleService.SelectedSubCategory = 0;
          this.getMenuItems(response.response[0], 0);
        }, 0);
      }
    });
    this.apiSubscription.add(subMenuSubscriber);
  }

  getCategories() {
    //this._shared.setGlobalLoader(true);
    const catSubscription = this.categoryService.getCategories(this.organizationId).subscribe((cats) => {
      //this._shared.setGlobalLoader(false);
        this.categories = cats;
    });
    this.apiSubscription.add(catSubscription);
}

  ngAfterViewInit() {
    this.headerComponent.itemBarcodeFocus();
  }

  initTableOrders(table: User) {
    this.session.set(Constants.SELECTED_TABLE, table);
    this.orderService.initializeTableOrderMenu(table);
    setTimeout(() => {
      this.headerComponent.itemBarcodeFocus();
    }, 1000);
  }

  triggerScrollTop() {
    setTimeout(() => {
      const cartItemList = document.getElementById('cartItemList');
      cartItemList.scrollTop = 0;
    }, 50);
  }

  getShoppingCartItems() {
    this.shoppingCartService.getChairShoppingCart(this.session.get(Constants.ACTIVE_CHAIR), this.authenticationService.getOrganizationId());
    this.headerComponent.itemBarcodeFocus();
  }

  updateEbtStatus(index: number, cartItem: ShoppingCartItem, newEbtStatus: boolean) {
    const activeChair = this.session.get(Constants.ACTIVE_CHAIR);
    if (activeChair.name === 'EBT-1' || activeChair.name === 'EBT-2') {
      this.openOkayDialog('ERROR', 'Cannot update status in EBT shopping cart');
    } else {
      cartItem.menuItem.ebtEligible = newEbtStatus;
      this.menuItemService.saveMenuItem(cartItem.menuItem, null).subscribe(res => {}, (error => {
        this.openOkayDialog('ERROR', 'Cannot update EBT status of the item');
      }));
    }
  }

  getChairId(name: string) {
    const foundIndex = this.customerService.ActiveChairs.findIndex(c => c.name === name);
    return this.customerService.ActiveChairs[foundIndex].id;
  }

  addToShoppingCart(selectedMenuItem: MenuItem, displayIndex: number) {
    // if (this.ebtShoppingCart() && selectedMenuItem.ebtEligible === false) {
    //   this.openOkayDialog('ERROR', 'Cannot add item that is not EBT here');
    // } else {
      this.menuItemDisplayIndex = displayIndex;
      this.selectedItem = selectedMenuItem;
      if (selectedMenuItem.category.name === Constants.CATEGORY_TYPE_MISCELLANEOUS || selectedMenuItem.pricedByWeight === true) {
        this.editCartItemForm(this.convert(selectedMenuItem), -1);
      } else {
        /* See if selected menu item is in the shopping cart already */
        let itemFound = false;
        let alreadyExist = false;
        this.shoppingCartService.ShoppingCart.forEach((value, index) => {
          if (!alreadyExist && value.menuItem.id === selectedMenuItem.id && this.menuItemService.EmbeddedBarcodePrice === false) {
            alreadyExist = true;
            itemFound = true;
            this.increaseItemQuantity(index);
          }
        });

        if (!itemFound) {
          /* Add new shopping cart item */
          const cartItem = this.convert(selectedMenuItem);
          this.paymentService.adjustSplitPayBalance(Constants.ACTION_ADD, cartItem);
          //this.cartLoaderFlag = true;
          const cartSubscription = this.shoppingCartService.saveShoppingCartItem(cartItem).subscribe((ci) => {
            //this.cartLoaderFlag = false;
            this.shoppingCartService.ShoppingCart.unshift(ci);
            this.shoppingCartService.DisplayShoppingCart = true;
            this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
            this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
            this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
            this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
            this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
            this.triggerScrollTop();
          });
          this.apiSubscription.add(cartSubscription);
        }
      }
    // }
    this.headerComponent.itemBarcodeFocus();
  }

  clearCart() {
    //this.cartLoaderFlag = true;
    const cartSubscription = this.shoppingCartService.clearCart(this.session.get(Constants.ACTIVE_CHAIR).id, this.authenticationService.getOrganizationId()).subscribe((response: any) => {
      //this.cartLoaderFlag = false;
      this.shoppingCartService.ShoppingCart.splice(0, this.shoppingCartService.ShoppingCart.length);
      this.shoppingCartService.calculateServiceFee();
      this.shoppingCartService.DisplayShoppingCart = false;
      this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
      this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
      this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
      this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
      this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
    }, (error) => { this.openOkayDialog('ERROR', 'Not able to clear shopping cart'); });
    this.headerComponent.itemBarcodeFocus();
    this.clearCartsubscription.add(cartSubscription);
  }

  increaseItemQuantity(index: number) {
    //this.cartLoaderFlag = true;
    const unitPrice = this.shoppingCartService.ShoppingCart[index].price / this.shoppingCartService.ShoppingCart[index].quantity;
    const originalItem = this.shoppingCartService.ShoppingCart[index];
    originalItem.price = unitPrice;
    this.paymentService.adjustSplitPayBalance(Constants.ACTION_ADD, originalItem);
    this.shoppingCartService.ShoppingCart[index].quantity++;
    this.shoppingCartService.ShoppingCart[index].price = unitPrice * this.shoppingCartService.ShoppingCart[index].quantity;
    const cartSubscription = this.shoppingCartService.saveShoppingCartItem(this.shoppingCartService.ShoppingCart[index]).subscribe((ci) => {
      //this.cartLoaderFlag = false;
      this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
      this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
      this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
      this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
      this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
    });
    this.apiSubscription.add(cartSubscription);
    this.headerComponent.itemBarcodeFocus();
  }

  convert(menuItem: MenuItem): ShoppingCartItem {
    const cartItem = new ShoppingCartItem();
    cartItem.organizationId = this.organizationId;
    cartItem.menuItem = menuItem;
    cartItem.price = menuItem.price;
    cartItem.user = this.getUser();
    cartItem.customer = this.session.get(Constants.ACTIVE_CHAIR);
    return cartItem;
  }

  removeFromShoppingCart(selectedCartItem: ShoppingCartItem) {
    //this.cartLoaderFlag = true;
    if (selectedCartItem.quantity > 1) {
      selectedCartItem.quantity--;
      selectedCartItem.price = selectedCartItem.menuItem.price * selectedCartItem.quantity;

      if (this.storage.get(this.paymentService.getCustomerBalanceKey())) {
        let unitPrice;
        if (selectedCartItem.menuItem.pricedByWeight) {
          unitPrice = BigDecimal.divide(selectedCartItem.price, selectedCartItem.weight);
        } else {
          unitPrice = BigDecimal.divide(selectedCartItem.price, selectedCartItem.quantity);
        }
        const originalItem = selectedCartItem;
        originalItem.price = unitPrice;


        this.paymentService.adjustSplitPayBalance(Constants.ACTION_SUBTRACT, originalItem);
      }
      const cartSubscription = this.shoppingCartService.saveShoppingCartItem(selectedCartItem).subscribe((res) => {
        //this.cartLoaderFlag = false;
        this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
        this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
        this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
        this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
        this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
      });
      this.apiSubscription.add(cartSubscription);
    } else {
      const index: number = this.shoppingCartService.ShoppingCart.indexOf(selectedCartItem);
      const cartSubscription = this.shoppingCartService.deleteShoppingCartItem(selectedCartItem).subscribe((res) => {
        //this.cartLoaderFlag = false;
        if (this.storage.get(this.paymentService.getCustomerBalanceKey())) {
          this.paymentService.adjustSplitPayBalance(Constants.ACTION_SUBTRACT, selectedCartItem);
        }
        this.shoppingCartService.ShoppingCart.splice(index, 1);
        if (this.shoppingCartService.ShoppingCart.length === 0) {
          this.shoppingCartService.DisplayShoppingCart = false;
        }
        this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
        this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
        this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
        this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
        this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
      });
      this.apiSubscription.add(cartSubscription);
    }
    this.headerComponent.itemBarcodeFocus();
  }

  getMenuItems(category: Category, _index: number) {
    this.toggleService.SelectedSubCategory = _index;
    this.toggleService.ShowNewItemForm = false;
    //this._shared.setGlobalLoader(true);
    const menuSubscription = this.menuItemService.getMenuItemsByCategory(category, this.organizationId).subscribe((mn) => {
      //this._shared.setGlobalLoader(false);
      this.menuItemService.OrderMenuItems = mn;
      this.toggleService.ShowOrderMenuItems = true;
    });
    this.apiSubscription.add(menuSubscription);
    this.headerComponent.itemBarcodeFocus();
  }

  getUser() {
    const user = new User();
    user.id = this.session.get(Constants.IDENTITY).id;
    return user;
  }

  getTables() {
    //this._shared.setGlobalLoader(true);
    const tableSubscription = this.customerService.getTables(this.organizationId).subscribe((tbls) => {
      //this._shared.setGlobalLoader(false); 
      this.customerService.Tables = tbls;
    });
    this.apiSubscription.add(tableSubscription);
  }

  editCartItemForm(cartItem: ShoppingCartItem, indx: number) {
    this.cartItemIndex = indx;
    this.shoppingCartService.DisplayShoppingCart = false;
    this.toggleService.ShowOrderMenuItems = false;
    this.toggleService.ShowCartItemToEdit = true;
    this.cartItemFormData = cartItem;
    this.tempFormData = new ShoppingCartItem();
    this.tempFormData.quantity = cartItem.quantity;
    this.tempFormData.weight = cartItem.weight;
    this.tempFormData.price = BigDecimal.divide(cartItem.price, cartItem.quantity);
    this.tempFormData.notes = cartItem.notes;
    this.tempFormData.menuItem = cartItem.menuItem;
    this.tempFormData.removeTax = cartItem.removeTax;

    if (this.tempFormData.menuItem.pricedByWeight === true && indx < 0) {
      this.toggleService.ShowItemWeightForm = true;
      this.toggleService.ShowCartItemToEdit = false;
      this.tempFormData.weight = 0.00;
      this.itemWeightFocus();
    } else {
      this.itemCurrentPriceFocus();
    }
  }

  closeEditCartItemForm() {
    this.toggleService.ShowCartItemToEdit = false;
    this.shoppingCartService.DisplayShoppingCart = true;
    this.toggleService.ShowOrderMenuItems = true;
    this.headerComponent.itemBarcodeFocus();
  }

  saveUpdatedCartItem() {
    this.shoppingCartService.DisplayShoppingCart = true;
    this.toggleService.ShowCartItemToEdit = false;
    this.cartItemFormData.notes = this.tempFormData.notes;
    this.cartItemFormData.quantity = this.tempFormData.quantity;
    this.cartItemFormData.weight = this.cartItemFormData.weight;
    this.cartItemFormData.price = this.tempFormData.price * this.tempFormData.quantity;
    this.cartItemFormData.pricePermanent = this.tempFormData.pricePermanent;
    this.cartItemFormData.removeTax = this.tempFormData.removeTax;
    if (this.cartItemFormData.removeTax === true) {
      this.cartItemFormData.menuItem.taxRate = 0;
      const foundIndex = this.shoppingCartService.ShoppingCart.findIndex(s => s.menuItem.id === this.cartItemFormData.menuItem.id);
      this.shoppingCartService.ShoppingCart[foundIndex].removeTax = true;
    }
    //this.cartLoaderFlag = true;
    const cartSubscription = this.shoppingCartService.saveShoppingCartItem(this.cartItemFormData).subscribe((ci) => {
      //this.cartLoaderFlag = false;
      if ((this.tempFormData.menuItem.category.name === Constants.CATEGORY_TYPE_MISCELLANEOUS
          || this.tempFormData.menuItem.pricedByWeight === true) && this.cartItemIndex >= 0) {
        this.shoppingCartService.ShoppingCart[this.cartItemIndex] = ci;
      } else if (this.tempFormData.menuItem.category.name === Constants.CATEGORY_TYPE_MISCELLANEOUS) {
        this.shoppingCartService.ShoppingCart.unshift(ci);
      }
      this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
      this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
      this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
      this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
      this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
      this.toggleService.ShowOrderMenuItems = true;
      this.tempFormData = null;
      if (this.storage.get(this.paymentService.getCustomerBalanceKey())) {
        this.paymentService.updateSplitBalance();
      }
    });
    this.apiSubscription.add(cartSubscription);

    if (this.cartItemFormData.pricePermanent === true) {
      this.cartItemFormData.menuItem.price = this.tempFormData.price;
      this.menuItemService.updateLocalStorage(this.cartItemFormData.menuItem);
      const idx = this.menuItemService.OrderMenuItems.findIndex(oi => oi.id === this.cartItemFormData.menuItem.id);
      if (idx >= 0) {
        this.menuItemService.OrderMenuItems[idx].price = this.tempFormData.price;
      }
      this.tempFormData.menuItem.price = this.tempFormData.price;

      const numberOfItems = this.menuItemService.SearchableMenuItems.length;
      for (let i = 0; i < numberOfItems; i++) {
        if (this.menuItemService.SearchableMenuItems[i].id === this.tempFormData.menuItem.id) {
          this.menuItemService.SearchableMenuItems[i].price = this.tempFormData.price;
        }
      }
    }
    this.headerComponent.itemBarcodeFocus();
  }

  addWeightedItemToCart() {
    this.shoppingCartService.DisplayShoppingCart = true;
    this.toggleService.ShowCartItemToEdit = false;
    this.toggleService.ShowItemWeightForm = false;
    this.cartItemFormData.quantity = 1;
    this.cartItemFormData.price = this.tempFormData.price * this.tempFormData.weight;
    this.cartItemFormData.weight = this.tempFormData.weight;
    if (this.storage.get(this.paymentService.getCustomerBalanceKey())) {
      this.paymentService.adjustSplitPayBalance(Constants.ACTION_SUBTRACT, this.cartItemFormData);
    }
    //this.cartLoaderFlag = true;
    const cartSubscription = this.shoppingCartService.saveShoppingCartItem(this.cartItemFormData).subscribe((ci) => {
      //this.cartLoaderFlag = false;
      if (ci.weight) {
        ci.menuItem.name = ci.menuItem.name + ' (' + ci.weight + ' lbs)';
      }
      this.shoppingCartService.ShoppingCart.unshift(ci);
      this.paymentService.InvoiceAmount = this.shoppingCartService.getShoppingCarTotal();
      this.shoppingCartService.updateObservableCartSum(this.shoppingCartService.CartSum);
      this.shoppingCartService.updateObservableCartSumTax(this.shoppingCartService.CartSumTax);
      this.shoppingCartService.updateObservableCartSumTotal(this.shoppingCartService.CartSumTotal);
      this.shoppingCartService.updateObservableItems(this.shoppingCartService.ShoppingCart);
      this.toggleService.ShowOrderMenuItems = true;
      this.headerComponent.itemBarcodeFocus();
    });
    this.apiSubscription.add(cartSubscription);
  }

  closeWeightedItemToCart() {
    this.shoppingCartService.DisplayShoppingCart = true;
    this.toggleService.ShowOrderMenuItems = true;
    this.toggleService.ShowItemWeightForm = false;
    this.headerComponent.itemBarcodeFocus();
  }

  saveNewItem() {
    this.menuItemService.NewMenuItem.active = true;
    this.menuItemService.NewMenuItem.activeDigiMenu = false;
    this.menuItemService.NewMenuItem.activeOnline = false;
    this.menuItemService.NewMenuItem.alcohol = false;
    this.menuItemService.NewMenuItem.description = this.menuItemService.NewMenuItem.name;
    this.menuItemService.NewMenuItem.fulfillmentTime = 45.00;
    this.menuItemService.NewMenuItem.onlinePrice = this.menuItemService.NewMenuItem.price;
    this.menuItemService.NewMenuItem.sortText = this.menuItemService.NewMenuItem.name;
    this.menuItemService.NewMenuItem.organizationId = this.authenticationService.getOrganizationId();

    if (this.menuItemService.NewMenuItem.category.id) {
      this.menuItemService.NewMenuItem.category.organizationId = this.menuItemService.NewMenuItem.organizationId;
    }
    //this._shared.setGlobalLoader(true);
    const menuSubscription = this.menuItemService.quickAddMenuItem(this.menuItemService.NewMenuItem).subscribe(mi => {
      //this._shared.setGlobalLoader(false);
      this.menuItemService.SearchableMenuItems.push(mi);
      if (this.menuItemService.NewMenuItem.pricedByWeight === true ) {
        this.editCartItemForm(this.convert(mi), -1);
      }
      else {
        this.addToShoppingCart(mi, -1);
        this.menuItemService.NewMenuItem = null;
        this.shoppingCartService.DisplayShoppingCart = true;
        this.toggleService.ShowOrderMenuItems = true;
        this.toggleService.ShowCartItemToEdit = false;
        this.toggleService.ShowItemWeightForm = false;
        this.toggleService.ShowNewItemForm = false;
      }
      
      this.headerComponent.itemBarcodeFocus();
    });
    this.apiSubscription.add(menuSubscription);
  }

  cancelNewItemAdd() {
    this.menuItemService.NewMenuItem = null;
    this.shoppingCartService.DisplayShoppingCart = true;
    this.toggleService.ShowOrderMenuItems = true;
    this.toggleService.ShowCartItemToEdit = false;
    this.toggleService.ShowItemWeightForm = false;
    this.toggleService.ShowNewItemForm = false;
    this.headerComponent.itemBarcodeFocus();
  }

  ebtShoppingCart() {
    if (this.session.get(Constants.ACTIVE_CHAIR).name === 'EBT-1' || this.session.get(Constants.ACTIVE_CHAIR).name === 'EBT-2') {
      return true;
    }
    return false;
  }

  openOkayDialog(msgHeader: string, msg: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {});
    this.apiSubscription.add(dialogSubscription);
  }

  itemCurrentPriceFocus() {
    setTimeout(() => {
      this.itemCurrentPriceElement.nativeElement.focus();
    }, 1000);
  }

  itemNotFoundPriceFocus() {
    setTimeout(() => {
      this.itemNotFoundPriceElement.nativeElement.focus();
    }, 1000);
  }

  itemWeightFocus() {
    setTimeout(() => {
      this.itemWeightElement.nativeElement.focus();
    }, 1000);
  }

  bindKeyPad(inputName) {
    this.activeKeypad = inputName;
    this.bindKeypad = true;
  }

  unBindKeyPad() {
    // this.bindKeypad = false;
  }

  setAmount(data) {
    if (data.key.indexOf('.') != -1) {
      let keys = data.key.split('.');
      if(keys.length == 2) {
        this[keys[0]][keys[1]] = data.value;
      }
      else if(keys.length == 3) {
        this[keys[0]][keys[1]][keys[2]] = data.value;
      }
      else if(keys.length == 4) {
        this[keys[0]][keys[1]][keys[2]][keys[3]] = data.value;
      }
    }
    else {
      this[data.key] = data.value;
    }
  }
  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
    this.clearCartsubscription.unsubscribe();
  }
}

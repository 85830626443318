import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ReceiptService} from '../../services/receipt.service';
import {AuthenticationService} from '../../services/authentication.service';
import {MatTableDataSource} from '@angular/material/table';
import {Receipt} from '../../models/Receipt';
import {SearchParams} from '../../models/SearchParams';
import {Constants} from '../../models/Constants';
import {PaymentRequest} from '../../models/PaymentRequest';
import {PaymentService} from '../../services/payment.service';
import {WebsocketService} from '../../services/websocket.service';
import {User} from '../../models/User';
import {Router} from '@angular/router';
import {OrderService} from '../../services/order.service';
import {OrderItem} from '../../models/OrderItem';
import {BatchCloseComponent} from '../../dialogs/batch-close/batch-close.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SettingsService} from '../../services/settings.service';
import {Property} from '../../models/Property';
import {MessageDialogConfirmationComponent} from '../message-dialog-confirmation/message-dialog-confirmation.component';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {Subscription} from 'rxjs';
import {BigDecimal} from "../../models/BigDecimal";
import { CurrencyPipe } from '@angular/common';
import { SharedService } from 'src/app/services/shared.service';
import {SessionStorageService} from "angular-web-storage";

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit, OnDestroy {
  private apiSubscription = new Subscription();
  public orderItems: Array<OrderItem> = [];
  public orderItemDataSource = null;
  public availableTerminals: Array<Property> = [];

  public receiptSummaryColumns = ['receiptNo', 'firstName', 'updated', 'netto', 'tax', 'paymentType', 'status', 'total', 'action'];
  public orderItemsColumns = ['orderItemId', 'returnQuantity', 'orderItemName', 'orderItemQty', 'orderItemPrice', 'returnedItemStatus', 'cardType'];

  @ViewChild('receiptNumberInput') receiptBarcodeElement: ElementRef;

  constructor(private receiptService: ReceiptService, private authenticationService: AuthenticationService, public orderService: OrderService,
              public paymentService: PaymentService, private websocketService: WebsocketService, private router: Router,
              private dialog: MatDialog, private settingsService: SettingsService, private currencyPipe: CurrencyPipe,
              private _shared: SharedService, public session: SessionStorageService
              ) { }

  ngOnInit() {
    setTimeout(() => {
      this.getReceipts();
    }, 0);

    let webSocketLoaderSub = this._shared.webSocketLoader.subscribe((response) => {
      if (!response.flag && response.status && response.status == 'SUCCESS') {
        this.getReceipts();
      }
    });

    this.apiSubscription.add(webSocketLoaderSub)
  }

  home() {
    this.router.navigate(['/home']);
  }

  paymentsHome() {
    this.orderService.ShowOrderSummary = true;
    this.orderService.ShowOrderDetails = false;
  }

  highlight(orderItem: OrderItem) {
    orderItem.highlighted = !orderItem.highlighted;
  }

  applyRefund(itemsForRefund: Array<OrderItem>) {
    // console.log(itemsForRefund, 'Refunded Items');
    const items = new Array<OrderItem>();
    for (let i = 0; i < itemsForRefund.length; i++) {
      const item = this.orderService.getUnNestedOrderItem(itemsForRefund[i]);
      // console.log(item, 'Refunded Item');
      items.push(item);
    }
    this._shared.setGlobalLoader(true);
    const paySubscription = this.paymentService.submitRefund(items).subscribe(response => {
          // console.log(response);
          if (items[0].receipt.paymentType === this.paymentService.CASH || items[0].receipt.paymentType === this.paymentService.OTHER) {
            this.openCashConfirmRefundDialog(items);
            this._shared.setGlobalLoader(false);
          } else {
            this._shared.setWebSocketLoader(true);
            this.websocketService.refundTransaction(items);
          }
        },
        (error) => {
          this._shared.setGlobalLoader(false);
          alert('******* Error processing REFUND *********');
    });
    this.apiSubscription.add(paySubscription);
  }

  voidPayment(receipt: Receipt) {
    const user = new User();
    user.systemId = this.authenticationService.getIdentity().username;
    const paymentRequest = new PaymentRequest();
    paymentRequest.user = user;
    paymentRequest.paymentTerminalPresent = this.authenticationService.paymentTerminalPresent();
    paymentRequest.organizationId = this.authenticationService.getOrganizationId();
    paymentRequest.receiptNumber = receipt.receiptNo;
    paymentRequest.refNumber = receipt.paymentRef;
    paymentRequest.paymentType = Constants.PAYMENT_TYPE_VOID;
    this._shared.setGlobalLoader(true);
    const paySubscription = this.paymentService.submitPayment(paymentRequest).subscribe((rcpt) => {
          this._shared.setWebSocketLoader(true);
          this.websocketService.voidTransaction(rcpt);
        },
        (error) => {
          alert('******* Error processing VOID payment *********');
    });
    this.apiSubscription.add(paySubscription);
  }

  closeBatch() {
    const user = new User();
    user.systemId = this.authenticationService.getIdentity().username;
    const paymentRequest = new PaymentRequest();
    paymentRequest.user = user;
    paymentRequest.paymentTerminalPresent = this.authenticationService.paymentTerminalPresent();
    paymentRequest.organizationId = this.authenticationService.getOrganizationId();
    paymentRequest.paymentType = Constants.CLOSE_BATCH;
    this._shared.setGlobalLoader(true);
    const propSubscription = this.settingsService.getProperties(this.authenticationService.getOrganizationId()).subscribe((response) => {
      this.availableTerminals = response.filter(p => p.name.includes('TERMINAL_'));
      const msgHeader = 'CLOSE BATCH';
      this._shared.setGlobalLoader(false);
      const msg = JSON.stringify(this.availableTerminals);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '580px';
      dialogConfig.height = '300px';
      dialogConfig.data = {description: msgHeader, message: msg};
      const dialogRef = this.dialog.open(BatchCloseComponent, dialogConfig);
      const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
        if (result === true && this.settingsService.SelectedTerminal !== 'NONE') {
          paymentRequest.terminal = this.settingsService.SelectedTerminal;
          const pymtSubscription = this.paymentService.submitPayment(paymentRequest).subscribe((rcpt) => {
                this.websocketService.closeBatchTransaction(paymentRequest.terminal);
              },
              (error) => {
                alert('******* Error CLOSING BATCH *********');
              });
          this.apiSubscription.add(pymtSubscription);
        }
      });
      this.apiSubscription.add(dialogSubscription);
    });
    this.apiSubscription.add(propSubscription);
  }

  getReceipts() {
    const endDate = new Date();
    const startDate = this.addDays(endDate, -1);
    const searchParams = new SearchParams();
    searchParams.startDate = startDate;
    searchParams.endDate = endDate;
    searchParams.filterCards = false;
    searchParams.organizationId = this.authenticationService.getOrganizationId();
    this.orderService.ShowOrderSummary = true;
    this.orderService.ShowOrderDetails = false;
    this._shared.setGlobalLoader(true);
    const receiptSubscription = this.receiptService.getReceiptsByDate(searchParams).subscribe((rcpts) => {
      this.paymentService.Receipts = rcpts;
      this._shared.setGlobalLoader(false);
      this.paymentService.MatTableReceiptDataSource = this.paymentService.Receipts;
    });
    this.apiSubscription.add(receiptSubscription);
  }

  getOrderItems(receipt: Receipt) {
    this.orderService.ShowOrderSummary = false;
    this.orderService.ShowOrderDetails = true;
    const orderSubscription = this.orderService.getOrderItems(receipt.id).subscribe(oi => {
      for (let i = 0; i < oi.length; i++) {
          oi[i].returnQuantity = oi[i].quantity;
          if (oi[i].subOrderItems.length > 0) {
            let subOrderItemNames = '';
            for (let j = 0; j < oi[i].subOrderItems.length; j++) {
              if (subOrderItemNames.length > 0) {
                subOrderItemNames += ' # ';
              }
              subOrderItemNames += oi[i].subOrderItems[j].subMenuItem.name;
            }
            oi[i].name += ' (' + subOrderItemNames + ')';
          }
      }
      this.orderItems = oi;
      this.orderItemDataSource = new MatTableDataSource<OrderItem>(this.orderItems);
    });
    this.apiSubscription.add(orderSubscription);
  }

  receiptNumberLookup(data: string) {
    const searchParams = new SearchParams();
    searchParams.receiptNumber = data;
    searchParams.organizationId = this.authenticationService.getOrganizationId();

    if (data) {
      const receiptSubscription = this.receiptService.getReceiptsByDate(searchParams).subscribe(rcpts => {
        this.receiptBarcodeElement.nativeElement.value = '';
        this.paymentService.Receipts = rcpts;
        this.paymentService.MatTableReceiptDataSource = this.paymentService.Receipts;
      }, error => { alert('NO SEARCH RESULTS'); });
      this.apiSubscription.add(receiptSubscription);
    }
  }

  receiptNumberInputFocus() {
    setTimeout(() => {
      this.receiptBarcodeElement.nativeElement.focus();
    },0);
  }

  goToLink(receipt: Receipt) {
    const url = Constants.ERESTAU_DOMAIN + '/public/api/render/receipt?receiptId=' + receipt.id;
    window.open(url, '', 'width=600,height=700,left=300,top=100');
  }

  addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

  confirmRefund() {
    let refundAmount = 0;
    for (let i = 0; i < this.orderItems.length; i++) {
      if (this.orderItems[i].checked === true) {
        const unitPrice = this.orderItems[i].totalPrice / this.orderItems[i].quantity;
        refundAmount = refundAmount + this.orderItems[i].returnQuantity * unitPrice;
      }
    }
    const msg = 'Total refund amount [ $' + BigDecimal.round(refundAmount,2) + ' ] correct?';
    this.openDialogConfirmation('REFUND CONFIRMATION', msg, 'REFUND');
  }

  confirmVoid(receipt: Receipt) {
    const msg = 'Are you sure you want to void receipt with amount [ ' + this.currencyPipe.transform(receipt.total) + ' ] ?';
    if (receipt.paymentType === this.paymentService.CASH
        || receipt.paymentType === this.paymentService.OTHER
        || receipt.paymentType === this.paymentService.SPLIT_PAYMENT) {
      this.openDeleteReceiptDialogConfirmation('DELETE RECEIPT', msg, receipt.id);
    } else {
      this.openDialogConfirmation('VOID CONFIRMATION', msg, receipt);
    }
  }

  openDeleteReceiptDialogConfirmation(msgHeader: string, msg: string, receiptId: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {description: msgHeader, message: msg};
    dialogConfig.width = '640px';

    const dialogRef = this.dialog.open(MessageDialogConfirmationComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation === true) {
        const paySubscription = this.paymentService.deleteReceipt(receiptId).subscribe(response => {
          if (response.message && response.message.startsWith('ERROR')) {
            const err = response.message.substring(6);
            const errorDialogConfig = new MatDialogConfig();
            errorDialogConfig.width = '530px';
            errorDialogConfig.height = '270px';
            errorDialogConfig.data = {description: 'ERROR', message: err};

            this.dialog.open(MessageDialogComponent, errorDialogConfig);
          } else {
            const foundIndex = this.paymentService.Receipts.findIndex(r => r.id === receiptId);
            this.paymentService.Receipts.splice(foundIndex, 1);
            this.paymentService.MatTableReceiptDataSource = this.paymentService.Receipts;
          }
        }, (error) => { alert(error); });
        this.apiSubscription.add(paySubscription);
      }
    });
    this.apiSubscription.add(dialogSubscription);
  }

  openDialogConfirmation(msgHeader: string, msg: string, target: any): void {
    if (target === 'REFUND' && this.orderItems.filter(i => i.checked)[0].receipt.paymentType === this.paymentService.CASH) {
      // TODO open cash drawer
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {description: msgHeader, message: msg};
    dialogConfig.width = '640px';

    const dialogRef = this.dialog.open(MessageDialogConfirmationComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation === true) {
        if (target.netto) {
          this.voidPayment(target);
        } else if (target === 'REFUND') {
          const itemsForRefund = this.orderItems.filter(i => i.checked);
          this.applyRefund(itemsForRefund);
        }
      }
    });
    this.apiSubscription.add(dialogSubscription);
  }

  openCashConfirmRefundDialog(orderItems: Array<OrderItem>) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: 'SUCCESS', message: 'CASH Refund Completed'};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      this.orderService.saveOrderItems(orderItems).subscribe((res) => {
        this.getReceipts();
        this.orderService.ShowOrderDetails = false;
        this.orderService.ShowOrderSummary = true;
      });
    });
    this.apiSubscription.add(dialogSubscription);
  }

  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
  }
}

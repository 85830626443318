import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DragScrollModule } from 'ngx-drag-scroll';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CategoryComponent} from './components/category/category.component';
import {RestaurantOrderComponent} from './components/order/restaurant-order/restaurant-order.component';
import {HomeComponent} from './components/home/home.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {TableComponent} from './components/table/table.component';
import {AngularWebStorageModule} from 'angular-web-storage';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MessageDialogComponent} from './components/message-dialog/message-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {LoginComponent} from './components/login/login.component';
import {FooterComponent} from './components/footer/footer.component';
import {AuthInterceptor} from './interceptors/auth-interceptor';
import {CheckoutComponent} from './components/checkout/checkout.component';
import {HeaderComponent} from './components/header/header.component';
import {UserComponent} from './components/user/user.component';
import {ReceiptComponent} from './components/receipt/receipt.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxCurrencyModule} from 'ngx-currency';
import {ReportsComponent} from './components/reports/reports.component';
import {InventoryComponent} from './components/inventory/inventory.component';
import {ChartsModule, ThemeService} from 'ng2-charts';
import {OnlineOrdersComponent} from './components/online-orders/online-orders.component';
import {PaymentOptionsComponent} from './components/payment-options/payment-options.component';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {SettingsComponent} from './components/settings/settings.component';
import {ReceiptDialogComponent} from './dialogs/receipt-dialog/receipt-dialog.component';
import {RetailOrderComponent} from './components/order/retail-order/retail-order.component';
import {BarcodeLabelDialogComponent} from './components/inventory/message-dialog/barcode-label/barcode-label-dialog.component';
import {PaymentsComponent} from './components/payments/payments.component';
import {MenuItemDetailsComponent} from './components/menu-item-details/menu-item-details.component';
import {SidesComponent} from './components/sides/sides.component';
import {TooltipDirective} from './directives/TooltipDirective';
import {CheckoutDialogComponent} from './components/checkout/message-dialog/checkout-dialog.component';
import {CustomerDialogComponent} from './components/header/customer-dialog/customer-dialog.component';
import {MessageDialogConfirmationComponent} from './components/message-dialog-confirmation/message-dialog-confirmation.component';
import {BatchCloseComponent} from './dialogs/batch-close/batch-close.component';
import {InputFocusDirective} from './directives/InputFocusDirective';
import { KeypadComponent } from './components/keypad/keypad.component';
import {AgGridModule} from 'ag-grid-angular';
import { ProductItemFormComponent } from './components/product-item-form/product-item-form.component';
import { ImageRendererComponent } from './components/image-renderer/image-renderer.component';
import { PaymentResponseLogComponent } from './components/payment-response-log/payment-response-log.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { DatePipe, CurrencyPipe } from '@angular/common';
import { TipAdjustRendererComponent } from './components/tip-adjust-rendender/tip-adjust-renderer.component';
import { SideItemSelectionComponent } from './components/side-item-selection/side-item-selection.component';

@NgModule({
    declarations: [
        AppComponent,
        CategoryComponent,
        RestaurantOrderComponent,
        HomeComponent,
        MenuItemComponent,
        MenuItemDetailsComponent,
        SidesComponent,
        TableComponent,
        MessageDialogComponent,
        ReceiptDialogComponent,
        CheckoutDialogComponent,
        LoginComponent,
        FooterComponent,
        CheckoutComponent,
        HeaderComponent,
        UserComponent,
        ReceiptComponent,
        SpinnerComponent,
        ReportsComponent,
        InventoryComponent,
        OnlineOrdersComponent,
        PaymentOptionsComponent,
        SettingsComponent,
        RetailOrderComponent,
        BarcodeLabelDialogComponent,
        PaymentsComponent,
        TooltipDirective,
        InputFocusDirective,
        CustomerDialogComponent,
        MessageDialogConfirmationComponent,
        BatchCloseComponent,
        ImageRendererComponent,
        KeypadComponent, 
        ProductItemFormComponent, TipAdjustRendererComponent,
        PaymentResponseLogComponent,
        SideItemSelectionComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        AngularWebStorageModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        NgxCurrencyModule,
        ChartsModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatBadgeModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatSelectModule,
        MatExpansionModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,       
        AgGridModule.withComponents([TipAdjustRendererComponent]),
        SlickCarouselModule,
        DragScrollModule
    ],
    entryComponents: [MessageDialogComponent, MessageDialogConfirmationComponent, BarcodeLabelDialogComponent, ReceiptDialogComponent, BatchCloseComponent, CheckoutDialogComponent, CustomerDialogComponent],
    providers: [
        ThemeService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        DatePipe, CurrencyPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

import { Component, OnInit, Output, Input, OnChanges, EventEmitter } from '@angular/core';
import {BigDecimal} from '../../models/BigDecimal';

@Component({
  selector: 'app-side-item-selection',
  templateUrl: './side-item-selection.component.html',
  styleUrls: ['./side-item-selection.component.css']
})
export class SideItemSelectionComponent implements OnInit, OnChanges {

  @Input('inputItem') inputItem: any;
  @Output('outputItem') outputItem = new EventEmitter();

  public openSideAdd: boolean = false;
  public activeKeypad: any = 'price';
  public bindKeypad: boolean = true;
  public isPreference: boolean = false;

  public availableSides: Array<any> = [];
  public selectedSides: Array<any> = [];

  public sideForm: any = {
    quantity: 0,
    price: 0,
    pricePermanent: false,
    preference: "",
    notes: "",
    subTaxTotal: 0,
    subPriceTotal: 0
  };

  constructor() { } 

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.inputItem) {

      // Check Preference Present
      if(this.inputItem.menuItem.subMenuItems.find(item => item.preference)) {
        this.isPreference = true;
      } else {
        this.isPreference = false;
      }

      // Set Form Data
      this.sideForm = this.inputItem.sideForm;

      // Set Selected Sides
      this.updateSelectedSides();

      // Update Preference
      this.updatePreference();

      // Set Open Side Flag
      if (!this.inputItem.isEditMode) {
        this.openSideAdd = true;
      } else {
        this.openSideAdd = false;
      }
    }
  }

  updateSelectedSides(action?, index?, item?) {
    if (!action) {
      this.selectedSides = [...this.inputItem.subShoppingCartItems];
    } else if (action == 'add') {
      let isAvailableInShoppingCart = this.inputItem.subShoppingCartItems.find(shoppingItem => shoppingItem.subMenuItem.id == item.subMenuItem.id);
      if (isAvailableInShoppingCart) {
        item['id'] = isAvailableInShoppingCart.id;
        this.selectedSides.push(item);
      } else {
        this.selectedSides.push(item);
      }
    } else if (action == 'remove') {
      this.selectedSides.splice(index, 1);
    }

    // Sort Sides
    this.selectedSides.sort((a, b) => {
      return b.price - a.price;
    });

    // Update Available Sides
    this.updateAvailableSides();
  }

  updateAvailableSides() {
    this.availableSides = [];
    this.inputItem.menuItem.subMenuItems.forEach((item) => {
      if (!this.selectedSides.find(menu => menu.subMenuItem.id == item.id)) {
        this.availableSides.push(this.mapItem(item))
      }
    });

    // Calculate Total
    this.calculateTotal();
  }

  updatePreference() {
    // Remove If existing selected Preference
    this.selectedSides = this.selectedSides.filter(selectedItem => !selectedItem.preference);
    
    // Default Preference Selection
    if (!this.sideForm.preference) {
      let preferenceFound = false;
      this.inputItem.menuItem.subMenuItems.forEach((value, index) => {
        if (value.preference && !preferenceFound) {
          this.sideForm.preference = value.name;
          preferenceFound = true;
          this.updateSelectedSides('add', index, this.mapItem(value));
        }  
      });
    } 
    // Preference Change Method
    else {
      this.inputItem.menuItem.subMenuItems.forEach((value, index) => {
        if (value.preference && value.name === this.sideForm.preference ) {
          this.updateSelectedSides('add', index, this.mapItem(value));
        }  
      });
    }
  }

  mapItem(item) {
    return {
      id : null,
      organizationId : item.organizationId,
      number : item.number,
      name : item.name,
      description : item.description,
      price : item.price,
      taxRate : item.taxRate,
      preference : item.preference,
      subMenuItem : item,
      subMenuType : item.subMenuType,
      updated : item.updated
    }
  }

  bindKeyPad(inputName) {
    this.activeKeypad = inputName;
    this.bindKeypad = true;
    if (this.openSideAdd) {
      this.openSideAdd = false;
    }
  }

  unBindKeyPad() {
  }

  setAmount(data) {
    if (data.key.indexOf('.') != -1) {
      let keys = data.key.split('.');
      if(keys.length == 2) {
        this[keys[0]][keys[1]] = data.value;
      }
      else if(keys.length == 3) {
        this[keys[0]][keys[1]][keys[2]] = data.value;
      }
      else if(keys.length == 4) {
        this[keys[0]][keys[1]][keys[2]][keys[3]] = data.value;
      }
    }
    else {
      this[data.key] = data.value;
    }
  }

  toggleAddSide() {
    this.openSideAdd = !this.openSideAdd;
  }

  sendSelectedSides() {
    this.inputItem.sideForm = this.sideForm;
    this.inputItem.subShoppingCartItems = this.selectedSides;
    this.outputItem.emit(this.inputItem);
  }

  cancelSideItem() {
    this.outputItem.emit('cancel');
  }

  addToSelection(item, index) {
    this.updateSelectedSides('add', index, item);
  }

  removeSide(item, index) {
    this.updateSelectedSides('remove', index, item);
  }

  calculateTotal() {
    let totalSidePrice = 0;
    let totalSideTax = 0;
    this.selectedSides.forEach((value, index) => {
      if (index >= this.inputItem.menuItem.numOfSubMenus) {
        totalSidePrice += value.price;
        totalSideTax += (value.price * BigDecimal.divide(value.taxRate, 100));
      }
    });
    this.sideForm.subPriceTotal = Number(BigDecimal.round((totalSidePrice), 2));
    this.sideForm.subTaxTotal = Number(BigDecimal.round((totalSideTax), 2));
  }

  round(nbr: number, decimalPlaces: number) {
    const factorOfTen = Math.pow(10, decimalPlaces);
    return Math.round(nbr * factorOfTen) / factorOfTen;
  }


}

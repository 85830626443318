import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'angular-web-storage';
import {CustomerService} from 'src/app/services/customer.service';
import {ShoppingCartServiceService} from 'src/app/services/shopping-cart-service.service';
import {Constants} from 'src/app/models/Constants';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {ReceiptRequest} from 'src/app/models/ReceiptRequest';
import {ReceiptService} from 'src/app/services/receipt.service';
import {PaymentService} from 'src/app/services/payment.service';
import {PaymentRequest} from 'src/app/models/PaymentRequest';
import {WebsocketService} from 'src/app/services/websocket.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CloseOrderRequest} from 'src/app/models/CloseOrderRequest';
import {ToggleService} from 'src/app/services/toggle.service';
import {CheckoutDialogComponent} from './message-dialog/checkout-dialog.component';
import {Receipt} from '../../models/Receipt';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {Pair} from '../../models/Pair';
import {Subscription} from 'rxjs';
import {BigDecimal} from '../../models/BigDecimal';
import {MessageDialogConfirmationComponent} from '../message-dialog-confirmation/message-dialog-confirmation.component';
import {SettingsService} from '../../services/settings.service';
import {BatchCloseComponent} from '../../dialogs/batch-close/batch-close.component';
import { CurrencyPipe } from '@angular/common';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('tenderedCashAmountInput') tenderedCashAmountInput: ElementRef;
    @ViewChild('tenderedOtherAmountInput') tenderedOtherAmountInput: ElementRef;
    @ViewChild('tenderedSplitPaymentAmountInput') tenderedSplitPaymentAmountInput: ElementRef;
    @ViewChild('tenderedCreditCardAmountInput') tenderedCreditCardAmountInput: ElementRef;
    @ViewChild('tenderedEbtAmountInput') tenderedEbtAmountInput: ElementRef;
    @ViewChild('paymentAmountField') paymentAmountField: ElementRef;

    // public ebtStatus: string = 'ebt';

    // public paymentAccordion: any = [
    //     {
    //         paymentType: 'cash',
    //         title: 'Cash',
    //         isOpen: false
    //     },
    //     {
    //         paymentType: 'card',
    //         title: 'Credit/Debit/Gift/EBT',
    //         isOpen: false
    //     },
    //     {
    //         paymentType: 'other',
    //         title: 'Others',
    //         isOpen: false
    //     }
    // ]

    public organizationId: number;
    public paymentRequest: PaymentRequest;
    public processPaymentAsDebit: boolean;
    public changeAmount: number = 0;
    public cashTenderedAmount = 0.00;
    public ebtTenderedAmount = 0.00;
    public cardTenderedAmount = 0.00;
    public splitPaymentTenderedAmount = 0.00;
    public phoneNumber: number;
    public isCombinedPayment: boolean;
    public customerInvoiceAmount: number;
    public cartAmount: number;
    public cartTaxAmount: number;
    public cartTotalAmount: number;
    public notifyDisplayPole = false;
    public receipt: Receipt;
    private apiSubscription = new Subscription();
    private routeSubscription = new Subscription();
    private behaviorSubscription = new Subscription();
    public showMatLoader: boolean = false;

    public bindKeypad: boolean = false;
    public availableTerminals: any;

    // New Properties Started
    public cartInfo: any = {};
    public cartStep: any = [
        {
            step: '0',
            isActive: false,
            stepTitle: 'step1',
            isPass: false
        },
        {
            step: '1',
            isActive: false,
            stepTitle: 'step2',
            isPass: false
        },
        {
            step: '2',
            isActive: false,
            stepTitle: 'step3',
            isPass: false
        }
    ];
    public cartStepSelected: any = null;
    public toggleSummary: boolean = false;
    public toggleTransactions: boolean = false;
    public selectedPaymentMethod: string = null;
    public selectedSplitPaymentMethod: string = null;
    public paymentAmount: number = 0.0;
    public constants = Constants;
    public checkoutLoader: boolean = false;
    public paymentProgressBar: any = { spinner: false, status: null };
    public isRetail: boolean = false;
    public ebtPaymentProcess: boolean = false;

    constructor(private router: Router,
                public shoppingCartService: ShoppingCartServiceService,
                private customerService: CustomerService,
                private session: SessionStorageService,
                private storage: LocalStorageService,
                public authenticationService: AuthenticationService,
                private receiptService: ReceiptService,
                public paymentService: PaymentService,
                private websocketService: WebsocketService,
                public toggleService: ToggleService,
                private dialog: MatDialog,
                private route: ActivatedRoute, private settingsService: SettingsService,
                private _currency: CurrencyPipe
        ) {
    }

    changeOtherEBT() {
        if (this.ebtPaymentProcess) {
            this.paymentAmount = this.cartInfo.nonEbtSubTotal;
        } else {
            this.paymentAmount = this.cartInfo.balance;
        }
    }

    ngOnInit() {
        // Check Is it Retail Or Restaurent
        if (this.session.get('orgType') === Constants.ORGANIZATION_TYPE_RETAIL) {
            this.isRetail = true;
        }

        this.organizationId = this.authenticationService.getOrganizationId();
        this.paymentRequest = new PaymentRequest();
        this.paymentRequest.organizationId = this.organizationId;

        if (this.storage.get(this.paymentService.getCustomerBalanceKey())) {
            this.paymentService.getSubmittedSplitPayments(this.authenticationService.getOrganizationId(), this.session.get(Constants.ACTIVE_CHAIR).id);
        }

        const paramsSubscription = this.route.queryParams.subscribe(params => {
            this.isCombinedPayment = false;
            const payment = params['payment'] || 'individual';
            if (payment === 'group') {
                this.isCombinedPayment = true;
                if (this.paymentService.SelectedPaymentType == null) {
                    this.paymentService.SelectedPaymentType = Constants.PAYMENT_TYPE_CASH;
                    this.customerService.ActiveChairs = this.session.get(Constants.ACTIVE_TABLE_CHAIRS);
                }
                this.getSelectedItemsForPayment();
            } else {
                const cartSumSubscription = this.shoppingCartService.getObservableCartSum().subscribe((cs) => {
                    this.cartAmount = cs;
                });
                const cartSumTaxSubscription = this.shoppingCartService.getObservableCartSumTax().subscribe((cst) => {
                    this.cartTaxAmount = cst;
                });
                const cartTotalSubscription = this.shoppingCartService.getObservableCartSumTotal().subscribe((t) => {
                    this.cartTotalAmount = t;
                    this.shoppingCartService.CustomerInvoiceAmount = this.cartTotalAmount;
                });
                this.behaviorSubscription.add(cartSumSubscription);
                this.behaviorSubscription.add(cartSumTaxSubscription);
                this.behaviorSubscription.add(cartTotalSubscription);
            }
            this.toggleService.ShowActiveChairs = true;
        });
        this.routeSubscription.add(paramsSubscription);

        // Observe Payment Progress
        const paymentProgressObservable = this.paymentService.paymentProgressObservable.subscribe((response) => {
            this.paymentProgressBar = response;
            if (response.spinner == false) {
                if (response.status == 'ERROR') {
                    if (this.selectedPaymentMethod == 'SPLIT') {
                        this.getCartInfo(this.selectedSplitPaymentMethod, true);
                    } else {
                        this.getCartInfo(this.selectedPaymentMethod, true);
                    }
                } else {
                    if (this.selectedPaymentMethod == 'SPLIT') {
                        this.getCartInfo(this.selectedSplitPaymentMethod);
                    } else {
                        this.getCartInfo(this.selectedPaymentMethod);
                    }
                }
            }
        });

        // Subscribe Cart Refresh
        const cartRefreshObservable = this.shoppingCartService.cartRefreshObservable.subscribe((response) => {
            if (response && !this.paymentProgressBar.spinner) {
                this.changePaymentMethod('CREDIT_CARD', true);
            }
            this.shoppingCartService.triggerCartRefresh(false);
        });

        // Subscription Properties
        this.behaviorSubscription.add(paymentProgressObservable);
        this.behaviorSubscription.add(cartRefreshObservable);

        // Initialize Checkout
        if (this.paymentService.SelectedPaymentType) {
            this.changePaymentMethod(this.paymentService.SelectedPaymentType, true); 
        } else {
            this.changePaymentMethod('CREDIT_CARD', true);
        }
        this.toggleSummary = false;
        this.toggleTransactions = false;
    }

    // Click Event of Main Payment Method
    changePaymentMethod(method, isInitialCall?) {
        // if (method == 'EBT' && this.cartInfo.nonEbtTotal > 0) {
        //     method = 'SPLIT';
        //     this.selectedSplitPaymentMethod = 'EBT';
        // }
        this.selectedPaymentMethod = method;
        this.selectedSplitPaymentMethod = this.selectedSplitPaymentMethod? this.selectedSplitPaymentMethod : 'CREDIT_CARD';
        this.paymentService.SelectedPaymentType = method;
        this.resetStep();
        if (!isInitialCall) {
            if (this.selectedPaymentMethod == 'CASH' || this.selectedPaymentMethod == 'SPLIT') {
                this.changeStep(1);
            }
        }
        this.getCartInfo(method);
    }

    // Set to By Default 1st Step
    resetStep() {
        this.cartStep.forEach((value) => {
            value.isActive = false;
            value.isPass = false;
        })
        this.cartStep[0].isActive = true;
        this.cartStepSelected = this.cartStep[0];
    }

    byPassPaymentProcess(method) {
        this.paymentAmount = this.cartInfo.balance;
        this.sendPayment();
    }

    getCartInfo(method, retainAmount?) {
        // Get Active Chair
        if (!retainAmount) {
            this.paymentAmount = 0;
        }
        this.checkoutLoader = true;
        const activeChair = this.session.get(Constants.ACTIVE_CHAIR);
        const getPaymentSummary = this.paymentService.getPaymentSummary(this.organizationId, activeChair.id, method).subscribe((response) => {
            this.checkoutLoader = false;
            this.cartInfo = {...response};
            this.cartCalculations(response);
            if (this.changeAmount > 0 && this.cartInfo.balance <= 0) {
                this.cartInfo.balance = this.changeAmount;
                this.changeStep(2);
            } else if (this.changeAmount == 0 && this.cartInfo.balance == 0) {
                this.backOrderPage();
            }
            else {
                if (this.cartInfo.authorizedPayments.length > 0) {
                    this.selectedPaymentMethod = this.constants.PAYMENT_TYPE_SPLIT;
                    this.changeStep(1);
                }
                this.shoppingCartService.updateObservableCartSum(response.ebtTotal + response.nonEbtSubTotal);
                this.shoppingCartService.updateObservableCartSumTax(response.taxTotal);
                this.shoppingCartService.updateObservableCartSumTotal(response.invoiceAmount);
            }

            // Focus on Amount Field
            setTimeout(() => {
                if (this.cartStepSelected.step == 1) {
                    this.autoFocusAmount();
                }
            }, 0);
        });
        this.apiSubscription.add(getPaymentSummary);
    }

    cartCalculations(response) {
        let cartCalculations = {
            ebtBalance: 0,
            ebtPaid: 0,
            nonEbtPaid: 0
        }

        response.authorizedPayments.forEach((payment) => {
            if (payment.paymentType == 'EBT') {
                cartCalculations.ebtPaid += payment.amount;
            } else {
                cartCalculations.nonEbtPaid += payment.amount;
            }
        });

        // Calculate EBT balance
        cartCalculations.ebtBalance = response.ebtTotal - cartCalculations.ebtPaid;

        // Validate with Main Balance and Compare
        if (cartCalculations.ebtBalance > response.balance) {
            cartCalculations.ebtBalance = response.balance;
        }

        // Extend Cart Info Object
        this.cartInfo = {...this.cartInfo, cartCalculations};
    }

    // Update Step Status
    updateStepStatus() {
        let markPass = false;
        this.cartStep.forEach((value) => {
            if (value.isActive) {
                this.cartStepSelected = value;
                markPass = true;
            } else {
                if (!markPass) {
                    value.isPass = true;
                    this.toggleSummary = true;
                }
            }
        })
    }

    // Update Split Payment Method
    updatedSplitPayment(method) {
        this.selectedSplitPaymentMethod = method;
        this.paymentService.SplitPaySelected = method;
        this.getCartInfo(this.selectedSplitPaymentMethod);
    }

    // Change Step
    changeStep(number) {
        this.toggleSummary = false;
        this.toggleTransactions = false;
        this.cartStep.forEach((value) => {
            value.isActive = false;
        })
        this.cartStep[number].isActive = true;
        this.updateStepStatus();

        
    }

    // Go to Specific Step
    goToStep(item) {
        if ((item.isPass || item.isActive) && !this.cartStep[2].isActive) {
            this.cartStepSelected = item;
        }
    }

    // Init Payment by Auto Filling Up Payment Methods
    autoFocusAmount() {
        if (this.selectedPaymentMethod != 'SPLIT' && this.selectedPaymentMethod != 'CASH') {
            if (this.selectedPaymentMethod == 'EBT') {
                this.paymentAmount = this.cartInfo.cartCalculations.ebtBalance;
            } else {
                this.paymentAmount = this.cartInfo.balance;
            }
            if (this.selectedPaymentMethod == 'OTHER' && this.ebtPaymentProcess) {
                this.paymentAmount = this.cartInfo.nonEbtSubTotal;
            }
            this.unBindKeyPad();
        } else {
            this.paymentAmountField.nativeElement.focus();
        }
    }

    // Validate Amount Before Sending to Service
    validateAmount() {
        if (this.selectedPaymentMethod == 'EBT' || this.selectedSplitPaymentMethod == 'EBT') {
            if (this.paymentAmount <= this.cartInfo.cartCalculations.ebtBalance) {
                return true;
            } else {
                this.openOkayDialog('ERROR', `Please enter amount which is less then or equal ${this.cartInfo.cartCalculations.ebtBalance}`);
                return false;
            }
        } else if (this.selectedPaymentMethod != 'CASH' && this.selectedSplitPaymentMethod != 'CASH') {
            if (this.paymentAmount <= this.cartInfo.balance) {
                return true;
            }
            else {
                this.openOkayDialog('ERROR', `Please enter amount which is less then or equal ${this.cartInfo.balance}`);
                return false;
            }
        } else if (this.selectedPaymentMethod == 'CASH') {
            if (this.paymentAmount >= this.cartInfo.balance) {
                return true;
            }
            else {
                this.openOkayDialog('ERROR', `Please enter amount which is equal ${this.cartInfo.balance} or more`);
                return false;
            }
        } else {
            return true;
        }
    }

    // Send Payment
    sendPayment() {
        if (this.validateAmount()) {
            // Set Payment Methods Based on Selections
            let selectedPaymentType;
            if (this.selectedPaymentMethod == this.constants.PAYMENT_TYPE_SPLIT) {
                selectedPaymentType = this.selectedSplitPaymentMethod;
                this.paymentRequest.splitPayment = true;
            }
            else {
                selectedPaymentType = this.selectedPaymentMethod;
            }

            // Get Active Chair
            const activeChair = this.session.get(Constants.ACTIVE_CHAIR);

            // Prepare Receipt Request
            const receiptRequest = new ReceiptRequest();
            receiptRequest.organizationId = this.authenticationService.getOrganizationId();
            receiptRequest.chairId = activeChair.id;
            receiptRequest.user = activeChair.user;
            receiptRequest.user.systemId = this.authenticationService.getIdentity().username;
            receiptRequest.paymentType = selectedPaymentType;
            receiptRequest.tenderedAmount = this.paymentAmount;
            receiptRequest.invoiceAmount = this.cartInfo.invoiceAmount;

            // Prepare Payment Request
            this.paymentRequest.paymentType = selectedPaymentType;
            this.paymentRequest.tenderedAmount = this.paymentAmount;
            this.paymentRequest.giftCard = false;
            this.paymentRequest.chairId = activeChair.id;
            this.paymentRequest.organizationId = activeChair.organizationId;
            this.paymentRequest.userId = this.authenticationService.getIdentity().username;
            this.paymentRequest.invoiceAmount = this.cartInfo.invoiceAmount;
            if (this.selectedPaymentMethod == Constants.PAYMENT_TYPE_DEBIT_CARD) {
                this.paymentRequest.processAsDebit = true;
            } else {
                this.paymentRequest.processAsDebit = false;
            }
            
            this.paymentRequest.user = activeChair.user;
            this.paymentRequest.user.systemId = this.authenticationService.getIdentity().username;

            // Prepare Close Order Request
            const closeOrderRequest = new CloseOrderRequest();
            closeOrderRequest.organizationId = this.paymentRequest.organizationId;
            if (!this.isCombinedPayment) {
                closeOrderRequest.chairId = this.paymentRequest.chairId;
            }

            // Payment Method Condition
            switch(selectedPaymentType) {
                case Constants.PAYMENT_TYPE_CREDIT_CARD:
                    // Terminal Verify
                    if (this.authenticationService.paymentTerminalPresent()) {
                        // Request
                        this.payCreditCard(receiptRequest, closeOrderRequest);
                    } else {
                        this.noPaymentTerminalDialog('ERROR', 'NO PAYMENT TERMINAL');
                    }
                    break;
                case Constants.PAYMENT_TYPE_DEBIT_CARD:
                    // Terminal Verify
                    if (this.authenticationService.paymentTerminalPresent()) {
                        // Request
                        this.payCreditCard(receiptRequest, closeOrderRequest);
                    } else {
                        this.noPaymentTerminalDialog('ERROR', 'NO PAYMENT TERMINAL');
                    }
                    break;
                case Constants.PAYMENT_TYPE_OTHER:
                    // Specific Request Body Updates
                    this.shoppingCartService.TenderedAmount = this.paymentAmount;
                    closeOrderRequest.receiptRequest = receiptRequest;
                    this.shoppingCartService.CloseOrderRequest = closeOrderRequest;

                    // Request
                    this.payOther();
                    break;
                case this.constants.PAYMENT_TYPE_CASH:
                    // Specific Request Body Updates
                    this.shoppingCartService.TenderedAmount = this.paymentAmount;
                    closeOrderRequest.receiptRequest = receiptRequest;
                    this.shoppingCartService.CloseOrderRequest = closeOrderRequest;

                    // Request
                    this.payCash();
                    break;
                case this.constants.PAYMENT_TYPE_EBT:
                    this.payEbt(receiptRequest, closeOrderRequest);
                    break;
                case this.constants.PAYMENT_TYPE_GIFT_CARD:
                    this.payGiftCard(receiptRequest, closeOrderRequest);
                    break;
                default:
                    alert('Please choose Payment Methods');
                    break;
            }
        }
    }

    payCreditCard(receiptRequest, closeOrderRequest) {

        // Set Payment Progress On
        this.paymentService.setPaymentProgress(true);
        //this._shared.setGlobalLoader(true);

        // Request Body Changes
        this.paymentRequest.paymentTerminalPresent = true;
        this.paymentRequest.transactionType = Constants.TRANSACTION_TYPE_SALE;

        // Service Call
        const paySubscription = this.paymentService.submitPayment(this.paymentRequest).subscribe((rcpt) => {
            closeOrderRequest.receiptRequest = receiptRequest;
            if (this.selectedPaymentMethod == Constants.PAYMENT_TYPE_DEBIT_CARD) {
                closeOrderRequest.receiptRequest.processAsDebit = true;
            } else {
                closeOrderRequest.receiptRequest.processAsDebit = false;
            }
            this.shoppingCartService.CloseOrderRequest = closeOrderRequest;
            this.websocketService.connect(Constants.PAYMENT_TYPE_CREDIT_CARD, rcpt.terminal);
        },
        (error) => {
            this.paymentService.setPaymentProgress(false, 'ERROR');
            // this._shared.setGlobalLoader(false);
            this.openOkayDialog('ERROR', `Error processing ${this.constants.PAYMENT_TYPE_CREDIT_CARD} payment`);
        });
        this.apiSubscription.add(paySubscription); 
    }

    payCash() {
        // Set Payment Progress On
        this.paymentService.setPaymentProgress(true);
        // this._shared.setGlobalLoader(true);


        const paySubscription = this.paymentService.submitPayment(this.paymentRequest).subscribe((rcpt) => {
                this.shoppingCartService.ShoppingCart = [];
                if (rcpt.changeAmount || rcpt.changeAmount == 0) {
                    this.changeAmount = rcpt.changeAmount;
                }
                this.paymentService.PaymentConfirmation = true;
                this.paymentService.SelectedPaymentType = this.constants.PAYMENT_TYPE_CASH;
                this.toggleService.ProcessPaymentAsEBT = false;
                this.shoppingCartService.EbtCartItems = false;
                this.receipt = rcpt;

                // Set Payment Progress Off
                this.paymentService.setPaymentProgress(false);
                // this._shared.setGlobalLoader(false);

                // if (this.changeAmount > 0) {
                //     this.changeStep(2);
                // }
            },
            (error) => {
                this.paymentService.setPaymentProgress(false, 'ERROR');
                // this._shared.setGlobalLoader(false);
                this.openOkayDialog('ERROR', `Error Processing ${this.constants.PAYMENT_TYPE_CASH} Payment`);
            }
        );
        this.apiSubscription.add(paySubscription);
    }
    
    payEbt(receiptRequest, closeOrderRequest) {
        // Request Body
        this.paymentRequest.processAsDebit = false;

        // Redirect Credit Card Payment
        if (this.cartInfo.ebtEnabledOnTerminal) {
            this.payCreditCard(receiptRequest, closeOrderRequest);
        } else {
            this.ebtPaymentProcess = true;
            this.payOther();
        }
        
    }
    
    payOther() {
        // Set Payment Progress On
        this.paymentService.setPaymentProgress(true);
        if (this.ebtPaymentProcess) {
            this.paymentRequest.invoiceAmount = this.cartInfo.nonEbtSubTotal;
            this.paymentRequest.taxIncluded = false;
        } else {
            this.paymentRequest.taxIncluded = true;
        }
        
        // this._shared.setGlobalLoader(true);

        this.paymentRequest.paymentTerminalPresent = false;

        // Service Call
        const paySubscription = this.paymentService.submitPayment(this.paymentRequest).subscribe((rcpt) => {
            if (rcpt.receiptNo && rcpt.changeAmount === 0) {
                this.shoppingCartService.resetSplitPayment(this.paymentRequest.chairId, rcpt.id).subscribe(res => {});
            }
            this.shoppingCartService.ShoppingCart = [];
            this.receipt = rcpt;
            this.toggleService.ProcessPaymentAsEBT = false;
            this.shoppingCartService.EbtCartItems = false;

            // Set Payment Progress Off
            this.paymentService.setPaymentProgress(false);
            // this._shared.setGlobalLoader(false);
        },
        (error) => {
            this.paymentService.setPaymentProgress(false, 'ERROR');
            // this._shared.setGlobalLoader(false);
            this.openOkayDialog('ERROR', `Error Processing ${this.constants.PAYMENT_TYPE_OTHER} Payment`);
        });
        this.apiSubscription.add(paySubscription);
    }
    
    payGiftCard(receiptRequest, closeOrderRequest) {
        // Request Body
        this.paymentRequest.paymentType = 'CREDIT_CARD';
        this.paymentRequest.processAsDebit = false;
        this.paymentRequest.giftCard = true;

        // Redirect Credit Card Payment
        this.payCreditCard(receiptRequest, closeOrderRequest);
    }

    toggleSummaryFn() {
        this.toggleSummary = !this.toggleSummary;
    }

    toggleTransactionsFn() {
        this.toggleTransactions = !this.toggleTransactions;
        this.toggleSummary = true;
    }

    // OLD CODE STATED

    round(nbr: number, decimalPlaces: number) {
        const factorOfTen = Math.pow(10, decimalPlaces);
        return Math.round(nbr * factorOfTen) / factorOfTen;
    }

    toggleEbtPaymentCheckBox(evt) {
        if (evt === 'EBT') {
            this.toggleService.ProcessPaymentAsEBT = true;
        } else {
            this.toggleService.ProcessPaymentAsEBT = false;
        }
    }

    setFocusOnTenderedField(evt) {
        this.shoppingCartService.getShoppingCarTotal();
        if (this.shoppingCartService.EbtCartItems) {
            this.toggleService.ProcessPaymentAsEBT = true;
        } else {
            this.toggleService.ProcessPaymentAsEBT = false;
        }
        this.paymentService.PaymentConfirmation = false;
        this.notifyDisplayPole = true;
        this.getShoppingCartItemsForChair();
        if (evt === this.paymentService.CASH) {
            // setTimeout(() => {
            //     this.paymentAmountField.nativeElement.focus();
            // }, 0);
        } else if (evt === this.paymentService.OTHER) {
            // setTimeout(() => {
            //     this.paymentAmountField.nativeElement.focus();
            // }, 0);
        } else if (evt === this.paymentService.SPLIT_PAYMENT) {
            setTimeout(() => {
                if (this.storage.get(this.paymentService.getCustomerBalanceKey())) {
                    const amountDue = this.storage.get(this.paymentService.getCustomerBalanceKey());
                    if (this.paymentService.isCashBack(amountDue)) {
                        this.paymentService.Balance = Math.abs(amountDue);
                    } else {
                        this.paymentService.Balance = amountDue;
                    }
                } else {
                    this.paymentService.Balance = this.shoppingCartService.CustomerInvoiceAmount;
                }
                this.paymentService.SplitPaySelected = 'NONE';
                //this.paymentAmountField.nativeElement.focus();
            }, 0);
        } else if (evt === this.paymentService.CREDIT_CARD) {
            // setTimeout(() => {
            //     this.paymentAmountField.nativeElement.focus();
            // }, 0);
        } else if (evt === this.paymentService.EBT) {
            // setTimeout(() => {
            //     this.paymentAmountField.nativeElement.focus();
            // }, 0);
        }
    }

    sendMessageToDisplayPole(total: number) {
        const msgSubscription = this.shoppingCartService.sendShoppingCartItemsTotalForChairToDisplayPole(this.session.get(Constants.ACTIVE_CHAIR),
            this.authenticationService.getOrganizationId(),
            total,
            this.paymentService.SelectedPaymentType)
            .subscribe(r => {
            });
        this.apiSubscription.add(msgSubscription);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.getSelectedItemsForPayment();
            this.setFocusOnTenderedField(this.paymentService.SelectedPaymentType);
        });
    }

    getShoppingCartItemsForChair() {
        if (this.session.get(Constants.ACTIVE_CHAIR).name === 'EBT') {
            this.shoppingCartService.EbtCartItems = true;
        } else {
            this.shoppingCartService.EbtCartItems = false;
        }
        const userCartSubscription = this.shoppingCartService.getUserShoppingCartItemsForChair(this.session.get(Constants.ACTIVE_CHAIR), this.organizationId).subscribe((ci) => {
            this.shoppingCartService.ShoppingCart = ci;
            if (this.shoppingCartService.ShoppingCart && this.shoppingCartService.ShoppingCart.length) {
                this.shoppingCartService.DisplayShoppingCart = true;
            }

            const itemsSubscription = this.shoppingCartService.getObservableItems().subscribe(itms => {
                const cartSumSubscription = this.shoppingCartService.getObservableCartSum().subscribe((cs) => {
                    this.cartAmount = cs;
                });
                const taxSumSubscription = this.shoppingCartService.getObservableCartSumTax().subscribe((cst) => {
                    this.cartTaxAmount = cst;
                });
                const cartTotalSubscription = this.shoppingCartService.getObservableCartSumTotal().subscribe((total) => {
                    this.cartTotalAmount = total;
                    this.shoppingCartService.CustomerInvoiceAmount = total;
                    this.paymentService.InvoiceAmount = total;
                    this.cardTenderedAmount = total;
                    this.cashTenderedAmount = total;
                    this.ebtTenderedAmount = total;
                    if (this.notifyDisplayPole) {
                        this.sendMessageToDisplayPole(total);
                        this.notifyDisplayPole = false;
                    }
                    if (this.paymentService.SelectedPaymentType === this.paymentService.CASH) {
                        this.cashTenderedAmount = 0.00;
                    }
                });
                this.behaviorSubscription.add(cartSumSubscription);
                this.behaviorSubscription.add(taxSumSubscription);
                this.behaviorSubscription.add(cartTotalSubscription);
            });
            this.behaviorSubscription.add(itemsSubscription);
        });
        this.apiSubscription.add(userCartSubscription);
    }

    getSelectedItemsForPayment() {
        if (!this.paymentService.SelectedPaymentType) {
            this.paymentService.SelectedPaymentType = Constants.PAYMENT_TYPE_CASH;
        }

        const itemsSubscription = this.shoppingCartService.getObservableItems().subscribe(itms => {
            this.customerInvoiceAmount = this.shoppingCartService.getShoppingCartItemsTotal(itms);
            this.paymentService.InvoiceAmount = this.customerInvoiceAmount;
            this.paymentRequest.selectedShoppingCartItems = itms;
        });
        this.behaviorSubscription.add(itemsSubscription);

        return this.isCombinedPayment;
    }

    textReceipt() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '640px';
        dialogConfig.disableClose = true;
        dialogConfig.data = {message: 'SMS'};

        const dialogRef = this.dialog.open(CheckoutDialogComponent, dialogConfig);

        const dialogSubscription = dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                this.receipt.customerPhoneNumber = this.paymentService.CustomerPhoneNumber;
                this.receiptService.smsReceipt(this.receipt).subscribe((response) => {
                    this.paymentService.CustomerPhoneNumber = null;
                });
            }
        });
        this.apiSubscription.add(dialogSubscription);
    }

    emailReceipt() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '640px';
        dialogConfig.disableClose = true;
        dialogConfig.data = {message: 'EMAIL'};

        const dialogRef = this.dialog.open(CheckoutDialogComponent, dialogConfig);

        const dialogSubscription = dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                this.receipt.customerEmail = this.paymentService.CustomerEmail;
                this.receiptService.emailReceipt(this.receipt).subscribe((response) => {
                    this.paymentService.CustomerEmail = null;
                });
            }
        });
        this.apiSubscription.add(dialogSubscription);
    }

    noPaymentTerminalDialog(msgHeader: string, msg: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '500px';
        dialogConfig.height = '270px';
        dialogConfig.data = {description: msgHeader, message: msg};

        const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

        const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
            this.toggleService.ShowPaymentProgress = false;
        });
        this.apiSubscription.add(dialogSubscription);
    }

    printInvoice() {
        const activeChair = this.session.get(Constants.ACTIVE_CHAIR);

        const receiptRequest = new ReceiptRequest();
        receiptRequest.organizationId = this.authenticationService.getOrganizationId();
        receiptRequest.chairId = activeChair.id;
        receiptRequest.user = activeChair.user;
        receiptRequest.user.systemId = this.authenticationService.getIdentity().username;
        receiptRequest.paymentType = this.paymentService.SelectedPaymentType;
        receiptRequest.invoiceAmount = this.shoppingCartService.CartSumTotal;
        if (!this.isCombinedPayment) {
            receiptRequest.chairId = this.session.get(Constants.ACTIVE_CHAIR).id;
        }
        const invSubscription = this.receiptService.getInvoice(receiptRequest).subscribe((rcpt) => {
        });
        this.apiSubscription.add(invSubscription);
    }

    submitMoMoPayment() {
        const momoSubscription = this.paymentService.submitMtnMoMoPayment(this.phoneNumber, this.shoppingCartService.TenderedAmount).subscribe((response) => {
            },
            (error) => {
                console.log(error);
            });
        this.apiSubscription.add(momoSubscription);
    }

    navigateToOrderView(paymentSuccessful: boolean) {
        if (paymentSuccessful === true) {
            this.shoppingCartService.ShoppingCart = [];
        }
        this.paymentService.SelectedPaymentType = '';
        const orgType = this.session.get('orgType');
        if (Constants.ORGANIZATION_TYPE_RETAIL === orgType) {
            this.router.navigate(['/retailorder'], {queryParams: {pageView: 'quickStart'}});
        } else {
            this.router.navigate(['/restaurantorder'], {queryParams: {pageView: 'quickStart'}});
        }
    }

    validateSplit(paymentMode, amount, balance) {
        if(typeof(amount) != 'number') {
            amount = Number(amount);
        }
        if(typeof(balance) != 'number') {
            balance = Number(balance);
        }
        amount = BigDecimal.round(amount, 2);
        balance = BigDecimal.round(balance, 2);
        
        if (paymentMode == 'NONE') {
            this.openOkayDialog('ERROR', 'Kindly Select Payment Mode');
            return false;
        }
        else if (paymentMode != 'NONE' && amount <= 0) {
            this.openOkayDialog('ERROR', 'Please Enter Amount!');
            return false;
        }
        else if (paymentMode == 'CREDIT_CARD' && amount > balance) {
            this.openOkayDialog('ERROR', 'Amount should be less then or equal Remaining Amount!');
            return false;
        } 
        else {
            return true;
        }
    }

    // completeSplitPaymentTransaction() {
    //     this.showMatLoader = true;
    //     this.toggleService.ShowPaymentProgress = true;
    //     this.paymentService.CashBack = false;
    //     this.toggleService.ShowDoneButton = false;
    //     this.paymentService.SplitSummary = [];
    //     const activeChair = this.session.get(Constants.ACTIVE_CHAIR);

    //     const receiptRequest = new ReceiptRequest();
    //     receiptRequest.organizationId = this.authenticationService.getOrganizationId();
    //     receiptRequest.chairId = activeChair.id;
    //     receiptRequest.user = activeChair.user;
    //     receiptRequest.user.systemId = this.authenticationService.getIdentity().username;
    //     receiptRequest.paymentType = this.paymentService.SPLIT_PAYMENT;
    //     receiptRequest.invoiceAmount = this.shoppingCartService.CartSumTotal;
    //     receiptRequest.tenderedAmount = this.shoppingCartService.CartSumTotal + this.paymentService.Balance;

    //     const closeOrderRequest = new CloseOrderRequest();
    //     closeOrderRequest.organizationId = this.paymentRequest.organizationId;
    //     closeOrderRequest.chairId = activeChair.id;
    //     closeOrderRequest.receiptRequest = receiptRequest;
    //     const splitSubscription = this.paymentService.closeSplitPaymentTransaction(closeOrderRequest).subscribe(r => {
    //         this.paymentService.Balance = 0.00;
    //         this.storage.remove(this.paymentService.getCustomerBalanceKey());
    //         this.storage.remove(this.paymentService.getCustomerSplitPaidKey());
    //         this.toggleService.ShowPaymentProgress = false;
    //         this.showMatLoader = false;
    //         this.navigateToOrderView(true);
    //     }, (error) => {
    //         console.log(error);
    //     });
    //     this.apiSubscription.add(splitSubscription);
    // }

    completePayment() {
        this.paymentService.setPaymentProgress(true);
        // this._shared.setGlobalLoader(true);
        const activeChair = this.session.get(Constants.ACTIVE_CHAIR);
        const closePayment = this.paymentService.deletePaymentSummary(activeChair.id).subscribe((response: any) => {
            // if ( this.selectedPaymentMethod != this.constants.PAYMENT_TYPE_CASH ) {
                // Receipt Request
                const receiptRequest = new ReceiptRequest();
                receiptRequest.organizationId = this.authenticationService.getOrganizationId();
                receiptRequest.chairId = activeChair.id;
                receiptRequest.user = activeChair.user;
                receiptRequest.user.systemId = this.authenticationService.getIdentity().username;
                receiptRequest.paymentType = this.selectedPaymentMethod;
                receiptRequest.invoiceAmount = this.cartInfo.invoiceAmount;
                receiptRequest.tenderedAmount = this.cartInfo.cartCalculations.ebtPaid + this.cartInfo.cartCalculations.nonEbtPaid;
    
                if (this.cartInfo.authorizedPayments.length > 1) {
                    // Close Order Request
                    const closeOrderRequest = new CloseOrderRequest();
                    closeOrderRequest.organizationId = this.paymentRequest.organizationId;
                    closeOrderRequest.chairId = activeChair.id;
                    closeOrderRequest.receiptRequest = receiptRequest;
                    const paymentCompletion = this.paymentService.closeSplitPaymentTransaction(closeOrderRequest).subscribe(r => {
                        this.paymentService.Balance = 0.00;
                        this.ebtPaymentProcess = false;
                        this.paymentService.setPaymentProgress(false);
                        // this._shared.setGlobalLoader(false);
                        this.navigateToOrderView(true);
                        
                    }, (error) => {
                        this.paymentService.setPaymentProgress(false);
                        // this._shared.setGlobalLoader(false);
                        console.log(error);
                    });
                    this.apiSubscription.add(paymentCompletion);
                } else {
                    let refNum = this.cartInfo.authorizedPayments.length > 0 && this.cartInfo.authorizedPayments[0].refNum? this.cartInfo.authorizedPayments[0].refNum : '0';
                    const closeOrderSubscription = this.shoppingCartService.closeOrder(refNum).subscribe((response) => {
                        this.paymentService.Balance = 0.00;
                        this.paymentService.setPaymentProgress(false);
                        this.ebtPaymentProcess = false;
                        // this._shared.setGlobalLoader(false);
                        this.navigateToOrderView(true);
                    }, (error) => {
                        this.paymentService.setPaymentProgress(false);
                        // this._shared.setGlobalLoader(false);
                        console.log(error);
                    });
                    this.apiSubscription.add(closeOrderSubscription);
                }
                
                
            // } else {
            //     this.paymentService.setPaymentProgress(false);
            //     this.navigateToOrderView(true);
            // }
        });
        this.apiSubscription.add(closePayment);
    }

    onSplitPaymentTypeSelect(event) {
        const value = event.target.value;
        const text = event.target.options[event.target.options.selectedIndex].text;
        this.paymentService.SplitPaySelected = value;
        setTimeout(() => {
            this.splitPaymentTenderedAmount = 0.00;
            this.tenderedSplitPaymentAmountInput.nativeElement.focus();
        }, 0);
    }

    deleteSplitPayment(splitPaymentId: number) {
        this.checkoutLoader = true;
        const deleteSubscription = this.paymentService.deleteSplitPayment(splitPaymentId).subscribe(res => {
            // const foundIndex = this.paymentService.SplitSummary.findIndex(sp => sp.id === splitPaymentId);
            // this.paymentService.SplitSummary.splice(foundIndex, 1);
            // this.paymentService.addSplitPayBalance(res.amount);
            // this.paymentService.displayPoleBalance();
            this.checkoutLoader = false;
            this.getCartInfo(this.selectedPaymentMethod);
        }, (error) => {
            this.checkoutLoader = false;
            this.openOkayDialog('ERROR', 'Could not delete Payment');
        });
        this.apiSubscription.add(deleteSubscription);
    }

    openOkayDialog(msgHeader: string, msg: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '500px';
        dialogConfig.height = '270px';
        dialogConfig.data = {description: msgHeader, message: msg};

        const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    // Not Used
    onEbtChecked(event) {
        if (event.checked === true) {
            this.removeTaxOnEBT(true);
        } else {
            this.removeTaxOnEBT(false);
        }
    }

    // Not Used
    removeTaxOnEBT(isEBT: boolean) {
        if (isEBT) {
            this.paymentService.PaymentType = this.paymentService.EBT;
            this.shoppingCartService.getShoppingCarTotal();
            const pair = new Pair();
            pair.key = 'EBT Tax = ' + this.shoppingCartService.CartSumTax;
            pair.value = 'EBT Total = $' + this.shoppingCartService.CartSumTotal;
            pair.organizationId = this.authenticationService.getOrganizationId();
            this.paymentService.updateDisplayPole(pair);
        } else {
            this.paymentService.PaymentType = this.paymentService.CREDIT_CARD;
            this.shoppingCartService.getShoppingCarTotal();
            const pair = new Pair();
            pair.key = 'EBT Tax = ' + this.shoppingCartService.CartSumTax;
            pair.value = 'EBT Total = $' + this.shoppingCartService.CartSumTotal;
            pair.organizationId = this.authenticationService.getOrganizationId();
            this.paymentService.updateDisplayPole(pair);
        }
    }

  backOrderPage() {
    this.router.navigateByUrl('/retailorder?pageView=quickStart');
  }

  bindKeyPad() {
    this.bindKeypad = true;
  }

  unBindKeyPad() {
      this.bindKeypad = false  
  }

  setAmount(data) {
    this[data.key] = data.value;
  }     

//   toggleAccordion(accordion, isMulti) {
//       if (isMulti) {
//           if (!accordion.isOpen) {
//             this.paymentAccordion.forEach((item) => {
//                 item.isOpen = false;
//             });
//             accordion.isOpen = true;
//           } else {
//             accordion.isOpen = false;
//           }
//       } else {
//         accordion.isOpen = !accordion.isOpen;
//       }
//   }

//   changeTab(status) {
//       this.ebtStatus = status;
//   }

    voidTransation(event) { 
        if (event.paymentType === 'CREDIT_CARD') {
            const msg = 'Are you sure you want to void transaction with amount [ ' + this._currency.transform(event.amount) + ' ] ?';
            this.openDialogConfirmation('VOID CONFIRMATION', msg, event);
        } else {
            this.deleteSplitPayment(event.id);
        }
    }

    openDialogConfirmation(msgHeader: string, msg: string, event: any): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {description: msgHeader, message: msg};
        dialogConfig.width = '640px';

        const dialogRef = this.dialog.open(MessageDialogConfirmationComponent, dialogConfig);
// 
        const dialogSubscription = dialogRef.afterClosed().subscribe(confirmation => {
        if (confirmation === true) {
            this.proceedVoidPayment(event);
        }
        });
        this.apiSubscription.add(dialogSubscription);
    }

    proceedVoidPayment(event) {
        const propSubscription = this.settingsService.getProperties(this.authenticationService.getOrganizationId()).subscribe((response) => {
        this.availableTerminals = response.filter(p => p.name.includes('TERMINAL_'));
        const msgHeader = 'Select Terminal';
        const msg = JSON.stringify(this.availableTerminals);

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '580px';
        dialogConfig.height = '300px';
        dialogConfig.data = {description: msgHeader, message: msg};

        const dialogRef = this.dialog.open(BatchCloseComponent, dialogConfig);
            const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
                if (result === true && this.settingsService.SelectedTerminal !== 'NONE') {
                    let terminal = this.settingsService.SelectedTerminal;
                    let orgId = this.authenticationService.getOrganizationId();

                    // Set Payment Progress On
                    this.paymentService.setPaymentProgress(true);

                    const paymentRequest = new PaymentRequest();
                    paymentRequest.terminal = terminal;
                    paymentRequest.refNumber = event.refNum;
                    paymentRequest.organizationId = orgId;
                    paymentRequest.chairId = this.session.get(Constants.ACTIVE_CHAIR).id;
                    paymentRequest.paymentType = Constants.PAYMENT_TYPE_VOID;
                    paymentRequest.transactionId = event.id;
                    paymentRequest.splitPayment = true;
                    const voidTransation = this.paymentService.voidTransaction(paymentRequest).subscribe((response) => {
                        // this.checkoutLoader = false;
                        // this.getCartInfo(this.selectedPaymentMethod);
                        let receipt = new Receipt();
                        receipt.terminal = terminal;

                        this.websocketService.voidTransaction(receipt);
                    }, (error) => {
                        // Set Payment Progress On
                        this.paymentService.setPaymentProgress(false);

                        console.log(error);
                    });
                    this.apiSubscription.add(voidTransation); 
                }
            });
            this.apiSubscription.add(dialogSubscription);
        });
        this.apiSubscription.add(propSubscription);
        
    }

  ngOnDestroy() {
        this.apiSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
        this.behaviorSubscription.unsubscribe();
     }
}


<div class="page-frame">
    <div class="page-frame-body">
        <div class="full-height-container pad-20">
            <div class="shadow-radius-container grow-height">
                <div class="row grow-height space-0">
                    <div class="col-md-6" style="border-right:2px solid #cdcdcd;">
                        <div class="summery-container">
                            <div class="summery-container-header">
                                <h2><b>Selected Items</b></h2>
                            </div>
                            <div class="summery-container-body">
                                <div *ngIf="showMatLoader" style="padding-top: 30px; padding-bottom: 30px;"><mat-spinner></mat-spinner></div>
                                <div class="dot-table" *ngIf="!showMatLoader">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="heightlight-item-header white">
                                                <div class="row"> 
                                                    <div class="col-md-7">
                                                        <span>Name & Description</span>
                                                    </div>
                                                    <div class="col"> 
                                                        <span>Category</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                        <div class="dot-table-row" *ngIf="allItemsInMeal?.length > 0">
                                            <div class="heightlight-item" [ngClass]="{ 'white': item.category }" *ngFor="let item of allItemsInMeal;">
                                                <div class="row align-items-center">
                                                    <div class="col-md-7">
                                                        <label>{{ item.name }}</label>
                                                        <p>{{ item.description }}</p>
                                                    </div>
                                                    <div class="col" *ngIf="item.category">
                                                        <label>{{item.category ? item.category.name : null}}</label>
                                                    </div>
                                                    <div class="col" *ngIf="!item.category">
                                                        <label>${{ item.price }}</label>
                                                    </div>
                                                    <div class="col" *ngIf="!item.category" style="max-width: 65px;">
                                                        <span class="dot-table-action-icon" (click)="removeAddedSideItem(item)"><i class="fas fa-window-close"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="summery-container-footer">
                                <button class="btn btn-45-f btn-danger" type="button" (click)="goBackToMenuItems()">
                                    <i class="fas fa-times-circle"></i> Cancel
                                </button>
                                <button class="btn btn-45-f btn-success float-end" type="button" (click)="saveSidesToMeal()">
                                    <i class="fas fa-check-circle"></i> Save
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="summery-container">
                            <div class="summery-container-header">
                                <h2><b>Sides</b></h2>
                            </div>
                            <div class="summery-container-body">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="heightlight-item-header white">
                                                <div class="row"> 
                                                    <div class="col-md-7">
                                                        <span>Name & Description</span>
                                                    </div>
                                                    <div class="col"> 
                                                        <span>Price</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                        <div class="dot-table-row" *ngIf="sideItems?.length > 0" style="border-bottom: none;">
                                            <div class="heightlight-item" *ngFor="let item of sideItems">
                                                <div class="row align-items-center">
                                                    <div class="col-md-7">
                                                        <label>{{ item.name }}</label>
                                                        <p>{{ item.description }}</p>
                                                    </div>
                                                    <div class="col">
                                                        <label>${{ item.price }}</label>
                                                    </div>
                                                    <div class="col" style="max-width: 65px;">
                                                        <span class="dot-table-action-icon" (click)="onAddSideClicked(item)"><i class="fas fa-plus-square"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

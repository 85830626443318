import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtAuth } from '../models/JwtAuth';
import { Identity } from '../models/Identity';
import { Observable } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';
import * as jwt_decode from 'jwt-decode';
import { Constants } from '../models/Constants';
import {Organization} from "../models/Organization";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private organization: Organization;

  constructor(private httpClient: HttpClient, private session: SessionStorageService) { }

  authenticate(identity: Identity): Observable<JwtAuth> {
    const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa(identity.username + ':' + identity.password + ':' + identity.organizationId + ':' + Constants.APP_TAG) });
    return this.httpClient.get<JwtAuth>(Constants.AUTHENTICATION_DOMAIN + '/api/authentication/token', {headers});
  }

  isAuthenticated(): boolean {
    if (this.session.get('identity')) {
      return true;
    }
    return false;
  }

  getToken(): any {
    return this.session.get('jwtToken')
  }

  setToken(token: any) {
    this.session.set('jwtToken', token);
  }

  isTokenExpired(token?: string): boolean {
    if(!token) token = this.getToken();
    if(!token) return true;

    const date = this.getTokenExpirationDate(token);
    if(date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  getTokenExpirationDate(token: string): Date {
    const decodedToken = this.decodeToken(token);

    if (decodedToken['exp'] === undefined) return null;

    const date = new Date(0); 
    date.setUTCSeconds(decodedToken['exp']);
    return date;
  }

  setIdentity(principal: Identity) {
    const decodedToken = this.decodeToken(this.getToken());
    principal.password = null;
    principal.role = decodedToken['role'];
    principal.id = decodedToken['id'];
    principal.organizationId = decodedToken['org'];
    this.session.set('identity', principal);
  }

  getIdentity(): Identity {
    return this.session.get('identity');
  }

  decodeToken(token: string): any {
    return jwt_decode(token);
  }

  getOrganizationId(): number {
    return this.getIdentity().organizationId;
  }

  isRetail() {
    if (this.session.get('orgType') === Constants.ORGANIZATION_TYPE_RETAIL) {
      return true;
    }
    return false;
  }

  isRestaurant() {
    if (this.session.get('orgType') === Constants.ORGANIZATION_TYPE_RESTAURANT) {
      return true;
    }
    return false;
  }

  paymentTerminalPresent() {
    return this.session.get(Constants.ORGANIZATION_KEY).paymentTerminalPresent;
  }

  get Username() {
    return this.getIdentity().username;
  }

  get OrganizationName() {
    let orgDetails = this.session.get(Constants.ORGANIZATION_KEY);
    return orgDetails? orgDetails.name : null;
  }

  set Organization(organization: Organization) {
    this.organization = organization;
  }

  get Organization() {
    return this.organization;
  }
}

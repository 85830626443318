<h1 mat-dialog-title style="text-align: center;">
  <i class="fas fa-check-square fa-3x" style="color: green;" *ngIf="description === 'SUCCESS'"></i>
  <i class="fas fa-exclamation-triangle fa-3x" style="color: darkorange;" *ngIf="description === 'WARNING'"></i>
  <i class="fas fa-times-circle fa-3x" style="color: red;" *ngIf="description === 'ERROR'"></i>
</h1>
<div mat-dialog-content>
  <p style="white-space: pre-line; font-weight: bold; font-size: 1.2em; text-align: center; padding-bottom: 20px; padding-top: 20px;">{{message}}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onOkClick()" style="margin-left: 41%; font-weight: bold; font-size: 3em; padding-bottom: 4px; padding-top: 4px;">
    OK
  </button>
</div>

<div class="page-frame">
	<div class="page-frame-header">
		<div class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="page-logo">
                    <i class="fas fa-globe"></i>
                    Online Orders
                </div>
                <div class="page-buttons"> 
                    <button class="btn btn-60 btn-icon-text btn-white" (click)="getOnlineOrders()" *ngIf="showOnlineShoppingCartItems">
                        <i class="fas fa-arrow-left"></i> <span>Back</span>
                    </button>
                </div>
            </div>
        </div>
	</div>
	<div class="page-frame-body">
		<div class="full-height-container pad-20">
            <div class="shadow-radius-container grow-height full-height-container">
                <div class="mat-table-container" style="overflow: auto;" *ngIf="showOnlineOrders"> 
					<table mat-table [dataSource]="dataSource">
				  
						<!--- Note that these columns can be defined in any order.
								The actual rendered columns are set as a property on the row definition" -->
				
						<!-- Order Number Column -->
						<ng-container matColumnDef="orderNumber">
							<th mat-header-cell *matHeaderCellDef> Order Number </th>
							<td mat-cell *matCellDef="let order"> {{order.orderNumber}} </td>
						</ng-container>
				
						<!-- Name Column -->
						<ng-container matColumnDef="name">
							<th mat-header-cell *matHeaderCellDef> Name </th>
							<td mat-cell *matCellDef="let order"> {{order.name}} </td>
						</ng-container>
					
						<!-- Phone Number Column -->
						<ng-container matColumnDef="phoneNumber">
							<th mat-header-cell *matHeaderCellDef> Phone Number </th>
							<td mat-cell *matCellDef="let order"> {{order.phoneNumber}} </td>
						</ng-container>
					
						<!-- Address Column -->
						<!-- <ng-container matColumnDef="address">
							<th mat-header-cell class="mat-description-column" *matHeaderCellDef> Address </th>
							<td mat-cell *matCellDef="let order"> {{order.address}} </td>
						</ng-container> -->
				
						<!-- Updated Column -->
						<ng-container matColumnDef="updated">
							<th mat-header-cell *matHeaderCellDef> Updated </th>
							<td mat-cell *matCellDef="let order"> {{ order.updated | date : 'MMMM d, h:mm' }} {{ order.updated | date : 'a' | lowercase }} </td>
						</ng-container>
				
						<!-- Delivery Column -->
						<ng-container matColumnDef="delivery">
							<th mat-header-cell *matHeaderCellDef> Delivery </th>
							<td mat-cell *matCellDef="let order"> {{order.delivery}} </td>
						</ng-container>
				
						<!-- Select Delivery Time Column -->
						<ng-container matColumnDef="deliveryTime">
							<th mat-header-cell *matHeaderCellDef> Delivery Time </th>
							<td class="tipAdjust" mat-cell *matCellDef="let order">
								<span class="tip-adjust-section">
									<input type="number" class="form-control" [(ngModel)]="order.fulfillmentTime">
									<i (click)="updateDeliveryTime(order)" class="fas fa-save" style="color: green; cursor: pointer; padding-left: 2px;"></i>
								</span>
							</td>
						</ng-container>
				
						<!-- Select Save Icon Column -->
						<!-- <ng-container matColumnDef="saveIcon">
							<th mat-header-cell *matHeaderCellDef> </th>
							<td (click)="updateDeliveryTime(order)" mat-cell *matCellDef="let order">
								<i class="fa fa-save fa-2x" style="color: #4863A0; cursor: pointer;"></i>
							</td>
						</ng-container> -->
				
						<!-- Status Menu Column -->
						<ng-container matColumnDef="status">
							<th mat-header-cell *matHeaderCellDef> Status </th>
							<td mat-cell *matCellDef="let order"> {{order.status}} </td>
						</ng-container>

						<!-- Action Delete-->
						<!-- <ng-container matColumnDef="actionDelete">
							<th mat-header-cell *matHeaderCellDef> </th>
							<td mat-cell *matCellDef="let order; let i = index">
								<i (click)="deleteOnlineOrder(order, i)" class="fa fa-trash-alt fa-2x" style="color: red; cursor: pointer;"></i>
							</td>
						</ng-container> -->
				
						<!-- Action -->
						<!-- <ng-container matColumnDef="actionDetails">
							<th mat-header-cell *matHeaderCellDef> </th>
							<td mat-cell *matCellDef="let order">
								<i (click)="getOnlineShoppingCartItems(order)" class="fa fa-search fa-2x" style="color: cornflowerblue; cursor: pointer;"></i>
							</td>
						</ng-container> -->

						<ng-container matColumnDef="action">
							<th mat-header-cell *matHeaderCellDef> </th>
							<td class="action" mat-cell *matCellDef="let order; let i = index" style="min-width: 140px;">
								<i class="fas fa-arrow-circle-right" (click)="getOnlineShoppingCartItems(order)"></i>
								<span style="width: 45px; height: 35px; display: inline-block;">
									<i class="fa fa-trash-alt text-red" (click)="deleteOnlineOrder(order, i)"></i>
								</span>	
							</td>
						</ng-container>
					
							<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						</table>
				</div>

				<div class="online-order-container" *ngIf="showOnlineShoppingCartItems">
					<div class="online-order-left">
						<div class="online-order-section">
							<h4>{{ onlineOrder.name }}</h4>
							<p>
								{{ onlineOrder.address }}
							</p>
						</div>
						<div class="online-order-section online-order-row">
							<label>Mobile:</label>
							<p>{{ onlineOrder.phoneNumber }}</p>
						</div>
						<div class="online-order-section online-order-row">
							<label>Updated:</label>
							<p>{{ onlineOrder.updated | date : 'MMMM d, h:mm' }} {{ onlineOrder.updated | date : 'a' | lowercase }}</p>
						</div>
						<div class="online-order-section online-order-row">
							<label>Total Amount:</label>
							<p>{{ onlineOrder.totalAmount | currency }}</p>
						</div>
						<div class="online-order-section online-order-row">
							<label>Status:</label>
							<select class="form-select" (change)="onStatusSeletion(onlineOrder)" name="status" [(ngModel)]="selectedStatus" placeholder="Status">
								<option [value]="status.id" *ngFor="let status of statues">{{status.value}}</option>
							</select>
							<!-- <mat-select (selectionChange)="onStatusSeletion(onlineOrder)" name="status" [(ngModel)]="selectedStatus" placeholder="Status">
								<mat-option value={{selectedStatus}}>{{selectedStatus}}</mat-option>
								<mat-option *ngFor="let status of statues" [value]="status.id"> 
									{{status.value}}
								</mat-option>
							</mat-select> -->
						</div>
					</div>
					<div class="online-order-right">
						<div class="mat-table-container" *ngIf="showOnlineShoppingCartItems">
							<table mat-table [dataSource]="shoppingCartDataSource">
							
								<!--- Note that these columns can be defined in any order.
										The actual rendered columns are set as a property on the row definition" -->
							
								<!-- Name Column -->
								<ng-container matColumnDef="menuItemName">
									<th mat-header-cell *matHeaderCellDef> Name </th>
									<td mat-cell *matCellDef="let cartItem"> {{cartItem.menuItemName}} </td>
								</ng-container>
							
								<!-- Quantity Column -->
								<ng-container matColumnDef="quantity">
									<th mat-header-cell *matHeaderCellDef> Quantity </th>
									<td mat-cell *matCellDef="let cartItem"> {{cartItem.quantity}} </td>
								</ng-container>
							
								<!-- Tax Rate Column -->
								<ng-container matColumnDef="taxRate">
									<th mat-header-cell *matHeaderCellDef> Tax Rate </th>
									<td mat-cell *matCellDef="let cartItem"> {{cartItem.taxRate}}% </td>
								</ng-container>
							
								<!-- Price Column -->
								<ng-container matColumnDef="price">
									<th mat-header-cell class="mat-description-column" *matHeaderCellDef> Price </th>
									<td mat-cell *matCellDef="let cartItem"> {{cartItem.price | currency}} </td>
								</ng-container>
						
								<!-- Notes Column -->
								<!-- <ng-container matColumnDef="notes">
									<th mat-header-cell *matHeaderCellDef> Notes </th>
									<td mat-cell *matCellDef="let cartItem"> {{cartItem.notes}} </td>
								</ng-container> -->
						
								<tr mat-header-row *matHeaderRowDef="shoppingCartDisplayedColumns; sticky: true"></tr>
								<tr mat-row *matRowDef="let row; columns: shoppingCartDisplayedColumns;"></tr>
							</table>
						</div>
					</div>	 
				</div>
            </div>
        </div>
	</div>
</div>



<!-- <div class="shopping-cart-summary-container mat-elevation-z8" *ngIf="showOnlineShoppingCartItems">
	<table mat-table [dataSource]="orderSummaryDataSource">
	
		<ng-container matColumnDef="cartName">
			<th mat-header-cell *matHeaderCellDef> Name </th>
			<td mat-cell *matCellDef="let order"> {{order.name}} </td>
		</ng-container>
	
		<ng-container matColumnDef="cartPhoneNumber">
			<th mat-header-cell *matHeaderCellDef> Phone Number </th>
			<td mat-cell *matCellDef="let order"> {{order.phoneNumber}} </td>
		</ng-container>
	
		<ng-container matColumnDef="cartAddress">
			<th mat-header-cell class="mat-description-column" *matHeaderCellDef> Address </th>
			<td mat-cell *matCellDef="let order"> {{order.address}} </td>
		</ng-container>

		<ng-container matColumnDef="cartUpdated">
			<th mat-header-cell *matHeaderCellDef> Updated </th>
			<td mat-cell *matCellDef="let order"> {{ order.updated | date : 'MMMM d, h:mm' }} {{ order.updated | date : 'a' | lowercase }} </td>
		</ng-container>

		<ng-container matColumnDef="cartStatuscheck">
			<th mat-header-cell *matHeaderCellDef> Status </th>
			<td mat-cell *matCellDef="let order">
				<mat-select (selectionChange)="onStatusSeletion(order)" name="status" [(ngModel)]="selectedStatus" placeholder="Status">
					<mat-option value={{selectedStatus}}>{{selectedStatus}}</mat-option>
					<mat-option *ngFor="let status of statues" [value]="status.id">
					{{status.value}}
					</mat-option>
				</mat-select>
			</td>
		</ng-container>
	
		<ng-container matColumnDef="cartTotalAmount">
			<th mat-header-cell *matHeaderCellDef> Total Amount </th>
			<td mat-cell *matCellDef="let order"> {{order.totalAmount | currency}} </td>
		</ng-container>

		<ng-container matColumnDef="cartReturn">
			<th mat-header-cell *matHeaderCellDef> </th>
			<td mat-cell *matCellDef="let order">
				<i (click)="getOnlineOrders()" class="fa fa-undo fa-2x" style="color: cornflowerblue; cursor: pointer;"></i>
			</td>
		</ng-container>
		
		<ng-container matColumnDef="cartPrinter">
			<th mat-header-cell *matHeaderCellDef> </th>
			<td mat-cell *matCellDef="let order">
				<i (click)="print(order)" class="fa fa-print fa-2x" style="color: cornflowerblue; cursor: pointer;"></i>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="detailsDisplayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: detailsDisplayedColumns;"></tr>
	</table>
</div>

 -->

<div class="page-frame">
	<div class="page-frame-header">
		<div class="page-header">
			<div class="d-flex align-items-center justify-content-between">
				<div class="page-logo">
					<i class="far fa-list-alt"></i>
					Category
				</div>
				<div class="page-buttons">
					<button (click)="categoryFormDisplay()" class="btn btn-60 btn-icon-text btn-white">
						<i class="fas fa-plus"></i> <span>Add Category</span>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="page-frame-body">
		<div class="full-height-container pad-20">
			<div class="shadow-radius-container grow-height">
                <!-- List Start Here -->
				<div class="ag-table-grid" *ngIf="showCategoryList">
					<ag-grid-angular 
						[columnDefs]="columnDefs"
						[rowData]="categories"
						[rowSelection]="rowSelection"
						[rowHeight]="'60'"
                        [headerHeight]="'40'"
						[rowClass]="'ag-custom-table-row'"
						(gridReady)="onGridReady($event)"
						(rowClicked)="onRowClicked($event)"
						class="ag-theme-alpine user-table">
					</ag-grid-angular>
				</div>
                <!-- List End Here -->
                <!-- Form Start Here -->
                <div class="product-form-container grow-height" *ngIf="showCategoryForm">
                    <form #categoryForm="ngForm" (ngSubmit) ="saveCategory()">
                        <div class="summery-container">
							<div class="summery-container-header">
								<h2><b>Create/Update Category</b></h2>
							</div>
							<div class="summery-container-body pad-l-20 pad-r-20">
								<div class="row">
                                    <div class="col-md-6" style="border-right:2px solid #cdcdcd;">
                                        <div class="form-container pad-r-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Name:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" type="text" name="name" [(ngModel)]="categoryFormData.name" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Menu Name:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" type="text" name="menuName" [(ngModel)]="categoryFormData.menuName" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Sort Order:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" type="number" name="sortOrder" [(ngModel)]="categoryFormData.sortOrder" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Active:</label>
                                                </div>
                                                <div class="form-field-control d-flex flex-row">
                                                    <div class="form-radio-field">
                                                        <span class="custom-radio align-self-center">
                                                            <input type="radio" id="active1" [(ngModel)]="categoryFormData.active" [value]="true" name="active" />
                                                            <label for="active1"><i class="fas fa-check"></i></label>
                                                        </span>
                                                        <label for="active1">Yes</label>
                                                    </div>
                                                    <div class="form-radio-field">
                                                        <span class="custom-radio align-self-center">
                                                            <input type="radio" id="active2" [(ngModel)]="categoryFormData.active" [value]="false" name="active" />
                                                            <label for="active2"><i class="fas fa-check"></i></label>
                                                        </span>
                                                        <label for="active2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Digital Active:</label>
                                                </div>
                                                <div class="form-field-control d-flex flex-row">
                                                    <div class="form-radio-field">
                                                        <span class="custom-radio align-self-center">
                                                            <input type="radio" id="activeDigiMenu1" [(ngModel)]="categoryFormData.activeDigiMenu" [value]="true" name="activeDigiMenu" />
                                                            <label for="activeDigiMenu1"><i class="fas fa-check"></i></label>
                                                        </span>
                                                        <label for="activeDigiMenu1">Yes</label>
                                                    </div>
                                                    <div class="form-radio-field">
                                                        <span class="custom-radio align-self-center">
                                                            <input type="radio" id="activeDigiMenu2" [(ngModel)]="categoryFormData.activeDigiMenu" [value]="false" name="activeDigiMenu" />
                                                            <label for="activeDigiMenu2"><i class="fas fa-check"></i></label>
                                                        </span>
                                                        <label for="activeDigiMenu2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-container pad-l-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Parent Category:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <select class="form-select" id="parent" name="parent" [(ngModel)]="categoryFormData.parent.id">
                                                        <option [value]="undefined">------------------------------</option>
                                                        <option *ngFor="let cat of categories" [value]="cat.id">{{cat.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Section:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" type="text" name="section" [(ngModel)]="categoryFormData.section" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Sort Text:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" type="text" name="sortText" [(ngModel)]="categoryFormData.sortText" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Active Online:</label>
                                                </div>
                                                <div class="form-field-control d-flex flex-row">
                                                    <div class="form-radio-field">
                                                        <span class="custom-radio align-self-center">
                                                            <input type="radio" id="activeOnline1" [(ngModel)]="categoryFormData.activeOnline" [value]="true" name="activeOnline" />
                                                            <label for="activeOnline1"><i class="fas fa-check"></i></label>
                                                        </span>
                                                        <label for="activeOnline1">Yes</label>
                                                    </div>
                                                    <div class="form-radio-field">
                                                        <span class="custom-radio align-self-center">
                                                            <input type="radio" id="activeOnline2" [(ngModel)]="categoryFormData.activeOnline" [value]="false" name="activeOnline" />
                                                            <label for="activeOnline2"><i class="fas fa-check"></i></label>
                                                        </span>
                                                        <label for="activeOnline2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
							</div>
							<div class="summery-container-footer">
                                <button (click)="categoryListDisplay()" class="btn btn-40 btn-danger" type="button">
									<i class="fas fa-times-circle"></i> Cancel
								</button>
								<button  class="btn btn-40 btn-success float-end" type="submit">
									<i class="fas fa-check-circle"></i> Save
								</button>
							</div>
						</div>
                    </form>
                </div>
                <!-- Form End here -->
			</div>
		</div>
	</div>
</div>



import { MenuItem } from './MenuItem';
import { SubMenuType } from './SubMenuType';

export class SubMenuItem {
	id: number;
    number: string;
    name: string;
    description: string;
    price: number;
    taxRate: number;
    discountPrice: number;
    subMenuType: SubMenuType;
    menuItems: Array<MenuItem> = [];
    imageUrl: string;
    imageFilename: string;
    updatedBy: string;
    organizationId: number;
    preference: boolean;
    sideSelected: boolean;
}
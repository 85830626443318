import {Component, OnDestroy, OnInit} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatTableDataSource } from '@angular/material/table';
import { Constants } from '../../models/Constants';
import { SettingsService } from 'src/app/services/settings.service';
import { Property } from 'src/app/models/Property';
import {ClientEnvironmentProperty} from '../../models/ClientEnvironmentProperty';
import { LocalStorageService } from 'angular-web-storage';
import {UserTerminal} from '../../models/UserTerminal';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, OnDestroy {
  private apiSubscription = new Subscription();
  public showPropertyForm = false;
  public showPropertyList = false;
  public showMerchantForm = false;
  public showUserTerminalForm = false;
  public showEditTerminalForm: boolean = false;
  public showClientEnvPropertyList = false;
  public clientEnvironmentProperties: Array<ClientEnvironmentProperty>;
  public availableTerminals: Array<Property> = [];
  public properties: Array<Property> = [];
  public property: Property;
  public userTerminal = new UserTerminal();
  public currentUserTerminal: UserTerminal;
  public merchant: any;
  public panelOpenState: any;

  public displayedColumns = ['name', 'value', 'actionDetails'];
  public clientEnvPropsColumns = ['propertyName', 'propertyValue', 'terminal'];
  public dataSource = null;
  public clientEnvPropsDataSource = null;

  public activeTabId: number = null;
  public settingTab: Array<any> = [
    {
      name: "Properties Panel",
      description: "Add or Update Organization Properties",
      isActive: false,
      tabIndex: 1
    },
    {
      name: "Device Settings Panel",
      description: "Update Device Properties",
      isActive: false,
      tabIndex: 2
    },
    {
      name: "Merchant Panel",
      description: "Update Merchant Information",
      isActive: false,
      tabIndex: 3
    },
    {
      name: "User Terminal Panel",
      description: "Terminal Assignment",
      isActive: false,
      tabIndex: 4
    }
  ]

  constructor(private dialog: MatDialog, public authenticationService: AuthenticationService,
              private settingsService: SettingsService, private localCache: LocalStorageService) { }

  ngOnInit() {
    this.settingTab[0].isActive = true;
    this.activeTabId = 1;
    const propSubscription = this.settingsService.getPropertyTypes().subscribe((response) => {
      for (var i = 0; i < response.length; i++) {
        const property = new Property();
        property.name = response[i];
        property.value = response[i];
        this.properties.push(property);
      }
    });
    this.apiSubscription.add(propSubscription);

    this.property = new Property();
    this.property.organizationId = this.authenticationService.getOrganizationId();

    this.getProperties();
    this.getMerchantInfo();
    this.getAllClientEnvironmentProperties();

    this.getTerminalProperties();
  }

  settingTabSwitch(item) {
    this.settingTab.find(data => data.isActive).isActive = false;
    item.isActive = true;
    this.activeTabId = item.tabIndex;
  }

  getOrganizationUserTerminals() {
    const termSubscription = this.settingsService.getUserTerminals(this.authenticationService.getOrganizationId()).subscribe(response => {
    });
    this.apiSubscription.add(termSubscription);
  }

  getUserTerminal() {
    const settingSubscription = this.settingsService.getUserTerminalByUser(this.authenticationService.getIdentity().id).subscribe(terminal => {
      if (terminal) {
        const foundIndex = this.availableTerminals.findIndex(t => t.name === terminal.name);
        terminal.description = this.availableTerminals[foundIndex].value;
        this.currentUserTerminal = terminal;
        this.showEditTerminalForm = false;
      }
    });
    this.apiSubscription.add(settingSubscription);
  }

  assignUserTerminal() {
    this.userTerminal.userId = this.authenticationService.getIdentity().id;
    this.userTerminal.organizationId = this.authenticationService.getOrganizationId();
    const termSubscription = this.settingsService.saveUserTerminal(this.userTerminal).subscribe(response => {
      this.getUserTerminal();
    });
    this.apiSubscription.add(termSubscription);
  }

  getTerminalProperties() {
    this.showUserTerminalForm = true;
    this.showEditTerminalForm = false;
    const termSubscription = this.settingsService.getProperties(this.authenticationService.getOrganizationId()).subscribe((response) => {
      this.availableTerminals = response.filter(p => p.name.includes('TERMINAL_'));
      this.getUserTerminal();
    });
    this.apiSubscription.add(termSubscription);
  }

  editTerminalForm() {
    this.showEditTerminalForm = true;
  }

  getProperties() {
    const propSubscription = this.settingsService.getProperties(this.authenticationService.getOrganizationId()).subscribe((response) => {
      this.showPropertyList = true;
      this.showPropertyForm = false;
      this.dataSource = new MatTableDataSource<Property>(response);
      this.availableTerminals = response.filter(p => p.name.includes('TERMINAL_'));
    });
    this.apiSubscription.add(propSubscription);
  }

  getMerchantInfo() {
    const settingSubscription = this.settingsService.getDeliveryInfo(this.authenticationService.getOrganizationId()).subscribe((response) => {
      this.merchant = response;
      this.showMerchantForm = true;
    },
    (error) => {
      console.log(error);
    });
    this.apiSubscription.add(settingSubscription);
  }

  saveSetting() {
    const settingSubscription = this.settingsService.saveSetting(this.property).subscribe((response) => {
      this.showPropertyList = true;
      this.showPropertyForm = false;
      this.getProperties();
      if (this.property.name === Constants.FEE_PARTY_OF_FIVE_OR_MORE) {
        this.localCache.set(Constants.FEE_PARTY_OF_FIVE_OR_MORE, this.property.value);
      } else if (this.property.name === Constants.FEE_PARTY_OF_FOUR_OR_LESS) {
        this.localCache.set(Constants.FEE_PARTY_OF_FOUR_OR_LESS, this.property.value);
      }
    },
    (error) => {
      console.log(error);
    });
    this.apiSubscription.add(settingSubscription);
  }

  deleteProperty(property: Property, rowIndx: number) {
    const settingSubscription = this.settingsService.deleteSetting(property.id).subscribe((response) => {
      if (this.localCache.get(Constants.FEE_PARTY_OF_FIVE_OR_MORE)) {
        this.localCache.remove(Constants.FEE_PARTY_OF_FIVE_OR_MORE);
      }
      if (this.localCache.get(Constants.FEE_PARTY_OF_FOUR_OR_LESS)) {
        this.localCache.remove(Constants.FEE_PARTY_OF_FOUR_OR_LESS);
      }
      this.getProperties();
    }, (error) => { console.log(error); });
    this.apiSubscription.add(settingSubscription);
  }

  saveMerchantInfo() {
    const settingSubscription = this.settingsService.saveMerchntInfo(this.merchant).subscribe((response) => {
    },
    (error) => {
      console.log(error);
    });
    this.apiSubscription.add(settingSubscription);
  }

  addProperty() {
    const settingSubscription = this.settingsService.getPropertyTypes().subscribe((response) => {
      this.showPropertyForm = true;
      this.showPropertyList = false;
      this.property = new Property();
      this.property.organizationId = this.authenticationService.getOrganizationId();
    });
    this.apiSubscription.add(settingSubscription);
  }

  editProperty(propertyToEdit: Property) {
    this.showPropertyForm = true;
    this.showPropertyList = false;
    this.property = propertyToEdit;
  }

  getAllClientEnvironmentProperties() {
    const settingSubscription = this.settingsService.getAllClientEnvironmentProperties(this.authenticationService.getOrganizationId()).subscribe(r => {
      this.clientEnvironmentProperties = r;
      // console.log(this.clientEnvironmentProperties);
      this.clientEnvPropsDataSource = new MatTableDataSource<ClientEnvironmentProperty>(this.clientEnvironmentProperties);
      this.showClientEnvPropertyList = true;
    });
    this.apiSubscription.add(settingSubscription);
  }

  saveClientEnvironmentProperties() {
    const settingSubscription = this.settingsService.saveClientEnvironmentProperties(this.clientEnvironmentProperties).subscribe(r => {
      this.openDialog('SUCCESS', 'Client Environment Properties Saved');
    }, (error) => {
      this.openDialog('ERROR', 'Could not save Client Environment Properties');
    });
    this.apiSubscription.add(settingSubscription);
  }

  restartClientService() {
    const settingSubscription = this.settingsService.restartClientService(this.authenticationService.getOrganizationId()).subscribe(r => {
      this.openDialog('SUCCESS', 'Client Service Restart Request Submitted');
    }, (error) => {
      this.openDialog('ERROR', 'Could submit request for Client Service Restart');
    });
    this.apiSubscription.add(settingSubscription);
  }

  openDialog(msgHeader: string, msg: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
    this.apiSubscription.add(dialogSubscription);
  }

  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
  }
}

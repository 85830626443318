import { Injectable } from '@angular/core';
import {Customer} from '../models/Customer';
import {Constants} from '../models/Constants';
import {ShoppingCartServiceService} from './shopping-cart-service.service';
import {CategoryService} from './category.service';
import {ToggleService} from './toggle.service';
import {SessionStorageService} from 'angular-web-storage';
import {MenuItemService} from './menu-item.service';
import {AuthenticationService} from './authentication.service';
import {Router} from '@angular/router';
import {User} from '../models/User';
import {CustomerService} from './customer.service';
import {HttpClient} from '@angular/common/http';
import {Category} from '../models/Category';
import {Observable} from 'rxjs';
import {OrderItem} from '../models/OrderItem';
import {Receipt} from '../models/Receipt';
import {MenuItem} from '../models/MenuItem';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private activeChair: Customer;
  private showOrderSummary = true;
  private showOrderDetails = false;

  private _subMenuObserable = new Subject<any>();
  public watchSubMenu = this._subMenuObserable.asObservable();

  constructor(private shoppingCartService: ShoppingCartServiceService, private categoryService: CategoryService,
              private toggleService: ToggleService, private menuItemService: MenuItemService, private router: Router,
              private authenticationService: AuthenticationService, private session: SessionStorageService,
              private customerService: CustomerService, private httpClient: HttpClient) { }

    getOrderItems(receiptId: number): Observable<Array<OrderItem>> {
        return this.httpClient.get<Array<OrderItem>>(Constants.ERESTAU_DOMAIN + '/api/orderItems/receipt/' + `${receiptId}`);
    }

    saveOrderItems(orderItems: Array<OrderItem>): Observable<Array<OrderItem>> {
        return this.httpClient.post<Array<OrderItem>>(Constants.ERESTAU_DOMAIN + '/api/orderItems', orderItems);
    }

    initializeOrderMenu() {
        this.customerService.getTables(this.authenticationService.getOrganizationId()).subscribe(tbls => {
          this.customerService.Tables = tbls;
          const selectedTableId = this.session.get(Constants.SELECTED_TABLE).id;
          const foundIndex = this.customerService.Tables.findIndex(t => t.id === selectedTableId);
          if (foundIndex >= 0) {
              this.initializeTableOrderMenu(this.customerService.Tables[foundIndex]);
          } else {
              this.initializeTableOrderMenu(this.customerService.Tables[0]);
          }
        });
    }

    initializeTableOrderMenu(table: User) {
        this.initTableChairs(table.id);
        this.session.set(Constants.SELECTED_TABLE, table);
        if (this.session.get('orgType') === Constants.ORGANIZATION_TYPE_RETAIL) {
          // this.router.navigateByUrl('/retailorder?pageView=quickStart');
            this.router.navigateByUrl('/retailorder');
        } else {
            this.router.navigateByUrl('/restaurantorder');
        }
    }

    initTableChairs(tableId: number) {
        this.customerService.getActiveTableChairs(tableId, this.authenticationService.getOrganizationId()).subscribe((ch) => {
          this.customerService.ActiveChairs = ch;
          this.session.set(Constants.ACTIVE_TABLE_CHAIRS, ch);
          /* Make the first chair active */
          this.session.set(Constants.ACTIVE_CHAIR, ch[0]);

          this.toggleService.ShowActiveChairs = true;
          this.toggleService.ShowParentCategories = true;
          this.shoppingCartService.DisplayShoppingCart = true;
          this.shoppingCartService.DisplayTables = 'FALSE';
          this.toggleService.SelectedSeat = 0; // Default to first seat of table
          this.categoryService.getParentCategories(this.authenticationService.getOrganizationId()).subscribe((cats) => {
            this.categoryService.ParentCategories = cats;
            this.toggleService.SelectedCategory = 0;
            /* Choose the first category as the active categroy*/
            this.getOrderSubCategories(this.categoryService.ParentCategories[0].id, 0);
            this.shoppingCartService.updateObservableItems([]);
            this.shoppingCartService.getChairShoppingCart(this.session.get(Constants.ACTIVE_CHAIR), this.authenticationService.getOrganizationId());
          });
        });
    }

    getOrderSubCategories(categoryId: number, arrayIndex: number) {
        const category = new Category();
        category.id = categoryId;
        this.toggleService.SelectedCategory = arrayIndex;
        this.toggleService.ShowNewItemForm = false;
        this.toggleService.ShowItemWeightForm = false;
        this.menuItemService.getMenuItemsByCategory(category, this.authenticationService.getOrganizationId()).subscribe(response => {
            if (response[0].number) {
                this.menuItemService.OrderMenuItems = response;
                this.toggleService.ShowOrderMenuItems = true;
                this.toggleService.ShowSubCategories = false;
                this._subMenuObserable.next({ response: null, flag: false});
            } else {
                this.categoryService.SubCategories = response;
                this.toggleService.ShowSubCategories = true;
                this._subMenuObserable.next({ response: response, flag: true});
                this.toggleService.ShowOrderMenuItems = false;
            }
            this.toggleService.ShowCartItemToEdit = false;
        });
    }

    getUnNestedOrderItem(orderItem: OrderItem): OrderItem {
        const oItem = new OrderItem();

        if (orderItem.user) {
            const user = new User();
            user.id = orderItem.user.id;
            user.organizationId = orderItem.organizationId;
            oItem.user = user;
        }

        if (orderItem.updatedBy) {
            const updatedBy = new User();
            updatedBy.id = orderItem.updatedBy.id;
            updatedBy.organizationId = orderItem.organizationId;
            oItem.updatedBy = updatedBy;
        }

        if (orderItem.customer) {
            const customer = new Customer();
            customer.id = orderItem.customer.id;
            customer.organizationId = orderItem.organizationId;
            oItem.customer = customer;
        }

        if (orderItem.menuItem) {
            const menuItem = new MenuItem();
            menuItem.id = orderItem.menuItem.id;
            menuItem.organizationId = orderItem.organizationId;
            oItem.menuItem = menuItem;
        }

        if (orderItem.receipt) {
            const receipt = new Receipt();
            receipt.id = orderItem.receipt.id;
            receipt.organizationId = orderItem.organizationId;
            receipt.paymentRef = orderItem.receipt.paymentRef;
            receipt.paymentAuthCode = orderItem.receipt.paymentAuthCode;
            receipt.terminal = orderItem.receipt.terminal;
            receipt.paymentType = orderItem.receipt.paymentType;
            oItem.receipt = receipt;
        }

        oItem.id = orderItem.id;
        oItem.name = orderItem.name;
        oItem.quantity = orderItem.quantity;
        oItem.number = orderItem.number;
        oItem.price = orderItem.price;
        oItem.taxRate = orderItem.taxRate;
        oItem.totalPrice = orderItem.totalPrice;
        oItem.discountPrice = orderItem.discountPrice;
        oItem.updated = orderItem.updated;
        oItem.orderPlaced = orderItem.orderPlaced;
        oItem.orderReady = orderItem.orderReady;
        oItem.orderServed = orderItem.orderServed;
        oItem.orderType = orderItem.orderType;
        oItem.orderStatus = orderItem.orderStatus;
        oItem.discount = orderItem.discount;
        oItem.notes = orderItem.notes;
        oItem.organizationId = orderItem.organizationId;
        oItem.checked = orderItem.checked;
        oItem.highlighted = orderItem.highlighted;
        oItem.returned = orderItem.returned;
        oItem.returnQuantity = orderItem.returnQuantity;

        return oItem;
    }

    set ShowOrderSummary(showOrderSummary: boolean) {
      this.showOrderSummary = showOrderSummary;
    }

    get ShowOrderSummary() {
      return this.showOrderSummary;
    }

    set ShowOrderDetails(showOrderDetails: boolean) {
        this.showOrderDetails = showOrderDetails;
    }

    get ShowOrderDetails() {
        return this.showOrderDetails;
    }

    set ActiveChair(activeChair: Customer) {
        this.activeChair = activeChair;
    }

    get ActiveChair() {
        return this.activeChair;
    }
}

import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {ShoppingCartItem} from "../models/ShoppingCartItem";

@Injectable({
  providedIn: 'root'
})
export class ToggleService {

  constructor() { }

  private showPaymentProgress: boolean = false;
  private setFocusOnTenderedAmount: boolean = false;
  private showAdditionalTableChairForm: boolean = false;
  private showParentCategories: boolean = false;
  private showMoMoIcon: boolean = true;
  private showFooterIcon: boolean = false;
  private showActiveChairs = false;
  private showOrderMenuItems = true;
  private showSubCategories = false;
  private deliveryAvailable = false;
  private pickupAvailable = false;
  private selectedSeat: number;
  private selectedCategory: number;
  private selectedSubCategory: number;
  private showReportMenu = false;
  private showDailyRevenueReport = false;
  private showTaxRateReport = false;
  private showCategoryReport = false;
  private showCartItemToEdit = false;
  private showItemWeightForm = false;
  private showNewItemForm = false;
  private showOrderItemSummary = false;
  private computeBalance = false;
  private showDoneButton = false;
  private processPaymentAsEBT = false;
  private showRevenueBarchart = false;
  private showSideItems = false;
  private retailStoreBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);

  set ShowPaymentProgress(showPaymentProgress: boolean) {
    this.showPaymentProgress = showPaymentProgress;
  }

  get ShowPaymentProgress() {
    return this.showPaymentProgress;
  }

  set ShowSideItems(showSideItems: boolean) {
    this.showSideItems = showSideItems;
  }

  get ShowSideItems() {
    return this.showSideItems;
  }

  set SetFocusOnTenderedAmount(setFocusOnTenderedAmount: boolean) {
    this.setFocusOnTenderedAmount = setFocusOnTenderedAmount;
  }

  get SetFocusOnTenderedAmount() {
    return this.setFocusOnTenderedAmount;
  }

  set ShowAdditionalTableChairForm(showAdditionalTableChairForm: boolean) {
    this.showAdditionalTableChairForm = showAdditionalTableChairForm;
  }

  get ShowAdditionalTableChairForm() {
    return this.showAdditionalTableChairForm;
  }

  set ShowParentCategories(showParentCategories: boolean) {
    this.showParentCategories = showParentCategories;
  }

  get ShowParentCategories() {
    return this.showParentCategories;
  }

  set ShowMoMoIcon(showMoMoIcon: boolean) {
    this.showMoMoIcon = showMoMoIcon;
  }

  get ShowMoMoIcon() {
    return this.showMoMoIcon;
  }
  
  set ShowFooterIcon(showFooterIcon: boolean) {
    this.showFooterIcon = showFooterIcon;
  }

  get ShowFooterIcon() {
    return this.showFooterIcon;
  }

  set DeliveryAvailable(deliveryAvailable: boolean) {
    this.deliveryAvailable = deliveryAvailable;
  }

  get DeliveryAvailable() {
    return this.deliveryAvailable;
  }

  set PickupAvailable(pickupAvailable: boolean) {
    this.pickupAvailable = pickupAvailable;
  }

  get PickupAvailable() {
    return this.pickupAvailable;
  }

  get SelectedSeat() {
    return this.selectedSeat;
  }

  set SelectedSeat(selectedSeat: number) {
    this.selectedSeat = selectedSeat;
  }

  get SelectedCategory() {
    return this.selectedCategory;
  }

  set SelectedCategory(selectedCategory: number) {
    this.selectedCategory = selectedCategory;
  }

  get SelectedSubCategory() {
    return this.selectedSubCategory;
  }

  set SelectedSubCategory(selectedSubCategory: number) {
    this.selectedSubCategory = selectedSubCategory;
  }

  set ShowOrderMenuItems(showOrderMenuItems: boolean) {
    this.showOrderMenuItems = showOrderMenuItems;
  }

  get ShowOrderMenuItems() {
    return this.showOrderMenuItems;
  }

  set ShowSubCategories(showSubCategories: boolean) {
    this.showSubCategories = showSubCategories;
  }

  get ShowSubCategories() {
    return this.showSubCategories;
  }

  set ShowActiveChairs(showActiveChairs: boolean) {
    this.showActiveChairs = showActiveChairs;
  }

  get ShowActiveChairs() {
    return this.showActiveChairs;
  }

  get ShowDailyRevenueReport() {
    return this.showDailyRevenueReport;
  }

  set ShowDailyRevenueReport(showDailyRevenueReport: boolean) {
    this.showDailyRevenueReport = showDailyRevenueReport;
  }

  get ShowTaxRateReport() {
    return this.showTaxRateReport;
  }

  set ShowTaxRateReport(showTaxRateReport: boolean) {
    this.showTaxRateReport = showTaxRateReport;
  }

  get ShowCategoryReport() {
    return this.showCategoryReport;
  }

  set ShowCategoryReport(showCategoryReport: boolean) {
    this.showCategoryReport = showCategoryReport;
  }

  get ShowReportMenu() {
    return this.showReportMenu;
  }

  set ShowReportMenu(showReportMenu: boolean) {
    this.showReportMenu  = showReportMenu;
  }

  set ShowCartItemToEdit(showCartItemToEdit: boolean) {
    this.showCartItemToEdit = showCartItemToEdit;
  }

  get ShowCartItemToEdit() {
    return this.showCartItemToEdit;
  }

  set ShowItemWeightForm(showItemWeightForm: boolean) {
    this.showItemWeightForm = showItemWeightForm;
  }

  get ShowItemWeightForm() {
    return this.showItemWeightForm;
  }

  set ShowNewItemForm(showNewItemForm: boolean) {
    this.showNewItemForm = showNewItemForm;
  }

  get ShowNewItemForm() {
    return this.showNewItemForm;
  }

  set ShowOrderItemSummary(showOrderItemSummary: boolean) {
    this.showOrderItemSummary = showOrderItemSummary;
  }

  get ShowOrderItemSummary() {
    return this.showOrderItemSummary;
  }

  set ShowDoneButton(showDoneButton: boolean) {
    this.showDoneButton = showDoneButton;
  }

  get ShowDoneButton() {
    return this.showDoneButton;
  }

  set ProcessPaymentAsEBT(processPaymentAsEBT: boolean) {
    this.processPaymentAsEBT = processPaymentAsEBT;
  }

  get ProcessPaymentAsEBT() {
    return this.processPaymentAsEBT;
  }

  set ComputeBalance(computeBalance: boolean) {
    this.computeBalance = computeBalance;
  }

  get ComputeBalance() {
    return this.computeBalance;
  }

  public getRetailStoreObservableSubject(): Observable<boolean> {
    return this.retailStoreBehaviorSubject;
  }

  public setRetailStoreObservableSubject(retailStoreSubject: boolean): void {
    this.retailStoreBehaviorSubject.next(retailStoreSubject);
  }

  set ShowRevenueBarchart(showRevenueBarchart: boolean) {
    this.showRevenueBarchart = showRevenueBarchart;
  }

  get ShowRevenueBarchart() {
    return this.showRevenueBarchart;
  }
}

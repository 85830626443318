<div class="page-frame">
    <div class="page-frame-header" style="border-bottom: 2px solid #cdcdcd;">
        <app-header></app-header>
    </div>
    <div class="page-frame-body">
        <div class="full-height-container pad-20">
            <div class="shadow-radius-container grow-height">
                <div class="row space-0" style="height: 100%;">
                    <div class="col-md-3">
                        <div class="info-icons">
                            <a href="javascript:;" (click)="showSelectedItems()">
                                <i class="fas fa-receipt"></i>
                            </a>
                            <a href="javascript:;">
                                <i class="fas fa-folder-open"></i>
                            </a>
                            <a href="/erestau/orderitems/todaySalesPosUser">
                                <i class="fas fa-chart-bar"></i>
                            </a>
                            <a href="javascript:;">
                                <i class="fas fa-check-circle"></i>
                            </a>
                        </div>
                        <div class="payment-option-details">
                            <div class="dot-table">
                                <div class="dot-table-header">
                                    <div class="dot-table-row">
                                        <div class="row"> 
                                            <div class="col-md-8 text-start">
                                                <label>Payment Due:</label>
                                            </div>
                                            <div class="col text-end"> 
                                                <label>{{ invoiceAmount | currency}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dot-table-body">
                                    <div class="dot-table-row">
                                        <div class="row">
                                            <div class="col-md-8 text-start">
                                                <span class="custom-radio">
                                                    <input type="radio" id="cash" value="CASH" checked name="paymethod" />
                                                    <label (click)="updatePaymentType('CASH')" for="cash"><i class="fas fa-check"></i></label>
                                                </span>
                                                <label for="active1">Cash</label>
                                            </div>
                                            <div class="col text-end">
                                                <img class="payment-option-image" src="/assets/images/cash_icon.png">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-row">
                                        <div class="row">
                                            <div class="col-md-8 text-start">
                                                <span class="custom-radio">
                                                    <input type="radio" id="credit" value="EC" name="paymethod" />
                                                    <label for="credit" (click)="updatePaymentType('CREDIT_CARD')"><i class="fas fa-check"></i></label>
                                                </span>
                                                <label for="active1">Credit / Debit</label>
                                            </div>
                                            <div class="col text-end">
                                                <img class="payment-option-image" src="/assets/images/credit-card-icon.png">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dot-table-footer">
                                    <div class="dot-table-row no-border">
                                        <div class="row space-10">
                                            <div class="col text-start">
                                                <button (click)="showSelectedItems()" class="btn btn-40 btn-success" type="button">
                                                    <i class="fas fa-check-circle"></i> Pay
                                                </button>
                                            </div> 
                                            <div class="col text-right">
                                                <button class="btn btn-40 btn-danger float-end" type="button" (click)="backOrderPage()">
                                                    <i class="fas fa-times-circle"></i> Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9" style="border-left:2px solid #cdcdcd;">
                        <div class="expandable-list">
                            <div class="expandable-list-item" *ngFor="let table of tableData">
                                <div class="expandable-list-header" [ngClass]="{ 'expanded': table.isExpand }" (click)="toggleTable(table)">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <h2>
                                                <ng-container *ngIf="table.isExpand">
                                                    <i class="fas fa-chevron-down"></i>
                                                </ng-container>
                                                <ng-container *ngIf="!table.isExpand">
                                                    <i class="fas fa-chevron-right"></i>
                                                </ng-container>
                                                
                                                {{ table.userId }} <div class="loader" *ngIf="table.showLoader"></div>
                                            </h2>
                                        </div>
                                        <div class="col text-right">
                                            <span class="orange-mark" *ngIf="table.isChecked"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="expandable-list-body" *ngIf="table.isExpand && table.data.length > 0">
                                    <div class="light-record-list">
                                        <div class="light-record-list-header">
                                            <div class="light-record-list-item">
                                                <div class="row space-10">
                                                    <div class="col-md-auto">
                                                        <mat-checkbox style="visibility: hidden;"></mat-checkbox>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <label>Name</label>
                                                    </div>
                                                    <div class="col-md">
                                                        <label>Quantity</label>
                                                    </div>
                                                    <div class="col-md">
                                                        <label>Price</label>
                                                    </div>
                                                    <div class="col-md">
                                                        <label>Customer Seat</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="light-record-list-body">
                                            <div class="light-record-list-item" *ngFor="let record of table.data; let index = index;">
                                                <div class="row space-10">
                                                    <div class="col-md-auto">
                                                        <mat-checkbox [disabled]="record.isDisable" (change)="markSelectedTable(record, index)" [(ngModel)]="record.isChecked"></mat-checkbox>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <label>{{ record.menuItem.name }}</label>
                                                    </div>
                                                    <div class="col-md">
                                                        <label>{{ record.quantity }}</label>
                                                    </div>
                                                    <div class="col-md">
                                                        <label>{{ record.price | currency }}</label>
                                                    </div>
                                                    <div class="col-md">
                                                        <label>{{ record.customer.name }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


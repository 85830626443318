<div style="text-align: center; font-weight: bold; font-size: 1.5em;">Add Seat To Table</div>
<br/><br/>
<mat-dialog-content>
    <span style="padding-right: 4px; font-weight: bold; font-size: 2em; color: #000000;">Table Name:</span>
    <span style="font-weight: bold; font-size: 2em; color: darkblue">{{chair.user.userId}}</span>
    <br/><br/><br/>
    <span style="margin-left:25px; font-weight: bold; font-size: 2em; color: #000000;">Seat Name:</span>
    <span style="padding-left: 8px;"><input maxlength="4" size="10" type="text" [(ngModel)]="chair.name" style="font-size: 1.5em; font-weight: bold;"/></span>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="onDismiss()" mat-button><span class="cancel-button">Cancel</span></button>
    <button (click)="onConfirm()" mat-button><span class="print-button">Add</span></button>
</mat-dialog-actions>

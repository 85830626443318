import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { AddressService } from 'src/app/services/address.service';
import { Receipt } from 'src/app/models/Receipt';
import { ShoppingCartServiceService } from 'src/app/services/shopping-cart-service.service';
import { ReceiptService } from 'src/app/services/receipt.service';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { PaymentService } from 'src/app/services/payment.service';
import {MatTableDataSource} from '@angular/material/table';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ReceiptDialogComponent} from '../../dialogs/receipt-dialog/receipt-dialog.component';
import {Constants} from '../../models/Constants';
import {SearchParams} from '../../models/SearchParams';
import {WebsocketService} from '../../services/websocket.service';
import {PaymentRequest} from '../../models/PaymentRequest';
import {Router} from '@angular/router';
import {User} from '../../models/User';
import {MessageDialogConfirmationComponent} from '../message-dialog-confirmation/message-dialog-confirmation.component';
import {BatchCloseComponent} from '../../dialogs/batch-close/batch-close.component';
import {Property} from '../../models/Property';
import {SettingsService} from '../../services/settings.service';
import {ReportService} from '../../services/report.service';
import {Subscription} from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { DatePipe, CurrencyPipe } from '@angular/common';
import {BigDecimal} from '../../models/BigDecimal';
import {TipAdjustRendererComponent} from '../tip-adjust-rendender/tip-adjust-renderer.component';
import { SharedService } from 'src/app/services/shared.service';
import {LocalStorageService, SessionStorageService} from 'angular-web-storage';


@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit, OnDestroy {
  private apiSubscription = new Subscription();
  public disableReceiptDelete: any;
  public currentDateTime: Date;
  public showReceiptSummary = false;
  public receiptSummaryDataSource = null;
  public receipts: Array<Receipt> = [];
  public receiptStartDate: Date;
  public receiptEndDate: Date;
  public receiptTotal = 0;
  public tipTotal = 0;
  @ViewChild('receiptNumberInput') receiptBarcodeElement: ElementRef;


  public restailReceiptSummaryColumns = ['receiptNo', 'firstName', 'updated', 'netto', 'tax', 'paymentType', 'status', 'total', 'action'];
  public restaurantReceiptSummaryColumns = ['receiptNo', 'firstName', 'updated', 'netto', 'tax', 'paymentType', 'tipAmount', 'status', 'total', 'action'];
  public receiptSummaryColumns: any;
  public availableTerminals: Array<Property> = [];

  public frameworkComponents = {
    tipAdjustComponent: TipAdjustRendererComponent
  };

  columnDefs = [
    {
      headerName: 'Receipt No',
      field: 'receiptNo',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Name',
      field: 'user.firstName',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Date',
      field: 'updated',
      valueFormatter: params => this.dateFormatter(params.value),
      filter: 'agDateColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Net',
      field: 'netto',
      valueFormatter: params => this.currencyFormatter(params.value),
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Tax',
      field: 'tax',
      valueFormatter: params => this.currencyFormatter(params.value),
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Type',
      field: 'paymentType',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Tip Amount',
      hide: !this.authenticationService.isRestaurant(),
      cellClass: 'ag-custom-table-col',
      cellRenderer: 'tipAdjustComponent',
      cellRendererParams: {
        clicked: function(data: any) {
          // ReceiptComponent.prototype.tipAdjust.apply(data);
          // self.tipAdjust(data);
        }
      }
    },
    {
      headerName: 'Auth Amount',
      field: 'total',
      valueFormatter: params => this.currencyFormatter(params.value),
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: '',
      field: '',
      cellClass: 'ag-table-col-action',
      cellRenderer: this.actionsRenderer
    }
  ];
  public gridOptions: GridOptions;
  public rowSelection = 'single';

  public rowData: any = [];

  constructor(public receiptService: ReceiptService, private shoppingCartService: ShoppingCartServiceService, public reportService: ReportService,
              private addressService: AddressService, public authenticationService: AuthenticationService, private router: Router, private storage: LocalStorageService,
              private paymentService: PaymentService, private websocketService: WebsocketService, private dialog: MatDialog, private settingsService: SettingsService,
              private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private _shared: SharedService, public session: SessionStorageService
  ) { }

  ngOnInit() {
    this.disableReceiptDelete = JSON.parse(this.storage.get('DELETE_RECEIPT_PROPERTY'));
    this.gridOptions = ({
      rowHeight: 40
    } as GridOptions);

    if (this.authenticationService.isRestaurant()) {
      this.receiptSummaryColumns = this.restaurantReceiptSummaryColumns;
    } else {
      this.receiptSummaryColumns = this.restailReceiptSummaryColumns;
    }
    this.currentDateTime = new Date();
    this.receiptService.calculateReceiptTotal();
    setTimeout(() => {
      this.getTodaysReceipts();
    }, 0);
  }

  home() {
    this.router.navigate(['/home']);
  }

  searchReceipts() {
    const searchParams = new SearchParams();
    searchParams.startDate = this.receiptStartDate;
    searchParams.endDate = this.receiptEndDate;
    searchParams.organizationId = this.authenticationService.getOrganizationId();

    if (searchParams.startDate && searchParams.endDate) {
      this.showReceiptSummary = false;
      this._shared.setGlobalLoader(true);
      const receiptSubscription = this.receiptService.getReceiptsByDate(searchParams).subscribe(rcpts => {
        this.computeTotalTip(rcpts);
        this.receiptService.ReceiptDataSource = rcpts;
        this.rowData = rcpts;
        this.showReceiptSummary = true;
        this.receipts = rcpts;
        this._shared.setGlobalLoader(false);
      }, error => { alert('NO SEARCH RESULTS'); });
      this.apiSubscription.add(receiptSubscription);
    }
  }

  deleteReceipt(receipt: Receipt) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      description: 'DELETE RECEIPT CONFIRMATION',
      message: 'Are you sure you want to delete receipt with amount $' + BigDecimal.round(receipt.total, 2)};
    dialogConfig.width = '640px';
    dialogConfig.height = '300px';

    const dialogRef = this.dialog.open(MessageDialogConfirmationComponent, dialogConfig);
    const dialogSubscription = dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation === true) {
        this.receiptService.deleteReceipt(receipt.id).subscribe(response => {
          if (response.message.startsWith('ERROR')) {
            this.messageDialog('ERROR', response.message);
          } else {
            this.messageDialog('SUCCESS', 'Receipt deleted');
            const foundIndex = this.receipts.findIndex(rc => rc.id === receipt.id);
            this.receipts.splice(foundIndex, 1);
            this.receiptService.ReceiptDataSource = this.receipts;
            this.computeTotalTip(this.receipts);
          }
        }, (error) => {
          this.messageDialog('ERROR', 'Could not delete receipt');
        });
      }
    });
    this.apiSubscription.add(dialogSubscription);
  }

  printRevenueReport() {
    const searchParams = new SearchParams();
    if (!this.receiptStartDate && !this.receiptEndDate) {
      this.receiptStartDate = new Date(new Date().toDateString());
      this.receiptEndDate = new Date(new Date().toDateString());
    }
    if (!this.receiptStartDate || !this.receiptEndDate) {
      return;
    }
    searchParams.startDate = this.receiptStartDate;
    searchParams.endDate = this.receiptEndDate;
    searchParams.type = 'range';
    searchParams.organizationId = this.authenticationService.getOrganizationId();
    this._shared.setGlobalLoader(true);
    const receiptSubscription = this.reportService.printRevenueReport(searchParams).subscribe(response => {
      this._shared.setGlobalLoader(false);
      this.messageDialog('SUCCESS', 'Report sent to printer');
    }, (error) => {
      this._shared.setGlobalLoader(false);
      this.messageDialog('ERROR', 'Could not send report to printer');
    });
    this.apiSubscription.add(receiptSubscription);
  }

  tipAdjust(receipt: Receipt) {
    const msg = 'Tip amount [ $' + BigDecimal.round(receipt.tipAmount, 2) + ' ] correct?';
    this.openDialogConfirmation(receipt, 'TIP ADJUST CONFIRMATION', msg);
    // receipt.tipAmount = null;
  }

  closeBatch() {
    const user = new User();
    user.id = this.authenticationService.getIdentity().id;
    user.systemId = this.authenticationService.getIdentity().username;
    const paymentRequest = new PaymentRequest();
    paymentRequest.user = user;
    paymentRequest.organizationId = this.authenticationService.getOrganizationId();
    paymentRequest.paymentType = Constants.CLOSE_BATCH;
    paymentRequest.paymentTerminalPresent = this.authenticationService.paymentTerminalPresent();
    this._shared.setGlobalLoader(true);
    const propSubscription = this.settingsService.getProperties(this.authenticationService.getOrganizationId()).subscribe((response) => {
      this.availableTerminals = response.filter(p => p.name.includes('TERMINAL_'));
      this._shared.setGlobalLoader(false);
      const msgHeader = 'CLOSE BATCH';
      const msg = JSON.stringify(this.availableTerminals);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '580px';
      dialogConfig.height = '300px';
      dialogConfig.data = {description: msgHeader, message: msg};
      const dialogRef = this.dialog.open(BatchCloseComponent, dialogConfig);
      const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
        if (result === true && this.settingsService.SelectedTerminal !== 'NONE') {
          paymentRequest.terminal = this.settingsService.SelectedTerminal;
          const paySubscription = this.paymentService.submitPayment(paymentRequest).subscribe((rcpt) => {
                this.getTodaysReceipts();
                this.websocketService.closeBatchTransaction(paymentRequest.terminal);
              },
              (error) => {
                alert('******* Error CLOSING BATCH *********');
              });
          this.apiSubscription.add(paySubscription);
        }
      });
      this.apiSubscription.add(dialogSubscription);
    });
    this.apiSubscription.add(propSubscription);
  }

  openOkayDialog(msgHeader: string, msg: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {});
    this.apiSubscription.add(dialogSubscription);
  }

  getTodaysReceipts() {
    this.showReceiptSummary = false;
    this._shared.setGlobalLoader(true);
    const receiptSubscription = this.receiptService.getTodaysReceipts(this.authenticationService.getOrganizationId()).subscribe((rcpts) => {
      this._shared.setGlobalLoader(false);
      this.receiptNumberInputFocus();
      this.computeTotalTip(rcpts);
      this.receiptService.ReceiptDataSource = rcpts;
      this.rowData = rcpts;
      this.showReceiptSummary = true;
      this.receipts = rcpts;
    });
    this.apiSubscription.add(receiptSubscription);
  }

  computeTotalTip(receipts: Array<Receipt>) {
    this.tipTotal = 0;
    this.receiptTotal = 0;
    for (let i = 0; i < receipts.length; i++) {
      if (receipts[i].changeAmount >= 0) {
        this.receiptTotal += receipts[i].total;
        this.tipTotal += receipts[i].tipAmount;
      } else {
        this.receiptTotal += receipts[i].amountPaid;
      }
    }
  }

  printReceipt(receipt: Receipt) {
    this._shared.setGlobalLoader(true);
    const printSubscription = this.receiptService.printReceiptCopy(receipt).subscribe(response => {
      this._shared.setGlobalLoader(false);
      this.openOkayDialog('SUCCESS', 'Receipt copy sent to printer');
    }, (error) => {
      this._shared.setGlobalLoader(false);
      alert(error);
    });
    this.apiSubscription.add(printSubscription);
  }

  receiptDetails(receipt: Receipt) {
    this.openSafeHtmlPipeDialog();
  }

  openDialogConfirmation(receipt: Receipt, msgHeader: string, msg: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {description: msgHeader, message: msg};
    dialogConfig.width = '640px';

    const dialogRef = this.dialog.open(MessageDialogConfirmationComponent, dialogConfig);
    const dialogSubscription = dialogRef.afterClosed().subscribe(confirmation => {
      const foundIndex = this.receipts.findIndex(rc => rc.id === receipt.id);
      this.receipts[foundIndex] = receipt;
      if (confirmation === true) {
        // console.log('receipt console', receipt);
        this._shared.setGlobalLoader(true);
        this._shared.setWebSocketLoader(true);
        const receiptSubscription = this.receiptService.tipAdjust(receipt).subscribe(r => {
          this.computeTotalTip(this.receipts);
          this.websocketService.tipAdjustTransaction(receipt);
        }, error => { console.log(error); });
        this.apiSubscription.add(receiptSubscription);
      } else {
        this.showReceiptSummary = false;
        this.receipts[foundIndex].tipAmount = null;
        this.receiptService.ReceiptDataSource = this.receipts;
        this.rowData = this.receipts;
        this.showReceiptSummary = true;
      }
    });
    this.apiSubscription.add(dialogSubscription);
  }

  openSafeHtmlPipeDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '500px';
    const dialogRef = this.dialog.open(ReceiptDialogComponent, dialogConfig);
    dialogRef.componentInstance.htmlContent = '<img src="https://erestaupos.com/menuimages/jayscaribbean/Screen%20Shot%202020-02-25%20at%2012.16.49.png"/>';
  }

  goToLink(receipt: Receipt) {
    const url = Constants.ERESTAU_DOMAIN + '/public/api/render/receipt?receiptId=' + receipt.id;
    window.open(url, '', 'width=600,height=700,left=300,top=100');
  }

  receiptNumberLookup(data: string) {
    const searchParams = new SearchParams();
    searchParams.receiptNumber = data;
    searchParams.organizationId = this.authenticationService.getOrganizationId();

    if (data) {
      this._shared.setGlobalLoader(true);
      const receiptSubscription = this.receiptService.getReceiptsByDate(searchParams).subscribe(rcpts => {
        this.receiptBarcodeElement.nativeElement.value = '';
        this.receipts = rcpts;
        this._shared.setGlobalLoader(false);
        this.receiptSummaryDataSource = new MatTableDataSource<Receipt>(this.receipts);
      }, error => { alert('NO SEARCH RESULTS'); });
      this.apiSubscription.add(receiptSubscription);
    }
  }

  receiptNumberInputFocus() {
    setTimeout(() => {
      this.receiptBarcodeElement.nativeElement.focus();
    },0);
  }

  messageDialog(header: string, msg: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: header, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {});
    this.apiSubscription.add(dialogSubscription);
  }

  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
  }

  onGridReady(params) {
    params.api.sizeColumnsToFit();
  }

  onRowClicked(e) {
    if (e.event.target !== undefined) {
        const actionType = e.event.target.getAttribute('data-action-type');
        switch (actionType) {
            case 'Print':
                this.printReceipt(e.node.data);
                break;
            case 'Search':
                this.goToLink(e.node.data);
                break;
            case 'Delete':
                this.deleteReceipt(e.node.data);
                break;
            case 'Show':
                // alert('click show');
                break;
            case 'tipAdjust':
                this.tipAdjust(e.node.data);
                break;
        }
    }
  }

  dateFormatter(value) {
    return this.datePipe.transform(value,'MMMM d, h:mm');
  }

  currencyFormatter(value) {
    return this.currencyPipe.transform(value,'$');
  }

  actionsRenderer(curVal: any): string {
    return `
        <i data-action-type="Print" class="fas fa-print"></i>
        <i data-action-type="Search" class="fas fa-search"></i>
        <i data-action-type="Delete" class="far fa-trash-alt text-light-red"></i>
    `;
  }

  tipRenderer(curVal: any): string {
      return `
          <i data-action-type="Print" class="fa fa-print"></i>
          <i data-action-type="Edit" class="fas fa-pencil-alt"></i>
          <i data-action-type="Delete" class="far fa-trash-alt text-light-red"></i>
      `;
  }

  setTipAmountObject(param) {
    return {
      param: param,
      data: 'yes'
    }
  }

}

<div class="page-frame">
    <div class="page-frame-header">
        <div class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="page-logo">
                    <i class="far fa-credit-card"></i>
                    Authorized / Declined Payments
                </div>
            </div>
        </div>
    </div>
    <div class="page-frame-body">
        <div class="full-height-container pad-20">
            <div class="shadow-radius-container grow-height full-height-container">
                <!-- <span class="payment-loader" *ngIf="paymentProgressBar.spinner"><mat-spinner></mat-spinner></span> -->
				<!-- Form Start Here -->
				<div class="form-container">
                    <form #receiptSearchForm="ngForm" (ngSubmit) ="searchLogs()">
                        <div class="summery-container">
							<div class="summery-container-body pad-20">
								<div class="row space-20">
                                    <div class="col-md-3">
										<div class="mat-datepicker">
                                            <mat-form-field appearance="outline">
                                                <input matInput [ngxMatDatetimePicker]="startDatePicker" [(ngModel)]="searchStartDate" [ngModelOptions]="{standalone: true}" placeholder="Start Date & Time">
                                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                                <ngx-mat-datetime-picker #startDatePicker></ngx-mat-datetime-picker>
                                            </mat-form-field>
										</div>
                                    </div>
									<div class="col-md-3">
										<div class="mat-datepicker">
											<mat-form-field appearance="outline">
                                                <input matInput [ngxMatDatetimePicker]="endDatePicker" [(ngModel)]="searchEndDate" [ngModelOptions]="{standalone: true}" placeholder="End Date & Time">
                                                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                                <ngx-mat-datetime-picker #endDatePicker></ngx-mat-datetime-picker>
                                            </mat-form-field>
										</div>
                                    </div>
									<div class="col-md-3"> 
										<button type="submit" class="btn btn-40 btn-white">Search</button>
                                    </div>
                                </div>
							</div>
						</div>
                    </form>
                </div>
				<!-- Form End Here -->
				<!-- Total Start Here -->
				<div class="receipt-total-section">
					<div class="receipt-total">
						<label>Total:</label>
						<span class="green-theme">{{totalApprovedAmount | currency}}</span>
					</div>
					<div class="receipt-total">
						<span (click)="printReport()"><i class="fa fa-print"></i></span>
					</div>
				</div>
				<!-- Total End Here -->
                <!-- Ag Grid Start Here -->
                <div class="ag-table-grid" *ngIf="showAgGrid">
					<ag-grid-angular 
						[columnDefs]="columnDefs"
						[rowData]="rowData"
						[rowSelection]="rowSelection"
						[rowHeight]="'60'"
                        [headerHeight]="'40'"
						[rowClass]="'ag-custom-table-row'"
						(gridReady)="onGridReady($event)"
						(rowClicked)="onRowClicked($event)"
						class="ag-theme-alpine">
					</ag-grid-angular>
				</div>
                <!-- Ag Grid End Here -->
				
            </div>
        </div>
    </div>
</div>
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {PaymentService} from '../../services/payment.service';
import {SearchParams} from '../../models/SearchParams';
import {AuthenticationService} from '../../services/authentication.service';
import {PaymentResponse} from '../../models/PaymentResponse';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {BigDecimal} from '../../models/BigDecimal';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { MessageDialogComponent} from '../message-dialog/message-dialog.component';
import { Subscription} from 'rxjs';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { GridOptions } from 'ag-grid-community';
import {MessageDialogConfirmationComponent} from '../message-dialog-confirmation/message-dialog-confirmation.component';
import {SettingsService} from '../../services/settings.service';
import {BatchCloseComponent} from '../../dialogs/batch-close/batch-close.component';
import {PaymentRequest} from "../../models/PaymentRequest";
import {WebsocketService} from 'src/app/services/websocket.service';
import {Receipt} from '../../models/Receipt';
import {Constants} from "../../models/Constants";
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-payment-response-log',
  templateUrl: './payment-response-log.component.html',
  styleUrls: ['./payment-response-log.component.css']
})
export class PaymentResponseLogComponent implements OnInit, AfterViewInit, OnDestroy {

  private apiSubscription = new Subscription();
  public totalApprovedAmount = 0.00;
  public searchStartDate: Date;
  public searchEndDate: Date;
  public responseLogs: Array<PaymentResponse> = [];
  public dataSource = null;
  public showAgGrid = false;
  public displayedColumns = ['refNum', 'bogusAccountNum', 'cardType', 'approvedAmount', 'message', 'requestedAmount', 'resultTxt', 'authCode', 'created'];

  public gridOptions: GridOptions;
  public rowSelection = 'single';

  columnDefs = [
    {
      headerName: 'Ref',
      field: 'refNum',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Bogus Account Number',
      field: 'bogusAccountNum',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Num Card Type',
      field: 'cardType',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Approved Amount',
      field: 'approvedAmount',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col',
      valueFormatter: params => this.currencyFormatter(params.value),
    },
    {
      headerName: 'Message',
      field: 'message',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Requested Amount',
      field: 'requestedAmount',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col',
      valueFormatter: params => this.currencyFormatter(params.value),
    },
    {
      headerName: 'Result',
      field: 'resultTxt',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Auth Code',
      field: 'authCode',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Transaction Type',
      field: 'transactionType',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Time',
      field: 'created',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col',
      valueFormatter: params => this.dateFormatter(params.value)
    },
    {
      headerName: '',
      field: '',
      cellClass: 'product-table-col-action',
      cellRenderer: this.actionsRenderer
    }
  ];

  rowData = [];
  public availableTerminals: any;

  public paymentProgressBar: any = { spinner: false, status: null };

  constructor(private paymentService: PaymentService, private _shared: SharedService, private websocketService: WebsocketService, private authenticationService: AuthenticationService, private router: Router, private dialog: MatDialog, private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.searchStartDate = this.getDateTimeEST(new Date());
    this.searchEndDate = this.getDateTimeEST(new Date());
    this.searchStartDate.setHours(0, 0, 0, 0);
    this.gridOptions = ({
      rowHeight: 40
    } as GridOptions);

    const searchParams = new SearchParams();
    searchParams.startDate = this.searchStartDate;
    searchParams.endDate = this.searchEndDate;
    searchParams.organizationId = this.authenticationService.getOrganizationId();
    this.showAgGrid = false;
    setTimeout(() => {
      this._shared.setGlobalLoader(true); 
    }, 0);
    const result = this.paymentService.getResponseLog(searchParams).subscribe(res => {
      this.responseLogs = res;
      this.totalApprovedAmount = this.sumApprovedAmount(this.responseLogs);
      this.dataSource = new MatTableDataSource<PaymentResponse>(this.responseLogs);
      this.rowData = this.responseLogs;
      this.showAgGrid = true;
      this._shared.setGlobalLoader(false);
    }, (error) => {
      this._shared.setGlobalLoader(false);
    });
    this.apiSubscription.add(result);

    // Observe Payment Progress
    // const paymentProgressObservable = this.paymentService.paymentProgressObservable.subscribe((response) => {
    //   this.paymentProgressBar = response;
    //   if (response.spinner == false) { 
    //     this.searchLogs();
    //   }
    // });
  }

  ngAfterViewInit(): void {
  }

  searchLogs() {
    const searchParams = new SearchParams();
    searchParams.organizationId = this.authenticationService.getOrganizationId();
    searchParams.startDate = this.searchStartDate;
    searchParams.endDate = this.searchEndDate;
    this.showAgGrid = false;
    this._shared.setGlobalLoader(true);
    const result = this.paymentService.getResponseLog(searchParams).subscribe(res => {
      this.responseLogs = res;
      this.rowData = this.responseLogs;
      this.totalApprovedAmount = this.sumApprovedAmount(this.responseLogs);
      this.dataSource = new MatTableDataSource<PaymentResponse>(this.responseLogs);
      this.showAgGrid = true;
      this._shared.setGlobalLoader(false);
    }, (error) => {
      this._shared.setGlobalLoader(false);
    });
    this.apiSubscription.add(result);
  }

  sumApprovedAmount(transactions: Array<PaymentResponse>) {
    let sumTotal = 0.00;
    for (let i = 0; i < transactions.length; i++) {
      // if (transactions[i].)
      sumTotal += Number(transactions[i].approvedAmount);
    }
    return BigDecimal.round(sumTotal, 2);
  }

  printReport() {
    const searchParams = new SearchParams();
    searchParams.organizationId = this.authenticationService.getOrganizationId();
    searchParams.startDate = this.searchStartDate;
    searchParams.endDate = this.searchEndDate;
    this.openOkayDialog('SUCCESS', 'Job Sent to Printer');
    this.paymentService.printResponseLog(searchParams).subscribe(res => {
    });
  }


  openOkayDialog(msgHeader: string, msg: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {});
    this.apiSubscription.add(dialogSubscription);
  }

  getDateTimeEST(date: Date) {
    const d = new Date();

    // convert to msec since Jan 1 1970
    const localTime = d.getTime();

    // obtain local UTC offset and convert to msec
    const localOffset = d.getTimezoneOffset() * 60 * 1000;

    // obtain UTC time in msec
    const utcTime = localTime + localOffset;

    // obtain and add destination's UTC time offset
    const estOffset = this.getEstOffset();
    const usa = utcTime + (60 * 60 * 1000 * estOffset);

    // convert msec value to date string
    const nd = new Date(usa);

    return nd;
  }

  // Get time zone offset for NY, USA
  getEstOffset = () => {
    const stdTimezoneOffset = () => {
      const jan = new Date(0, 1);
      const jul = new Date(6, 1);
      return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    };

    const today = new Date();

    const isDstObserved = (today: Date) => {
      return today.getTimezoneOffset() < stdTimezoneOffset();
    };

    if (isDstObserved(today)) {
      return -4;
    } else {
      return -5;
    }
  }

  orderMenu() {
    if (this.authenticationService.isRetail()) {
      this.router.navigateByUrl('/retailorder?pageView=quickStart');
    } else {
      this.router.navigateByUrl('/restaurantorder?pageView=quickStart');
    }
  }

  currencyFormatter(value) {
    return this.currencyPipe.transform(value,'$');
  }

  dateFormatter(value) {
      return this.datePipe.transform(value,'hh:mm a, dd-MMM-yyy');
  }
  
  onGridReady(params) {
    params.api.sizeColumnsToFit();
  }

  onRowClicked(e) {
    if (e.event.target !== undefined) {
        const actionType = e.event.target.getAttribute('data-action-type');
        switch (actionType) {
            case 'Delete':
              this.voidTransation(e.data);
                break;
        }
    }
  }

  actionsRenderer(curVal: any): string {
    return `
        <i data-action-type="Delete" class="far fa-trash-alt text-light-red"></i>
    `;
  }

  voidTransation(event) { 
    const msg = 'Are you sure you want to void transaction with amount [ ' + this.currencyPipe.transform(event.approvedAmount) + ' ] ?';
    this.openDialogConfirmation('VOID CONFIRMATION', msg, event);
  }

  openDialogConfirmation(msgHeader: string, msg: string, event: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {description: msgHeader, message: msg};
    dialogConfig.width = '640px';

    const dialogRef = this.dialog.open(MessageDialogConfirmationComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation === true) {
        this.proceedVoidPayment(event);
      }
    });
    this.apiSubscription.add(dialogSubscription);
  }

  proceedVoidPayment(event) {
    
    const propSubscription = this.settingsService.getProperties(this.authenticationService.getOrganizationId()).subscribe((response) => {
    this.availableTerminals = response.filter(p => p.name.includes('TERMINAL_'));
    const msgHeader = 'Select Terminal';
    const msg = JSON.stringify(this.availableTerminals);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '580px';
    dialogConfig.height = '300px';
    dialogConfig.data = {description: msgHeader, message: msg};
    
    const dialogRef = this.dialog.open(BatchCloseComponent, dialogConfig);
        const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
            if (result === true && this.settingsService.SelectedTerminal !== 'NONE') {
              // Set Payment Progress On
              this._shared.setGlobalLoader(true);
              this._shared.setWebSocketLoader(true);
              this.paymentService.setPaymentProgress(true);
              const paymentRequest = new PaymentRequest();
              paymentRequest.terminal = this.settingsService.SelectedTerminal;
              paymentRequest.refNumber = event.refNum;
              paymentRequest.receiptNumber = event.receiptNumber;
              paymentRequest.organizationId = this.authenticationService.getOrganizationId();
              paymentRequest.paymentType = Constants.PAYMENT_TYPE_VOID;
              paymentRequest.transactionId = event.id;

                const voidTransation = this.paymentService.voidTransaction(paymentRequest).subscribe((response) => {

                  let receipt = new Receipt();
                  receipt.terminal = this.settingsService.SelectedTerminal;
                  receipt.receiptNo = paymentRequest.receiptNumber;

                  this.websocketService.voidTransaction(receipt);
                  // this.searchLogs();
                }, (error) => {
                  console.log(error);
                });
                this.apiSubscription.add(voidTransation);
            }
        });
        this.apiSubscription.add(dialogSubscription);
    });
    this.apiSubscription.add(propSubscription);
  }

  

  ngOnDestroy(): void {
    this.apiSubscription.unsubscribe();
  }

}

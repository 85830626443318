import {Component, OnDestroy, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {AuthenticationService} from '../../services/authentication.service';
import {SubMenuItemService} from '../../services/sub-menu-item.service';
import {SubMenuItem} from '../../models/SubMenuItem';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { SharedService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-side',
    templateUrl: './sides.component.html',
    styleUrls: ['./sides.component.css']
})
export class SidesComponent implements OnInit, OnDestroy {
    private apiSubscription = new Subscription();
    public displayedColumns = ['itemNo', 'name', 'description', 'price', 'actionEdit'];
    public organizationId: number;
    displaySideForm: boolean;
    displaySideList: boolean;
    isLoading: boolean;
    addSideForm: FormGroup;
    subMenuItems: any;
    sideItemFormData: SubMenuItem;
    selectedItem: any;

    public gridOptions: GridOptions;
  public rowSelection = 'single';

  columnDefs = [
    {
      headerName: 'Item No.',
      field: 'number',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Price',
      field: 'price',
      filter: 'agTextColumnFilter',
      valueFormatter: params => this.currencyFormatter(params.value),
      cellClass: 'ag-custom-table-col',
    },
    {
      headerName: 'Is Preference',
      field: 'preference',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: '',
      field: '',
      cellClass: 'ag-table-col-action',
      cellRenderer: this.actionsRenderer
    }
  ];


    constructor(private location: Location,
                private formBuilder: FormBuilder,
                private authenticationService: AuthenticationService,
                private subMenuItemService: SubMenuItemService,
                private router: Router, private datePipe: DatePipe, private currencyPipe: CurrencyPipe,
                private _shared: SharedService ) { }

    ngOnInit() {
        this.displaySideList = true;
        this.displaySideForm = false;
        this.organizationId = this.authenticationService.getOrganizationId();
        this.sideItemFormData = new SubMenuItem();
        this.sideItemFormData.organizationId = this.organizationId;
        this.addSideForm = this.formBuilder.group({
            itemNumber: ['', Validators.required],
            name: ['', Validators.required],
            description: ['', Validators.required],
            preference: [false],
            price: ['', [Validators.required]],
            discountPrice: ['', Validators.required],
            taxRate: ['', Validators.required]
        });
        setTimeout(() => {
          this._shared.setGlobalLoader(true);
        },0);
        const menuSubscription = this.subMenuItemService.getSubMenuItems(this.organizationId).subscribe((subMenuItems) => {
            this.subMenuItems = subMenuItems;
            this._shared.setGlobalLoader(false);
        });
        const preferenceSubscription = this.addSideForm.get('preference').valueChanges.subscribe((value) => {
          if (value) {
            this.addSideForm.get('price').setValue("");
            this.addSideForm.get('price').setValidators([]);
            this.addSideForm.get('price').updateValueAndValidity();
            this.addSideForm.get('price').disable();
          }
          else {
            this.addSideForm.get('price').setValue("");
            this.addSideForm.get('price').setValidators([Validators.required]);
            this.addSideForm.get('price').updateValueAndValidity();
            this.addSideForm.get('price').enable();
          }
        })
        this.apiSubscription.add(menuSubscription);
        this.apiSubscription.add(preferenceSubscription);
    }

    currencyFormatter(value) {
        return this.currencyPipe.transform(value,'$');
    }

    dateFormatter(value) {
        return this.datePipe.transform(value,'MMMM d, h:mm');
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
      }
    
      onRowClicked(e) {
        if (e.event.target !== undefined) {
            const actionType = e.event.target.getAttribute('data-action-type');
            switch (actionType) {
                case 'Edit':
                    this.editSideItem(e.node.data);
                    break;
            }
        }
      }
    
      actionsRenderer(curVal: any): string {
        return `
            <i data-action-type="Edit" class="fas fa-pencil-alt"></i>
        `;
      }

      back() {
        this.ngOnInit();
      }

    getSideItems() {
    }

    addSideItem() {
        this.displaySideForm = true;
        this.displaySideList = false;
    }

    submitSideItem(data) {
        this.isLoading = true;
        const subMenu = {
            id: this.selectedItem ? this.selectedItem.id : null,
            number: data.value.itemNumber,
            name: data.value.name,
            description: data.value.description,
            preference: data.value.preference,
            price: data.value.price,
            discountPrice: data.value.discountPrice,
            organizationId: this.organizationId,
            taxRate: data.value.taxRate,
            subMenuType: null,
            menuItems: [],
            imageUrl: '',
            imageFilename: '',
            updatedBy: '',
            sideSelected: false
        }
        this._shared.setGlobalLoader(true);
        const menuSubscription = this.subMenuItemService.saveSubMenuItem(subMenu).subscribe(() => {
            this.isLoading = false;
            this._shared.setGlobalLoader(false);
            location.reload();
        });
        this.apiSubscription.add(menuSubscription);
    }

    editSideItem(sideItem: SubMenuItem) {
        this.selectedItem = sideItem;
        this.displaySideForm = true;
        this.displaySideList = false;
        this.sideItemFormData = sideItem;
        this.addSideForm = this.formBuilder.group({
            itemNumber: [sideItem.number ? sideItem.number : null],
            name: [sideItem.name],
            description: [sideItem.description],
            preference: [sideItem.preference],
            price: [sideItem.price],
            discountPrice: [sideItem.discountPrice],
            id: [sideItem ? sideItem.id : null],
            taxRate: [sideItem.taxRate]
        });
    }

    ngOnDestroy() {
        this.apiSubscription.unsubscribe();
    }
}

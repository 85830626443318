import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../models/Constants';
import { Observable } from 'rxjs';
import { OnlineOrder } from '../models/OnlineOrder';

@Injectable({
  providedIn: 'root'
})
export class OnlineOrderService {

  constructor(private httpClient: HttpClient) { }

  getOnlineOrders(organizationId: number): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/onlineorders/' + `${organizationId}`);
  }

  getOnlineShoppingCartItems(organizationId: number, orderId: number): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/onlineorders/' + `${organizationId}` + '/cartitems/' + `${orderId}`);
  }

  updateOnlineOrderStatus(order: OnlineOrder): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/onlineorders/status', order);
  }

  updateOrderDeliveryTime(order: OnlineOrder): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/onlineorders/deliverytime', order);
  }

  printOrder(orderId: number): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/onlineorders/print/' + `${orderId}`);
  }

  deleteOnlineShoppingCartItems(orderId: number): Observable<any> {
    return this.httpClient.delete<any>(Constants.ERESTAU_DOMAIN + '/api/onlineorders/' + `${orderId}`);
  }
}
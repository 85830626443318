<div style="text-align: center; font-weight: bold; font-size: 2em;">{{description}}</div>
<br/><br/>
<mat-dialog-content>
    <span style="font-weight: bold; font-size: 2.2em; color: darkblue">
        Terminal:
        <select id="terminalName" name="terminalName" [(ngModel)]="settingsService.SelectedTerminal" style="font-size: 30px; width: 360px; height: 70px; padding: 10px 15px;">
            <option value="NONE">--- Select Terminal ---</option>
            <option *ngFor="let property of availableTerminals" [value]="property.name">{{property.value}}</option>
        </select>
    </span>
    <br/><br/><br/>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="onCancelClick()" mat-button><span class="cancel-button">Cancel</span></button>
    <button (click)="onOkClick()" mat-button><span class="ok-button">OK</span></button>
</mat-dialog-actions>

export class Constants {
    public static SOFTWARE_VERSION = '3.0.4';
    public static DEFAULT_TABLE_PIN = '1234';
    public static ACTIVE_CHAIR = 'activeChair';
    public static LAST_CHAIR_SELECTED = 'lastNonEbtChairSelected';
    public static LANE_1_EBT = 'lane1EBT';
    public static LANE_2_EBT = 'lane2EBT';
    public static ACTIVE_TABLE_CHAIRS = 'activeTableChairs';
    public static IDENTITY = 'identity';
    public static CLOSE_BATCH = 'CLOSE_BATCH';
    public static PAYMENT_TYPE_CASH = 'CASH';
    public static PAYMENT_TYPE_CREDIT_CARD = 'CREDIT_CARD';
    public static PAYMENT_TYPE_DEBIT_CARD = 'DEBIT_CARD';
    public static PAYMENT_TYPE_SPLIT_PAYMENT = 'SPLIT_PAYMENT';
    public static PAYMENT_TYPE_SPLIT = 'SPLIT';
    public static PAYMENT_TYPE_OTHER = 'OTHER';
    public static PAYMENT_TYPE_EBT = 'EBT';
    public static PAYMENT_TYPE_VOID = 'VOID';
    public static PAYMENT_TYPE_RETURN = 'RETURN';
    public static PAYMENT_TYPE_SAMSUNG_PAY = 'SAMSUNG_PAY';
    public static PAYMENT_TYPE_APPLE_PAY = 'APPLE_PAY';
    public static PAYMENT_TYPE_GOOGLE_PAY = 'GOOGLE_PAY';
    public static PAYMENT_TYPE_GIFT_CARD = 'GIFT_CARD';
    public static PAYMENT_TYPE_MOBILE_MONEY = 'MOBILE_MONEY';
    public static LAST_PAYMENT_TYPE_SELECTED = 'lastPaymentTypeSelected';
    public static SELECTED_TABLE = 'SELECTED_TABLE';
    public static SELECTED_SHOPPING_CART_ITEMS = 'selectedShoppingCartItems';
    public static TRANSACTION_TYPE_SALE = 'SALE';
    public static TRANSACTION_TYPE_VOID = 'VOID';
    public static TRANSACTION_TYPE_RETURN = 'RETURN';
    public static TRANSACTION_TYPE_FORCEAUTH = 'FORCEAUTH';
    public static TRANSACTION_TYPE_AUTH = 'AUTH';
    public static TRANSACTION_TYPE_ADJUST = 'ADJUST';
    public static TRANSACTION_TYPE_BATCHCLOSE = 'BATCHCLOSE';

    public static STATUS_CODE_SUCCESS = '200';
    public static STATUS_CODE_BAD_REQUEST = 400;
    public static STATUS_CODE_GATEWAY_TIMEOUT = 504;

    public static MENU_IMAGE_WIDTH = 100;
    public static MENU_IMAGE_HEIGHT = 70;

    public static ORDER_STATUS_READY = 'READY';
    public static ORDER_STATUS_WAITING = 'WAITING';
    public static ORDER_STATUS_BEING_PREPARED = 'BEING_PREPARED';
    public static ORDER_STATUS_PICKED_UP = 'PICKED_UP';
    public static ORDER_STATUS_FULFILLED = 'FULFILLED';
    public static ORDER_STATUS_UNKNOWN = 'UNKNOWN';
    public static ORDER_STATUS_NEW = 'NEW';
    public static STORED_ITEMS = 'stored_items';
    public static STORED_CATEGORIES = 'stored_categories';
    public static ACTION_ADD = 'ADD';
    public static ACTION_SUBTRACT = 'SUBTRACT';

    public static ITEM_PARTY_OF_FIVE_OR_MORE = 'PARTY OF FIVE OR MORE';
    public static ITEM_PARTY_OF_FOUR_OR_LESS = 'PARTY OF FOUR OR LESS';
    public static FEE_PARTY_OF_FIVE_OR_MORE = 'FEE_PARTY_OF_FIVE_OR_MORE';
    public static FEE_PARTY_OF_FOUR_OR_LESS = 'FEE_PARTY_OF_FOUR_OR_LESS';

    public static TITLE_CREDIT_CARD = 'Credit / Debit Card';
    public static TITLE_EBT = 'EBT Card';
    public static TITLE_CASH = 'Cash';
    public static TITLE_GIFT_CARD = 'Gift Card';
    public static TITLE_OTHER = 'Other';
    public static TITLE_SPLIT = 'Split Payments';

    static ORGANIZATION_TYPE_RETAIL = 'RETAIL';
    static ORGANIZATION_TYPE_RESTAURANT = 'RESTAURANT';

    static CATEGORY_TYPE_MISCELLANEOUS = 'MISCELLANEOUS';

    static ORGANIZATION_KEY = 'organization_key';

    public static APP_TAG = 'erestau';
    public static AUDIENCE_API = 'API';

    /******************************************
     * Used by the developer on their machine *
     ******************************************/
    // static ERESTAU_DOMAIN = 'http://localhost/restaurantsolutions';
    // static AUTHENTICATION_DOMAIN = 'http://localhost/securitysolutions';
    // static WEBSOCKET_DOMAIN = 'http://localhost/websocketsolutions';

    // static ERESTAU_DOMAIN = 'http://localhost:8081';
    // static AUTHENTICATION_DOMAIN = 'http://localhost:8082';
    // static WEBSOCKET_DOMAIN = 'http://localhost:8083';

    /*****************************************
     * Use for Development (DEV) environment *
     *****************************************/
    // static ERESTAU_DOMAIN = 'https://erestaupos.kapava.com/restaurantsolutions';
    // static AUTHENTICATION_DOMAIN = 'https://erestaupos.kapava.com/securitysolutions';
    // static WEBSOCKET_DOMAIN = 'https://erestaupos.kapava.com/websocketsolutions';

    /*****************************************
     * Use for Production (PROD) environment *
     *****************************************/
    static ERESTAU_DOMAIN = 'https://erestaupos.com/restaurantsolutions';
    static AUTHENTICATION_DOMAIN = 'https://erestaupos.com/securitysolutions';
    static WEBSOCKET_DOMAIN = 'https://erestaupos.com/websocketsolutions';
    //
    public static MTN_MOMO_URI = 'https://developer.mtn.cm/OnlineMomoWeb/faces/transaction/transactionRequest.xhtml?idbouton=2&typebouton=PAIE&_clP=&_email=info@yedohphysiotherapy.com';
}

<div class="page-frame">
    <div class="page-frame-header">
        <div class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="page-logo">
                    <i class="fa fa-receipt"></i>
                    Receipts
                </div>
                <div class="page-buttons"> 
                    <button *ngIf="session.get('identity').role !== 'ROLE_MANAGER'" class="btn btn-60 btn-icon-text btn-white">
                        <i class="far fa-times-circle"></i> <span>Close Batch</span>
                    </button>
					<button *ngIf="session.get('identity').role === 'ROLE_MANAGER'" (click)="closeBatch()" class="btn btn-60 btn-icon-text btn-white">
						<i class="far fa-times-circle"></i> <span>Close Batch</span>
					</button>
                </div>
            </div>
        </div>
    </div>
    <div class="page-frame-body">
        <div class="full-height-container pad-20">
            <div class="shadow-radius-container grow-height full-height-container">
                
				<!-- Form Start Here -->
				<div class="form-container">
                    <form #receiptSearchForm="ngForm" (ngSubmit)="searchReceipts()">
                        <div class="summery-container">
							<div class="summery-container-body pad-20">
								<div class="row space-20">
                                    <div class="col-md-3">
                                        <input class="form-control" #receiptNumberInput (keyup.enter)="receiptNumberLookup($event.target.value)" placeholder="Scan"/>
                                    </div>
                                    <div class="col-md-3">
										<div class="mat-datepicker">
											<mat-form-field appearance="outline">
												<input matInput [matDatepicker]="startDatePicker" [(ngModel)]="receiptStartDate" [ngModelOptions]="{standalone: true}" placeholder="Start Date">
												<mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
												<mat-datepicker #startDatePicker></mat-datepicker>
											</mat-form-field>
										</div>
                                    </div>
									<div class="col-md-3">
										<div class="mat-datepicker">
											<mat-form-field appearance="outline">
												<input matInput [matDatepicker]="endDatePicker" [(ngModel)]="receiptEndDate" [ngModelOptions]="{standalone: true}" placeholder="End Date">
												<mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
												<mat-datepicker #endDatePicker></mat-datepicker>
											</mat-form-field>
										</div>
                                    </div>
									<div class="col-md-3"> 
										<button type="submit" class="btn btn-40 btn-white">Search</button>
                                    </div>
                                </div>
							</div>
						</div>
                    </form>
                </div>
				<!-- Form End Here -->
				<!-- Total Start Here -->
				<div class="receipt-total-section">
					<div class="receipt-total">
						<label>Total:</label>
						<span class="green-theme">{{receiptTotal | currency}}</span>
					</div>
					<div class="receipt-total" *ngIf="authenticationService.isRestaurant()">
						<label>Tips:</label>
						<span class="green-theme">{{tipTotal | currency}}</span>
					</div>
					<div class="receipt-total">
						<span *ngIf="session.get('identity').role !== 'ROLE_MANAGER'"><i class="fa fa-print"></i></span>
						<span *ngIf="session.get('identity').role === 'ROLE_MANAGER'" (click)="printRevenueReport()"><i class="fa fa-print"></i></span>
					</div>
				</div>
				<!-- Total End Here -->
				<!-- List Start Here -->
				<div class="mat-table-container" style="overflow: auto;" *ngIf="showReceiptSummary">
					<table mat-table [dataSource]="receiptService.ReceiptDataSource">

						<ng-container matColumnDef="receiptNo">
							<th mat-header-cell *matHeaderCellDef> Receipt No </th>
							<td mat-cell *matCellDef="let receipt"> {{receipt.receiptNo}} </td>
						</ng-container>
				
						<ng-container matColumnDef="firstName">
							<th mat-header-cell *matHeaderCellDef> Name </th>
							<td mat-cell *matCellDef="let receipt"> {{receipt.user.firstName}} </td>
						</ng-container>
				
						<ng-container matColumnDef="updated">
							<th mat-header-cell *matHeaderCellDef> Date </th>
							<td mat-cell *matCellDef="let receipt"> {{receipt.updated | date : 'MMMM d, h:mm' }} {{ receipt.updated | date : 'a' | lowercase }} </td>
						</ng-container>
				
						<ng-container matColumnDef="netto">
							<th mat-header-cell class="mat-description-column" *matHeaderCellDef> Net </th>
							<td mat-cell *matCellDef="let receipt"> {{receipt.netto | currency}} </td>
						</ng-container>
				
						<ng-container matColumnDef="tax">
							<th mat-header-cell *matHeaderCellDef> Tax </th>
							<td mat-cell *matCellDef="let receipt"> {{receipt.tax | currency}} </td>
						</ng-container>
				
						<ng-container matColumnDef="paymentType">
							<th mat-header-cell *matHeaderCellDef> Type </th>
							<td mat-cell *matCellDef="let receipt"> {{receipt.paymentType}} </td>
						</ng-container>
				
						<ng-container matColumnDef="status">
							<th mat-header-cell *matHeaderCellDef> Status </th>
							<td mat-cell *matCellDef="let receipt"> {{receipt.status}} </td>
						</ng-container>
				
						<ng-container matColumnDef="tipAmount" *ngIf="authenticationService.isRestaurant()">
							<th mat-header-cell *matHeaderCellDef> Tip Amount </th>
							<td class="tipAdjust" mat-cell *matCellDef="let receipt">
								<span class="tip-adjust-section">
									<input *ngIf="receipt.status !== 'SALE'" class="form-control readonly-field" currencyMask [(ngModel)]="receipt.tipAmount" readonly>
									<input (blur)="tipAdjust(receipt)" *ngIf="receipt.status === 'SALE' && receipt.paymentType === 'CREDIT_CARD'" class="form-control" currencyMask [(ngModel)]="receipt.tipAmount">
									<i (click)="tipAdjust(receipt)" *ngIf="receipt.status === 'SALE' && receipt.paymentType === 'CREDIT_CARD'" class="fa fa-check-square" style="color: green; cursor: pointer; padding-left: 2px;"></i>
								</span>
							</td>
						</ng-container>
				
						<ng-container matColumnDef="total">
							<th mat-header-cell *matHeaderCellDef> Auth Amount </th>
							<td mat-cell *matCellDef="let receipt"> {{receipt.total | currency}} </td>
						</ng-container>
				
						<ng-container matColumnDef="action">
							<th mat-header-cell *matHeaderCellDef> </th>
							<td class="action" mat-cell *matCellDef="let receipt">
								<i *ngIf="session.get('identity').role !== 'ROLE_MANAGER'" class="fa fa-print not-clicable"></i>
								<i *ngIf="session.get('identity').role === 'ROLE_MANAGER'" (click)="printReceipt(receipt)" class="fa fa-print"></i>
								<i (click)="goToLink(receipt)" class="fa fa-search"></i>
								<span style="width: 45px; height: 35px; display: inline-block;">
									<i (click)="deleteReceipt(receipt)" *ngIf="receipt.paymentType === 'CASH' && disableReceiptDelete.value === 'TRUE'" class="fa fa-trash-alt text-gray not-clicable"></i>
									<i (click)="deleteReceipt(receipt)" *ngIf="receipt.paymentType === 'CASH' && disableReceiptDelete.value === 'FALSE'" class="fa fa-trash-alt text-red"></i>
								</span>	
							</td>
						</ng-container>
				
						<tr mat-header-row *matHeaderRowDef="receiptSummaryColumns; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: receiptSummaryColumns;"></tr>
					</table>
				</div>
				<!-- List End Here -->
            </div>
        </div>
    </div>
</div>







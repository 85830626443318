<div class="header" *ngIf="toggleService.ShowActiveChairs === true">
	<div class="chair-selection">
		<div class="cart-icon" *ngIf="isRetail">
			<button (click)="orderMenu()" class="btn btn-40 btn-white color-orange btn-icon">
				<i class="fas fa-shopping-cart"></i>
				{{session.get('SELECTED_TABLE').userId === 'STATION-1' ? -1 : -2}}
			</button>
		</div>
		<div class="add-chair" *ngIf="!isRetail">
			<button (click)="addChairToExistingTable('additionalChair')" class="btn btn-40 btn-white btn-icon">
				<i class="fas fa-plus"></i>
			</button>
		</div>
		<div class="add-chair" *ngIf="!isRetail">
			<button (click)="switchTable()" class="btn btn-40 btn-white btn-icon">
				<img src="/assets/images/table-icon-gray.png" style="height: 19px;" />
			</button>
		</div>
		<div class="chair-slider">
			<div class="chair-container">
				<!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
					<div ngxSlickItem class="chair" *ngFor="let chair of customerService.ActiveChairs; let i = index;" [ngClass]="{'active': toggleService.SelectedSeat === i}">
						<button (click)="initializeOrder(chair, i)" class="btn btn-40 btn-white">
							{{chair?.name}}
						</button>
					</div>
				</ngx-slick-carousel> -->
				<span class="chair-left-shadow shadow-border"></span>
				<span class="chair-right-shadow shadow-border"></span>
				<drag-scroll [drag-scroll-y-disabled]="true">
					<div class="chair-list">
						<div class="chair" *ngFor="let chair of customerService.ActiveChairs; let i = index;" [ngClass]="{'active': toggleService.SelectedSeat === i}">
							<button (click)="initializeOrder(chair, i)" class="btn btn-40 btn-white">
								{{chair?.name}}
							</button>
						</div>
					</div>
				</drag-scroll>
			</div>
		</div>
	</div>
	<div class="payment-options">
		<div class="amount" *ngIf="!isRetail" style="cursor: pointer;" (click)="paymentOptions()">
			{{shoppingCartService.CustomerInvoiceAmount | currency}}
		</div>
		<div class="amount"  *ngIf="isRetail">
			{{shoppingCartService.CustomerInvoiceAmount | currency}}
		</div>
		<div class="options">
			<!-- <button class="btn btn-60 btn-white btn-image btn-credit-card" (click)="proceedToCheckout(activeChair, this.paymentService.CREDIT_CARD)">
				<img height="42" width="110" src="assets/images/credit-card-icon.png" />
			</button>
			<button class="btn btn-60 btn-white btn-image" *ngIf="!shoppingCartService.EbtCartItems" (click)="proceedToCheckout(activeChair, this.paymentService.CASH)">
				<img height="42" width="80" src="assets/images/cash_icon.png" />
			</button>
			<button class="btn btn-60 btn-white" *ngIf="shoppingCartService.EbtCartItems" (click)="paymentWarning()">
				<img height="42" width="80" src="assets/images/cash_icon.png" />
			</button>
			<button class="btn btn-60 btn-white" (click)="proceedToCheckout(activeChair, this.paymentService.OTHER)">
				OTHER
			</button>
			<button class="btn btn-60 btn-white" *ngIf="!shoppingCartService.EbtCartItems" (click)="proceedToCheckout(activeChair, this.paymentService.SPLIT_PAYMENT)">
				SPLIT
			</button>
			<button class="btn btn-60 btn-white" *ngIf="shoppingCartService.EbtCartItems" (click)="paymentWarning()">
				SPLIT
			</button> -->
			<button class="btn btn-60 btn-white" [disabled]="shoppingCartService.CustomerInvoiceAmount == 0" (click)="processToCheckout(activeChair)">
				<i class="fas fa-shopping-cart"></i> CHECKOUT
			</button>
		</div>
	</div>
	<div class="product-search" *ngIf="isRetail && isSerachShow">
		<form [formGroup]="itemSearchForm">
			<div class="search-field">
				<i class="fas fa-search"></i>
				<input (keyup.enter)="inputKeyUpEvent($event)" (blur)="itemBarcodeFocus()" #itemNameId type="text" [(ngModel)]="itemName" formControlName="products" placeholder="Click Here To Search Product" aria-label="Product" matInput [matAutocomplete]="auto">
				<mat-autocomplete (optionSelected)="selectedOption($event)" #auto="matAutocomplete" [displayWith]="displayFn" class="single-autocomplete">
					<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
						{{option.longName}}
					</mat-option>
				</mat-autocomplete>
				<input #itemBarcodeId (keyup.enter)="barcodeLookup($event.target.value)"
					   type="text" [(ngModel)]="itemBarcode"
					   size="15px"
					   style="height: 0px !important; font-size: 1px; opacity: 0; filter:alpha(opacity=0);"
					   formControlName="itemBarcode"
					   placeholder="Scan Product">
			</div>
		</form>
	</div>
</div>

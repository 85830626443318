import {Component, OnDestroy, OnInit} from '@angular/core';
import { Customer } from 'src/app/models/Customer';
import { User } from 'src/app/models/User';
import { Table } from 'src/app/models/Table';
import { CustomerService } from 'src/app/services/customer.service';
import { Role } from 'src/app/enums/Role';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Constants } from 'src/app/models/Constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { SessionStorageService } from 'angular-web-storage';
import { ToggleService } from 'src/app/services/toggle.service';
import { ActivatedRoute } from '@angular/router';
import {Subscription} from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnDestroy {
  private apiSubscription = new Subscription();
  private routeSubscription = new Subscription();
  public chairs: any;
  public tableFormData: Table;
  public organizationId: number;
  public showTableList = false;
  public showTableForm = false;
  public additionalTableChair = '';
  public firstChairOfSelectedTable: Customer;
  public page = '';

  columnDefs = [
    {
      headerName: 'Table Name',
      field: 'userId',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Table Prefix',
      field: 'firstName',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Password',
      field: 'pin',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Chairs',
      field: 'lastName',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Account Active',
      field: 'accountNonLocked',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: '',
      field: '',
      cellClass: 'ag-table-col-action',
      cellRenderer: this.actionsRenderer
    }
  ];
  public gridOptions: GridOptions;
  public rowSelection = 'single';

  rowData = [];

  constructor(public customerService: CustomerService, private authenticationService: AuthenticationService,
              private dialog: MatDialog, private session: SessionStorageService, 
              private route: ActivatedRoute, public toggleService: ToggleService, private _shared: SharedService) { }

  ngOnInit() {
    this.gridOptions = ({
      rowHeight: 40
    } as GridOptions);

    const paramSubscription = this.route.paramMap.subscribe(params => {
      this.page = this.route.snapshot.paramMap.get("page");
      if (this.page === 'additionalChair') {
        this.toggleService.ShowAdditionalTableChairForm = true;
        this.showTableForm = false;
        this.showTableList = false;
      } else {
        this.toggleService.ShowAdditionalTableChairForm = false;
        this.showTableForm = false;
        //this.showTableList = true;
      }
    });
    this.routeSubscription.add(paramSubscription);

    this.getTables('init');

    this.organizationId = this.authenticationService.getOrganizationId();
    this.tableFormData = new Table();

    this.chairs = [
      {"id" : 1, "name" : "1"},
      {"id" : 2, "name" : "2"},
      {"id" : 3, "name" : "3"},
      {"id" : 4, "name" : "4"},
      {"id" : 5, "name" : "5"},
      {"id" : 6, "name" : "6"},
      {"id" : 7, "name" : "7"},
      {"id" : 8, "name" : "8"},
      {"id" : 9, "name" : "9"},
      {"id" : 10, "name" : "10"}
    ];
  }

  onGridReady(params) {
    params.api.sizeColumnsToFit();
  }

  actionsRenderer(curVal: any): string {
    return `
        <i data-action-type="Edit" class="fas fa-pencil-alt"></i>
    `;
  }

  onRowClicked(e) {
    if (e.event.target !== undefined) {
        const actionType = e.event.target.getAttribute('data-action-type');
        switch (actionType) {
            case 'Edit':
                this.editTable(e.node.data);
                break;
        }
    }
  }

  saveNewTableChair() {
    const selectedTable = this.session.get(Constants.SELECTED_TABLE);
    const tableSubscription = this.customerService.getTableChairs(selectedTable.id, this.authenticationService.getOrganizationId()).subscribe((chrs) => {
      this.firstChairOfSelectedTable = new Customer();
      this.firstChairOfSelectedTable.id = chrs[0].id;
      this.firstChairOfSelectedTable.organizationId = chrs[0].organizationId;
      this.firstChairOfSelectedTable.pin = chrs[0].pin;
      this.firstChairOfSelectedTable.name = chrs[0].name;
      this.firstChairOfSelectedTable.user = chrs[0].user;

      const tablePrefix = this.firstChairOfSelectedTable.name.substring(0, this.firstChairOfSelectedTable.name.indexOf('-'));
      const additionalChairName = tablePrefix + '-' + this.additionalTableChair;

      const additionalChair = this.firstChairOfSelectedTable;
      additionalChair.id = 0;
      additionalChair.name = additionalChairName;
      additionalChair.user.middleName = (chrs.length + 1).toString();

      if (additionalChair.user.lastName) {
        additionalChair.user.lastName = additionalChair.user.lastName + ''; '' + additionalChairName;
      } else {
        let tableSummary = '';
        for (let i = 0; i < chrs.length; i++) {
          if (tableSummary.length > 0) {
            tableSummary = tableSummary + '; '
          }
          tableSummary = tableSummary + chrs[i].name;
        }
        additionalChair.user.lastName = tableSummary + '; ' + additionalChairName;
      }

      const addSubscription = this.customerService.saveAdditionalTableChair(additionalChair).subscribe((resp) => {
        const getSubscription = this.customerService.getActiveTableChairs(selectedTable.id, this.organizationId).subscribe((response) => {
          this.customerService.ActiveChairs = response;
        });
        this.apiSubscription.add(getSubscription);
      });
      this.apiSubscription.add(addSubscription);
    });
    this.apiSubscription.add(tableSubscription);
  }

  saveTableInformation() {
    const dt = new Date();
    const year = dt.getFullYear();
    const month = dt.getMonth();
    const day = dt.getDate();
    const date = new Date(year + 20, month, day);

    const tableUser = new User();
    tableUser.id = this.tableFormData.id;
    tableUser.organizationId = this.organizationId;
    tableUser.firstName = this.tableFormData.prefix;
    tableUser.middleName = this.tableFormData.numberOfChairs.toString();
    tableUser.role = Role.ROLE_TABLE;
    tableUser.pin = Constants.DEFAULT_TABLE_PIN;
    tableUser.userId = this.tableFormData.name;
    tableUser.accountExpiration = date;
    tableUser.credentialExpiration = date;
    tableUser.accountNonExpired = true;
    tableUser.accountNonLocked = true;
    tableUser.credentialsNonExpired = true;
    tableUser.enabled = true;

    const tableChairs = new Array<Customer>();

    for (let i:number = 1; i <= this.tableFormData.numberOfChairs; i++) {
      const tableChair = new Customer();
      tableChair.organizationId = this.organizationId;
      tableChair.name = this.tableFormData.prefix + '-' + i.toString();
      if (tableUser.lastName) {
        tableUser.lastName = tableUser.lastName + '; ';
      }
      tableUser.lastName = tableUser.lastName === undefined ? tableChair.name : tableUser.lastName + tableChair.name;
      tableChair.user = tableUser;
      tableChair.pin = tableUser.pin;
      tableChairs.push(tableChair);
    }
    this._shared.setGlobalLoader(true);
    const saveSubscription = this.customerService.saveTable(tableChairs).subscribe((tc) => {
      this.openDialog('Success', '[ ' + tableChairs.length + ' ] chairs created for table [ ' + tableUser.userId + ' ]');
      this._shared.setGlobalLoader(false);
    },
    (err) => {
      this.openDialog('ERROR', err.error);
      this._shared.setGlobalLoader(false);
    });
    this.apiSubscription.add(saveSubscription);
  }

  getTables(tState: string) {
    setTimeout(() => {
      this._shared.setGlobalLoader(true);
    }, 0);
    const getSubscription = this.customerService.getTables(this.authenticationService.getOrganizationId()).subscribe((tbls) => {
      this.customerService.Tables = tbls;
      this.rowData = tbls;
      this.showTableList = true;
      this._shared.setGlobalLoader(false);

      if (tState === 'init') {
        this.showTableList = true;
        this.showTableForm = false;
        this.toggleService.ShowAdditionalTableChairForm = false;
      }
      if (tState === 'tableList') {
        this.showTableList = true;
        this.showTableForm = false;
        this.toggleService.ShowAdditionalTableChairForm = false;
      }
    });
    this.apiSubscription.add(getSubscription);
    
  }

  editTable(table: User) {
    this.tableFormData = new Table();
    this.tableFormData.id = table.id;
    this.tableFormData.name = table.userId;
    this.tableFormData.prefix = table.firstName;
    this.tableFormData.organizationId = this.authenticationService.getOrganizationId();
    this.tableFormData.numberOfChairs = +table.middleName;
    if (this.tableFormData.numberOfChairs === 0) {
      this.tableFormData.numberOfChairs = 2;
    }
    this.showTableList = false;
    this.showTableForm = true;
    this.toggleService.ShowAdditionalTableChairForm = false;
  }

  addTable() {
    this.tableFormData = new Table();
    this.showTableForm = true;
    this.showTableList = false;
    this.toggleService.ShowAdditionalTableChairForm = false;
  }

  openDialog(msgHeader: string, msg: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {description: msgHeader, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
    this.apiSubscription.add(dialogSubscription);
  }

  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }
}

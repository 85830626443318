import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'angular-web-storage';

import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { ToggleService } from 'src/app/services/toggle.service';
import {CustomerService} from '../../services/customer.service';
import {User} from '../../models/User';
import {CategoryService} from '../../services/category.service';
import {ShoppingCartServiceService} from '../../services/shopping-cart-service.service';
import {MenuItemService} from '../../services/menu-item.service';
import {OrderService} from '../../services/order.service';
import {Constants} from '../../models/Constants';
import {Customer} from '../../models/Customer';
import {PaymentService} from '../../services/payment.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy, AfterViewInit {

  subscription = new Subscription();
  public selectedTableId: number;
  public version: any = Constants.SOFTWARE_VERSION;
  
  @Output() emittedCheckoutClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router, public session: SessionStorageService, private storage: LocalStorageService,
              public authenticationService: AuthenticationService, private organizationService: OrganizationService,
              public toggleService: ToggleService, private customerService: CustomerService, private paymentService: PaymentService,
              private categoryService: CategoryService, private shoppingCartService: ShoppingCartServiceService,
              private menuItemService: MenuItemService, private orderService: OrderService) { }


  ngOnInit() {
    if (this.authenticationService.isAuthenticated()) {
      const orgSubscription = this.organizationService.getOrganization(this.authenticationService.getOrganizationId()).subscribe((response) => {
          this.toggleService.DeliveryAvailable = response.deliveryAvailable;
          this.toggleService.PickupAvailable = response.pickupAvailable;
          this.toggleService.ShowFooterIcon = true;
          if (this.customerService.Tables.length === 0) {
            this.customerService.getTables(this.authenticationService.getOrganizationId()).subscribe(resp => {
              this.customerService.Tables = resp;
            });
          }
        },
        (error) => {
          console.log(error);
        });
      this.subscription.add(orgSubscription);
    }
  }

  ngAfterViewInit() {

  }

  home() {
    this.toggleService.ShowFooterIcon = true;
    this.router.navigate(['/home']);
  }

  logout() {
    this.toggleService.ShowFooterIcon = false;
    this.storage.remove(this.menuItemService.getMenuItemStorageKey());
    this.storage.remove(this.categoryService.getCategoryStorageKey());
    this.session.clear();
    this.router.navigate(['/']);
  }

  toggleOrganizationDeliveryAvailability() {
    const deliverySubscription = this.organizationService.toggleOrganizationDeliveryAvailability(this.authenticationService.getOrganizationId()).subscribe((response) => {
        this.toggleService.DeliveryAvailable = response.deliveryAvailable;
    },
    (error) => {
      console.log(error);
    });
    this.subscription.add(deliverySubscription);
  }

  toggleOrganizationPickupuAvailability() {
    const pickupSubscription = this.organizationService.toggleOrganizationPickupAvailability(this.authenticationService.getOrganizationId()).subscribe((response) => {
        this.toggleService.PickupAvailable = response.pickupAvailable;
    },
    (error) => {
      console.log(error);
    });
    this.subscription.add(pickupSubscription);
  }

  proceedToCheckout(chair: Customer, paymentType: string) {
    this.paymentService.PaymentConfirmation = false;
    this.paymentService.TenderedAmount = 0.00;
    this.toggleService.SetFocusOnTenderedAmount = true;
    this.paymentService.PaymentType = paymentType;
    this.paymentService.ActiveChair = this.session.get(Constants.ACTIVE_CHAIR);
    this.session.set(Constants.LAST_PAYMENT_TYPE_SELECTED, this.paymentService.PaymentType);
    this.shoppingCartService.checkOut(this.session.get(Constants.ACTIVE_CHAIR), paymentType);
    this.emittedCheckoutClick.emit({type: 'checkoutClicked', value: paymentType});
    this.router.navigate(['/checkout'], { queryParams: { payment: 'individual' } });
  }

  onTableSelected(event) {
    const value = event.target.value;
    const text = event.target.options[event.target.options.selectedIndex].text;
    const selectedTable = new User();
    selectedTable.id = value;
    selectedTable.userId = text;
    this.selectedTableId = selectedTable.id;
    if (this.selectedTableId > 0) {
      this.orderService.initializeTableOrderMenu(selectedTable);
    }
  }

  openCashDrawer() {
    const cashDrawerSubscription = this.paymentService.openCashDrawer(this.authenticationService.getOrganizationId()).subscribe(r => {});
    this.subscription.add(cashDrawerSubscription);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

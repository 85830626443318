import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, Input, AfterViewInit} from '@angular/core';
import { CustomerService} from 'src/app/services/customer.service';
import { Customer} from 'src/app/models/Customer';
import { SessionStorageService} from 'angular-web-storage';
import { CategoryService} from 'src/app/services/category.service';
import { ShoppingCartServiceService} from 'src/app/services/shopping-cart-service.service';
import { Router} from '@angular/router';
import { Constants} from 'src/app/models/Constants';
import { AuthenticationService} from 'src/app/services/authentication.service';
import { PaymentService} from 'src/app/services/payment.service';
import { ToggleService} from 'src/app/services/toggle.service';
import { MenuItemService} from '../../services/menu-item.service';
import { MenuItem} from '../../models/MenuItem';
import { from, Observable, Subscription} from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { FormBuilder, FormGroup} from '@angular/forms';
import { Category} from '../../models/Category';
import { OrderType} from '../../enums/OrderType';
import { CustomerDialogComponent} from './customer-dialog/customer-dialog.component';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { MessageDialogComponent} from '../message-dialog/message-dialog.component';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  public organizationId: number;
  public customerInvoiceAmount: number;
  public isRetail = false;
  public dataTokenizationKey: any;
  public activeChair: any;
  filteredOptions: Observable<Array<MenuItem>>;
  itemSearchForm: FormGroup;
  itemName: string;
  itemBarcode: string;
  apiSubscription = new Subscription();
  behaviorSubscription = new Subscription();

  @Output() emittedItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() emittedCheckoutClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() emittedFocusEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() emittedChairClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitSubMenuEvent: EventEmitter<any> = new EventEmitter<any>();
  // @Input() inputSearch: string;
  @ViewChild('itemBarcodeId') searchElement: ElementRef;
  @ViewChild('itemNameId') itemNameElement: ElementRef;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  @Input('isSerachShow') isSerachShow: boolean;

  // Slider Configuration
  slideConfig = {
    "infinite": false,
    "dots" : false,
    "slidesToShow": 4, 
    "slidesToScroll": 4,
    "adaptiveHeight": true,
    "arrows": true,
    "prevArrow":"<span class='prev-arrow'><i class='fas fa-chevron-left'></i></span>",
    "nextArrow":"<span class='next-arrow'><i class='fas fa-chevron-right'></i></span>",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor(public customerService: CustomerService, private _shared: SharedService, private categoryService: CategoryService, 
              public session: SessionStorageService, public shoppingCartService: ShoppingCartServiceService, private dialog: MatDialog,
              private router: Router, private authenticationService: AuthenticationService, private menuItemService: MenuItemService,
              public paymentService: PaymentService, public toggleService: ToggleService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    // Subscrbe Barcode Focus
    let barcodeFocusSubscriber = this._shared.barcodeFocusEvent.subscribe((event: any) => {
      if (event) {
        this.itemBarcodeFocus();
      }
    });
    this.apiSubscription.add(barcodeFocusSubscriber);

    if (this.session.get('orgType') === Constants.ORGANIZATION_TYPE_RETAIL) {
      this.isRetail = true;
    }
    const itemsSubscription = this.shoppingCartService.getObservableItems().subscribe(itms => {
      this.customerInvoiceAmount = this.shoppingCartService.getShoppingCartItemsTotal(itms);
      this.paymentService.InvoiceAmount = this.customerInvoiceAmount;
    });
    this.behaviorSubscription.add(itemsSubscription);
    this.organizationId = this.authenticationService.getOrganizationId();

    const tokenSubscription = this.paymentService.getDataTokenizationKey(this.organizationId).subscribe((data: any) => {
        if (data) {
            this.dataTokenizationKey = data.dataTokenizationKey.toString();
            const node = document.createElement('script');
            node.src = 'https://secure.networkmerchants.com/token/Collect.js';
            node.type = 'text/javascript';
            node.async = false;
            node.setAttribute('data-tokenization-key', this.dataTokenizationKey);
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    });
    this.apiSubscription.add(tokenSubscription);

    if (this.isRetail) {
      const menuSubscription = this.menuItemService.getMenuItems(this.authenticationService.getOrganizationId()).subscribe(mi => {
        this.menuItemService.SearchableMenuItems = mi;
      });
      this.apiSubscription.add(menuSubscription);

      this.itemSearchForm = this.formBuilder.group({
        products: [''],
        itemBarcode: ''
      });

      this.filteredOptions = this.itemSearchForm.get('products').valueChanges
          .pipe(
              startWith(''),
              map(longName => longName ? this._filter(longName) : this.menuItemService.SearchableMenuItems.slice())
          );
    }

    if (this.customerService.ActiveChairs && this.customerService.ActiveChairs.length === 0) {
      this.customerService.ActiveChairs = this.session.get(Constants.ACTIVE_TABLE_CHAIRS);
    }
  }

  ngAfterViewInit() {
    this.itemBarcodeFocus();
  }

  private _filter(value: string): Array<MenuItem> {
    let filterValue = value;
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    }
    return this.menuItemService.SearchableMenuItems.filter(option => option.longName.toLowerCase().indexOf(filterValue) >= 0);
  }

  nextChair() {
    this.slickModal.slickNext();
  }
  
  prevChair() {
    this.slickModal.slickPrev();
  }

  displayFn(itm?: MenuItem): string | undefined {
    return itm ? itm.longName : undefined;
  }

  orderMenu() {
    this.router.navigateByUrl('/retailorder?pageView=quickStart');
    this.itemBarcodeFocus();
  }

  paymentWarning() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: 'ERROR', message: 'This operation is not allowed with EBT'};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      this.toggleService.ShowPaymentProgress = false;
    });
    this.apiSubscription.add(dialogSubscription);
  }

  addChairToExistingTable(page: string) {
    const chair = new Customer();
    chair.organizationId = this.session.get(Constants.ACTIVE_CHAIR).organizationId;
    chair.orderStatus = OrderType.INACTIVE;
    chair.pin = '1234';
    chair.user = this.session.get(Constants.ACTIVE_CHAIR).user;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = true;
    dialogConfig.data = {message: chair};

    const dialogRef = this.dialog.open(CustomerDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        chair.user.lastName = chair.user.lastName + '; ' + chair.name;
        this._shared.setGlobalLoader(true);
        this.customerService.saveAdditionalTableChair(chair).subscribe(r => {
          this._shared.setGlobalLoader(false);
          this.customerService.ActiveChairs.push(r);
        }, error => { 
          this._shared.setGlobalLoader(false);
          alert ( '***** ERROR ***** [ ' + error.message + ' ]'); 
        });
      }
    });
    this.apiSubscription.add(dialogSubscription);
  }

  initializeOrder(chair: Customer, _index: number) {
    // let fromCustomerId = this.session.get(Constants.ACTIVE_CHAIR).id;
    // const toCustomerId = chair.id;
    // let listingEbtItems = false;
    //
    // if (chair.name === 'EBT-1') {
    //   listingEbtItems = true;
    //   fromCustomerId = this.getChairId('Scan-1');
    // } else if (chair.name === 'EBT-2') {
    //   listingEbtItems = true;
    //   fromCustomerId = this.getChairId('Scan-2');
    // } else if (chair.name === 'Scan-1') {
    //   listingEbtItems = false;
    //   fromCustomerId = this.getChairId('EBT-1');
    // } else if (chair.name === 'Scan-2') {
    //   listingEbtItems = false;
    //   fromCustomerId = this.getChairId('EBT-2');
    // }

    // if (this.isRetail) {
    //   const cartSubscription = this.shoppingCartService.moveEbtItems(this.organizationId, fromCustomerId, toCustomerId).subscribe(response => {
    //     this.emittedChairClick.emit({type: 'chairClicked', value: listingEbtItems ? 'EBT' : 'NON-EBT'});
    //     this.loadCustomerShoppingCart(chair, _index, listingEbtItems);
    //   }, (error) => {
    //     alert('ERROR moving EBT items from [' + this.getChairName(fromCustomerId) + '] to [' + this.getChairName(toCustomerId) + ']');
    //   });
    //   this.apiSubscription.add(cartSubscription);
    // } else {
      this.loadCustomerShoppingCart(chair, _index, false);
    // }

    setTimeout(() => {
      this.shoppingCartService.triggerCartRefresh(true);
    }, 1000); 
    
  }

  loadCustomerShoppingCart(chair: Customer, _index: number, isEBT: boolean) {
    this.toggleService.SelectedSeat = _index; // This is used by style in html to highlight the selected seat
    this.session.set(Constants.ACTIVE_CHAIR, chair);
    this.toggleService.ShowCartItemToEdit = false;
    this.toggleService.ShowItemWeightForm = false;
    this.getOrderParentCategories();

    this.shoppingCartService.updateObservableItems([]);
    this.shoppingCartService.getChairShoppingCart(this.session.get(Constants.ACTIVE_CHAIR), this.organizationId);
    this.customerInvoiceAmount = this.shoppingCartService.CustomerInvoiceAmount;
    this.paymentService.ActiveChair = this.session.get(Constants.ACTIVE_CHAIR);
    this.paymentService.PaymentType = this.session.get(Constants.LAST_PAYMENT_TYPE_SELECTED);

    this.shoppingCartService.DisplayTables = 'NO';
    this.toggleService.ShowParentCategories = true;
    this.shoppingCartService.EbtCartItems = isEBT;
    if (this.isRetail) {
      this.itemBarcodeFocus();
    }
  }

  getChairId(name: string) {
    const foundIndex = this.customerService.ActiveChairs.findIndex(c => c.name === name);
    return this.customerService.ActiveChairs[foundIndex].id;
  }

  getChairName(id: number) {
    const foundIndex = this.customerService.ActiveChairs.findIndex(c => c.id === id);
    return this.customerService.ActiveChairs[foundIndex].name;
  }

  getOrderParentCategories() {
    const catSubscription = this.categoryService.getParentCategories(this.organizationId).subscribe((cats) => {
      this.categoryService.ParentCategories = cats;
      this.getOrderSubCategories(cats[0].id, 0);
    });
    this.apiSubscription.add(catSubscription);
  }

  getOrderSubCategories(categoryId: number, _index: number) {
    this.toggleService.SelectedCategory = _index;
    const catSubscription = this.categoryService.getSubCategories(categoryId, this.authenticationService.getOrganizationId()).subscribe((response) => {
      this.toggleService.ShowCartItemToEdit = false;
      if (response[0].number) {
        this.menuItemService.OrderMenuItems = response;
        this.toggleService.ShowOrderMenuItems = true;
        this.toggleService.ShowSubCategories = false;
      } else {
        this.categoryService.SubCategories = response;
        this.toggleService.ShowSubCategories = true;
      }
    });
    this.apiSubscription.add(catSubscription);
  }

  proceedToCheckout(chair: Customer, paymentType: string) {
    this.paymentService.PaymentConfirmation = false;
    this.paymentService.TenderedAmount = 0.00;
    this.toggleService.SetFocusOnTenderedAmount = true;
    this.paymentService.PaymentType = paymentType;
    this.paymentService.ActiveChair = this.session.get(Constants.ACTIVE_CHAIR);
    this.session.set(Constants.LAST_PAYMENT_TYPE_SELECTED, this.paymentService.PaymentType);
    this.shoppingCartService.checkOut(this.session.get(Constants.ACTIVE_CHAIR), paymentType);
    this.emittedCheckoutClick.emit({type: 'checkoutClicked', value: paymentType});
    this.router.navigate(['/checkout'], { queryParams: { payment: 'individual' } });
  }

  processToCheckout(chair: Customer) {
    this.paymentService.PaymentConfirmation = false;
    this.paymentService.TenderedAmount = 0.00;
    this.router.navigate(['/checkout'], { queryParams: { payment: 'individual' } });
  }

  public loadScript() {
      ( window as any).CollectJS.startPaymentRequest();
      ( window as any).CollectJS.configure({
          variant: 'lightbox',
          callback: (token) => {
              if (token) {
                  // this.checkoutLoading = true;
                  const tokenSubscription = this.paymentService.sendToken(token, this.customerInvoiceAmount, this.organizationId).subscribe((response) => {
                      if (response.response === 1 || response.responseText === 'APPROVED' || response.responseText === 'SUCCESS') {
                          this.router.navigate(['/restaurantorder'], { queryParams: { pageView: 'quickStart' } });
                      }
                  });
                  this.apiSubscription.add(tokenSubscription);
              }
          }
      });
  }

  paymentOptions() {
    this.router.navigateByUrl('/paymentoptions');
  }

  selectedOption(event) {
    const selectedValue = event.option.value;
    this.emittedItem.emit({type: 'ItemSelected', value: selectedValue});
    this.itemSearchForm.reset();
    this.itemBarcodeFocus();
  }

  itemBarcodeFocus() {
    if (this.searchElement) {
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
  }

  home() {
    this.router.navigate(['/home']);
  }

  barcodeLookup(data: string) {
    if (data.length < 1) {
      return;
    }
    const menuSubscription = this.menuItemService.getMenuItem(this.authenticationService.getOrganizationId(), data).subscribe(i => {
        this.emittedItem.emit({type: 'ItemSelected', value: i});
      },
  (error) => {
          // alert('********* Product not found *********');
          const catSubscription = this.categoryService.getParentCategories(this.authenticationService.getOrganizationId()).subscribe((cats) => {
            this.menuItemService.NewMenuItem = new MenuItem();
            this.menuItemService.NewMenuItem.category = new Category();
            this.menuItemService.NewMenuItem.price = 0.00;
            this.menuItemService.NewMenuItem.taxRate = 6.0;
            this.menuItemService.NewMenuItem.stock = 5;
            this.menuItemService.NewMenuItem.name = 'Grocery Item';
            this.menuItemService.NewMenuItem.category.id = this.categoryService.ParentCategories[0].id;
            this.menuItemService.NewMenuItem.barcode = data;
            this.menuItemService.NewMenuItem.ebtEligible = true;
            this.menuItemService.NewMenuItem.taxRate = 3;
            this.menuItemService.NewMenuItem.pricedByWeight = false;

            this.categoryService.ParentCategories = cats;
            this.shoppingCartService.DisplayShoppingCart = false;
            this.toggleService.ShowCartItemToEdit = false;
            this.toggleService.ShowItemWeightForm = false;
            this.toggleService.ShowOrderMenuItems = false;
            this.toggleService.ShowNewItemForm = true;
            this.emittedFocusEvent.emit({type: 'BarcodeScan', value: 'Item Not Found'});
          });
          this.apiSubscription.add(catSubscription);
    });
    this.apiSubscription.add(menuSubscription);
    this.itemSearchForm.reset();
    this.itemBarcodeFocus();
  }

  inputKeyUpEvent(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.barcodeLookup(this.itemNameElement.nativeElement.value);
    }
  }

  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
    this.behaviorSubscription.unsubscribe();
  }

  switchTable() {
    this.router.navigateByUrl('/restaurantorder');
    setTimeout(() => {
      window.location.reload();
    }, 0);
  }
}

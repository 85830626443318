import {AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import {MenuItemService} from 'src/app/services/menu-item.service';
import {MenuItem} from 'src/app/models/MenuItem';
import {CategoryService} from 'src/app/services/category.service';
import {Category} from 'src/app/models/Category';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {FileUploadService} from 'src/app/services/file-upload.service';
import {Constants} from '../../models/Constants';
import {Router} from '@angular/router';
import {MessageDialogConfirmationComponent} from '../message-dialog-confirmation/message-dialog-confirmation.component';
import {Subscription} from 'rxjs';
import {ImageRendererComponent} from '../image-renderer/image-renderer.component';
import {GridOptions} from 'ag-grid-community';
import { FormGroup, FormBuilder } from '@angular/forms';
import {BarcodeLabelDialogComponent} from 'src/app/components/inventory/message-dialog/barcode-label/barcode-label-dialog.component';
import {OrganizationService} from '../../services/organization.service';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { SharedService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MenuItemComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('barcodeInput') barcodeElement: ElementRef;

    // Show Hide Booleans Flag
    public showMenuItemForm = false;
    public showMenuItemList = false;
    public searchByBarcode = false;
    public displayItem = false;
    public showFullForm = false;


    
    // Added New properties
    public itemSearchForm: FormGroup;
    public menuItemFormData: MenuItem;
    public trueFalseArray: any;
    public categories: Array<Category> = [];
    public organizationId: number;
    public isRetail: boolean = false;

    showSpinner = false;
    selectedFiles: FileList;
    currentFileUpload: File;
    progress: { percentage: number } = {percentage: 0};

    public rowSelection = 'single';
    public rowData = [];
    public frameworkComponents = {
        imageDisplayComponent: ImageRendererComponent
    };
    public url: string = Constants.ERESTAU_DOMAIN + '/public/api/image/resize?org=' + this.authenticationService.getOrganizationId() + '&width=' + Constants.MENU_IMAGE_WIDTH + '&height=' + Constants.MENU_IMAGE_HEIGHT + '&imageName=';
    public columnDefs = [
        {
            headerName: '',
            field: 'imageUrl',
            cellClass: 'product-table-col-image',
            cellRenderer: 'imageDisplayComponent',
            cellRendererParams: {
                url: this.url
            }
        },
        {
            headerName: 'No.',
            field: 'number',
            filter: 'agTextColumnFilter',
            cellClass: 'product-table-col',
            width: 120
        },
        {
            field: 'name',
            sortable: true,
            filter: 'agTextColumnFilter',
            cellClass: 'product-table-col',
            cellStyle: {
                cursor: 'pointer'
            },
            width: 400,
        },
        {
            headerName: 'Category',
            field: 'category.name',
            filter: 'agTextColumnFilter',
            cellClass: 'product-table-col',
            width: 300
        },
        {
            headerName: 'EBT',
            field: 'ebtEligible',
            filter: 'agTextColumnFilter',
            cellClass: 'product-table-col',
            width: 120
        },
        {
            headerName: 'Tax%',
            field: 'taxRate',
            filter: 'agNumberColumnFilter',
            cellClass: 'product-table-col',
            width: 120
        },
        {
            field: 'price',
            filter: 'agNumberColumnFilter',
            cellClass: 'product-table-col',
            width: 120,
            valueFormatter: params => this.currencyFormatter(params.value)
        },
        {
            headerName: '',
            field: '',
            cellClass: 'product-table-col-action',
            width: 300,
            cellRenderer: this.actionsRenderer
        },
        {
            field: 'barcode',
            hide: true
        }
    ];
    public gridOptions: GridOptions;
    private apiSubscription = new Subscription();

    

    constructor(private menuItemService: MenuItemService, private categoryService: CategoryService,
                private dialog: MatDialog, private authenticationService: AuthenticationService,
                private fileUploadService: FileUploadService, private router: Router, 
                private formBuilder: FormBuilder, private organizationService: OrganizationService,
                private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private _shared: SharedService) {
    }

    ngOnInit() {
        // Get ORG
        const orgSubscription = this.organizationService.getOrganization(this.authenticationService.getOrganizationId()).subscribe((org) => {
            if (org.retail === true) {
                this.isRetail = true;
            } else {
                this.isRetail = false;
                this.showProductList();
            }
        },
        (error) => {
            console.log(error);
        });
        this.apiSubscription.add(orgSubscription);

        this.gridOptions = ({
            rowHeight: 75 
        } as GridOptions);

        this.itemSearchForm = this.formBuilder.group({
            itemBarcode: ''
        });
        this.showProductList();
    }

    currencyFormatter(value) {
       return this.currencyPipe.transform(value,'$');
    }

    barcodeInputFocus() {
        setTimeout(() => {
            this.barcodeElement.nativeElement.focus();
        },0);
    }

    barcodeLookup(data: string) {
        this.showMenuItemForm = false;
        this._shared.setGlobalLoader(true);
        const menuSubscription = this.menuItemService.getMenuItem(this.authenticationService.getOrganizationId(), data).subscribe((item) => {
            this.menuItemFormData = item;
            if (!this.menuItemFormData.stock) {
              this.menuItemFormData.stock = 1;
            } else {
              this.menuItemFormData.stock++;
            }
            const items = this.menuItemService.getMenuItemsFromLocalStorage();
            const foundIndex = items.findIndex(itm => itm.id === this.menuItemFormData.id);
            items[foundIndex] = this.menuItemFormData;
            this.menuItemService.storeMenuItemsInLocalStorage(items);
            this.displayItem = true;
            this._shared.setGlobalLoader(false);
          },
           (error) => {
              this.menuItemFormData = new MenuItem();
              this.menuItemFormData.organizationId = this.authenticationService.getOrganizationId();
              this.menuItemFormData.barcode = data;
              this.menuItemFormData.pricedByWeight = false;
              this.menuItemFormData.fulfillmentTime = 45.00;
              this.menuItemFormData.activeOnline = true;
              this.displayItem = false;
              
              // Load Add Form
              this.searchByBarcode = true;
              this.showMenuItemForm = true;
              this.showMenuItemList = false;
              this.menuItemFormData = new MenuItem();
              this.menuItemFormData.category = new Category();
              this._shared.setGlobalLoader(false);
        });
        this.apiSubscription.add(menuSubscription);
        this.itemSearchForm.reset();
      }

    openPrintDialog(menuItem: MenuItem): void {
        this.menuItemService.LabelItemName = menuItem.name;
        if (menuItem.pricedByWeight === true) {
            this.menuItemService.WeightedItem = true;
        }
        const dialogRef = this.dialog.open(BarcodeLabelDialogComponent,{
            width: '750px',
            disableClose: true
        });

        const dialogSubscription = dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
            menuItem.barcodePrintQuantity = this.menuItemService.LabelQuantity;
            menuItem.barcodeLabelSize = this.menuItemService.LabelSize;
            menuItem.weight = this.menuItemService.CapturedWeight;
                this._shared.setGlobalLoader(true);
                const labelSubscription = this.menuItemService.printBarcodeLabel(menuItem).subscribe((response) => {
                    const foundIndex = this.menuItemService.MenuItems.findIndex(mi => Number(mi.id) === Number(menuItem.id));
                    if (foundIndex >= 0) {
                        this.menuItemService.MenuItems[foundIndex].barcode = response.barcode;
                    }
                    this.menuItemService.LabelQuantity = 1;
                    this._shared.setGlobalLoader(false);
                }, (error) => {
                    this._shared.setGlobalLoader(false);
                    alert(error);
                });
                this.apiSubscription.add(labelSubscription);
            }
            this.menuItemService.WeightedItem = false;
        });
        this.apiSubscription.add(dialogSubscription);
    }

    incrementItemCount(barcode) {
        this.menuItemFormData.stock++;
    }

    decrementItemCount(barcode) {
        this.menuItemFormData.stock--;
    }

    updateMenuItemCount(menuItem: MenuItem) {
        this._shared.setGlobalLoader(true);
        const menuSubscription = this.menuItemService.updateMenuItemCount(menuItem).subscribe((response) => {
            this.menuItemFormData = null;
            this.displayItem = false;
            this.barcodeInputFocus();
            this._shared.setGlobalLoader(false);
        });
        this.apiSubscription.add(menuSubscription);
    }

    showBarcodeScan() {
        this.searchByBarcode = true;
        this.showMenuItemForm = false;
        this.showMenuItemList = false;
        this.barcodeInputFocus();
    }

    showProductList() {
        this.searchByBarcode = false;
        this.showSpinner = true;
        this.getMenuItems()
    }

    addMenuItem() {
        this.searchByBarcode = false;
        this.showFullForm = false;
        this.showMenuItemForm = true;
        this.showMenuItemList = false;
        this.menuItemFormData = new MenuItem();
        this.menuItemFormData.category = new Category();
        this.menuItemFormData.stock = 5;
        this.menuItemFormData.pricedByWeight = false;
        this.menuItemFormData.ebtEligible = true;
        this.menuItemFormData.active = true;
        this.menuItemFormData.activeDigiMenu = true;
        this.menuItemFormData.activeOnline = true;
        this.menuItemFormData.fulfillmentTime = 45;
    }

    toggleForm() {
        this.showFullForm = !this.showFullForm;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.organizationId = this.authenticationService.getOrganizationId();
            this.menuItemFormData = new MenuItem();
            this.menuItemFormData.category = new Category();
            this.menuItemFormData.organizationId = this.organizationId;
            this.trueFalseArray = [
                {id: 'true', name: 'True'},
                {id: 'false', name: 'False'}
            ];
            this.getCategories();
        });
    }

    actionsRenderer(curVal: any): string {
        return `
            <i data-action-type="Print" class="fa fa-print"></i>
            <i data-action-type="Edit" class="fas fa-pencil-alt"></i>
            <i data-action-type="Delete" class="far fa-trash-alt text-light-red"></i>
        `;
    }

    linkRenderer(curVal: any): string {
        return `
                <a (click)="google.com" ">curVal.value</a>
        `;
    }

    public handleAction(e) {
        if (e.event.target !== undefined) {
            const actionType = e.event.target.getAttribute('data-action-type');
            switch (actionType) {
                case 'Edit':
                    this.editMenuItem(e.node.data);
                    break;
                case 'Delete':
                    this.deleteMenuItem(e.node.data);
                    break;
                case 'Print':
                    this.openPrintDialog(e.node.data);
                    break;
            }
        }
    }

    applyFilter(filterValue: string) {
        this.gridOptions.api.setQuickFilter(filterValue);
    }

    home() {
        this.router.navigate(['/home']);
    }

    saveMenuItem() {
        if (this.selectedFiles) {
            this.currentFileUpload = this.selectedFiles.item(0);
        }

        this.menuItemFormData.sortText = this.menuItemFormData.name;
        this.menuItemFormData.organizationId = this.authenticationService.getOrganizationId();
        if (this.menuItemFormData.category.id) {
            const foundIndex = this.categories.findIndex(c => Number(c.id) === Number(this.menuItemFormData.category.id));
            if (foundIndex >= 0) {
                this.menuItemFormData.category.name = this.categories[foundIndex].name;
            }
            this.menuItemFormData.category.organizationId = this.menuItemFormData.organizationId;
        }

        this._shared.setGlobalLoader(true);
        const menuSubscription = this.menuItemService.saveMenuItem(this.menuItemFormData, this.currentFileUpload).subscribe((menu) => {
                this.menuItemService.updateLocalStorage(menu);
                this.showProductList();
                this.openDialog('SUCCESS', 'Menu Item [ ' + menu.number + ' - ' + menu.name + ' ] saved');
                this._shared.setGlobalLoader(false);
            },
            (err) => {
                this._shared.setGlobalLoader(false);
                this.openDialog('ERROR', err.error);
            });
        this.apiSubscription.add(menuSubscription);
    }

    getCategories() {
        this._shared.setGlobalLoader(true);
        const catSubscription = this.categoryService.getCategories(this.organizationId).subscribe((cats) => {
            this.categories = cats;
            this._shared.setGlobalLoader(false);
        });
        this.apiSubscription.add(catSubscription);
    }

    openDialog(msgHeader: string, msg: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '500px';
        dialogConfig.height = '270px';
        dialogConfig.data = {description: msgHeader, message: msg};

        const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

        const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
        });
        this.apiSubscription.add(dialogSubscription);
    }

    getMenuItems() {
        this._shared.setGlobalLoader(true);
        const itemSubscription = this.menuItemService.getProducts.subscribe((itms) => {
            this.menuItemService.MenuItems = itms;
            this.rowData = itms;
            this.showMenuItemForm = false;
            this.showMenuItemList = true;
            this.showSpinner = false;
            this._shared.setGlobalLoader(false);
        });
        this.apiSubscription.add(itemSubscription);
        this.menuItemService.updateMenuItems(this.authenticationService.getOrganizationId());
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    editMenuItem(menuItem: MenuItem) {
        this.showMenuItemForm = true;
        this.showMenuItemList = false;
        this.menuItemFormData = menuItem;
    }

    deleteMenuItem(menuItem: MenuItem) {
        this.openConfirmActionDialog('DELETE', menuItem.name + ' - $' + menuItem.price, menuItem.id);
    }

    upload() {
        this.progress.percentage = 0;
        this.currentFileUpload = this.selectedFiles.item(0);
        this._shared.setGlobalLoader(true);
        const fileSubscription = this.fileUploadService.saveFile(this.currentFileUpload).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
                this.progress.percentage = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
                alert('File Successfully Uploaded');
            }
            this.selectedFiles = undefined;
            this._shared.setGlobalLoader(false);
        });
        this.apiSubscription.add(fileSubscription);
    }

    selectFile(event) {
        this.selectedFiles = event.target.files;
    }

    onNameClicked(e) {
        if (e.column.colId === 'name') {
            this.menuItemService.saveSelectedItem(e.node.data);
            this.router.navigate(['/menuItemDetails']);
        }
    }

    openConfirmActionDialog(msgHeader: string, msg: string, id: number): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {description: msgHeader, message: msg};
        dialogConfig.width = '640px';
        dialogConfig.height = '350px';

        const dialogRef = this.dialog.open(MessageDialogConfirmationComponent, dialogConfig);

        const dialogSubscription = dialogRef.afterClosed().subscribe(confirmation => {
            if (confirmation === true) {
                this._shared.setGlobalLoader(true);
                const delSubscription = this.menuItemService.deleteMenuItem(id).subscribe(response => {
                    if (response.message.startsWith('Item present')) {
                        this._shared.setGlobalLoader(false);
                        this.openDialog('ERROR', response.message);
                    } else {
                        this._shared.setGlobalLoader(false);
                        const searchableFoundIndex = this.menuItemService.SearchableMenuItems.findIndex(itm => itm.id === id);
                        this.menuItemService.SearchableMenuItems.splice(searchableFoundIndex, 1);
                        const items = this.menuItemService.getMenuItemsFromLocalStorage();
                        const foundIndex = items.findIndex(itm => itm.id === id);
                        items.splice(foundIndex, 1);
                        this.menuItemService.storeMenuItemsInLocalStorage(items);
                        this.getMenuItems();
                    }
                }, (error) => {
                    this._shared.setGlobalLoader(false);
                    this.openDialog('ERROR', error.message);
                });
                this.apiSubscription.add(delSubscription);
            } else {
                console.log('CANCEL');
            }
        });
        this.apiSubscription.add(dialogSubscription);
    }

    ngOnDestroy() {
        this.apiSubscription.unsubscribe();
    }
}

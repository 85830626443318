<div style="text-align: center; font-weight: bold; font-size: 1.5em;">Send Receipt {{message}}</div>
<br/><br/>
<mat-dialog-content>
    <div *ngIf="message === 'SMS'">
        <span style="padding-right: 4px; font-weight: bold; font-size: 2em; color: #000000;">Phone:</span>
        <input size="35" type="text" name="phone" [(ngModel)]="paymentService.CustomerPhoneNumber" style="font-size: 1.5em; font-weight: bold;"/>
    </div>
    <div *ngIf="message === 'EMAIL'">
        <span style="padding-right: 4px; font-weight: bold; font-size: 2em; color: #000000;">Email:</span>
        <input size="38" type="text" name="email" [(ngModel)]="paymentService.CustomerEmail" style="font-size: 1.5em; font-weight: bold;"/>
    </div>
    <br/><br/><br/>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="onDismiss()" mat-button><span class="cancel-button">Cancel</span></button>
    <button (click)="onConfirm()" mat-button><span class="print-button">Send</span></button>
</mat-dialog-actions>

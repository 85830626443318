<div id="main">
    <br />
    <div style="margin-left: 100px;">
        <i class="fa fa-chart-line fa-5x" style="color: cornflowerblue;"></i> <span style="color: #265656; font-weight: bold; font-size: 3em;"> INVENTORY </span>
        <i (click)="lookupItemByBarcode()" class="fa fa-barcode fa-5x" style="margin-left: 200px; color: cornflowerblue; cursor: pointer;"></i> <span (click)="lookupItemByBarcode()" style="color: #265656; cursor: pointer; font-weight: bold; font-size: 1.6em;"> Barcode </span>
        <i (click)="lookupItemByName()" class="fa fa-list-alt fa-5x" style="margin-left: 50px; color: cornflowerblue; cursor: pointer;"></i> <span (click)="lookupItemByName()" style="color: #265656; cursor: pointer; font-weight: bold; font-size: 1.6em;"> Product List </span>
        <i (click)="initAddProduct()" class="fa fa-plus-square fa-5x" style="margin-left: 50px; color: cornflowerblue; cursor: pointer;"></i> <span (click)="initAddProduct()" style="color: #265656; cursor: pointer; font-weight: bold; font-size: 1.6em;"> Add Product </span>
    </div>
    <HR />
    <span class="error">
	</span>
    <!-- --------------------------------- BARCODE SCAN FORM BEGINS ---------------------- -->
    <table width="100%" *ngIf="searchByBarcode">
        <tr>
            <td style="font-size: 3em; font-weight: bold;">Scan: </td>
            <td style="padding-left: 1px;">
                <form [formGroup]="itemSearchForm">
                    <input #barcodeInput (keyup.enter)="barcodeLookup($event.target.value)" name="scanner" size="50"
                           formControlName="itemBarcode"
                           style="margin-top: 23px; border: 2px solid #000000; border-radius: 5px; width: 75%; font-weight: bold; font-size: 1.8em;"/>
                </form>
            </td>
            <td width="55%">
                <div *ngIf="displayItem"
                     style="margin-top:20px; border: 5px solid #dcdcdc; width: 100%; margin-right: 0.4em; float: right;"
                     class="tbl">
                    <div style="height: 3em;" class="tblrowcart">
                        <div class="widthsize2em">
                            <span class="selected" style="width: 4em;">{{menuItem.stock}}</span>
                        </div>
                        <div style="padding-left: 7px;" class="widthsize12em">
                            <a style="font-size: 1.5em;" class="easymenu"
                               href="javascript:;" (click)="editMenuItem(menuItem, false)">{{menuItem.name}}</a>
                            <br />
                            <span style="margin-bottom: 0.5em; font-size: 0.9em;"></span>
                        </div>
                        <div style="padding-left:7px; font-weight: bold;" class="widthsize2em">
                            {{menuItem.price | currency}}
                            <br />
                            <span style="margin-bottom: 0.5em; font-size: 0.9em;"></span>
                        </div>
                        <div style="text-align: center;" class="widthsize2n5em">
                            <a href="javascript:;" (click)="openPrintDialog(menuItem)"
                               style="border: 0px; padding: 10px 20px;" class="buttontrans">
                                <i  class="fa fa-print fa-3x" style="color: cornflowerblue; cursor: pointer;"></i>
                            </a>
                        </div>
                        <div style="text-align: center;" class="widthsize2n5em">
                            <a href="javascript:;" (click)="incrementItemCount(menuItem.barcode)"
                               style="border: 0px; padding: 10px 20px;" class="buttontrans">
                                <i class="fas fa-plus-circle fa-3x" style="color: #0080ff;"></i>
                            </a>
                        </div>
                        <div style="text-align: center;" class="widthsize2n5em">
                            <a href="javascript:;" (click)="decrementItemCount(menuItem.barcode)"
                               style="border: 0px; padding: 10px 20px;" class="buttontrans">
                                <i class="fas fa-minus-circle fa-3x" style="color: red;"></i>
                            </a>
                        </div>
                        <div style="text-align: center;" class="widthsize2n5em">
                            <a href="javascript:;" (click)="updateMenuItemCount(menuItem)"
                               style="border: 0px; padding: 10px 20px;" class="buttontrans">
                                <i class="fas fa-check-circle fa-3x" style="color: green;"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </table>
    <!-- -------------------- BARCODE SCAN FORM ENDS-------------------------- -->

    <!-- -------------------- ITEM ADD FORM STARTS --------------------- -->
    <div *ngIf="showItemAddForm">
        <br/>
        <div style="margin-left: 10%; margin-top: 30px;"><span style="color: #0080ff; font-weight: bold; font-size: 2em;"> CREATE NEW ITEM </span></div><br/>
        <form #menuItemForm="ngForm" (ngSubmit) ="addNewItem()">
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Item Name: </span>
            </label>
            <input type="text" name="name" size="50" [(ngModel)]="menuItem.name" style="width: 18em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Price: </span>
            </label>
            <input #itemPriceInput type="text" name="price" currencyMask [(ngModel)]="menuItem.price" style="width: 5em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">EBT? </span>
            </label>
            <div>
                <div style="display: flex; justify-content: start; margin-left:20px; text-align: center; margin-left: 8px; font-weight: bold; font-size: 4em; color: #000000;">
                    <div style="padding-left: 12px;">
                        <input type="radio" [(ngModel)]="menuItem.ebtEligible" class="large-radio" id="ebt" name="ebtEligible" [value]=true>
                    </div>
                    <div><span style="font-weight: bold; font-size: 2em; padding-left: 4px;">Yes</span></div>
                    <div style="padding-left: 60px;">
                        <input type="radio" [(ngModel)]="menuItem.ebtEligible" class="large-radio" id="nonEbt" name="ebtEligible" [value]=false>
                    </div>
                    <div><span style="font-weight: bold; font-size: 2em; padding-left: 4px;">No</span></div>
                </div>
            </div>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Tax Rate: </span>
            </label>
            <input type="text" name="taxRate" [(ngModel)]="menuItem.taxRate" style="width: 5em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/><span style="font-size: 3em;">%</span>
            <br />
            <br />
            <label style="width: 14em;">
                <span style="font-size: 1.5em; font-weight: bold;">Priced By Weight? </span>
            </label>
            <div>
                <div style="display: flex; justify-content: start; margin-left:20px; text-align: center; margin-left: 8px; font-weight: bold; font-size: 4em; color: #000000;">
                    <div style="padding-left: 12px;">
                        <input type="radio" [(ngModel)]="menuItem.pricedByWeight" class="large-radio" id="pricedByWeight" name="pricedByWeight" [value]=true>
                    </div>
                    <div><span style="font-weight: bold; font-size: 2em; padding-left: 4px;">Yes</span></div>
                    <div style="padding-left: 60px;">
                        <input type="radio" [(ngModel)]="menuItem.pricedByWeight" class="large-radio" id="notPricedByWeight" name="pricedByWeight" [value]=false>
                    </div>
                    <div><span style="font-weight: bold; font-size: 2em; padding-left: 4px;">No</span></div>
                </div>
            </div>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Category: </span>
            </label>
            <select [(ngModel)]="menuItem.category.id" id="iCategory" name="category">
                <option *ngFor="let cat of categoryService.ParentCategories; let i = index" [value]="cat.id">{{cat.name}}</option>
            </select>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Quantity: </span>
            </label>
            <input type="text" name="stock" [(ngModel)]="menuItem.stock" style="width: 5em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Image: </span>
            </label>
            <input type="file" size="60" name="imageUrl" (change)="selectFile($event)" style="border-radius: 5px;"/>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Barcode: </span>
            </label>
            <input type="text" name="barcode" size="50" [(ngModel)]="menuItem.barcode" style="width: 18em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/>
            <br />
            <br />
            <div style="margin-top: 15px; margin-left: 10%;">
                <input value="SAVE" name="save" type="submit" style="cursor: pointer; width: 165px; border-radius: 5px; color: white !important; background-color: #4c9909;" class="submit" />
                <input (click)="cancelAdd()" value="CANCEL" name="save" type="button" style="cursor: pointer; margin-left:40px; width: 165px; border-radius: 5px; color: white !important; background-color: red;" class="submit" />
            </div>
        </form>
    </div>
    <!-- -------------------- ITEM ADD FORM ENDS ----------------------- -->

    <!-- -------------------- ITEM EDIT FORM STARTS --------------------- -->
    <div *ngIf="showItemEditForm">
        <br/>
        <div style="margin-left: 10%; margin-top: 30px;"><span style="color: #0080ff; font-weight: bold; font-size: 2em;"> EDIT ITEM </span><span style="margin-left: 5%;"><img [src]="url+menuItem.imageFilename"/></span></div><br/>
        <form #menuItemForm="ngForm" (ngSubmit) ="saveEditedMenuItem()">
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Item Number: </span>
            </label>
            <input type="text" name="name" size="50" [(ngModel)]="menuItem.number" style="width: 18em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Item Name: </span>
            </label>
            <input type="text" name="name" size="50" [(ngModel)]="menuItem.name" style="width: 18em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Price: </span>
            </label>
            <input #priceEditInput type="text" name="price" currencyMask [(ngModel)]="menuItem.price" style="width: 5em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Quantity: </span>
            </label>
            <input type="text" name="stock" [(ngModel)]="menuItem.stock" style="width: 5em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Category: </span>
            </label>
            <select id="category" name="category" [(ngModel)]="menuItem.category.id" style="width: 12em;">
                <option *ngFor="let cat of categories; let i = index" [value]="cat.id">{{cat.name}}</option>
            </select>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Image: </span>
            </label>
            <input type="file" size="60" name="imageUrl" (change)="selectFile($event)" style="border-radius: 5px;"/>
            <br />
            <br />
            <label>
                <span style="font-size: 1.5em; font-weight: bold;">Barcode: </span>
            </label>
            <input type="text" name="barcode" size="50" [(ngModel)]="menuItem.barcode" style="width: 18em; height:2em; font-size: 2em; font-weight: bold; border-radius: 5px;"/>
            <br />
            <br />
            <div style="margin-top: 15px; margin-left: 10%;">
                <input value="SAVE" name="save" type="submit" style="cursor: pointer; width: 165px; border-radius: 5px; color: white !important; background-color: #4c9909;" class="submit" />
                <input (click)="cancelEdit()" value="CANCEL" name="save" type="button" style="cursor: pointer; margin-left:40px; width: 165px; border-radius: 5px; color: white !important; background-color: red;" class="submit" />
            </div>
        </form>
    </div>
    <!-- -------------------- ITEM EDIT FORM ENDS ----------------------- -->

    <!-- -------------------- START List Menu Items -------------------- -->
    <div class="menu-item-container mat-elevation-z8" *ngIf="searchByItemName">
        <div class="search-header">
            <mat-form-field appearance="outline" style="width: 400px;">
                <input #nameSearchInput matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" style="font-size: 14px; font-weight: bold; border-radius: 5px; border-bottom-width: 1px;">
            </mat-form-field>
        </div>
        <div *ngIf="showSpinner">
            <app-spinner>loading.......</app-spinner>
        </div>
<!--    </div>-->
    <ag-grid-angular
            (firstDataRendered)="onFirstDataRendered($event)"
            (rowClicked)="handleAction($event)"
            (cellClicked)="onNameClicked($event)"
            [columnDefs]="columnDefs"
            [frameworkComponents]="frameworkComponents"
            [gridOptions]="gridOptions"
            [rowData]="rowData"
            [rowSelection]="rowSelection"
            class="ag-theme-alpine menu-table"
    >
    </ag-grid-angular>
    <!-- -------------------- END List Menu Items -------------------- -->
</div>

import { User } from './User';
import { OrderType } from '../enums/OrderType';

export class Customer {
    id: number;
    pin: string;
    name: string;
    firstName: string;
    lastName: string;
    address: string;
    email: string;
    phoneNumber: string;
    user: User;
    orderStatus: OrderType;
    organizationId: number;
}
<div class="page-frame">
    <div class="page-frame-header">
        <div class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="page-logo">
                    <i class="fas fa-chart-bar"></i>
                    Products
                </div>
                <div class="page-buttons">
                    <button *ngIf="isRetail" (click)="showBarcodeScan()" class="btn btn-60 btn-icon-text btn-white barcode-button">
                        <i class="fas fa-barcode"></i> <span>Barcode</span>
                    </button>
                    <button (click)="showProductList()" class="btn btn-60 btn-icon-text btn-white">
                        <i class="fas fa-warehouse"></i> <span>Product List</span>
                    </button>
                    <button (click)="addMenuItem()" class="btn btn-60 btn-icon-text btn-white">
                        <i class="fas fa-plus"></i> <span>Add Product</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="page-frame-body">
        <div class="full-height-container pad-20">
            <div class="shadow-radius-container" [ngClass]="{'grow-height': showMenuItemForm || showMenuItemList}">
                <div class="barcode-scanning-container" *ngIf="searchByBarcode">
                    <div class="row">
                        <div class="col-md-4 d-flex align-items-center">
                            <form [formGroup]="itemSearchForm">
                                <div class="search-field">
                                    <input placeholder="Scan" class="form-control" #barcodeInput (keyup.enter)="barcodeLookup($event.target.value)" name="scanner"
                                    size="50" formControlName="itemBarcode" />
                                    <i class="fas fa-search"></i>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-8 d-flex align-items-center">
                            <div class="product-result" *ngIf="displayItem">
                                <div class="product-result-quantity">
                                    {{menuItemFormData.stock}}
                                </div>
                                <div class="product-result-name" (click)="editMenuItem(menuItemFormData)">
                                    {{menuItemFormData.name}}
                                </div>
                                <div class="product-result-price">
                                    {{menuItemFormData.price | currency}}
                                </div>
                                <div class="product-result-qty-action">
                                    <i (click)="incrementItemCount(menuItemFormData.barcode)" class="fas fa-plus-circle text-green"></i>
                                    <i (click)="decrementItemCount(menuItemFormData.barcode)" class="fas fa-minus-circle text-light-red"></i>
                                </div>
                                <div class="product-result-action">
                                    <span (click)="openPrintDialog(menuItemFormData)"><i class="fas fa-print"></i></span>
                                    <span (click)="updateMenuItemCount(menuItemFormData)" class="green"><i class="fas fa-check-circle"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="product-form-container grow-height" *ngIf="showMenuItemForm">
                    <form class="grow-height full-width" #menuItemForm="ngForm" (ngSubmit)="saveMenuItem()">
                        <div class="summery-container">
							<div class="summery-container-header">
								<h2><b>Create Item</b></h2>
							</div>
							<div class="summery-container-body pad-l-20 pad-r-20">
								<div class="row">
                                    <div class="col-md-6" style="border-right:2px solid #cdcdcd;">
                                        <div class="form-container size-md pad-r-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Name:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" [(ngModel)]="menuItemFormData.name" maxlength="25" name="name" type="text" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Sales Price:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input currencyMask class="form-control" [(ngModel)]="menuItemFormData.price" name="price" type="text" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Purchase Price:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input currencyMask class="form-control" [(ngModel)]="menuItemFormData.purchasePrice" name="purchasePrice" type="text" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Quantity:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" type="text" name="stock" [(ngModel)]="menuItemFormData.stock" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Image Url:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <div class="file-uploader-image">
                                                        <input class="form-control" (change)="selectFile($event)" name="imageUrl" size="60" type="file" />
                                                        <span class="file-image" *ngIf="menuItemFormData.imageFilename">
                                                            <span class="file-image-wrapper">
                                                                <img [src]="url+menuItemFormData.imageFilename" />
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-field-row" *ngIf="isRetail">
                                                <div class="form-field-label">
                                                    <label>EBT Eligible:</label>
                                                </div>
                                                <div class="form-field-control d-flex flex-row">
                                                    <div class="form-radio-field">
                                                        <span class="custom-radio align-self-center">
                                                            <input type="radio" id="ebtEligible1" [(ngModel)]="menuItemFormData.ebtEligible" [value]="true" name="ebtEligible" />
                                                            <label for="ebtEligible1"><i class="fas fa-check"></i></label>
                                                        </span>
                                                        <label for="ebtEligible1">Yes</label>
                                                    </div>
                                                    <div class="form-radio-field">
                                                        <span class="custom-radio align-self-center">
                                                            <input type="radio" id="ebtEligible2" [(ngModel)]="menuItemFormData.ebtEligible" [value]="false" name="ebtEligible" />
                                                            <label for="ebtEligible2"><i class="fas fa-check"></i></label>
                                                        </span>
                                                        <label for="ebtEligible2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="showFullForm">
                                                <div class="form-field-row">
                                                    <div class="form-field-label">
                                                        <label>Medium Price:</label>
                                                    </div>
                                                    <div class="form-field-control">
                                                        <input currencyMask class="form-control" [(ngModel)]="menuItemFormData.mediumPrice" name="mediumPrice" type="text" />
                                                        <span class="field-info">(Used in Digital Menu)</span>
                                                    </div>
                                                </div>
                                                <div class="form-field-row">
                                                    <div class="form-field-label">
                                                        <label>Online Price:</label>
                                                    </div>
                                                    <div class="form-field-control">
                                                        <input currencyMask class="form-control" [(ngModel)]="menuItemFormData.onlinePrice" name="onlinePrice" type="text" />
                                                    </div>
                                                </div>
                                                <div class="form-field-row">
                                                    <div class="form-field-label">
                                                        <label>Active:</label>
                                                    </div>
                                                    <div class="form-field-control d-flex flex-row">
                                                        <div class="form-radio-field">
                                                            <span class="custom-radio align-self-center">
                                                                <input type="radio" id="active1" [(ngModel)]="menuItemFormData.active" [value]="true" name="active" />
                                                                <label for="active1"><i class="fas fa-check"></i></label>
                                                            </span>
                                                            <label for="active1">Yes</label>
                                                        </div>
                                                        <div class="form-radio-field">
                                                            <span class="custom-radio align-self-center">
                                                                <input type="radio" id="active2" [(ngModel)]="menuItemFormData.active" [value]="false" name="active" />
                                                                <label for="active2"><i class="fas fa-check"></i></label>
                                                            </span>
                                                            <label for="active2">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-field-row">
                                                    <div class="form-field-label">
                                                        <label>Active Online:</label>
                                                    </div>
                                                    <div class="form-field-control d-flex flex-row">
                                                        <div class="form-radio-field">
                                                            <span class="custom-radio align-self-center">
                                                                <input type="radio" id="activeOnline1" [(ngModel)]="menuItemFormData.activeOnline" [value]="true" name="activeOnline" />
                                                                <label for="activeOnline1"><i class="fas fa-check"></i></label>
                                                            </span>
                                                            <label for="activeOnline1">Yes</label>
                                                        </div>
                                                        <div class="form-radio-field">
                                                            <span class="custom-radio align-self-center">
                                                                <input type="radio" id="activeOnline2" [(ngModel)]="menuItemFormData.activeOnline" [value]="false" name="activeOnline" />
                                                                <label for="activeOnline2"><i class="fas fa-check"></i></label>
                                                            </span>
                                                            <label for="activeOnline2">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-field-row">
                                                    <div class="form-field-label">
                                                        <label>Digital Active:</label>
                                                    </div>
                                                    <div class="form-field-control d-flex flex-row">
                                                        <div class="form-radio-field">
                                                            <span class="custom-radio align-self-center">
                                                                <input type="radio" id="activeDigiMenu1" [(ngModel)]="menuItemFormData.activeDigiMenu" [value]="true" name="activeDigiMenu" />
                                                                <label for="activeDigiMenu1"><i class="fas fa-check"></i></label>
                                                            </span>
                                                            <label for="activeDigiMenu1">Yes</label>
                                                        </div>
                                                        <div class="form-radio-field">
                                                            <span class="custom-radio align-self-center">
                                                                <input type="radio" id="activeDigiMenu2" [(ngModel)]="menuItemFormData.activeDigiMenu" [value]="false" name="activeDigiMenu" />
                                                                <label for="activeDigiMenu2"><i class="fas fa-check"></i></label>
                                                            </span>
                                                            <label for="activeDigiMenu2">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-container size-md pad-l-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Item Number:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" type="text" name="number" [(ngModel)]="menuItemFormData.number" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Barcode:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" [(ngModel)]="menuItemFormData.barcode" name="barcode" size="40px;" type="text" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Category:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <select class="form-select" [(ngModel)]="menuItemFormData.category.id" id="category" name="category">
                                                        <option *ngFor="let cat of categories; let i = index" [value]="cat.id">{{cat.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Tax Rate:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" [(ngModel)]="menuItemFormData.taxRate" maxlength="3" name="taxRate" size="3" type="text" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Priced By Weight:</label>
                                                </div>
                                                <div class="form-field-control d-flex flex-row">
                                                    <div class="form-radio-field">
                                                        <span class="custom-radio align-self-center">
                                                            <input type="radio" id="pricedByWeight1" [(ngModel)]="menuItemFormData.pricedByWeight" [value]="true" name="pricedByWeight" />
                                                            <label for="pricedByWeight1"><i class="fas fa-check"></i></label>
                                                        </span>
                                                        <label for="pricedByWeight1">Yes</label>
                                                    </div>
                                                    <div class="form-radio-field">
                                                        <span class="custom-radio align-self-center">
                                                            <input type="radio" id="pricedByWeight2" [(ngModel)]="menuItemFormData.pricedByWeight" [value]="false" name="pricedByWeight" />
                                                            <label for="pricedByWeight2"><i class="fas fa-check"></i></label>
                                                        </span>
                                                        <label for="pricedByWeight2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="showFullForm">
                                                <div class="form-field-row">
                                                    <div class="form-field-label">
                                                        <label>Description:</label>
                                                    </div>
                                                    <div class="form-field-control">
                                                        <input class="form-control" [(ngModel)]="menuItemFormData.description" maxlength="255" name="description" size="100" type="text" />
                                                    </div>
                                                </div>
                                                <div class="form-field-row">
                                                    <div class="form-field-label">
                                                        <label>Small Price:</label>
                                                    </div>
                                                    <div class="form-field-control">
                                                        <input currencyMask class="form-control" [(ngModel)]="menuItemFormData.smallPrice" name="smallPrice" type="text" />
                                                        <span class="field-info">(Used in Digital Menu)</span>
                                                    </div>
                                                </div>
                                                
                                                <div class="form-field-row">
                                                    <div class="form-field-label">
                                                        <label>Online Tax Rate:</label>
                                                    </div>
                                                    <div class="form-field-control">
                                                        <input class="form-control" [(ngModel)]="menuItemFormData.onlineTaxRate" maxlength="3" name="onlineTaxRate" size="3" type="text" />
                                                    </div>
                                                </div>
                                                <div class="form-field-row">
                                                    <div class="form-field-label">
                                                        <label>Delivery Time:</label>
                                                    </div>
                                                    <div class="form-field-control">
                                                        <select class="form-select" [(ngModel)]="menuItemFormData.fulfillmentTime" id="fulfillmentTime" name="fulfillmentTime">
                                                            <option [ngValue]="5">5 Minutes</option>
                                                            <option [ngValue]="10">10 Minutes</option>
                                                            <option [ngValue]="15">15 Minutes</option>
                                                            <option [ngValue]="20">20 Minutes</option>
                                                            <option [ngValue]="25">25 Minutes</option>
                                                            <option [ngValue]="30">30 Minutes</option>
                                                            <option [ngValue]="35">35 Minutes</option>
                                                            <option [ngValue]="40">40 Minutes</option>
                                                            <option [ngValue]="45">45 Minutes</option>
                                                            <option [ngValue]="50">50 Minutes</option>
                                                            <option [ngValue]="55">55 Minutes</option>
                                                            <option [ngValue]="60">1 Hour</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-field-row">
                                                    <div class="form-field-label">
                                                        <label>Number of Sides:</label>
                                                    </div>
                                                    <div class="form-field-control">
                                                        <input class="form-control" [(ngModel)]="menuItemFormData.numOfSubMenus" name="numOfSubMenus" type="text" />
                                                    </div>
                                                </div>
                                                <div class="form-field-row">
                                                    <div class="form-field-label">
                                                        <label>Product Type:</label>
                                                    </div>
                                                    <div class="form-field-control d-flex flex-row">
                                                        <div class="form-check-field">
                                                            <span class="custom-check align-self-center">
                                                                <input type="checkbox" id="check1" [(ngModel)]="menuItemFormData.drink" [checked]="menuItemFormData.drink" name="drink"
                                                                value="{{menuItemFormData.drink}}" />
                                                                <label for="check1"><i class="fas fa-check"></i></label>
                                                            </span>
                                                            <label for="check1">Drink</label>
                                                        </div>
                                                        <div class="form-check-field">
                                                            <span class="custom-check align-self-center">
                                                                <input type="checkbox" id="check2" [(ngModel)]="menuItemFormData.alcohol" [checked]="menuItemFormData.alcohol" name="alcohol"
                                                                value="{{menuItemFormData.alcohol}}" />
                                                                <label for="check2"><i class="fas fa-check"></i></label>
                                                            </span>
                                                            <label for="check2">Alcohol</label>
                                                        </div>
                                                        <div class="form-check-field">
                                                            <span class="custom-check align-self-center">
                                                                <input type="checkbox" id="check3" [(ngModel)]="menuItemFormData.special" [checked]="menuItemFormData.special" name="special"
                                                                value="{{menuItemFormData.special}}" />
                                                                <label for="check3"><i class="fas fa-check"></i></label>
                                                            </span>
                                                            <label for="check3">Special</label>
                                                        </div>
                                                        <div class="form-check-field">
                                                            <span class="custom-check align-self-center">
                                                                <input type="checkbox" id="check4" [(ngModel)]="menuItemFormData.meal" [checked]="menuItemFormData.meal" name="meal"
                                                                value="{{menuItemFormData.meal}}" />
                                                                <label for="check4"><i class="fas fa-check"></i></label>
                                                            </span>
                                                            <label for="check4">Meal</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-center pad-t-10"> 
                                        <button (click)="toggleForm()" *ngIf="!showFullForm" class="btn btn-45-f btn-white" type="button">
                                            <i class="fas fa-plus"></i> View More
                                        </button>
                                        <button (click)="toggleForm()" *ngIf="showFullForm" class="btn btn-45-f btn-white" type="button">
                                            <i class="fas fa-minus"></i> View Less
                                        </button>
                                    </div>
                                </div>
							</div>
							<div class="summery-container-footer">
								<button (click)="showProductList()" class="btn btn-45-f btn-danger" type="button">
									<i class="fas fa-times-circle"></i> Cancel
								</button>
                                <button  class="btn btn-45-f btn-success float-end" type="submit">
									<i class="fas fa-check-circle"></i> Save
								</button>
							</div>
						</div>
                    </form>
                </div>
                <div class="product-list-container" [ngClass]="{'restaurant-view': !isRetail}" *ngIf="showMenuItemList">
                    <div *ngIf="showSpinner">
                        <app-spinner>loading.......</app-spinner>
                    </div>
            
                    <!-- List Menu Items -->
                    <div class="product-list-table">
                        <div class="product-list-table-search">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="search-field">
                                        <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search" />
                                        <i class="fas fa-search"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-list-table-body">
                            <ag-grid-angular 
                                (firstDataRendered)="onFirstDataRendered($event)" 
                                (rowClicked)="handleAction($event)"
                                (cellClicked)="onNameClicked($event)" 
                                [columnDefs]="columnDefs"
                                [frameworkComponents]="frameworkComponents" 
                                [gridOptions]="gridOptions" 
                                [rowData]="rowData"
                                [rowSelection]="rowSelection" 
                                [rowHeight]="'60'"
                                [headerHeight]="'40'"
                                [rowClass]="'product-table-row'"
                                class="ag-theme-alpine menu-table">
                            </ag-grid-angular>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</div>

import { MenuItem } from './MenuItem';
import { User } from './User';
import { OrderType } from '../enums/OrderType';
import { OrderStatus } from '../enums/OrderStatus';
import { Customer } from './Customer';
import { Receipt } from './Receipt';

export class ShoppingCartItem {
    id: number;
    quantity = 1;
    weight: number;
    number: string;
    updatedBy: string;
    updated: Date;
    receipt: Receipt;
    menuItem: MenuItem;
    price: number;
    discountPrice: number;
    user: User;
    customer: Customer;
    orderPlaced: Date;
    orderReady: Date;
    orderServed: Date;
    orderType: OrderType;
    orderStatus: OrderStatus;
    discount: number;
    notes: string;
    organizationId: number;
    selected: boolean;
    pricePermanent: boolean;
    removeTax: boolean;
    subShoppingCartItems: Array<any>;
    subTaxTotal: number;
    subPriceTotal: number;
}

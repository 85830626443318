import {Component, OnDestroy, OnInit} from '@angular/core';
import { ReceiptService } from 'src/app/services/receipt.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import {ToggleService} from '../../services/toggle.service';
import {ReportService} from '../../services/report.service';
import {DailyRevenue} from '../../models/DailyRevenue';
import {Constants} from '../../models/Constants';
import {SessionStorageService} from 'angular-web-storage';
import {SearchParams} from '../../models/SearchParams';
import {OrderItem} from '../../models/OrderItem';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { DatePipe, CurrencyPipe } from '@angular/common';
import {BigDecimal} from '../../models/BigDecimal';
import { SharedService } from 'src/app/services/shared.service';
import { TaxRateReport } from 'src/app/models/TaxRateReport';
import { ThrowStmt } from '@angular/compiler';
import {SalesByCategoryReport} from '../../models/SalesByCategoryReport';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit, OnDestroy {

  constructor(private _shared: SharedService, private datePipe: DatePipe, private currencyPipe: CurrencyPipe, public receiptService: ReceiptService, public authenticationService: AuthenticationService, private dialog: MatDialog,
              private reportService: ReportService, public toggleService: ToggleService, private session: SessionStorageService, private router: Router) { }
  private apiSubscription = new Subscription();
  public dailyRevenue: DailyRevenue = new DailyRevenue();
  public currentDate = new Date();
  public isRetail = false;
  public orderItems: Array<OrderItem> = [];
  public orderItemSummaryDatasource = null;
  public reportStartDate: Date;
  public reportEndDate: Date;
  public taxRateField: number;
  public categoryNameField: string;
  public orderTotal = 0;
  public taxTotal = 0;
  public grandTotal = 0;
  public loadSummeryTable = false;
  public taxRateData: TaxRateReport;
  public categoryReportData: SalesByCategoryReport;

  public showInventoryTable = false;
  public showMatLoader = false;

  columnDefs = [
    {
      headerName: 'Name',
      field: 'menuItem.name',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Price',
      field: 'price',
      valueFormatter: params => this.currencyFormatter(params.value),
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    }
  ];

  columnDefsInventory = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agTextColumnFilter',
      cellClass: 'product-table-col',
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      filter: 'agTextColumnFilter',
      cellClass: 'product-table-col',
    },
    {
      headerName: 'Price',
      field: 'salesPrice',
      valueFormatter: params => this.currencyFormatter(params.value),
      filter: 'agTextColumnFilter',
      cellClass: 'product-table-col',
    },
    {
      headerName: 'Total Amount',
      field: 'totalPrice',
      valueFormatter: params => this.currencyFormatter(params.value),
      filter: 'agTextColumnFilter',
      cellClass: 'product-table-col',
    }
  ];
  public gridOptions: GridOptions;
  public rowSelection = 'single';

  public rowData: any = [];
  public inventoryData: any = [];


  public chartType = 'bar';
  public chartOptions: any = {
    responsive: true,
    animation: {
      onComplete() {
        let chartInstance = this.chart,
            ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function(dataset, i) {
          let meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function(bar, index) {
            let data = BigDecimal.round(dataset.data[index], 2);
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    },
    scales: {
      xAxes: [{
        ticks: {
          fontColor: '#333333',
          fontSize: 16,
          fontStyle: 'bold'
        },
        gridLines: {
          color : '#f0f4f7',
          display : false,
          zeroLineColor: '#f0f4f7'
        },
        barThickness : 50,
      }],
      yAxes: [{
        ticks: {
          fontColor: '#333333',
          fontSize: 16,
          fontStyle: 'bold'
        },
        gridLines: {
          color : '#f0f4f7',
          drawBorder: false,
          zeroLineColor: '#f0f4f7'
        }
      }]
    }
  };
  public dataSet: Array<any> = [];

  public chartDatasets: Array<any> = [
    { data: this.dataSet, label: 'Sales Report' }
  ];

  public chartLabels: Array<any> = [];

  public chartColors: Array<any> = [
    {
      backgroundColor: [
        '#999999',
        '#999999',
        '#999999',
        '#999999',
        '#999999',
        '#999999',
        '#999999'
      ],
      borderColor: [
        '#999999',
        '#999999',
        '#999999',
        '#999999',
        '#999999',
        '#999999',
        '#999999'
      ],
      borderWidth: 2,
    }
  ];

  public orderItemSummaryColumns = ['name', 'quantity', 'price'];

  public dashboardData: any = {
    totalCostGoods: 0,
    totalDailyRevenue: 0,
    totalSellByInterval: 0,
    totalSellByIntervalUser: 0
  };

  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  ngOnInit() {
    this.gridOptions = ({
      rowHeight: 40
    } as GridOptions);

    this.toggleService.ShowDailyRevenueReport = false;
    this.toggleService.ShowOrderItemSummary = false;
    this.toggleService.ShowRevenueBarchart = false;
    this.toggleService.ShowReportMenu = true;
    this.toggleService.ShowTaxRateReport = false;
    this.getRevenueBarchartData();
    setTimeout(() => {
      this._shared.setGlobalLoader(true);
    }, 0);
    this.reportService.getTotalCostOfGoods(this.authenticationService.getOrganizationId()).subscribe(total => {
      this._shared.setGlobalLoader(false);
      this.dashboardData.totalCostGoods = total;
      // alert('Total Cost of Goods = ' + total);
    });
  }

  home() {
    this.router.navigate(['/home']);
  }

  reportMenu() {
    this.toggleService.ShowDailyRevenueReport = false;
    this.toggleService.ShowReportMenu = true;
    this.toggleService.ShowOrderItemSummary = false;
    this.toggleService.ShowTaxRateReport = false;
    this.toggleService.ShowCategoryReport = false;

    // Reset Data
    this.taxRateData = null;
    this.categoryReportData = null;
    
    this.closeInventoryView();
    // this.toggleService.ShowRevenueBarchart = false;
  }

  getInventoryCost() {
    this.showMatLoader = true;
    this.showInventoryTable = false;
    this.reportService.getInventoryCost(this.authenticationService.getOrganizationId()).subscribe(costResults => {
      this.inventoryData = costResults;
      this.showMatLoader = false;
      this.showInventoryTable = true;
    });
  }

  closeInventoryView() {
    this.showInventoryTable = false;
  }

  getDailyRevenue() {
    this.toggleService.ShowDailyRevenueReport = true;
    this.toggleService.ShowReportMenu = false;
    // this.toggleService.ShowRevenueBarchart = false;

    if (this.session.get('orgType') === Constants.ORGANIZATION_TYPE_RETAIL) {
      this.isRetail = true;
    }
    this._shared.setGlobalLoader(true);
    const reportSubscription = this.reportService.getDailyRevenueReport(this.authenticationService.getOrganizationId()).subscribe(report => {
      this._shared.setGlobalLoader(false);
      this.dailyRevenue = report;
    }, (error) => {
      this._shared.setGlobalLoader(false);
      alert(error);
    });
    this.apiSubscription.add(reportSubscription);
  }

  getTaxRateData() {
    this.reportStartDate = null;
    this.reportEndDate = null;
    this.toggleService.ShowTaxRateReport = true;
    this.toggleService.ShowCategoryReport = false;
    this.toggleService.ShowDailyRevenueReport = false;
    this.toggleService.ShowReportMenu = false;
  }

  getTaxRateRecords() {
    const searchParams = new SearchParams();
    if (!this.reportStartDate || !this.reportEndDate) {
      return;
    }
    searchParams.startDate = this.reportStartDate;
    searchParams.endDate = this.reportEndDate;
    searchParams.type = 'range';
    searchParams.organizationId = this.authenticationService.getOrganizationId();
    searchParams.taxRate = this.taxRateField;

    this._shared.setGlobalLoader(true);
    const taxRateSubscription = this.reportService.getSalesByTaxRate(searchParams).subscribe((response) => {
      this._shared.setGlobalLoader(false);
      this.taxRateData = response;
      console.log(response);
    });
  }

  getSalesByCategoryReport() {
    this.categoryNameField = 'RESTAURANT';
    this.reportStartDate = null;
    this.reportEndDate = null;
    this.toggleService.ShowCategoryReport = true;
    this.toggleService.ShowTaxRateReport = false;
    this.toggleService.ShowDailyRevenueReport = false;
    this.toggleService.ShowReportMenu = false;
  }

  getCategoryReportRecords() {
    const searchParams = new SearchParams();
    if (!this.reportStartDate || !this.reportEndDate || !this.categoryNameField) {
      return;
    }
    searchParams.startDate = this.reportStartDate;
    searchParams.endDate = this.reportEndDate;
    searchParams.type = 'range';
    searchParams.organizationId = this.authenticationService.getOrganizationId();
    searchParams.category = 'Restaurant'; // this.categoryNameField;

    this._shared.setGlobalLoader(true);
    const taxRateSubscription = this.reportService.getSalesByCategory(searchParams).subscribe((response) => {
      this._shared.setGlobalLoader(false);
      this.categoryReportData = response;
      console.log(response);
    });
  }

  getOrderItemSummary() {
    this.toggleService.ShowDailyRevenueReport = false;
    this.toggleService.ShowReportMenu = false;
    // this.toggleService.ShowRevenueBarchart = false;
    this.toggleService.ShowOrderItemSummary = true;

    if (this.session.get('orgType') === Constants.ORGANIZATION_TYPE_RETAIL) {
      this.isRetail = true;
    }
    const searchParams = new SearchParams();
    if (!this.reportStartDate || !this.reportEndDate) {
      return;
    }
    searchParams.startDate = this.reportStartDate;
    searchParams.endDate = this.reportEndDate;
    searchParams.type = 'range';
    searchParams.organizationId = this.authenticationService.getOrganizationId();
    this._shared.setGlobalLoader(true);
    const reportSubscription = this.reportService.getOrderItemSummaryReport(searchParams).subscribe(receipt => {
      this._shared.setGlobalLoader(false);
      this.loadSummeryTable = false;
      this.orderItems = receipt.orderItems;
      this.orderTotal = receipt.netto;
      this.taxTotal = receipt.tax;
      this.grandTotal = receipt.total;
      this.grandTotal = this.orderTotal + this.taxTotal;
      this.rowData = this.orderItems;
      this.loadSummeryTable = true;
    }, (error) => {
      this._shared.setGlobalLoader(false);
      console.log(error);
    });
    this.apiSubscription.add(reportSubscription);
  }

  getRevenueBarchartData() {
    this.toggleService.ShowRevenueBarchart = true;
    this.toggleService.ShowDailyRevenueReport = false;
    // this.toggleService.ShowReportMenu = false;
    this.toggleService.ShowOrderItemSummary = false;

    const searchParams = new SearchParams();
    if (!this.reportStartDate || !this.reportEndDate) {
      return;
    }
    searchParams.startDate = this.reportStartDate;
    searchParams.endDate = this.reportEndDate;
    searchParams.type = 'range';
    searchParams.organizationId = this.authenticationService.getOrganizationId();

    this.chartLabels.length = 0;
    this.dataSet.length = 0;
    this._shared.setGlobalLoader(true);
    const chartDataSubscription = this.receiptService.getBarChartData(searchParams).subscribe((response) => {
      this._shared.setGlobalLoader(false);
      for (let i = 0; i < response.length; i++) {
        this.chartLabels.push(response[i].dayAndMonth);
        this.dataSet.push(response[i].dayAndMonthTotal);
      }
    });
    this.apiSubscription.add(chartDataSubscription);
  }

  printRevenueReport() {
    this.toggleService.ShowDailyRevenueReport = false;
    this.toggleService.ShowReportMenu = false;
    this.toggleService.ShowOrderItemSummary = true;
    if (this.session.get('orgType') === Constants.ORGANIZATION_TYPE_RETAIL) {
      this.isRetail = true;
    }
    const searchParams = new SearchParams();
    if (!this.reportStartDate || !this.reportEndDate) {
      return;
    }
    searchParams.startDate = this.reportStartDate;
    searchParams.endDate = this.reportEndDate;
    searchParams.type = 'range';
    searchParams.organizationId = this.authenticationService.getOrganizationId();
    const printSubscription = this.reportService.printRevenueReport(searchParams).subscribe(response => {
      this.messageDialog('SUCCESS', 'Report sent to printer');
    }, (error) => {
      this.messageDialog('ERROR', 'Could not send report to printer');
    });
    this.apiSubscription.add(printSubscription);
  }

  printDailyRevenueReport() {
    const searchParams = new SearchParams();
    searchParams.startDate = new Date(new Date().toDateString());
    searchParams.endDate = new Date(new Date().toDateString());
    searchParams.type = 'daily';
    searchParams.organizationId = this.authenticationService.getOrganizationId();

    const printSubscription = this.reportService.printDailyRevenueReport(searchParams).subscribe(response => {
    }, (error) => {
      alert(error);
    });
    this.apiSubscription.add(printSubscription);
  }

  printTaxRateReport() {
    const searchParams = new SearchParams();
    if (!this.reportStartDate || !this.reportEndDate) {
      return;
    }
    searchParams.startDate = this.reportStartDate;
    searchParams.endDate = this.reportEndDate;
    searchParams.type = 'range';
    searchParams.organizationId = this.authenticationService.getOrganizationId();
    searchParams.taxRate = this.taxRateField;

    const printSubscription = this.reportService.printTaxRateReport(searchParams).subscribe(response => {
      this.messageDialog('SUCCESS', 'Report sent to printer');
    }, (error) => {
      alert(error);
    });
    this.apiSubscription.add(printSubscription);
  }

  printCategoryReport() {
    const searchParams = new SearchParams();
    if (!this.reportStartDate || !this.reportEndDate) {
      return;
    }
    searchParams.startDate = this.reportStartDate;
    searchParams.endDate = this.reportEndDate;
    searchParams.type = 'range';
    searchParams.organizationId = this.authenticationService.getOrganizationId();
    searchParams.category = 'Restaurant';

    const printSubscription = this.reportService.printCategoryReport(searchParams).subscribe(response => {
      this.messageDialog('SUCCESS', 'Report sent to printer');
    }, (error) => {
      alert(error);
    });
    this.apiSubscription.add(printSubscription);
  }

  messageDialog(header: string, msg: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: header, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {});
    this.apiSubscription.add(dialogSubscription);
  }

  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
  }

  onGridReady(params) {
    params.api.sizeColumnsToFit();
  }

  currencyFormatter(value) {
    return this.currencyPipe.transform(value, '$');
  }
}

<div class="page-frame">
    <div class="page-frame-header">
        <div class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="page-logo">
                    <i class="fas fa-money-check-alt"></i>
                    Payments
                </div>
                <div class="page-buttons"> 
                    <button *ngIf="session.get('identity').role !== 'ROLE_MANAGER'" class="btn btn-60 btn-icon-text btn-white">
                        <i class="far fa-times-circle"></i> <span>Close Batch</span>
                    </button>
                    <button *ngIf="session.get('identity').role === 'ROLE_MANAGER'" (click)="closeBatch()" class="btn btn-60 btn-icon-text btn-white">
                        <i class="far fa-times-circle"></i> <span>Close Batch</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="page-frame-body">
        <div class="full-height-container pad-20">
            <div class="shadow-radius-container grow-height full-height-container">
                <!-- Form Start Here -->
				<div class="form-container" *ngIf="!orderService.ShowOrderDetails">
                    <form #receiptSearchForm="ngForm">
                        <div class="summery-container">
							<div class="summery-container-body pad-20">
								<div class="row space-20">
                                    <div class="col-md-3">
                                        <input class="form-control" #receiptNumberInput (keyup.enter)="receiptNumberLookup($event.target.value)" placeholder="Scan"/>
                                    </div>
									<div class="col-md-3"> 
										<button type="submit" class="btn btn-40 btn-white">Search</button>
                                    </div>
                                </div>
							</div>
						</div>
                    </form>
                </div>
				<!-- Form End Here -->
				<!-- List Start Here -->
				<div class="mat-table-container" style="overflow: auto;" *ngIf="orderService.ShowOrderSummary">
					<table mat-table [dataSource]="paymentService.MatTableReceiptDataSource">
                        
                        <ng-container matColumnDef="receiptNo">
                            <th mat-header-cell *matHeaderCellDef> Receipt No </th>
                            <td mat-cell *matCellDef="let receipt"> {{receipt.receiptNo}} </td>
                        </ng-container>

                        <ng-container matColumnDef="firstName">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let receipt"> {{receipt.user.firstName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="updated">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let receipt"> {{receipt.updated | date : 'MMMM d, h:mm' }} {{ receipt.updated | date : 'a' | lowercase }} </td>
                        </ng-container>

                        <ng-container matColumnDef="netto">
                            <th mat-header-cell class="mat-description-column" *matHeaderCellDef> Net </th>
                            <td mat-cell *matCellDef="let receipt"> {{receipt.netto | currency}} </td>
                        </ng-container>

                        <ng-container matColumnDef="tax">
                            <th mat-header-cell *matHeaderCellDef> Tax </th>
                            <td mat-cell *matCellDef="let receipt"> {{receipt.tax | currency}} </td>
                        </ng-container>

                        <ng-container matColumnDef="paymentType">
                            <th mat-header-cell *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let receipt"> {{receipt.paymentType}} </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let receipt"> {{receipt.status}} </td>
                        </ng-container>

                        <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef> Total Amount </th>
                            <td mat-cell *matCellDef="let receipt"> {{receipt.total | currency}} </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
							<th mat-header-cell *matHeaderCellDef> </th>
							<td class="action" mat-cell *matCellDef="let receipt">
                                <span class="icon-space">
                                    <i (click)="goToLink(receipt)" class="fas fa-receipt"></i>
                                </span>
                                <span class="icon-space">
								    <i *ngIf="receipt.status === 'SALE' || receipt.status === 'BATCHED'" (click)="getOrderItems(receipt)" class="fas fa-undo"></i>
                                </span>
                                <span class="icon-space">
								    <i *ngIf="receipt.status === 'SALE' || receipt.status === 'BATCHED'" (click)="confirmVoid(receipt)" class="fa fa-trash-alt text-red"></i>
                                </span>
							</td>
						</ng-container>

                        <tr mat-header-row *matHeaderRowDef="receiptSummaryColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: receiptSummaryColumns;"></tr>
				
					</table>
				</div>
				<!-- List End Here -->
                <div *ngIf="orderService.ShowOrderDetails" class="mat-table-buttons">
                    <button class="btn btn-40 btn-white" (click)="paymentsHome()" type="submit">
                        <i class="fas fa-backward"></i> Go Back
                    </button>
                    <button class="btn btn-40 btn-success" (click)="confirmRefund()" type="submit">
                        <i class="fas fa-check-circle"></i> Apply Refund
                    </button>
				</div>
                <!-- List Start Here -->
				<div class="mat-table-container" style="overflow: auto;" *ngIf="orderService.ShowOrderDetails">
					<table mat-table [dataSource]="orderItemDataSource">
                        
                        <ng-container matColumnDef="orderItemId">
                            <th mat-header-cell *matHeaderCellDef> Select </th>
                            <td mat-cell *matCellDef="let orderItem">
                                <span *ngIf="orderItem.returned === false">
                                    <mat-checkbox [(ngModel)]="orderItem.checked"></mat-checkbox>
                                </span>
                                <span *ngIf="orderItem.returned === true">
                                    <mat-checkbox [(ngModel)]="orderItem.checked" disabled="true"></mat-checkbox>
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="returnQuantity">
                            <th mat-header-cell *matHeaderCellDef> Return Quantity </th>
                            <td mat-cell *matCellDef="let orderItem"> 
                                <input style="max-width:100px;" type="text" name="returnQuantity" class="form-control readonly-field" [(ngModel)]="orderItem.returnQuantity">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="orderItemName">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let orderItem"> {{orderItem.name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="orderItemQty">
                            <th mat-header-cell *matHeaderCellDef> Quantity </th>
                            <td mat-cell *matCellDef="let orderItem"> {{orderItem.quantity}} </td>
                        </ng-container>

                        <ng-container matColumnDef="orderItemPrice">
                            <th mat-header-cell *matHeaderCellDef> Price </th>
                            <td mat-cell *matCellDef="let orderItem"> {{orderItem.totalPrice | currency}} </td>
                        </ng-container>

                        <ng-container matColumnDef="returnedItemStatus">
                            <th mat-header-cell *matHeaderCellDef> Returned </th>
                            <td mat-cell *matCellDef="let orderItem"> {{orderItem.returned}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cardType">
                            <th mat-header-cell *matHeaderCellDef> Card Type </th>
                            <td mat-cell *matCellDef="let orderItem"> {{orderItem.receipt.cardType === null ? orderItem.receipt.paymentType : orderItem.receipt.cardType}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="orderItemsColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: orderItemsColumns;"></tr>

					</table>
				</div>
				<!-- List End Here -->
            </div>
        </div>
    </div>
</div> 














<!-- 


<div class="page-frame">
    <div class="page-frame-body">
<div version="2.0" class="spacefix" style="margin-top: 10px;">
    <a (click)="home()" class="buttontrans"
       style="margin-right: 5px; cursor: pointer;">
        <img height="35" src="assets/images/home.png" />
    </a>
    <span style="color: #265656; font-size: 2.6em; padding-left: 20%;">PAYMENTS </span>
    <div style="display: table-cell; padding-left: 20px; float: right;" id="submit_id_2">
        <button (click)="closeBatch()" class="mat-long-button">CLOSE BATCH</button>
    </div>
</div>
<hr class="line" />

<form #receiptSearchForm="ngForm">
    <div style="display: table-row; padding-left: 30%;">
        <div style="display: table-cell;">
            <mat-form-field appearance="outline" style="margin-left:20px; padding-right: 40px;">
                <input #receiptNumberInput matInput (keyup.enter)="receiptNumberLookup($event.target.value)" placeholder="SCAN"/>
            </mat-form-field>
        </div>
        <div style="display: table-cell; padding-left: 25px;" id="submit_id_1">
            <button mat-stroked-button>Search</button>
        </div>
    </div>
</form>

<br/>

<div *ngIf="orderService.ShowOrderSummary" class="receipt-summary-container mat-elevation-z8">
    <table mat-table [dataSource]="paymentService.MatTableReceiptDataSource">

        <ng-container matColumnDef="receiptNo">
            <th mat-header-cell *matHeaderCellDef> Receipt No </th>
            <td (click)="getOrderItems(receipt)" mat-cell *matCellDef="let receipt"> {{receipt.receiptNo}} </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let receipt"> {{receipt.user.firstName}} </td>
        </ng-container>

        <ng-container matColumnDef="updated">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let receipt"> {{receipt.updated | date : 'MMMM d, h:mm' }} {{ receipt.updated | date : 'a' | lowercase }} </td>
        </ng-container>

        <ng-container matColumnDef="netto">
            <th mat-header-cell class="mat-description-column" *matHeaderCellDef> Net </th>
            <td mat-cell *matCellDef="let receipt"> {{receipt.netto | currency}} </td>
        </ng-container>

        <ng-container matColumnDef="tax">
            <th mat-header-cell *matHeaderCellDef> Tax </th>
            <td mat-cell *matCellDef="let receipt"> {{receipt.tax | currency}} </td>
        </ng-container>

        <ng-container matColumnDef="paymentType">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let receipt"> {{receipt.paymentType}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let receipt"> {{receipt.status}} </td>
        </ng-container>

        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef> Total Amount </th>
            <td mat-cell *matCellDef="let receipt"> {{receipt.total | currency}} </td>
        </ng-container>

        <ng-container matColumnDef="actionDetails">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let receipt">
                <i (click)="goToLink(receipt)" class="fa fa-search fa-2x" style="color: cornflowerblue; cursor: pointer;"></i>
            </td>
        </ng-container>

        <ng-container matColumnDef="actionReturn">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let receipt">
                <i (click)="getOrderItems(receipt)" class="fa fa-fast-backward fa-2x" style="color: darkred; cursor: pointer;"></i>
            </td>
        </ng-container>

        <ng-container matColumnDef="actionVoid">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell  *matCellDef="let receipt; let i = index">
                <i (click)="confirmVoid(receipt)" class="fa fa-trash-alt fa-2x" style="color: red; cursor: pointer;"></i>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="receiptSummaryColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: receiptSummaryColumns;"></tr>
    </table>
</div>
<div *ngIf="orderService.ShowOrderDetails" style="display: flex; vertical-align: text-top; justify-content: space-between;">
    <button (click)="paymentsHome()" class="mat-back-button" style="margin-left: 2%;"><== GO BACK</button>
    <button (click)="confirmRefund()" class="mat-refund-button">APPLY REFUND</button>
</div>
<div *ngIf="orderService.ShowOrderDetails" class="orderItem-summary-container mat-elevation-z8">
    <table mat-table [dataSource]="orderItemDataSource">

        <ng-container matColumnDef="orderItemId">
            <th mat-header-cell *matHeaderCellDef> Select </th>
            <td mat-cell *matCellDef="let orderItem">
                <span *ngIf="orderItem.returned === false">
                    <mat-checkbox [(ngModel)]="orderItem.checked"></mat-checkbox>
                </span>
                <span *ngIf="orderItem.returned === true">
                    <mat-checkbox [(ngModel)]="orderItem.checked" disabled="true"></mat-checkbox>
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="returnQuantity">
            <th mat-header-cell *matHeaderCellDef> Return Quantity </th>
            <td mat-cell *matCellDef="let orderItem"> <input type="text" name="returnQuantity" [(ngModel)]="orderItem.returnQuantity" style="width: 3.4em; height: 42px; font-size: 2em; font-weight: bold;"> </td>
        </ng-container>

        <ng-container matColumnDef="orderItemName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let orderItem"> {{orderItem.menuItem.name}} </td>
        </ng-container>

        <ng-container matColumnDef="orderItemQty">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td mat-cell *matCellDef="let orderItem"> {{orderItem.quantity}} </td>
        </ng-container>

        <ng-container matColumnDef="orderItemPrice">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell *matCellDef="let orderItem"> {{orderItem.totalPrice | currency}} </td>
        </ng-container>

        <ng-container matColumnDef="returnedItemStatus">
            <th mat-header-cell *matHeaderCellDef> Returned </th>
            <td mat-cell *matCellDef="let orderItem"> {{orderItem.returned}} </td>
        </ng-container>

        <ng-container matColumnDef="cardType">
            <th mat-header-cell *matHeaderCellDef> Card Type </th>
            <td mat-cell *matCellDef="let orderItem"> {{orderItem.receipt.cardType === null ? orderItem.receipt.paymentType : orderItem.receipt.cardType}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="orderItemsColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: orderItemsColumns;"></tr>
    </table>
</div>

<br/>
<br/>
</div>
</div> -->

<div class="page-frame">
    <div class="page-frame-header">
        <div class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="page-logo">
                    <i class="fas fa-pizza-slice"></i>
                    Sides
                </div>
                <div class="page-buttons">
                    <button (click)="addSideItem()" class="btn btn-60 btn-icon-text btn-white">
                        <i class="fas fa-plus"></i> <span>Add Sides</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="page-frame-body">
        <div class="full-height-container pad-20">
            <div class="shadow-radius-container grow-height">
                <!-- List Start Here -->
                <div class="ag-table-grid" *ngIf="displaySideList">
                    <ag-grid-angular 
                        [columnDefs]="columnDefs" 
                        [rowData]="subMenuItems" 
                        [rowSelection]="rowSelection"
                        [rowHeight]="'60'"
                        [headerHeight]="'40'" 
                        [rowClass]="'ag-custom-table-row'"
                        (gridReady)="onGridReady($event)" 
                        (rowClicked)="onRowClicked($event)"
                        class="ag-theme-alpine user-table">
                    </ag-grid-angular>
                </div>
                <!-- List End Here -->
                <!-- Form Start Here -->
                <div class="product-form-container grow-height" *ngIf="displaySideForm">
                    <form [formGroup]="addSideForm" style="height: 100%;" (ngSubmit)="submitSideItem(addSideForm)">
                        <div class="summery-container">
							<div class="summery-container-header">
								<h2><b>Add Sides</b></h2>
							</div>
							<div class="summery-container-body pad-l-20 pad-r-20">
								<div class="row">
                                    <div class="col-md-6" style="border-right:2px solid #cdcdcd;">
                                        <div class="form-container pad-r-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Name:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" type="text" formControlName="name" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Price:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input currencyMask class="form-control" type="text" formControlName="price" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Discount Price:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input currencyMask class="form-control" type="text" formControlName="discountPrice" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Preference:</label>
                                                </div>
                                                <div class="form-field-control d-flex flex-row align-items-center">
                                                    <span class="custom-check">
                                                        <input id= "check1" type="checkbox" formControlName="preference" />
                                                        <label for="check1"><i class="fas fa-check"></i></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-container pad-l-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Item Number:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" type="text" formControlName="itemNumber" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Description:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <textarea class="form-control" rows="3" formControlName="description"> </textarea>
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Tax Rate:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input class="form-control" type="number" formControlName="taxRate" />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
							</div>
							<div class="summery-container-footer">
								<button (click)="back()" class="btn btn-40 btn-danger" type="button">
									<i class="fas fa-times-circle"></i> Cancel
								</button>
                                <button  class="btn btn-40 btn-success float-end" type="submit">
									<i class="fas fa-check-circle"></i> Save
								</button>
							</div>
						</div>
                    </form>
                </div>
                <!-- Form End here -->
            </div>
            
        </div>
    </div>
</div>
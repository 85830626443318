export class SearchParams {
    organizationId: number;
    startDate: Date;
    endDate: Date;
    receiptNumber: string;
    refNumber: string;
    type: string;
    filterCards: boolean;
    taxRate: number;
    category: string;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constants} from '../models/Constants';
import {DailyRevenue} from '../models/DailyRevenue';
import {OrderItem} from '../models/OrderItem';
import {SearchParams} from '../models/SearchParams';
import {Receipt} from '../models/Receipt';
import { TaxRateReport } from '../models/TaxRateReport';
import {SalesByCategoryReport} from '../models/SalesByCategoryReport';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private httpClient: HttpClient) { }

  getTotalCostOfGoods(organizationId: number): Observable<number> {
    return this.httpClient.get<number>(Constants.ERESTAU_DOMAIN + '/api/report/totalcostofgoods/' + `${organizationId}`);
  }

  getInventoryCost(organizationId: number): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/report/inventorycost/' + `${organizationId}`);
  }

  getDailyRevenueReport(organizationId: number): Observable<DailyRevenue> {
    return this.httpClient.get<DailyRevenue>(Constants.ERESTAU_DOMAIN + '/api/report/dailyRevenue?org=' + `${organizationId}`);
  }

  getRevenueReport(searchParams: SearchParams): Observable<Array<OrderItem>> {
    return this.httpClient.post<Array<OrderItem>>(Constants.ERESTAU_DOMAIN + '/api/report/revenue', searchParams);
  }

  printRevenueReport(searchParams: SearchParams): Observable<Array<OrderItem>> {
    return this.httpClient.post<Array<OrderItem>>(Constants.ERESTAU_DOMAIN + '/api/report/printRevenue', searchParams);
  }

  getOrderItemSummaryReport(searchParams: SearchParams): Observable<Receipt> {
    return this.httpClient.post<Receipt>(Constants.ERESTAU_DOMAIN + '/api/report/orderitems/summary', searchParams);
  }

  printDailyRevenueReport(searchParams: SearchParams): Observable<DailyRevenue> {
    return this.httpClient.post<DailyRevenue>(Constants.ERESTAU_DOMAIN + '/api/report/printRevenue', searchParams);
  }

  getSalesByTaxRate(searchParams: SearchParams): Observable<TaxRateReport> {
    return this.httpClient.post<TaxRateReport>(Constants.ERESTAU_DOMAIN + '/api/report/taxreport', searchParams);
  }

  getSalesByCategory(searchParams: SearchParams): Observable<SalesByCategoryReport> {
    return this.httpClient.post<SalesByCategoryReport>(Constants.ERESTAU_DOMAIN + '/api/report/categoryreport', searchParams);
  }

  printTaxRateReport(searchParams: SearchParams): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/report/printtaxreport', searchParams);
  }

  printCategoryReport(searchParams: SearchParams): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/report/printcategoryreport', searchParams);
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/User';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Subscription } from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {
  private apiSubscription = new Subscription();
  public userFormData: User;
  public userRoles: any;
  public isNewUser = false;

  columnDefs = [
    {
      headerName: 'Username',
      field: 'userId',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Full Name',
      field: 'fullName',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Password',
      field: 'pin',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Role',
      field: 'role',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Account Active',
      field: 'accountNonLocked',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: 'Account Expiration',
      field: 'accountExpiration',
      filter: 'agTextColumnFilter',
      cellClass: 'ag-custom-table-col'
    },
    {
      headerName: '',
      field: '',
      cellClass: 'ag-table-col-action',
      cellRenderer: this.actionsRenderer
    }
  ];
  public gridOptions: GridOptions;
  public rowSelection = 'single';

  rowData = [];

  constructor(public userService: UserService, private _shared: SharedService, private authenticationService: AuthenticationService, private dialog: MatDialog) { }

  ngOnInit() {
    this.gridOptions = ({
      rowHeight: 40
    } as GridOptions);

    setTimeout(() => {
      this.getUsers();
    }, 0);
    
    this.userRoles = [
      {'id' : 'ROLE_CASHIER', 'name' : 'CASHIER'},
      {'id' : 'ROLE_ADMIN', 'name' : 'ADMIN'},
      {'id' : 'ROLE_KITCHEN', 'name' : 'KITCHEN'},
      {'id' : 'ROLE_MANAGER', 'name' : 'MANAGER'},
      {'id' : 'ROLE_TABLE', 'name' : 'TABLE'},
      {'id' : 'ROLE_WAITER', 'name' : 'WAITER'},
      {'id' : 'ROLE_BAR', 'name' : 'BAR'},
      {'id' : 'ROLE_GUEST', 'name' : 'GUEST'},
      {'id' : 'ROLE_MENU', 'name' : 'MENU'}
    ];
  }

  onGridReady(params) {
    params.api.sizeColumnsToFit();
  }

  onRowClicked(e) {
    if (e.event.target !== undefined) {
        const actionType = e.event.target.getAttribute('data-action-type');
        switch (actionType) {
            case 'Edit':
                this.editUser(e.node.data);
                break;
        }
    }
  }

  addUser() {
    this.userService.DisplayUserList = false;
    this.userService.DisplayUserForm = true;
    this.userFormData = new User();
    this.userFormData.role = this.userRoles[4].id;
    this.userFormData.organizationId = this.authenticationService.getOrganizationId();
    this.userFormData.credentialExpiration = new Date();
    this.userFormData.accountExpiration = new Date();
    this.isNewUser = true;
  }

  editUser(user: User) {
    this.userService.DisplayUserList = false;
    this.userService.DisplayUserForm = true;
    this.userFormData = user;
    this.isNewUser = false;
  }

  getUsers() {
    this._shared.setGlobalLoader(true);
    const getSubscription = this.userService.getUsers(this.authenticationService.getOrganizationId()).subscribe((usrs) => {
      if (usrs && usrs.length > 0) {
        usrs.forEach((value) => {
          value['fullName'] = value.firstName + " " + value.lastName;
        })
      }
      this._shared.setGlobalLoader(false);
      this.rowData = usrs;
      this.userService.Users = usrs;
      this.userService.DisplayUserList = true;
      this.userService.DisplayUserForm = false;
    });
    this.apiSubscription.add(getSubscription);
  }

  saveUser() {
    this._shared.setGlobalLoader(true);
    const saveSubscription = this.userService.saveUser(this.userFormData).subscribe((usr) => {
          this.userFormData = usr;
          this.openDialog('SUCCESS', 'User with Username [ ' + usr.userId + ' ] saved');
          this._shared.setGlobalLoader(false);
        },
        (err) => {
          console.log(err.error);
          this.openDialog('ERROR', err.error);
          this._shared.setGlobalLoader(false);
        });
    this.apiSubscription.add(saveSubscription);
  }

  resetToDefaultPin() {
    this.userFormData.pin = 'adm';
  }

  _saveUser() {
  }

  openDialog(msgHeader: string, msg: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '270px';
    dialogConfig.data = {description: msgHeader, message: msg};

    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (msgHeader === 'SUCCESS') {
        this.getUsers();
        this.isNewUser = false;
      }
    });
    this.apiSubscription.add(dialogSubscription);
  }

  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
  }

  actionsRenderer(curVal: any): string {
    return `
        <i data-action-type="Edit" class="fas fa-pencil-alt"></i>
    `;
  }
  
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from '../models/Category';
import {Observable, of} from 'rxjs';
import { Constants } from '../models/Constants';
import {LocalStorageService} from 'angular-web-storage';
import {AuthenticationService} from "./authentication.service";
import {switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private parentCategories: Array<Category> = [];
  private subCategories: Array<Category> = [];

  constructor(private httpClient: HttpClient, private storage: LocalStorageService, private authenticationService: AuthenticationService) { }

  saveCategory(category: Category): Observable<Category> {
    if (category.id && category.id > 0) {
      const categories = this.getCategoriesFromLocalStorage();
      const foundIndex = categories.findIndex(cat => cat.id === category.id);
      categories[foundIndex] = category;
      this.storeCategoriesInLocalStorage(categories);
    }
    return this.httpClient.post<Category>(Constants.ERESTAU_DOMAIN + '/api/category', category).pipe(
        switchMap((c) => {
          const cats = this.getCategoriesFromLocalStorage();
          cats.push(c);
          this.storeCategoriesInLocalStorage(cats);
          return of(c);
        })
    );
  }

  getParentCategories(organizationId: number): Observable<Array<Category>> {
    return this.httpClient.get<Array<Category>>(Constants.ERESTAU_DOMAIN + `/api/categories/parent?org=${organizationId}`);
  }

  getCategories(organizationId: number): Observable<Array<Category>> {
    return this.httpClient.get<Array<Category>>(Constants.ERESTAU_DOMAIN + `/api/categories?org=${organizationId}`);
  }

  getSubCategories(parentCategoryId: number, organizationId: number): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(Constants.ERESTAU_DOMAIN + `/api/categories/${parentCategoryId}?org=${organizationId}`);
  }

  deleteCategory(cat: Category): Observable<string> {
    return this.httpClient.delete<string>(Constants.ERESTAU_DOMAIN + `/api/category/${cat.id}`);
  }

  initCategoriesInLocalStorage(organizationId: number) {
    return this.httpClient.get<Array<Category>>(Constants.ERESTAU_DOMAIN + `/api/categories?org=${organizationId}`)
      .pipe(
            switchMap(categories => {
              this.storage.set(this.getCategoryStorageKey(), JSON.stringify(categories));
              return of(categories);
            })
      );
  }

  getCategoryFromLocalStorage(categoryId: number) {
    const categories = this.getCategoriesFromLocalStorage();
    const foundIndex = categories.findIndex(cat => cat.id === categoryId);
    let indx = -1;
    // console.log('Search Cat ID = [' + categoryId + ']');
    for (let i = 0; i < categories.length; i++) {
      // console.log('Cat ID = [' + categories[i].id + ']');
      if (categories[i].id === categoryId) {
        indx = i;
        break;
      }
    }
    if (indx >= 0) {
      return categories[indx];
    }
    return undefined;
  }

  getCategoriesFromLocalStorage() {
    return JSON.parse(this.storage.get(this.getCategoryStorageKey()));
  }

  storeCategoriesInLocalStorage(categories: any) {
    this.storage.set(this.getCategoryStorageKey(), JSON.stringify(categories));
  }

  set ParentCategories(parentCategories: Array<Category>) {
    this.parentCategories = parentCategories;
  }

  get ParentCategories() {
    return this.parentCategories;
  }

  set SubCategories(subCategories: Array<Category>) {
    this.subCategories = subCategories;
  }

  get SubCategories() {
    return this.subCategories;
  }

  getCategoryStorageKey() {
    return Constants.STORED_CATEGORIES + '_' + this.authenticationService.getOrganizationId();
  }
}

<div style="text-align: center; font-weight: bold; font-size: 2em;">{{description}}</div>
<br/><br/>
<mat-dialog-content align="center">
    <span style="font-weight: bold; font-size: 2.2em; color: darkblue">{{message}}</span>
    <br/><br/><br/>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="onCancelClick()" mat-button><span class="cancel-button">Cancel</span></button>
    <button (click)="onOkClick()" mat-button><span class="ok-button">OK</span></button>
</mat-dialog-actions>

import { Component } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-tip-adjust-renderer',
  templateUrl: './tip-adjust-renderer.component.html',
  styleUrls: ['./tip-adjust-renderer.component.css']
})
export class TipAdjustRendererComponent implements ICellRendererAngularComp {

  public params: any;

  agInit(params: any): void {
    console.log(params);
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  } 
 
  tipAdjustEvent() {
    this.params.clicked(this.params.node.data); 
  }

  constructor() { }

}

<div class="page-frame">
	<div class="page-frame-header">
		<app-header></app-header>
	</div>
	<div class="page-frame-nav">
		<div version="2.0" id="menu"></div>
		<!-- --------------------------------- CATEGORY BEGINS ----------------------------- -->
		<div class="main-nav" *ngIf="toggleService.ShowParentCategories">
			<drag-scroll [drag-scroll-y-disabled]="true">
				<ul>
					<li *ngFor="let cat of categoryService.ParentCategories; let i = index;" (click)="orderService.getOrderSubCategories(cat.id, i); navClickEvent();"
					[ngClass]="{'active': toggleService.SelectedCategory === i}">
						{{cat?.name}}
					</li>
				</ul>
			</drag-scroll>
		</div>
		<div class="sub-nav" *ngIf="toggleService.ShowSubCategories">
			<drag-scroll [drag-scroll-y-disabled]="true">
				<ul>
					<li *ngFor="let cat of categoryService.SubCategories; let j = index;" (click)="getMenuItems(cat, j); navClickEvent();"
					[ngClass]="{'active': toggleService.SelectedSubCategory === j}">
						{{cat?.name}}
					</li>
				</ul>
			</drag-scroll>
		</div> 
		<!-- --------------------------------- CATEGORY ENDS ----------------------------- -->
	</div>
	<div class="page-frame-body">
		<div class="full-height-container" *ngIf="toggleService.ShowOrderMenuItems && menuItemService?.OrderMenuItems.length > 0">
            <div class="product-list">
                <div class="product-column" *ngFor="let item of menuItemService.OrderMenuItems; let i = index;">
                    <div class="product-items" (click)="addToShoppingCart(item, i)">
                        <div class="product-header">
                            <span>{{item?.id}}</span>
                            <label>{{item?.price | currency}}</label>
                        </div>
                        <div class="product-title">
                            <h3>{{item?.name}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="product-cart-window" *ngIf="shoppingCartService.DisplayShoppingCart">
			<div class="cart-loader" *ngIf="cartLoaderFlag">
				<span class="mat-loader"><mat-spinner></mat-spinner></span>
			</div>
			<div class="cart-header">
				<button class="btn btn-transparent-dark btn-icon-text btn-sm" (click)="clearCart()"><i class="far fa-trash-alt"></i> Remove All</button>
			</div>
            <div class="cart-item-list" id="cartItemList">
                <div class="cart-item" *ngFor="let cartItem of shoppingCartService.ShoppingCart, let i = index;">
                    <div class="cart-item-quantity">
                        <button class="btn btn-primary btn-40">{{cartItem.quantity}}</button>
                    </div>
                    <div class="cart-item-title">
                        <h2 tooltip="{{cartItem.menuItem.price | currency}}" placement="bottom" (click)="editCartItemForm(cartItem)">
                            {{cartItem.menuItem.name}}
                        </h2>
						<p *ngIf="cartItem.subShoppingCartItems && cartItem.subShoppingCartItems.length > 0"><b *ngIf="showPreference(cartItem)">*{{ showPreference(cartItem) }}*</b> {{ showCartSides(cartItem) }}</p>
                    </div>
                    <div class="cart-item-price">
                        <span>{{cartItem.price + cartItem.subPriceTotal | currency}}</span>
                    </div>
                    <div class="cart-item-action">
                        <button class="btn btn-success btn-40 btn-icon" (click)="increaseItemQuantity(i)"><i class="fas fa-plus"></i></button>
                        <button class="btn btn-danger btn-40 btn-icon" (click)="removeFromShoppingCart(cartItem)"><i class="fas fa-minus"></i></button>
                    </div>
                </div>
            </div>
        </div>
		<!-- -------------------- SIDE ITEMS STARTS ---------------------- -->
		<div class="full-height-container pad-20" *ngIf="toggleService.ShowSideItems">
			<div class="shadow-radius-container grow-height">
				<app-side-item-selection [inputItem]="inputItem" (outputItem)="outputSides($event)"></app-side-item-selection>
			</div>
		</div>
		
		<!-- -------------------- SIDE ITEMS END ------------------------- -->
		<!-- -------------------- EDIT CART ITEM STARTS ------------------ -->
		<div class="full-height-container pad-20" *ngIf="toggleService.ShowCartItemToEdit">
			<!-------------------------------- CASH BEGINS ------------------------------------->
			<div class="keypad-data-container">
				<div class="data-container">
					<form #cartItemForm="ngForm" class="full-width" (ngSubmit)="saveUpdatedCartItem()">
						<div class="summery-container">
							<div class="summery-container-header">
								<div class="row space-0">
									<div class="col d-flex">
										<h2 class="align-self-center"><b>{{cartItemFormData.menuItem.name}}</b></h2>
									</div>
								</div>
							</div>
							<div class="summery-container-body">
								<div class="dot-table">
									<div class="dot-table-header">
										<div class="dot-table-row">
											<div class="row"> 
												<div class="col-md-4">
													<label>&nbsp;</label>
												</div>
												<div class="col-md-4">
													<label>Selected</label>
												</div>
												<div class="col-md-4"> 
													<label>Changes</label>
												</div>
											</div>
										</div>
									</div>
									<div class="dot-table-body">
										<div class="dot-table-row">
											<div class="row">
												<div class="col-md-4 d-flex">
													<label class="align-self-center">Quantity</label>
												</div>
												<div class="col-md-4 d-flex">
													<span class="align-self-center">{{tempFormData.quantity}}</span>
												</div>
												<div class="col-md-4">
													<input 
														name="quantity" 
														class="form-control" 
														[(ngModel)]="tempFormData.quantity" 
														(focus)="bindKeyPad('quantity')"
														type="text" 
													/>
												</div>
											</div>
										</div>
										<div class="dot-table-row">
											<div class="row">
												<div class="col-md-4 d-flex">
													<label class="align-self-center">Price</label>
												</div>
												<div class="col-md-4 d-flex">
													<span class="align-self-center">{{tempFormData.price | currency}}</span>
												</div>
												<div class="col-md-4">
													<input 
														#itemCurrentPrice 
														name="price" 
														currencyMask 
														[(ngModel)]="tempFormData.price"
														(focus)="bindKeyPad('price')"
														type="text" 
														class="form-control"
													/>
												</div>
											</div>
										</div>
									</div>
									<div class="dot-table-footer">
										<div class="dot-table-row no-border">
											<div class="row space-10">
												<div class="col-md-auto d-flex"><label class="align-self-center">Permanent?</label></div>
												<div class="col-md-auto d-flex">
													<span class="custom-check align-self-center">
														<input 
															id= "check1"
															type="checkbox" 
															name="drink" 
															value="{{tempFormData.pricePermanent}}"
															[(ngModel)]="tempFormData.pricePermanent" 
															[checked]="tempFormData.pricePermanent" 
														/>
														<label for="check1"><i class="fas fa-check"></i></label>
													</span>
												</div>
												<div class="col">
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="summery-container-footer">
								<button class="btn btn-40 btn-danger" type="button" (click)="closeEditCartItemForm()">
									<i class="fas fa-times-circle"></i> Close
								</button>
								<button class="btn btn-40 btn-success float-end" type="submit">
									<i class="fas fa-check-circle"></i> Save
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="keypad-container">
					<ng-container *ngIf="activeKeypad == 'quantity'">
                        <app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'tempFormData.quantity'" [inputValue]="tempFormData.quantity" (outputValue)="setAmount($event)" [specialKeys]="false" [isPrice]="false" [isDotAllowed]="false"></app-keypad>
                    </ng-container>
                    <ng-container *ngIf="activeKeypad == 'price'">
                        <app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'tempFormData.price'" [inputValue]="tempFormData.price" (outputValue)="setAmount($event)" [specialKeys]="false" [isPrice]="true" [isDotAllowed]="true"></app-keypad>
                    </ng-container>
				</div>
			</div>
		</div>
		<!-- -------------------- EDIT CART ENDS STARTS ------------------ -->
		<!-- --------------------------------- SHOPPING CART BEGINS ---------------------- -->
		<div id="main">
			<span class="error">
			</span>

			<!-- -------------------- SHOW TABLES START ----------------- -->
			<div class="table-selection-view" *ngIf="shoppingCartService.DisplayTables === 'YES'">
				<div class="table-selection-header">
					<button class="btn btn-white btn-45">Remove all locks on table</button>
				</div>
				<div class="table-selection-body">
					<div class="errors">
					</div>
					<div class="table-selection-list">
						<div class="row space-20 space-v-20">
							<div class="col-md-2" *ngFor="let aTable of customerService.Tables; let i = index;">
								<div class="table-selection-block" (click)="initTableOrders(aTable)">
									<div class="table-selection-image">
										<img src="../../../../assets/images/tables.png" />
									</div>
									<label>{{aTable?.userId}}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- -------------------- SHOW TABLES  ENDS ----------------- -->

			<div class="clear">
			</div>
		</div>
	</div>
</div>
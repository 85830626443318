import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ShoppingCartItem } from '../models/ShoppingCartItem';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Customer } from '../models/Customer';
import {LocalStorageService, SessionStorageService} from 'angular-web-storage';
import { Identity } from '../models/Identity';
import { Constants } from '../models/Constants';
import { PaymentService } from './payment.service';
import { CloseOrderRequest } from '../models/CloseOrderRequest';
import { ShoppingCartSummary } from '../models/ShoppingCartSummary';
import {Pair} from '../models/Pair';
import {BigDecimal} from '../models/BigDecimal';
import {User} from "../models/User";
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartServiceService {

  public user: Identity = this.session.get('identity');
  private chairToCheckout: Customer;
  private shoppingCart: Array<ShoppingCartItem> = [];
  private displayShoppingCart = false;
  private displayTables: string;
  private cartSum = 0;
  private cartSumTax = 0;
  private cartSumTotal = 0;
  private tenderedAmount = 0;
  private customerInvoiceAmount = 0.00;
  private ebtCartItems = false;
  private closeOrderRequest: CloseOrderRequest;
  private cartSumSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  private cartSumTaxSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  private cartSumTotalSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  private shoppingCartSubject: BehaviorSubject<Array<ShoppingCartItem>> = new BehaviorSubject([]);

  // private _cartInfo: Subject<any> = new Subject();
  // public cartInfoObservable = this._cartInfo.asObservable();

  private _cartRefreshObservable = new Subject();
  public cartRefreshObservable = this._cartRefreshObservable.asObservable();
  public cartRefreshFlag: boolean = false;

  // private cartInfo: any = {}

  constructor(private httpClient: HttpClient, private _shared: SharedService, private session: SessionStorageService, private localCache: LocalStorageService, private paymentService: PaymentService) { 
    // this.resetCartInfo();
  }

  // resetCartInfo() {
  //   this.cartInfo = {
  //     cartSum : 0,
  //     cartSumTax : 0,
  //     cartSumTotal : 0,
  //     ebtSum: 0,
  //     nonEbtSum: 0
  //   }
  // }

  // updateCartInfo() {
  //   this._cartInfo.next(this.cartInfo);
  // }

  triggerCartRefresh(flag) {
    if (flag && this.cartRefreshFlag != flag) {
      this._cartRefreshObservable.next(flag);
    }
  }

  saveShoppingCartItem(cartItem: ShoppingCartItem): Observable<ShoppingCartItem> {
    return this.httpClient.post<ShoppingCartItem>(Constants.ERESTAU_DOMAIN + '/api/shoppingCartItem', cartItem);
  }

  getUserShoppingCartItems(organizationId: number): Observable<Array<ShoppingCartItem>> {
    return this.httpClient.get<Array<ShoppingCartItem>>(Constants.ERESTAU_DOMAIN + `/api/shoppingCartItems?org=${organizationId}`);
  }

  getOccupiedTables(organizationId: number): Observable<Array<User>> {
    return this.httpClient.get<Array<User>>(Constants.ERESTAU_DOMAIN + `/api/shoppingCartItems/tables/${organizationId}`);
  }

  getShoppingCartItemsByTable(tableId: number): Observable<Array<ShoppingCartItem>> {
    return this.httpClient.get<Array<ShoppingCartItem>>(Constants.ERESTAU_DOMAIN + `/api/shoppingCartItems/table/${tableId}`);
  }

  getUserShoppingCartItemsForChair(chair: Customer, organizationId: number): Observable<Array<ShoppingCartItem>> {
    return this.httpClient.get<Array<ShoppingCartItem>>(Constants.ERESTAU_DOMAIN + `/api/shoppingCartItems/chair/${chair.id}?org=${organizationId}`);
  }

  sendShoppingCartItemsTotalForChairToDisplayPole(chair: Customer, organizationId: number, total: number, paymentType: string): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + `/api/shoppingCartItems/chair/${chair.id}/displayPoleTotal/${total}/payment/${paymentType}?org=${organizationId}`);
  }
  
  deleteShoppingCartItem(cartItem: ShoppingCartItem): Observable<any> {
    return this.httpClient.delete(Constants.ERESTAU_DOMAIN + `/api/shoppingCartItem/${cartItem.id}`);
  }

  clearShoppingCartItem(customerId: number, organizationId: number): Observable<any> {
    return this.httpClient.delete(Constants.ERESTAU_DOMAIN + `/api/shoppingCartItems/customer/${customerId}/org/${organizationId}`);
  }

  moveEbtItems(organizationId: number, fromCustomerId: number, toCustomerId): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + `/api/shoppingCartItems/organization/${organizationId}/transfer/${fromCustomerId}/to/${toCustomerId}`, null);
  }

  moveEbtItem(organizationId: number, shoppingCartItemId: number, fromCustomerId: number, toCustomerId): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + `/api/shoppingCartItems/${shoppingCartItemId}/organization/${organizationId}/transfer/${fromCustomerId}/to/${toCustomerId}`, null);
  }

  transferCartItem(shoppingCartItemId: number, fromCustomerId: number, toCustomerId): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + `/api/shoppingCartItems/${shoppingCartItemId}/from/${fromCustomerId}/to/${toCustomerId}`, null);
  }

  getServiceFeeForPartyOfFiveOrMore(organizationId: number): Observable<number> {
    return this.httpClient.get<number>(Constants.ERESTAU_DOMAIN + `/api/properties/servicefee/bigparty/${organizationId}`);
  }

  getServiceFeeForPartyOfFourOrLess(organizationId: number): Observable<number> {
    return this.httpClient.get<number>(Constants.ERESTAU_DOMAIN + `/api/properties/servicefee/smallparty/${organizationId}`);
  }

  clearCart(customerId, organizationId): Observable<any> {
    return this.httpClient.delete(Constants.ERESTAU_DOMAIN + `/api/shoppingCartItems/customer/${customerId}/org/${organizationId}`);
  }

  checkOut(chair: Customer, paymenType: string) {
    this.ChairToCheckout = chair;
    this.paymentService.SelectedPaymentType = paymenType;
  }

  closeOrder(refNum: string): Observable<any> {
    const activeChair = this.session.get(Constants.ACTIVE_CHAIR);
    this.closeOrderRequest.refNum = refNum;
    this.closeOrderRequest.chairId = activeChair.id;
    return this.httpClient.post(Constants.ERESTAU_DOMAIN + '/api/shoppingCartItems/close/order', this.closeOrderRequest);
  }

  resetSplitPayment(customerId: number, receiptId: number) {
    return this.httpClient.get(Constants.ERESTAU_DOMAIN + `/api/payments/resetsplit/customer/${customerId}/receipt/${receiptId}`);
  }

  calculateServiceFee() {
      for (let i = 0; i < this.ShoppingCart.length; i++) {
        if (this.ShoppingCart[i].menuItem.name === Constants.ITEM_PARTY_OF_FIVE_OR_MORE) {
          const adjustedCartSum = this.CartSum - this.ShoppingCart[i].price;
          this.ShoppingCart[i].price = adjustedCartSum * BigDecimal.divide(this.getServiceFeeForBigParty(this.ShoppingCart[i].organizationId), 100);
          this.saveShoppingCartItem(this.ShoppingCart[i]).subscribe(r => {
            this.CustomerInvoiceAmount = this.getShoppingCarTotal();
          });
        } else if (this.ShoppingCart[i].menuItem.name === Constants.ITEM_PARTY_OF_FOUR_OR_LESS) {
          const adjustedCartSum = this.CartSum - this.ShoppingCart[i].price;
          this.ShoppingCart[i].price = adjustedCartSum * BigDecimal.divide(this.getServiceFeeForSmallParty(this.ShoppingCart[i].organizationId), 100);
          this.saveShoppingCartItem(this.ShoppingCart[i]).subscribe(r => {
            this.CustomerInvoiceAmount = this.getShoppingCarTotal();
          });
        }
      }
  }

  getServiceFeeForBigParty(organizationId: number) {
    if (this.localCache.get(Constants.FEE_PARTY_OF_FIVE_OR_MORE)) {
      return this.localCache.get(Constants.FEE_PARTY_OF_FIVE_OR_MORE);
    }
    this.getServiceFeeForPartyOfFiveOrMore(organizationId).subscribe(r => {
      this.localCache.set(Constants.FEE_PARTY_OF_FIVE_OR_MORE, r);
    });
    return 15.00;
  }

  getServiceFeeForSmallParty(organizationId: number) {
    if (this.localCache.get(Constants.FEE_PARTY_OF_FOUR_OR_LESS)) {
      return this.localCache.get(Constants.FEE_PARTY_OF_FOUR_OR_LESS);
    }
    this.getServiceFeeForPartyOfFourOrLess(organizationId).subscribe(r => {
      this.localCache.set(Constants.FEE_PARTY_OF_FOUR_OR_LESS, r);
    });
    return 5.00;
  }

  getShoppingCarTotal() {
    this.CartSum = 0;
    this.CartSumTax = 0;
    this.CartSumTotal = 0;
    // this.resetCartInfo();

    for(let i = 0; i < this.ShoppingCart.length; i++) {
      // Cart Info Value Fetch
      // if(this.shoppingCart[i].menuItem.ebtEligible) {
      //   this.cartInfo.ebtSum += this.shoppingCart[i].price;
      // }
      // else {
      //   this.cartInfo.nonEbtSum += this.shoppingCart[i].price;
      // }
      // this.cartInfo.cartSum += this.ShoppingCart[i].price;

      // Cart Tax Calculation
      this.calculateTaxByItems(this.ShoppingCart[i]);

      // Old Code
      this.CartSum = this.CartSum + this.ShoppingCart[i].price;
      if (this.ShoppingCart[i].subShoppingCartItems.length > 0) {
        this.CartSum = this.CartSum + this.shoppingCart[i].subPriceTotal;
      }
      
      // if (this.ShoppingCart[i].removeTax === true || this.EbtCartItems) {
      //   this.CartSumTax = this.CartSumTax + (this.ShoppingCart[i].price) * (0);
      // } else {
      //   this.CartSumTax = this.CartSumTax + (this.ShoppingCart[i].price * BigDecimal.divide(this.ShoppingCart[i].menuItem.taxRate, 100));
      // }
    }

    this.CartSumTotal = this.CartSumTax + this.CartSum;

    // New Total
    // this.cartInfo.cartSumTotal = this.CartSumTax + this.CartSum;

    this.updateObservableCartSumTax(this.CartSumTax);

    this.TenderedAmount = BigDecimal.round(this.CartSumTotal, 2);
    this.updateObservableCartSum(this.CartSum);
    this.updateObservableCartSumTotal(this.CartSumTotal);

    //this.triggerCartRefresh(true);
    return this.CartSumTotal;
  }

  getShoppingCartItemsTotal(shoppingCartItems: Array<ShoppingCartItem>) {
    this.CartSum = 0;
    this.CartSumTax = 0;
    this.CartSumTotal = 0;
    // this.resetCartInfo();

    for (let i = 0; i < shoppingCartItems.length; i++) {
      // Cart Info Value Fetch
      // if(shoppingCartItems[i].menuItem.ebtEligible) {
      //   this.cartInfo.ebtSum += shoppingCartItems[i].price;
      // }
      // else {
      //   this.cartInfo.nonEbtSum += shoppingCartItems[i].price;
      // }
      // this.cartInfo.cartSum += shoppingCartItems[i].price;

      // // Cart Tax Calculation
      this.calculateTaxByItems(shoppingCartItems[i]);

      // Old Code
      this.CartSum = this.CartSum + shoppingCartItems[i].price;
      if (shoppingCartItems[i].subShoppingCartItems.length > 0) {
        this.CartSum = this.CartSum + shoppingCartItems[i].subPriceTotal;
      }
      // if (shoppingCartItems[i].removeTax === true || this.EbtCartItems) {
      //   this.CartSumTax = this.CartSumTax + ((shoppingCartItems[i].price) * (0));
      // } else {
      //   this.CartSumTax = this.CartSumTax + (shoppingCartItems[i].price * BigDecimal.divide(shoppingCartItems[i].menuItem.taxRate, 100));
      // }
    }
    this.CartSumTotal = this.CartSumTax + this.CartSum;

    // New Total
    // this.cartInfo.cartSumTotal = this.CartSumTax + this.CartSum;

    this.TenderedAmount = BigDecimal.round(this.CartSumTotal, 2);
    this.ShoppingCart = shoppingCartItems;

    //this.triggerCartRefresh(true);
    return this.CartSumTotal;
  }

  calculateTaxByItems(item) {
    // if (item.removeTax === true || item.menuItem.ebtEligible) {
    //   this.CartSumTax = this.CartSumTax + (item.price) * (0);
    // } else {
    //   this.CartSumTax = this.CartSumTax + (item.price * BigDecimal.divide(item.menuItem.taxRate, 100));
    // }
    if (item.subShoppingCartItems.length > 0) {
      this.CartSumTax = this.CartSumTax + (item.price * BigDecimal.divide(item.menuItem.taxRate, 100));
      this.CartSumTax = this.CartSumTax + item.subTaxTotal
      // item.subShoppingCartItems.sort((a, b) => {
      //   return b.price - a.price;
      // });
      // item.subShoppingCartItems.forEach((subItem, index) => {
      //   if (index >= item.menuItem.numOfSubMenus) {
      //     this.CartSumTax = this.CartSumTax + (subItem.price * BigDecimal.divide(subItem.taxRate, 100));
      //   }
      // })
    } else {
      this.CartSumTax = this.CartSumTax + (item.price * BigDecimal.divide(item.menuItem.taxRate, 100));
    }
    
    // this.cartInfo.cartSumTax = this.CartSumTax;
  }

  // getCartInfo() {
  //   return this.cartInfo;
  // }

  getChairShoppingCart(chair: Customer, organizationId: number) {
    //this._shared.setGlobalLoader(true);
    this.getUserShoppingCartItemsForChair(chair, organizationId).subscribe((res) => {
      //this._shared.setGlobalLoader(false);
      this.updateObservableItems(res);
      this.ShoppingCart = res;
      for (let i = 0; i < this.ShoppingCart.length; i++) {
        if (this.ShoppingCart[i].weight) {
          this.ShoppingCart[i].menuItem.name = this.ShoppingCart[i].menuItem.name + ' (' + this.ShoppingCart[i].weight + ' lbs)';
        }
      }
      if (this.ShoppingCart.length) {
        this.DisplayShoppingCart = true;
      } else {
        this.DisplayShoppingCart = false;
      }
      this.paymentService.ActiveChair = this.session.get(Constants.ACTIVE_CHAIR);
      this.getObservableItems().subscribe(itms => {
        this.CustomerInvoiceAmount = this.getShoppingCartItemsTotal(res);
        this.ShoppingCart = res;
        this.updateObservableCartSum(this.CartSum);
        this.updateObservableCartSumTax(this.CartSumTax);
        this.updateObservableCartSumTotal(this.CartSumTotal);
      });
    });
    return this.ShoppingCart;
  }

  public getObservableCartSum(): Observable<number> {
    return this.cartSumSubject;
  }

  public updateObservableCartSum(cartSum: number) {
    this.cartSumSubject.next(cartSum);
  }

  public getObservableCartSumTax(): Observable<number> {
    return this.cartSumTaxSubject;
  }

  public updateObservableCartSumTax(cartSumTax: number) {
    this.cartSumTaxSubject.next(cartSumTax);
  }

  public getObservableCartSumTotal(): Observable<number> {
    return this.cartSumTotalSubject;
  }

  public updateObservableCartSumTotal(cartSumTotal: number) {
    this.cartSumTotalSubject.next(cartSumTotal);
  }

  public getObservableItems(): Observable<Array<ShoppingCartItem>> {
    return this.shoppingCartSubject;
  }

  public updateObservableItems(items: Array<ShoppingCartItem>): void {
      this.shoppingCartSubject.next(items);
  }

  set ShoppingCart(shoppingCart: Array<ShoppingCartItem>) {
    this.shoppingCart = shoppingCart;
  }

  get ShoppingCart() {
    return this.shoppingCart;
  }

  set DisplayShoppingCart(displayShoppingCart: boolean) {
    this.displayShoppingCart = displayShoppingCart;
  }

  get DisplayShoppingCart() {
    return this.displayShoppingCart;
  }

  set DisplayTables(displayTables: string) {
    this.displayTables = displayTables;
  }

  get DisplayTables() {
    return this.displayTables;
  }
  
  set ChairToCheckout(chair: Customer) {
    this.chairToCheckout = chair;
  }

  get ChairToCheckout() {
    return this.chairToCheckout;
  }

  set CartSum(cartSum: number) {
    this.cartSum = cartSum;
  }

  get CartSum() {
    return this.cartSum;
  }

  set CartSumTax(cartSumTax: number) {
    this.cartSumTax = cartSumTax;
  }

  get CartSumTax() {
    return this.cartSumTax;
  }

  set CartSumTotal(cartSumTotal: number) {
    this.cartSumTotal = cartSumTotal;
  }

  get CartSumTotal() {
    return this.cartSumTotal;
  }

  set TenderedAmount(tenderedAmount: number) {
    this.tenderedAmount = tenderedAmount;
  }

  get TenderedAmount() {
    return this.tenderedAmount;
  }

  get CustomerInvoiceAmount() {
    return this.customerInvoiceAmount;
  }

  set CustomerInvoiceAmount(customerInvoiceAmount: number) {
    this.customerInvoiceAmount = customerInvoiceAmount;
  }

  set CloseOrderRequest(closeOrderRequest: CloseOrderRequest) {
    this.closeOrderRequest = closeOrderRequest;
  }

  get CloseOrderRequest() {
    return this.closeOrderRequest;
  }

  set EbtCartItems(ebtCartItems: boolean) {
    this.ebtCartItems = ebtCartItems;
  }

  get EbtCartItems() {
    return this.ebtCartItems;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { Constants } from '../models/Constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import {SubMenuItem} from '../models/SubMenuItem';

@Injectable({
    providedIn: 'root'
})
export class SubMenuItemService {

    constructor(private httpClient: HttpClient,
                private session: SessionStorageService,
                private authenticationService: AuthenticationService) { }

    getSubMenuItems(organizationId: number): Observable<Array<SubMenuItem>> {
        return this.httpClient.get<Array<SubMenuItem>>(Constants.ERESTAU_DOMAIN + `/api/subMenuItems?org=${organizationId}`);
    }

    saveSubMenuItem(subMenuItem: SubMenuItem): Observable<any> {
        return this.httpClient.post<SubMenuItem>(Constants.ERESTAU_DOMAIN + '/api/subMenuItems', subMenuItem);
    }
}

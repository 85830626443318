import { ReceiptRequest } from './ReceiptRequest';
import { PaymentResponse } from 'src/app/models/PaymentResponse';
import { ShoppingCartItem } from './ShoppingCartItem';

export class CloseOrderRequest {
    chairId: number;
    organizationId: number;
    paymentResponse: PaymentResponse;
    receiptRequest: ReceiptRequest;
    refNum: string;
    selectedShoppingCartItems: Array<ShoppingCartItem> = [];
}

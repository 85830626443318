import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Organization } from '../models/Organization';
import { Observable } from 'rxjs';
import { Constants } from '../models/Constants';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private httpClient: HttpClient) { }

  getOrganization(organizationId: number): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/organizations/' + `${organizationId}`);
  }

  getOrganizations(): Observable<Array<Organization>> {
    return this.httpClient.get<Array<Organization>>(Constants.ERESTAU_DOMAIN + '/public/api/organizations');
  }

  toggleOrganizationDeliveryAvailability(organizationId: number): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/organizations/toggle/deliveryavailability?org=' + `${organizationId}`, null);
  }

  toggleOrganizationPickupAvailability(organizationId: number): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/organizations/toggle/pickupavailability?org=' + `${organizationId}`, null);
  }
}

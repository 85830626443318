<div class="page-frame">
	<div class="page-frame-header">
		<div class="page-header">
			<div class="d-flex align-items-center justify-content-between">
				<div class="page-logo">
					<i class="fas fa-users"></i>
					Users
				</div>
				<div class="page-buttons">
					<button (click)="addUser()" *ngIf="userService.DisplayUserList" class="btn btn-60 btn-icon-text btn-white">
						<i class="fas fa-user-plus"></i> <span>Add User</span>
					</button>
					<button (click)="resetToDefaultPin()" *ngIf="userService.DisplayUserForm && !isNewUser" class="btn btn-60 btn-icon-text btn-white">
						<i class="fas fa-unlock-alt"></i> <span>Reset Password</span>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="page-frame-body">
		<div class="full-height-container pad-20">
			<div class="shadow-radius-container grow-height">
				<!-- List Start Here -->
				<div class="ag-table-grid" *ngIf="userService.DisplayUserList">
					<ag-grid-angular 
						[columnDefs]="columnDefs"
						[rowData]="rowData"
						[rowSelection]="rowSelection"
						[rowHeight]="'60'"
						[headerHeight]="'40'"
						[rowClass]="'ag-custom-table-row'"
						(gridReady)="onGridReady($event)"
						(rowClicked)="onRowClicked($event)"
						class="ag-theme-alpine user-table">
					</ag-grid-angular>
				</div>
				<!-- List End Here -->
				<!-- Form Start Here -->
				<div class="product-form-container grow-height" *ngIf="userService.DisplayUserForm">
                    <form #userForm="ngForm" (ngSubmit)="saveUser()">
                        <div class="summery-container">
							<div class="summery-container-header">
								<h2><b>Create/Update Users</b></h2>
							</div>
							<div class="summery-container-body pad-l-20 pad-r-20">
								<div class="row">
                                    <div class="col-md-6" style="border-right:2px solid #cdcdcd;">
                                        <div class="form-container pad-r-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="_l_username_id">Username:</label>
                                                </div>
                                                <div class="form-field-control" *ngIf="isNewUser">
													<input  class="form-control" id="_username_id" name="userId" type="text" [(ngModel)]="userFormData.userId" />
                                                </div>
												<div class="form-field-control" *ngIf="!isNewUser">
													<h2>{{userFormData?.userId}}</h2 >
												</div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="_password_id">Password:</label>
                                                </div>
                                                <div class="form-field-control">
													<input class="form-control" id="_password_id" name="pin" type="password"	[(ngModel)]="userFormData.pin" />
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="_l_firstName_id">First Name:</label>
                                                </div>
                                                <div class="form-field-control" *ngIf="isNewUser">
                                                    <input class="form-control" id="_firstName_id" name="firstName" type="text"	[(ngModel)]="userFormData.firstName" />
                                                </div>
												<div class="form-field-control" *ngIf="!isNewUser">
													<h2>{{userFormData?.firstName}}</h2 >
												</div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="_l_middleName_id">Middle Name:</label>
                                                </div>
                                                <div class="form-field-control" *ngIf="isNewUser">
													<input class="form-control" id="_middleName_id" name="middleName" type="text" [(ngModel)]="userFormData.middleName" />
                                                </div>
												<div class="form-field-control" *ngIf="!isNewUser">
													<h2>{{userFormData?.middleName}}</h2 >
												</div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="_l_lastName_id">Last Name:</label>
                                                </div>
                                                <div class="form-field-control" *ngIf="isNewUser">
                                                    <input class="form-control" id="_lastName_id" name="lastName" type="text" [(ngModel)]="userFormData.lastName" />
                                                </div>
												<div class="form-field-control" *ngIf="!isNewUser">
													<h2>{{userFormData?.lastName}}</h2 >
												</div>
                                            </div>
											<div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="_l_phoneNumber_id">Phone Number:</label>
                                                </div>
                                                <div class="form-field-control" *ngIf="isNewUser">
													<input class="form-control" id="_phoneNumber_id" name="phoneNumber" type="text"	[(ngModel)]="userFormData.phoneNumber" />
                                                </div>
												<div class="form-field-control" *ngIf="!isNewUser">
													<h2>{{userFormData?.phoneNumber}}</h2 >
												</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-container pad-l-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="role">Role:</label>
                                                </div>
                                                <div class="form-field-control">
													<select class="form-select" id="role" name="role" [(ngModel)]="userFormData.role">
														<option *ngFor="let userRole of userRoles; let i = index" [value]="userRole.id">
															{{userRole.name}}</option>
													</select>
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="_accountExpiration_id">Account Expiration:</label>
                                                </div>
                                                <div class="form-field-control">
													<div class="mat-datepicker">
														<mat-form-field appearance="outline">
															<input matInput [matDatepicker]="accountPicker" [(ngModel)]="userFormData.accountExpiration" [ngModelOptions]="{standalone: true}" placeholder="Choose a date">
															<mat-datepicker-toggle matSuffix [for]="accountPicker"></mat-datepicker-toggle>
															<mat-datepicker #accountPicker></mat-datepicker>
														</mat-form-field>
													</div>
                                                </div>
                                            </div>
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label for="_credentialExpiration_id">Credential Expiration:</label>
                                                </div>
                                                <div class="form-field-control">
													<div class="mat-datepicker">
														<mat-form-field appearance="outline">
															<input matInput [matDatepicker]="credentialPicker" [(ngModel)]="userFormData.credentialExpiration" [ngModelOptions]="{standalone: true}" placeholder="Choose a date">
															<mat-datepicker-toggle matSuffix [for]="credentialPicker"></mat-datepicker-toggle>
															<mat-datepicker #credentialPicker></mat-datepicker>
														</mat-form-field>
													</div>
                                                </div>
                                            </div>
                                            
											<div class="form-field-row">
												<div class="form-field-label">
													<label>&nbsp;</label>
												</div>
												<div class="form-field-control user-checkbox">
													<div class="form-check-field">
														<span class="custom-check align-self-center">
															<input id="check1" type="checkbox" name="meal" value="{{userFormData.enabled}}"
															[(ngModel)]="userFormData.enabled" [checked]="userFormData.enabled" />
															<label for="check1"><i class="fas fa-check"></i></label>
														</span>
														<label for="check1">Enable Login</label>
													</div>
													<div class="form-check-field">
														<span class="custom-check align-self-center">
															<input id="check2" type="checkbox" name="meal" value="{{userFormData.accountNonLocked}}"
															[(ngModel)]="userFormData.accountNonLocked"	[checked]="userFormData.accountNonLocked" />
															<label for="check2"><i class="fas fa-check"></i></label>
														</span>
														<label for="check2">UnLock Account</label>
													</div>
													<div class="form-check-field">
														<span class="custom-check align-self-center">
															<input id="check3" type="checkbox" name="meal" value="{{userFormData.accountNonExpired}}"
															[(ngModel)]="userFormData.accountNonExpired" [checked]="userFormData.accountNonExpired" />
															<label for="check3"><i class="fas fa-check"></i></label>
														</span>
														<label for="check3">Account Never Expires</label>
													</div>
													<div class="form-check-field">
														<span class="custom-check align-self-center">
															<input id="check4" type="checkbox" name="meal" value="{{userFormData.credentialsNonExpired}}"
															[(ngModel)]="userFormData.credentialsNonExpired" [checked]="userFormData.credentialsNonExpired" />
															<label for="check4"><i class="fas fa-check"></i></label>
														</span>
														<label for="check4">Credentials Never Expires</label>
													</div>
												</div>
											</div>
                                        </div>
                                    </div>
                                </div>
							</div>
							<div class="summery-container-footer">
								<button (click)="getUsers()" class="btn btn-40 btn-danger" type="button">
									<i class="fas fa-times-circle"></i> Cancel
								</button>
								<button  class="btn btn-40 btn-success float-end" type="submit">
									<i class="fas fa-check-circle"></i> Save
								</button>
							</div>
						</div>
                    </form>
                </div>
				<!-- Form End Here -->
			</div>
		</div>
	</div>
</div>
import { Role } from '../enums/Role';

export class User {
 id: number;
 userId: string;
 pin: string;
 firstName: string;
 middleName: string;
 lastName: string;
 phoneNumber: string;
 systemId: string;
 role: Role;
 enabled: boolean = false;
 credentialsNonExpired: boolean = false;
 accountNonExpired: boolean = false;
 accountNonLocked: boolean = false;
 accountExpiration: Date;
 credentialExpiration: Date;
 organizationId: number;
}

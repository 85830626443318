<form>
    <div class="row">
        <div class="col-md-6">
            <div class="form-container">
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Item Name:</label>
                    </div>
                    <div class="form-field">
                        <input type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Description:</label>
                    </div>
                    <div class="form-field">
                        <input type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Price:</label>
                    </div>
                    <div class="form-field">
                        <input currencyMask type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Quantity:</label>
                    </div>
                    <div class="form-field">
                        <input type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Medium Price:</label>
                    </div>
                    <div class="form-field">
                        <input currencyMask type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Small Price:</label>
                    </div>
                    <div class="form-field">
                        <input currencyMask type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Tax Rate:</label>
                    </div>
                    <div class="form-field">
                        <input type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Online Price:</label>
                    </div>
                    <div class="form-field">
                        <input type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Online Tax Rate:</label>
                    </div>
                    <div class="form-field">
                        <input type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Category:</label>
                    </div>
                    <div class="form-field">
                        <select>
                            <option>Test Options</option>
                        </select>
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>EBT Eligible:</label>
                    </div>
                    <div class="form-field">
                        <select>
                            <option [value]="true">True</option>
                            <option [value]="false">False</option>
                        </select>
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Delivery Time:</label>
                    </div>
                    <div class="form-field">
                        <select>
                            <option [value]="5">5 Minutes</option>
                            <option [value]="10">10 Minutes</option>
                            <option [value]="15">15 Minutes</option>
                            <option [value]="20">20 Minutes</option>
                            <option [value]="25">25 Minutes</option>
                            <option [value]="30">30 Minutes</option>
                            <option [value]="35">35 Minutes</option>
                            <option [value]="40">40 Minutes</option>
                            <option [value]="45">45 Minutes</option>
                            <option [value]="50">50 Minutes</option>
                            <option [value]="55">55 Minutes</option>
                            <option [value]="60">1 Hour</option>
                        </select>
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Priced By Weight:</label>
                    </div>
                    <div class="form-field">
                        <select>
                            <option [value]="true">True</option>
                            <option [value]="false">False</option>
                        </select>
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Drink:</label>
                    </div>
                    <div class="form-field">
                        <input type="checkbox" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Alcohol:</label>
                    </div>
                    <div class="form-field">
                        <input type="checkbox" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Special:</label>
                    </div>
                    <div class="form-field">
                        <input type="checkbox" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Meal:</label>
                    </div>
                    <div class="form-field">
                        <input type="checkbox" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Number of Sides:</label>
                    </div>
                    <div class="form-field">
                        <input type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Sort Text:</label>
                    </div>
                    <div class="form-field">
                        <input type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Active:</label>
                    </div>
                    <div class="form-field">
                        <select>
                            <option [value]="'test'">Test</option>
                        </select>
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Active Online:</label>
                    </div>
                    <div class="form-field">
                        <select>
                            <option [value]="true">True</option>
                            <option [value]="false">False</option>
                        </select>
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Digital Active:</label>
                    </div>
                    <div class="form-field">
                        <select>
                            <option [value]="true">True</option>
                            <option [value]="false">False</option>
                        </select>
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Barcode:</label>
                    </div>
                    <div class="form-field">
                        <input type="text" />
                    </div>
                </div>
                <div class="form-field-row">
                    <div class="form-label">
                        <label>Image Url:</label>
                    </div>
                    <div class="form-field">
                        <input type="file" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">

        </div>
    </div>
</form>
import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { OnlineOrderService } from 'src/app/services/online-order.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OnlineOrder } from 'src/app/models/OnlineOrder';
import { OnlineShoppingCartItem } from 'src/app/models/OnlineShoppingCartItem';
import { Constants } from 'src/app/models/Constants';
import { MatTableDataSource } from '@angular/material/table';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-online-orders',
  templateUrl: './online-orders.component.html',
  styleUrls: ['./online-orders.component.css']
})
export class OnlineOrdersComponent implements OnInit, OnDestroy {

  private apiSubscription = new Subscription();
  public onlineOrder: OnlineOrder;
  public onlineOrders: Array<OnlineOrder> = [];
  public onlineShoppingCartItems: Array<OnlineShoppingCartItem> = [];
  public showOnlineOrders = false;
  public showOnlineShoppingCartItems = false;

  public dataSource = null;
  public orderSummaryDataSource = null;
  public shoppingCartDataSource = null;
  public displayedColumns = ['orderNumber', 'name', 'phoneNumber', 'delivery', 'updated', 'deliveryTime', 'status', 'action'];
  public detailsDisplayedColumns = ['cartName', 'cartPhoneNumber', 'cartAddress', 'cartUpdated', 'cartTotalAmount', 'cartStatuscheck', 'cartPrinter', 'cartReturn'];
  public shoppingCartDisplayedColumns = ['menuItemName', 'quantity', 'taxRate', 'price'];

  statues: any = [
    {
      id: Constants.ORDER_STATUS_NEW,
      value: Constants.ORDER_STATUS_NEW
    },
    {
      id: Constants.ORDER_STATUS_BEING_PREPARED,
      value: Constants.ORDER_STATUS_BEING_PREPARED
    },
    {
      id: Constants.ORDER_STATUS_READY,
      value: Constants.ORDER_STATUS_READY
    },
    {
      id: Constants.ORDER_STATUS_FULFILLED,
      value: Constants.ORDER_STATUS_FULFILLED
    },
    {
      id: Constants.ORDER_STATUS_PICKED_UP,
      value: Constants.ORDER_STATUS_PICKED_UP
    },
    {
      id: Constants.ORDER_STATUS_UNKNOWN,
      value: Constants.ORDER_STATUS_UNKNOWN
    }
  ];

  selectedStatus: string = Constants.ORDER_STATUS_UNKNOWN;

  constructor(private onlineOrderService: OnlineOrderService, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.getOnlineOrders();
  }

  getOnlineOrders() {
    const orderSubscription = this.onlineOrderService.getOnlineOrders(this.authenticationService.getOrganizationId()).subscribe((orders) => {
      this.onlineOrders = orders;
      this.showOnlineOrders = true;
      this.showOnlineShoppingCartItems = false;
      this.dataSource = new MatTableDataSource<OnlineOrder>(this.onlineOrders);
    },
    (error) => { console.log(error) });
    this.apiSubscription.add(orderSubscription);
  }

  deleteOnlineOrder(selectedOrder: OnlineOrder, rowIndx: number) {
    const delSubscription = this.onlineOrderService.deleteOnlineShoppingCartItems(selectedOrder.id).subscribe((response) => {
      this.onlineOrders.splice(rowIndx, 1);
      this.dataSource = new MatTableDataSource<OnlineOrder>(this.onlineOrders);
    },
    (error) => { alert(error) });
    this.apiSubscription.add(delSubscription);
  }

  getOnlineShoppingCartItems(selectedOnlineOrder: OnlineOrder) {
    this.selectedStatus = selectedOnlineOrder.status;
    this.onlineOrder = selectedOnlineOrder;
    this.onlineOrders = new Array<OnlineOrder>();
    this.onlineOrders.push(this.onlineOrder);
    this.orderSummaryDataSource = new MatTableDataSource<OnlineOrder>(this.onlineOrders);

    const cartSubscription = this.onlineOrderService.getOnlineShoppingCartItems(this.authenticationService.getOrganizationId(), this.onlineOrder.id).subscribe((cartItems) => {
      this.onlineShoppingCartItems = cartItems;
      this.showOnlineOrders = false;
      this.showOnlineShoppingCartItems = true;
      this.shoppingCartDataSource = new MatTableDataSource<OnlineShoppingCartItem>(cartItems);
    },
    (error) => { console.log(error) });
    this.apiSubscription.add(cartSubscription);
  }

  onStatusSeletion(order: OnlineOrder) {
    order.status = this.selectedStatus;
    const statusSubscription = this.onlineOrderService.updateOnlineOrderStatus(order).subscribe((response) => {
    },
    (error) => { console.log(error) });
    this.apiSubscription.add(statusSubscription);
  }

  updateDeliveryTime(order: OnlineOrder) {
    // alert("Delivery time updated to [ " + order.fulfillmentTime + " ] minutes");
    const deliverySubscription = this.onlineOrderService.updateOrderDeliveryTime(order).subscribe((response) => {
    },
    (error) => { console.log(error) });
    this.apiSubscription.add(deliverySubscription);
  }

  print(order: OnlineOrder) {
    const printSubscription = this.onlineOrderService.printOrder(order.id).subscribe((response) => {
      alert('{"Code" : "' + response.code + '", "Message" : "' + response.message + '"}');
    },
    (error) => {alert(error)});
    this.apiSubscription.add(printSubscription);
  }

  ngOnDestroy() {
    this.apiSubscription.unsubscribe();
  }
}

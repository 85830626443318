import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Customer } from '../models/Customer';
import { User } from '../models/User';
import { Constants } from '../models/Constants';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private activeChairs: Array<Customer> = [];
  private tables: Array<User> = [];

  constructor(private httpClient: HttpClient, private session: SessionStorageService) { }

  saveTable(customers: Array<Customer>): Observable<Array<Customer>> {
    return this.httpClient.post<Array<Customer>>(Constants.ERESTAU_DOMAIN + '/api/customers', customers);
  }

  saveAdditionalTableChair(customer: Customer): Observable<Customer> {
    return this.httpClient.post<Customer>(Constants.ERESTAU_DOMAIN + '/api/customer', customer);
  }

  getTables(organizationId: number): Observable<Array<User>> {
    return this.httpClient.get<Array<User>>(Constants.ERESTAU_DOMAIN + `/api/tables?org=${organizationId}`);
  }

  getTableChairs(tableId: number, organizationId: number): Observable<Array<Customer>> {
    return this.httpClient.get<Array<Customer>>(Constants.ERESTAU_DOMAIN + `/api/chairs/${tableId}?org=${organizationId}`);
  }

  getActiveTableChairs(tableId: number, organizationId: number): Observable<Array<Customer>> {
    return this.httpClient.get<Array<Customer>>(Constants.ERESTAU_DOMAIN + `/api/chairs/${tableId}?org=${organizationId}`);
  }

  get ActiveChairs() {
    return this.activeChairs;
  }

  set ActiveChairs(activeChairs: Array<Customer>) {
    this.activeChairs = activeChairs;
  }

  get Tables() {
    return this.tables;
  }

  set Tables(tables: Array<User>) {
    this.tables = tables;
  }
}
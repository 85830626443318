import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private _globalLoader: Subject<boolean> = new Subject<boolean>();
  public globalLoader: Observable<boolean> = this._globalLoader.asObservable();

  private _websocketLoader: Subject<any> = new Subject<any>();
  public webSocketLoader: Observable<any> = this._websocketLoader.asObservable();

  private _barcodeFocusEvent: Subject<any> = new Subject<any>();
  public barcodeFocusEvent = this._barcodeFocusEvent.asObservable();

  public loaderStatus: boolean;

  constructor() { }

  setGlobalLoader(response) {
    // if (response != this.loaderStatus) {
    //   this.loaderStatus = response;
      this._globalLoader.next(response);
    // }
  }

  setWebSocketLoader(response, status?) {
    let obj = {
      flag: response,
      status: status? status: null
    };
    this._websocketLoader.next(obj);
  }

  setBarcodeFocus() {
    this._barcodeFocusEvent.next(true);
    this._barcodeFocusEvent.next(false);
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Property} from '../../models/Property';
import {SettingsService} from '../../services/settings.service';

@Component({
  selector: 'app-batch-close',
  templateUrl: './batch-close.component.html',
  styleUrls: ['./batch-close.component.css']
})
export class BatchCloseComponent implements OnInit {
  description: string;
  message: string;
  availableTerminals: Array<Property> = [];

  constructor(private dialogRef: MatDialogRef<BatchCloseComponent>, @Inject(MAT_DIALOG_DATA) data, public settingsService: SettingsService) {
    this.description = data.description;
    this.message = data.message;
    this.availableTerminals = JSON.parse(data.message);
  }

  ngOnInit() {
    this.settingsService.SelectedTerminal = 'NONE';
  }

  onOkClick(): void {
    this.dialogRef.close(true);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

}

<div class="footer" *ngIf="toggleService.ShowFooterIcon == true">
    <div class="user-nav">
        <button (click)="home()" class="btn btn-success btn-60 btn-icon">
            <i class="fas fa-home"></i>
        </button>
        <button (click)="logout()" class="btn btn-danger btn-60 btn-icon">
            <i class="fas fa-power-off"></i>
        </button>
    </div>
    <div class="user-details">
        <h2>{{authenticationService.OrganizationName}} <span><i class="far fa-user"></i> {{authenticationService.Username}}</span></h2>
    </div>
    <div class="software-version">
        Version {{ version }}
    </div>
    <div class="footer-controls">
        <ul>
            <li (click)="toggleOrganizationDeliveryAvailability()" [ngClass]="{ 'success': toggleService.DeliveryAvailable == true, 'danger': toggleService.DeliveryAvailable == false }">
                <i class="fas fa-truck"></i>
            </li>
            <li (click)="toggleOrganizationPickupuAvailability()" [ngClass]="{ 'success': toggleService.PickupAvailable == true, 'danger': toggleService.PickupAvailable == false }">
                <i class="fas fa-walking"></i>
            </li>
            <li *ngIf="session.get('identity').role !== 'ROLE_MANAGER'">
                <i class="fas fa-laptop"></i>
            </li>
            <li *ngIf="session.get('identity').role === 'ROLE_MANAGER'" (click)="openCashDrawer()">
                <i class="fas fa-laptop"></i>
            </li>
        </ul>
    </div>
</div>

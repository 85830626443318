
<div class="page-frame">
    <div class="page-frame-header">
        <div class="setting-tabs">
            <ul>
                <li *ngFor="let item of settingTab" [ngClass]="{ 'active': item.isActive }" (click)="settingTabSwitch(item)">
                    <span class="setting-tab-icon"> 
                        <i class="fas fa-cog"></i>
                    </span>
                    <label>
                        {{ item.name }}
                        <span>{{ item.description }}</span>
                    </label>
                </li>
            </ul>
        </div>
    </div>
    <div class="page-frame-body">
        <div class="full-height-container pad-20" *ngIf="activeTabId == 1">
            <div class="shadow-radius-container full-height-container grow-height">
                <div class="add-row" *ngIf="showPropertyList">
                    <button (click)="addProperty()" class="btn btn-45-f btn-white" type="button">
                        <i class="fas fa-plus"></i> Add
                    </button>
                </div>
                <div class="mat-table-container grow-height" style="overflow: auto;" *ngIf="showPropertyList">
                    <table mat-table [dataSource]="dataSource">
                  
                        <!-- Setting Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let property"> {{property.name}} </td>
                        </ng-container>
                    
                        <!-- Setting Value Column -->
                        <ng-container matColumnDef="value">
                            <th mat-header-cell *matHeaderCellDef> Value </th>
                            <td mat-cell *matCellDef="let property"> {{property.value}} </td>
                        </ng-container>
                
                        <!-- Action Edit -->
                        <ng-container matColumnDef="actionDetails">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell class="action" *matCellDef="let property; let i = index" style="min-width: 150px">
                                <i class="fa fa-edit not-clicable" *ngIf="property.name === 'DISABLE_DELETE_RECEIPT' && property.value === 'TRUE'"></i>
                                <i class="fa fa-trash-alt text-gray not-clicable" *ngIf="property.name === 'DISABLE_DELETE_RECEIPT' && property.value === 'TRUE'"></i>
                                <i (click)="editProperty(property)" class="fa fa-edit text-light-blue" *ngIf="property.name !== 'DISABLE_DELETE_RECEIPT'"></i>
                                <i (click)="deleteProperty(property, i)" class="fa fa-trash-alt text-red" *ngIf="property.name !== 'DISABLE_DELETE_RECEIPT'"></i>
                            </td>
                        </ng-container>
                
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
				</div>

                <div class="property-form-container grow-height" *ngIf="showPropertyForm">
                    <form #propertiesForm="ngForm" class="grow-height full-width" (ngSubmit) ="saveSetting()">
                        <div class="summery-container">
							<div class="summery-container-header">
								<h2><b>Create/Update Property</b></h2>
							</div>
							<div class="summery-container-body pad-l-20 pad-r-20">
								<div class="row">
                                    <div class="col-md-6" style="border-right:2px solid #cdcdcd;">
                                        <div class="form-container size-md pad-r-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Name:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <select id="property" name="property" [(ngModel)]="property.name" class="form-select">
                                                        <option *ngFor="let property of properties; let i = index" [value]="property.value">{{property.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-container size-md pad-l-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label>Value:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input name="propertyValue" [(ngModel)]="property.value" type="text" id="name" class="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
							</div>
							<div class="summery-container-footer">
								<button (click)="getProperties()" class="btn btn-45-f btn-danger" type="button">
									<i class="fas fa-times-circle"></i> Cancel
								</button>
                                <button  class="btn btn-45-f btn-success float-end" type="submit">
									<i class="fas fa-check-circle"></i> Save
								</button>
							</div>
						</div>
                    </form>
                </div>
            </div>
        </div>
        <div class="full-height-container pad-20" *ngIf="activeTabId == 2">
            <div class="shadow-radius-container full-height-container grow-height" *ngIf="showClientEnvPropertyList">
                <div class="property-form-container grow-height">
                    <div class="summery-container">
                        <div class="summery-container-header">
                            <h2><b>Create/Update Property</b></h2>
                        </div>
                        <div class="summery-container-body">
                            <div class="dot-table">
                                <div class="dot-table-header">
                                    <div class="dot-table-row">
                                        <div class="row"> 
                                            <div class="col text-start">
                                                <span>Name</span>
                                            </div>
                                            <div class="col text-start">
                                                <span>Value</span>
                                            </div>
                                            <div class="col text-end">
                                                <span>Terminal</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dot-table-body">
                                    <div class="dot-table-row" *ngFor="let item of clientEnvironmentProperties; let i = index">
                                        <div class="row align-items-center">
                                            <div class="col text-start">
                                                <label>{{ item.propertyName }}</label>
                                            </div>
                                            <div class="col text-start">
                                                <input class="form-control" name="propertyValue{{i}}" [(ngModel)]="item.propertyValue" type="text" id="propertyValue{{i}}" />
                                            </div>
                                            <div class="col text-end">
                                                <label>{{ item.terminal }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summery-container-footer">
                            <button (click)="restartClientService()" class="btn btn-45-f btn-primary" type="button">
                                <i class="fas fa-play"></i> Restart Service
                            </button>
                            <button  class="btn btn-45-f btn-success float-end" type="button" (click)="saveClientEnvironmentProperties()">
                                <i class="fas fa-check-circle"></i> Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="full-height-container pad-20" *ngIf="activeTabId == 3">
            <div class="shadow-radius-container full-height-container grow-height">
                <div class="property-form-container grow-height" *ngIf="showMerchantForm">
                    <form #propertiesForm="ngForm" class="grow-height full-width" (ngSubmit) ="saveSetting()">
                        <div class="summery-container">
							<div class="summery-container-header">
								<h2><b>Update merchant information</b></h2>
							</div>
							<div class="summery-container-body pad-l-20 pad-r-20">
								<div class="row">
                                    <div class="col-md-6">
                                        <div class="form-container size-md pad-r-10">
                                            <div class="form-field-row">
                                                <div class="form-field-label">
                                                    <label style="white-space: nowrap;">Delivery Fee Amount:</label>
                                                </div>
                                                <div class="form-field-control">
                                                    <input name="deliveryFeeAmount" [(ngModel)]="merchant.deliveryFeeAmount" type="text" id="name" class="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
							</div>
							<div class="summery-container-footer">
								<button  class="btn btn-45-f btn-success float-end" (click)="saveMerchantInfo()" type="submit">
									<i class="fas fa-check-circle"></i> Save
								</button>
							</div>
						</div>
                    </form>
                </div>

            </div>
        </div>
        <div class="full-height-container pad-20" *ngIf="activeTabId == 4">
            <div class="shadow-radius-container full-height-container grow-height" *ngIf="showUserTerminalForm">
                <div class="property-form-container grow-height">
                    <form #merchantForm="ngForm" class="full-width grow-height" (ngSubmit) ="assignUserTerminal()">
                        <div class="summery-container">
                            <div class="summery-container-header">
                                <h2><b>Update User Terminal</b></h2>
                            </div>
                            <div class="summery-container-body">
                                <div class="dot-table">
                                    <div class="dot-table-header">
                                        <div class="dot-table-row">
                                            <div class="row"> 
                                                <div class="col text-start">
                                                    <span>Username</span>
                                                </div>
                                                <div class="col text-start">
                                                    <span>Terminal ID</span>
                                                </div>
                                                <div class="col text-end">
                                                    <span>Terminal Name</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-table-body">
                                        <div class="dot-table-row">
                                            <div class="row align-items-center">
                                                <div class="col text-start">
                                                    <label>{{authenticationService.getIdentity().username}}</label>
                                                </div>
                                                <div class="col text-start">
                                                    <label>{{currentUserTerminal?.name}}</label>
                                                </div>
                                                <div class="col text-end">
                                                    <ng-container *ngIf="!showEditTerminalForm">
                                                        <label>{{currentUserTerminal?.description}} <i (click)="editTerminalForm()" style="cursor: pointer;" class="fas fa-pencil-alt"></i></label>
                                                    </ng-container>
                                                    <ng-container *ngIf="showEditTerminalForm">
                                                        <select class="form-select" id="terminalName" name="terminalName" [(ngModel)]="userTerminal.name">
                                                            <option value="NONE">--- Select Terminal ---</option>
                                                            <option *ngFor="let property of availableTerminals; let i = index" [value]="property.name">{{property.value}}</option>
                                                        </select>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="summery-container-footer" *ngIf="showEditTerminalForm">
                                <button  class="btn btn-45-f btn-success float-end" type="button" (click)="assignUserTerminal()">
                                    <i class="fas fa-check-circle"></i> Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


<h3 matDialogTitle>Inner HTML Dialog</h3>
<mat-dialog-content>
    <span [innerHtml]="htmlContent"></span>
    <h2>Hello World!!</h2>
    <span style="font-weight: bold; font-size: 2em; color: #000000;">Quantity:</span>
    <span><input size="10" type="text" [(ngModel)]="menuItemService.LabelQuantity" style="font-size: 1.5em; font-weight: bold;"/></span>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button matDialogClose color="primary">Close Dialog</button>
</mat-dialog-actions>

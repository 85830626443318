<div class="row grow-height space-0" *ngIf="inputItem">
    <div class="col-md-6" style="border-right:2px solid #cdcdcd;">
        <div class="summery-container">
            <div class="summery-container-header">
                <div class="row space-0">
                    <div class="col d-flex">
                        <h2 class="align-self-center"><b>{{ inputItem.menuItem.name }}</b></h2>
                    </div>
                    <div class="col-md-auto" *ngIf="!openSideAdd && inputItem.isEditMode">
                        <button type="button" (click)="toggleAddSide()" class="btn btn-40 btn-white float-end">
                            <i class="fas fa-plus"></i> Add Sides
                        </button>
                    </div>
                </div>
            </div>
            <div class="summery-container-body">
                <!-- <div *ngIf="showMatLoader" style="padding-top: 30px; padding-bottom: 30px;"><mat-spinner></mat-spinner></div> -->
                <div class="dot-table">
                    <div class="dot-table-body" style="border-top: 1px dashed #cdcdcd;">
                        <div class="dot-table-row">
                            <div class="row align-items-center">
                                <div class="col-md-7">
                                    <label>Quantity :</label>
                                </div>
                                <div class="col text-end">
                                    <label *ngIf="!inputItem.isEditMode">{{ sideForm.quantity }}</label>
                                    <input *ngIf="inputItem.isEditMode" (focus)="bindKeyPad('quantity')" type="number" [(ngModel)]="sideForm.quantity" class="form-control" /> 
                                </div>
                            </div>
                        </div>
                        <div class="dot-table-row">
                            <div class="row align-items-center">
                                <div class="col-md-7">
                                    <label>Price :</label>
                                </div>
                                <div class="col text-end">
                                    <label *ngIf="!inputItem.isEditMode">{{ sideForm.price | currency }}</label>
                                    <input *ngIf="inputItem.isEditMode" (focus)="bindKeyPad('price')" type="text" currencyMask [(ngModel)]="sideForm.price" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="dot-table-row">
                            <div class="row align-items-center">
                                <div class="col-md-2">
                                    <label>Sides :</label>
                                </div>
                                <div class="col-md-10">
                                    <p class="info-message">Info: {{ inputItem.menuItem.numOfSubMenus }} Side ITems are free with the product rest will be chargable.</p>
                                    <ng-container *ngIf="selectedSides.length > 0" >
                                        <div class="row space-10">
                                            <ng-container *ngFor="let item of selectedSides; let index = index;">
                                                <div class="col-md-6" *ngIf="!item.preference">
                                                    <div class="heightlight-item compact">
                                                        <div class="row align-items-center">
                                                            <div class="col">
                                                                <label>{{ item.name }}</label>
                                                                <p>{{ item.price | currency }}</p>
                                                            </div>
                                                            <div class="col" style="max-width: 60px;">
                                                                <span class="dot-table-action-icon" (click)="removeSide(item, index)"><i class="fas fa-window-close"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="dot-table-row" *ngIf="isPreference || inputItem.isEditMode">
                            <div class="row align-items-center">
                                <div class="col-md-7">
                                    <div class="select-field-row" *ngIf="isPreference">
                                        <label>Preference :</label>
                                        <select (change)="updatePreference()" [(ngModel)]="sideForm.preference" class="form-select">
                                            <ng-container *ngFor="let item of inputItem.menuItem.subMenuItems; let index = $index;">
                                                <option [value]="item.name" *ngIf="item.preference">{{ item.name }}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                                <div class="col d-flex align-items-center justify-content-end" *ngIf="inputItem.isEditMode">
                                    <label>Permanent?:</label>
                                    <span class="custom-check align-self-center" style="margin-left: 5px;">
                                        <input 
                                            id= "check1"
                                            type="checkbox" 
                                            name="pricePermanent"
                                            value="{{sideForm.pricePermanent}}"
                                            [(ngModel)]="sideForm.pricePermanent" 
                                            [checked]="sideForm.pricePermanent" 
                                        />
                                        <label for="check1"><i class="fas fa-check"></i></label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="summery-container-footer">
                <button class="btn btn-45-f btn-danger" (click)="cancelSideItem()" type="button">
                    <i class="fas fa-times-circle"></i> Cancel
                </button>
                <button *ngIf="inputItem.isEditMode" class="btn btn-45-f btn-success float-end" (click)="sendSelectedSides()" type="button">
                    <i class="fas fa-check-circle"></i> Save
                </button>
                <button *ngIf="!inputItem.isEditMode" class="btn btn-45-f btn-success float-end" (click)="sendSelectedSides()" type="button">
                    <i class="fas fa-check-circle"></i> Add
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="keypad-container" *ngIf="!openSideAdd">
            <ng-container *ngIf="activeKeypad == 'quantity'">
                <app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'sideForm.quantity'" [inputValue]="sideForm.quantity" (outputValue)="setAmount($event)" [specialKeys]="false" [isPrice]="false" [isDotAllowed]="false"></app-keypad>
            </ng-container>
            <ng-container *ngIf="activeKeypad == 'price'">
                <app-keypad [bindKeyPad]="bindKeypad" [bindKey]="'sideForm.price'" [inputValue]="sideForm.price" (outputValue)="setAmount($event)" [specialKeys]="false" [isPrice]="true" [isDotAllowed]="true"></app-keypad>
            </ng-container>
        </div>
        <div class="summery-container" *ngIf="openSideAdd">
            <div class="summery-container-header">
                <div class="row space-0">
                    <div class="col d-flex">
                        <h2 class="align-self-center"><b>Available Side Items</b></h2>
                    </div>
                    <div class="col-md-auto" *ngIf="inputItem.isEditMode">
                        <button type="button" (click)="toggleAddSide()" class="btn btn-40 btn-white float-end">
                            <i class="far fa-times-circle"></i> Close
                        </button>
                    </div>
                </div>
            </div>
            <div class="summery-container-body">
                <div class="dot-table">
                    <div class="dot-table-body">
                        <div class="dot-table-row" style="border:none;">
                            <ng-container *ngFor="let item of availableSides; let index = index">
                                <div class="heightlight-item compact" *ngIf="!item.preference">
                                    <div class="row align-items-center">
                                        <div class="col-md-7">
                                            <label>{{ item.name }}</label>
                                        </div>
                                        <div class="col">
                                            <label>{{ item.price | currency }}</label>
                                        </div>
                                        <div class="col" style="max-width: 60px;">
                                            <span class="dot-table-action-icon" (click)="addToSelection(item, index)"><i class="fas fa-plus-square"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MenuItem} from '../models/MenuItem';
import {Observable, Subject, of} from 'rxjs';
import {Category} from '../models/Category';
import {Constants} from '../models/Constants';
import {LocalStorageService} from 'angular-web-storage';
import {switchMap} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {CategoryService} from './category.service';

@Injectable({
    providedIn: 'root'
})
export class MenuItemService {

    private menuItems: Array<MenuItem> = [];
    private searchableMenuItems: Array<MenuItem> = [];
    private orderMenuItems: Array<MenuItem> = [];
    private newMenuItem: MenuItem;
    private weightedItem = false;
    private capturedWeight: number;
    private labelQuantity = 1;
    private labelItemName: string;
    private selectedItem: any;
    private labelSize = 'large';
    private embeddedBarcodePrice = false;

    private _getProducts: Subject<any> = new Subject();
    public getProducts = this._getProducts.asObservable();

    constructor(private httpClient: HttpClient, private storage: LocalStorageService, private authenticationService: AuthenticationService, private categoryService: CategoryService) {
    }

    printBarcodeLabel(menuItem: MenuItem): Observable<any> {
        return this.httpClient.post<MenuItem>(Constants.ERESTAU_DOMAIN + '/api/labels/printBarcode', menuItem);
    }

    getBarcodePrice(barcode: string): Observable<any> {
        return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/menuitem/barcodeprice/' + `${barcode}`);
    }

    saveMenuItem(menuItem: MenuItem, file: File): Observable<any> {
        const data: FormData = new FormData();
        data.append('file', file);
        data.append('menuItem', JSON.stringify({menuItem}));

        if (menuItem.id && menuItem.id > 0) {
            const items = this.getMenuItemsFromLocalStorage();
            const foundIndex = items.findIndex(itm => itm.id === menuItem.id);
            items[foundIndex] = menuItem;
            this.storeMenuItemsInLocalStorage(items);
            return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/menuItem', data);
        }

        return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/menuItem', data).pipe(
            switchMap((mi) => {
                const items = this.getMenuItemsFromLocalStorage();
                items.push(mi);
                this.storeMenuItemsInLocalStorage(items);
                return of(mi);
            })
        );
    }

    deleteMenuItem(id: number) {
        return this.httpClient.delete<any>(Constants.ERESTAU_DOMAIN + '/api/menuItem/' + `${id}`);
    }

    inventoryAddMenuItem(menuItem: MenuItem, file: File): Observable<any> {
        const data: FormData = new FormData();
        data.append('file', file);
        data.append('menuItem', JSON.stringify({menuItem}));
        return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/menuItem/inventoryAdd', data).pipe(
            switchMap((mi) => {
                const items = this.getMenuItemsFromLocalStorage();
                items.push(mi);
                this.storeMenuItemsInLocalStorage(items);
                return of(mi);
            })
        );
    }

    updateMenuItemCount(menuItem: MenuItem): Observable<any> {
        const items = this.getMenuItemsFromLocalStorage();
        const foundIndex = items.findIndex(itm => itm.id === menuItem.id);
        items[foundIndex] = menuItem;
        this.storeMenuItemsInLocalStorage(items);
        return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/menuItem/update', menuItem);
    }

    quickAddMenuItem(menuItem: MenuItem): Observable<any> {
        return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/menuItem/quickAdd', menuItem).pipe(
            switchMap((mi) => {
                const items = this.getMenuItemsFromLocalStorage();
                items.push(mi);
                this.storeMenuItemsInLocalStorage(items);
                return of(mi);
            })
        );
    }

    getMenuItems(organizationId: number): Observable<Array<MenuItem>> {
        const items = this.getMenuItemsFromLocalStorage();
        if (!items) {
            return this.httpClient.get<Array<MenuItem>>(Constants.ERESTAU_DOMAIN + `/api/menuItems?org=${organizationId}`).pipe(
                switchMap((mi) => {
                    this.storeMenuItemsInLocalStorage(mi);
                    return of(mi);
                })
            );
        }
        return of(items);
    }

    updateMenuItems(organizationId: number) {
        const items = this.getMenuItemsFromLocalStorage();
        if (items) {
            this._getProducts.next(items);
        }
        this.httpClient.get<Array<MenuItem>>(Constants.ERESTAU_DOMAIN + `/api/menuItems?org=${organizationId}`).pipe(
            switchMap((mi) => {
                this.storeMenuItemsInLocalStorage(mi);
                return of(mi);
            })
        ).subscribe((itm: any) => {
            this._getProducts.next(itm);
        });
    }

    /**
     *
     * @param organizationId -
     * @param barcode -
     */
    getMenuItem(organizationId: number, barcode: string): Observable<MenuItem> {
        const items = this.getMenuItemsFromLocalStorage();
        if (barcode.startsWith('2')) {
            const productNumber = barcode.substring(1, barcode.length - 6);
            const pNumberIndex = items.findIndex(itm => itm.number === Number(productNumber));
            if (pNumberIndex >= 0) {
                const found = items[pNumberIndex];
                if (found.pricedByWeight === true) {
                    const price = this.extractPrice(barcode, found.onPremiseBarcode);
                    const menuItem = new MenuItem();
                    menuItem.id = found.id;
                    menuItem.name = found.name;
                    menuItem.price = price;
                    menuItem.pricedByWeight = false;
                    menuItem.category = found.category;
                    menuItem.ebtEligible = found.ebtEligible;
                    menuItem.taxRate = found.taxRate;
                    menuItem.organizationId = found.organizationId;
                    menuItem.stock = found.stock;
                    menuItem.description = found.description;
                    menuItem.active = found.active;
                    this.EmbeddedBarcodePrice = true;
                    return of(menuItem);
                }
            }
            // else {
            //     /* Create default product by weight called Produce/Meat/Fish */
            //     const price = this.extractPrice(barcode, false);
            //     const menuItem = new MenuItem();
            //     menuItem.name = 'Produce/Meat/Fish';
            //     menuItem.price = price;
            //     menuItem.pricedByWeight = false;
            //     // menuItem.category = found.category;
            //     menuItem.ebtEligible = true;
            //     menuItem.taxRate = 3.0;
            //     menuItem.organizationId = organizationId;
            //     menuItem.stock = 1;
            //     menuItem.description = 'Weighted grocery item';
            //     menuItem.active = true;
            //     this.EmbeddedBarcodePrice = true;
            //     return of(menuItem);
            // }
        } else {
            this.EmbeddedBarcodePrice = false;
        }
        const foundIndex = items.findIndex(itm => itm.barcode === barcode);
        if (foundIndex >= 0) {
            const found = items[foundIndex];
            return of(found);
        }
        return this.httpClient.get<MenuItem>(Constants.ERESTAU_DOMAIN + `/api/menuItem/find/${barcode}?org=${organizationId}`).pipe(
            switchMap((mi) => {
                items.push(mi);
                this.storeMenuItemsInLocalStorage(items);
                return of(mi);
            })
        );
    }

    getMenuItemsByCategory(category: Category, organizationId: number): Observable<Array<any>> {
        let items = this.getMenuItemsFromLocalStorage();
        if (items === null) {
            items = new Array<MenuItem>();
        }
        const categories = this.categoryService.getCategoriesFromLocalStorage();
        const foundSubCategories = categories.filter(cat => cat.parent);
        /* Find if there any sub categories for the categoryId being passed */
        const filteredSubCategories = foundSubCategories.filter(sc => sc.parent.id === category.id);
        if (filteredSubCategories.length > 0) {
            return of(filteredSubCategories);
        }
        const foundMenuItems = items.filter(itm => itm.category.id === category.id);
        if (foundMenuItems.length > 0) {
            /*
             * If menu items are found and the category for that menu item has sub categories, then
             * we want to return the sub categories instead
             */
            const firstElement = foundMenuItems[0];
            const foundIndex = foundSubCategories.findIndex(sc => sc.parent.id === firstElement.category.id);
            if (foundIndex >= 0) {
                return of(foundSubCategories);
            }
        }
        return of(foundMenuItems);
    }

    extractPrice(barcode: string, onPremiseBarcode: boolean): number {
        if (barcode && barcode.length === 12) {
            const centPrice = barcode.substring(9, barcode.length - 1);
            let dollarPrice = barcode.substring(7, barcode.length - 3);
            if (onPremiseBarcode) {
                dollarPrice = barcode.substring(6, barcode.length - 3);
            }
            return parseFloat(dollarPrice + '.' + centPrice);
        }
        return -0.00;
    }

    updateLocalStorage(menuItem: MenuItem) {
        const items = this.getMenuItemsFromLocalStorage();
        const foundIndex = items.findIndex(mi => mi.id === menuItem.id);
        items[foundIndex] = menuItem;
        this.storeMenuItemsInLocalStorage(items);
    }

    getMenuItemsFromLocalStorage() {
        return JSON.parse(this.storage.get(this.getMenuItemStorageKey()));
    }

    storeMenuItemsInLocalStorage(items: any) {
        this.storage.set(this.getMenuItemStorageKey(), JSON.stringify(items));
    }

    initMenuItemsInLocalStorage(organizationId: number) {
        return this.httpClient.get<Array<MenuItem>>(Constants.ERESTAU_DOMAIN + `/api/menuItems?org=${organizationId}`)
            .pipe(
                switchMap(items => {
                    this.storage.set(this.getMenuItemStorageKey(), JSON.stringify(items));
                    return of(items);
                })
            );
    }

    getMenuItemStorageKey() {
        return Constants.STORED_ITEMS + '_' + this.authenticationService.getOrganizationId();
    }

    set MenuItems(menuItems: Array<MenuItem>) {
        this.menuItems = menuItems;
    }

    get MenuItems() {
        return this.menuItems;
    }

    set SearchableMenuItems(searchableMenuItems: Array<MenuItem>) {
        this.searchableMenuItems = searchableMenuItems;
    }

    get SearchableMenuItems() {
        return this.searchableMenuItems;
    }

    set OrderMenuItems(orderMenuItems: Array<MenuItem>) {
        this.orderMenuItems = orderMenuItems;
    }

    get OrderMenuItems() {
        return this.orderMenuItems;
    }

    set NewMenuItem(newMenuItem: MenuItem) {
        this.newMenuItem = newMenuItem;
    }

    get NewMenuItem() {
        return this.newMenuItem;
    }

    set LabelQuantity(labelQuantity: number) {
        this.labelQuantity = labelQuantity;
    }

    get LabelQuantity() {
        return this.labelQuantity;
    }

    set LabelItemName(labelItemName: string) {
        this.labelItemName = labelItemName;
    }

    get LabelItemName() {
        return this.labelItemName;
    }

    set LabelSize(labelSize: string) {
        this.labelSize = labelSize;
    }

    get LabelSize() {
        return this.labelSize;
    }

    set CapturedWeight(capturedWeight: number) {
        this.capturedWeight = capturedWeight;
    }

    get CapturedWeight() {
        return this.capturedWeight;
    }

    set WeightedItem(weightedItem: boolean) {
        this.weightedItem = weightedItem;
    }

    get WeightedItem() {
        return this.weightedItem;
    }

    set EmbeddedBarcodePrice(embeddedBarcodePrice: boolean) {
        this.embeddedBarcodePrice = embeddedBarcodePrice;
    }

    get EmbeddedBarcodePrice() {
        return this.embeddedBarcodePrice;
    }

    saveSelectedItem(seleced) {
        this.selectedItem = seleced;
    }

    getSelectedItem() {
        return this.selectedItem;
    }
}

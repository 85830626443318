<div class="keypad" [ngClass]="{'special-keys': specialKeys}">
    <div class="keypad-column">
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('7')">7</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('4')">4</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('1')">1</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('0')">0</button>
        </div>
    </div>
    <div class="keypad-column">
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('8')">8</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('5')">5</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('2')">2</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('00')">00</button>
        </div>
    </div>
    <div class="keypad-column">
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('9')">9</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('6')">6</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('3')">3</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn" (click)="pressKey('.')">.</button>
        </div>
    </div>
    <div class="keypad-column" *ngIf="specialKeys">
        <div class="keypad-col">
            <button type="button" class="keypad-btn dark-key" (click)="pressKey('1', true)">$1</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn dark-key" (click)="pressKey('5', true)">$5</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn dark-key" (click)="pressKey('10', true)">$10</button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn dark-key" (click)="pressKey('20', true)">$20</button>
        </div>
    </div>
    <div class="keypad-column">
        <div class="keypad-col">
            <button type="button" class="keypad-btn orange-key" (click)="pressKey('back')"><i class="fas fa-backspace"></i></button>
        </div>
        <div class="keypad-col">
            <button type="button" class="keypad-btn orange-key" (click)="pressKey('clear')">Clr</button>
        </div>
        <div class="keypad-col" *ngIf="specialKeys">
            <button type="button" class="keypad-btn dark-key" (click)="pressKey('50', true)">$50</button>
        </div>
        <div class="keypad-col" *ngIf="specialKeys">
            <button type="button" class="keypad-btn dark-key" (click)="pressKey('100', true)">$100</button>
        </div>
    </div>
</div>
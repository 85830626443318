export class BigDecimal {

    public static round(nbr: number, decimalPlaces: number) {
        const factorOfTen = Math.pow(10, decimalPlaces);
        return Math.round(nbr * factorOfTen) / factorOfTen;
    }

    public static add(num1: number, num2: number) {
        return this.round(num1, 2) +  this.round(num2, 2);
    }

    public static diff(num1: number, num2: number) {
        return this.round(num1, 2) -  this.round(num2, 2);
    }

    public static divide(numerator: number, denominator: number) {
        return this.round(this.round(numerator, 2) / this.round(denominator, 2), 2);
    }

    public static percent(percentage: number, value: number) {
        return this.round((this.round(value, 2) * (this.round(percentage, 2) / 100)), 2);
    }
}

import { User } from './User';
import { ShoppingCartItem } from './ShoppingCartItem';

export class ReceiptRequest {
    organizationId: number;
    chairId: number;
    paymentType: string;
    invoiceAmount: number;
    tenderedAmount: number;
    processAsDebit: boolean;
    user: User;
    selectedShoppingCartItems: Array<ShoppingCartItem> = [];
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { SubMenuItemService } from '../../services/sub-menu-item.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { MenuItemService } from '../../services/menu-item.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-menu-item-details',
    templateUrl: './menu-item-details.component.html',
    styleUrls: ['./menu-item-details.component.css']
})
export class MenuItemDetailsComponent implements OnInit /*, OnDestroy*/ {
    public selectedMenuItem: any;
    public displayedColumns = ['name', 'description', 'category', 'actionRemove'];
    public sidesDisplayedColumns = ['name', 'description', 'price', 'actionAdd'];
    public sideItems: any;
    public allItemsInMeal: any = [];
    public organizationId: number;
    private subscription = new Subscription();
    public showMatLoader: boolean = false;

   constructor(private location: Location,
               private subMenuItemService: SubMenuItemService,
               private router: Router,
               private authenticationService: AuthenticationService,
               private menuItemService: MenuItemService) { }

    ngOnInit() {
       this.selectedMenuItem = this.menuItemService.getSelectedItem();
       if (this.selectedMenuItem.subMenuItems.length > 0) {
           this.allItemsInMeal = [...[this.selectedMenuItem], ...this.selectedMenuItem.subMenuItems];
       } else {
           this.allItemsInMeal.push(this.selectedMenuItem);
       }
       this.organizationId = this.authenticationService.getOrganizationId();
       const subSubscription = this.subMenuItemService.getSubMenuItems(this.organizationId).subscribe((subMenuItems) => {
         if (this.selectedMenuItem.subMenuItems.length > 0) {
             const itemSubMenu = this.selectedMenuItem.subMenuItems;
             for (let i = subMenuItems.length - 1; i >= 0; i--) {
                 for (let j = 0; j < itemSubMenu.length; j++) {
                     if (subMenuItems[i] && (subMenuItems[i].id === itemSubMenu[j].id)) {
                         subMenuItems.splice(i, 1);
                     }
                 }
             }
             this.sideItems = subMenuItems;
         } else {
             this.sideItems = subMenuItems;
         }
       });
       this.subscription.add(subSubscription);
    }

    onAddSideClicked(selected) {
       const addedItem = [selected];
       this.sideItems.forEach( (item, index) => {
          if (item.id === selected.id) {
            this.sideItems.splice(index, 1);
          }
       });
       this.sideItems = [...this.sideItems];
       this.allItemsInMeal = [...this.allItemsInMeal, ...addedItem];
    }

    removeAddedSideItem(selected) {
       this.allItemsInMeal.forEach( (item, index) => {
          if (item.id === selected.id) {
              this.allItemsInMeal.splice(index, 1);
          }
       });
       this.allItemsInMeal = [...this.allItemsInMeal];
       const addedBackToSide = [selected];
       this.sideItems = [...this.sideItems, ...addedBackToSide];
    }

    home() {
        this.router.navigate(['/home']);
    }

    saveSidesToMeal() {
        this.showMatLoader = true;
       this.allItemsInMeal.splice(0, 1);
       this.selectedMenuItem.subMenuItems = this.allItemsInMeal;
       const menuSubscription = this.menuItemService.saveMenuItem(this.selectedMenuItem, null).subscribe((saved) => {
        this.showMatLoader = false;
           this.router.navigate(['/menuItem']);
       });
       this.subscription.add(menuSubscription);
    }

    goBackToMenuItems() {
        this.router.navigate(['/menuItem']);
    }

    // ngOnDestroy() {
    //    this.subscription.unsubscribe();
    // }
}

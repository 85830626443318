import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Receipt } from '../models/Receipt';
import { Observable } from 'rxjs';
import { Constants } from '../models/Constants';
import { ReceiptRequest } from '../models/ReceiptRequest';
import {SearchParams} from '../models/SearchParams';
import {MatTableDataSource} from '@angular/material/table';
import {OrderItem} from '../models/OrderItem';
import {OrderService} from './order.service';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {

  private receipt: Receipt;
  private receiptRequest: ReceiptRequest;
  private receipts: Array<Receipt> = [];
  private receiptDataSource: any;

  private receiptTotal = 0;
  private receiptTax = 0;

  constructor(private httpClient: HttpClient, private orderService: OrderService) { }

  getInvoice(receiptRequest: ReceiptRequest): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/receipt/invoice', receiptRequest);
  }

  calculateReceiptTotal() {
    this.ReceiptTotal = 0;
    this.ReceiptTax = 0;

    for(let i = 0; i < this.Receipts.length; i++) {
      this.ReceiptTotal = this.ReceiptTotal + (this.Receipts[i].netto);
      this.ReceiptTax = this.ReceiptTax + (this.Receipts[i].tax);
    }

  }

  set ReceiptTotal(receiptTotal: number){
    this.receiptTotal = receiptTotal;
  }

  get ReceiptTotal(){
    return this.receiptTotal;
  }

  set ReceiptTax(receiptTax: number){
    this.receiptTax = receiptTax;
  }

  get ReceiptTax(){
    return this.receiptTax;
  }

  saveReceipt(receipt: Receipt): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/receipt', receipt);
  }

  updateReceipts(receipt: Receipt): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/receipt/batchclose', receipt);
  }

  findAndUpdateTipStatus(receipt: Receipt): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/receipt/findUpdateTip', receipt);
  }

  findAndUpdateVoidedStatus(receipt: Receipt): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/receipt/findUpdateVoidedStatus', receipt);
  }

  getReceipts(organizationId: number): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/receipts?org=' + `${organizationId}`);
  }

  getTodaysReceipts(organizationId: number): Observable<Array<Receipt>> {
    return this.httpClient.get<Array<Receipt>>(Constants.ERESTAU_DOMAIN + '/api/receipts/today?org=' + `${organizationId}`);
  }

  getReceiptsByDate(searchParams: SearchParams): Observable<Array<Receipt>> {
    return this.httpClient.post<Array<Receipt>>(Constants.ERESTAU_DOMAIN + '/api/receipts/search', searchParams);
  }

  deleteReceipt(receiptId: number) {
    return this.httpClient.delete<any>(Constants.ERESTAU_DOMAIN + '/api/receipt/' + `${receiptId}`);
  }

  smsReceipt(receipt: Receipt): Observable<Array<Receipt>> {
    return this.httpClient.post<Array<Receipt>>(Constants.ERESTAU_DOMAIN + '/api/receipts/sms', receipt);
  }

  emailReceipt(receipt: Receipt): Observable<Array<Receipt>> {
    return this.httpClient.post<Array<Receipt>>(Constants.ERESTAU_DOMAIN + '/api/receipts/email', receipt);
  }

  tipAdjust(receipt: Receipt): Observable<Array<Receipt>> {
    return this.httpClient.post<Array<Receipt>>(Constants.ERESTAU_DOMAIN + '/api/payments/tipadjust', receipt);
  }

  printReceiptCopy(receipt: Receipt): Observable<any> {
    return this.httpClient.post<Array<Receipt>>(Constants.ERESTAU_DOMAIN + '/api/receipt/printCopy', receipt);
  }

  updateReceiptStatus(receiptId: number, status: string) {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/receipt/' + `${receiptId}` + '/updatestatus/' + `${status}`);
  }

  getSalesReport(organizationId: number): Observable<any> {
    return this.httpClient.get<any>(Constants.ERESTAU_DOMAIN + '/api/sales/report/' + `${organizationId}`);
  }

  getBarChartData(searchParams: SearchParams): Observable<any> {
    return this.httpClient.post<any>(Constants.ERESTAU_DOMAIN + '/api/report/barchart', searchParams);
  }

  // getUnNestedReceipt(receipt: Receipt): Receipt {
  //   // const rec = new Receipt();
  //   //
  //   // if (receipt.user) {
  //   //   rec.user = receipt.user;
  //   // }
  //   //
  //   // if (receipt.customer) {
  //   //   rec.customer = receipt.customer;
  //   // }
  //   //
  //   // if (receipt.signedBy) {
  //   //   rec.signedBy = receipt.signedBy;
  //   // }
  //
  //   if (receipt.orderItems) {
  //     for (let i = 0; i < receipt.orderItems.length; i++) {
  //       receipt.orderItems[i].receipt = null;
  //     }
  //   }
  //
  //   // rec.id = receipt.id;
  //   // rec.receiptNo = receipt.receiptNo;
  //   // rec.updated = receipt.updated;
  //   // rec.discount = receipt.discount;
  //   // rec.signed = receipt.signed;
  //   // rec.paymentType = receipt.paymentType;
  //   // rec.cashAmount = receipt.cashAmount;
  //   // rec.cardAmount = receipt.cardAmount;
  //   // rec.tipAmount = receipt.tipAmount;
  //   // rec.mobileMoneyAmount = receipt.mobileMoneyAmount;
  //   // rec.totalAsString = receipt.totalAsString;
  //   // rec.total = receipt.total;
  //   // rec.nettoAsString = receipt.nettoAsString;
  //   // rec.netto = receipt.netto;
  //   // rec.tax = receipt.tax;
  //   // rec.street = receipt.street;
  //   // rec.businessName = receipt.businessName;
  //   // rec.phoneNumber = receipt.phoneNumber;
  //   // rec.city = receipt.city;
  //   // rec.state = receipt.state;
  //   // rec.zipCode = receipt.zipCode;
  //   // rec.taxIncluded = receipt.taxIncluded;
  //   // rec.currencySymbol = receipt.currencySymbol;
  //   // rec.allSubUsers = receipt.allSubUsers;
  //   // rec.amountPaid = receipt.amountPaid;
  //   // rec.changeAmount = receipt.changeAmount;
  //   // rec.paymentReceived = receipt.paymentReceived;
  //   // rec.paymentRef = receipt.paymentRef;
  //   // rec.paymentAuthCode = receipt.paymentAuthCode;
  //   // rec.bogusAccountNum = receipt.bogusAccountNum;
  //   // rec.cardType = receipt.cardType;
  //   // rec.uid = receipt.uid;
  //   // rec.taxNbr = receipt.taxNbr;
  //   // rec.status = receipt.status;
  //   // rec.organizationId = receipt.organizationId;
  //   // rec.customerPhoneNumber = receipt.customerPhoneNumber;
  //   // rec.customerEmail = receipt.customerEmail;
  //   // rec.terminal = receipt.terminal;
  //   // return rec;
  //   console.log(receipt);
  //   return receipt;
  // }

  set ReceiptRequest(receiptRequest: ReceiptRequest) {
    this.receiptRequest = receiptRequest;
  }

  get ReceiptRequest() {
    return this.receiptRequest;
  }

  set Receipts(receipts: Array<Receipt>) {
    this.receipts = receipts;
  }

  get Receipts() {
    return this.receipts;
  }

  set ReceiptDataSource(receipts: Array<Receipt>) {
    this.receiptDataSource = new MatTableDataSource<Receipt>(receipts);
  }

  get ReceiptDataSource() {
    return this.receiptDataSource;
  }
}
